import {
  ContractEntityLegalContractTypeEnum,
  EmployeeEntity,
  EmployeeToSeasonEntityStatusTypeEnum,
} from 'api/generated';
import { addDays, addMinutes } from 'date-fns';
import { t } from "i18next";
import { IDFormData } from "page-sections/employees/IDDocuments";
import * as ExcelJS from 'exceljs';


export const searchByName = (listData: any[], searchValue: string) => {

  const lowerSearchValue = searchValue.toLowerCase();
  const fields = ['firstName', 'lastName', 'email'];

  // Filtra la lista solo se il valore di ricerca è presente
  const searchResult = searchValue.length > 0
    ? listData.filter(item => {
      // Normal fields
      // Normal fields
      const normalFieldMatch = fields.some(field =>
        item[field]?.toLowerCase().includes(lowerSearchValue)
      );

      // Name
      const nameMatch = item.name?.toLowerCase().includes(lowerSearchValue);

      // Departments and Segments
      const departmentMatch = item.employeesToDepartments?.some((departmentEntry: any) =>
        departmentEntry.department.name.toLowerCase().includes(lowerSearchValue) ||
        departmentEntry.department.segment?.name.toLowerCase().includes(lowerSearchValue)
      );


      // Workpositions
      const workPositionMatch = item.employeesToDepartments?.some((workPosition: any) =>
        workPosition.workPosition.name.toLowerCase().includes(lowerSearchValue)
      );

      // Status (with null check)
      const statusMatch = item.employeesToSeasons?.at(0)?.statusType === 'CUSTOM'
        ? item.employeesToSeasons?.at(0)?.status?.name.toLowerCase().includes(lowerSearchValue)
        : translateStatus(item.employeesToSeasons?.at(0)?.statusType).name.toLowerCase().includes(lowerSearchValue);
      // Building (with null check for room)
      const buildingMatch = item.room
        ? item.room.building.name.toLowerCase().includes(lowerSearchValue)
        : false;

      // Room (with null check for room)
      const roomMatch = item.room
        ? item.room.name.toLowerCase().includes(lowerSearchValue)
        : false;

      // Shift template
      const shiftTemplateMatch = item?.description?.toLowerCase().includes(lowerSearchValue);
      return normalFieldMatch || nameMatch || departmentMatch || workPositionMatch || statusMatch || buildingMatch || roomMatch || shiftTemplateMatch;
    })
    : listData;



  return searchResult;
};
export const searchDocumentByName = (listData: IDFormData[], searchValue: string) => {
  const lowerSearchValue = searchValue.toLowerCase();
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item, index) => {
      // saving index to have it as a reference in filtred data
      item.index = index;
      if (!item.documentName)
        item.documentName = ""
      // concat document name and typename and look for the search value
      return item.documentName.concat(item.typeName).toLowerCase().includes(lowerSearchValue);
    })
    return searchResult;
  }

  return listData.map((item, index) => { item.index = index; return item });


}
export const getRoute = (pathname: string) => {
  const str = pathname.split('/');
  return `/${str[1]}`;
};
export const translateStatus = (statusType: EmployeeToSeasonEntityStatusTypeEnum) => {
  switch (statusType) {
    case 'ACTIVE':
      return { name: t('status.ACTIVE'), color: "#1fd14c" };
    case 'CANDIDATE':
      return { name: t('status.Candidate'), color: '#030fff' };
    case 'TERMINATED':
      return { name: t('status.Terminated'), color: '#ff0303' };
    default:
      return { name: 'Custom', color: '#54576e' };
  }
};

export const translateContractType = (contractType: ContractEntityLegalContractTypeEnum) => {
  switch (contractType) {
    case "LIMITED":
      return t("employees.contract.types.limited")
    case "UNLIMITED":
      return t("employees.contract.types.unlimited")
    case "INTERNSHIP":
      return t("employees.contract.types.internship")
    case "CALL":
      return t("employees.contract.types.call")
    case "APPRENTICESHIP":
      return t("employees.contract.types.apprenticeship")
    default:
      return t("employees.generalInformation.notAssigned")
  };

};


export function formatValue(value: number, currencySymbol: string = '€'): string {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  if (value >= 1_000_000) {
    return formatter.format(value / 1_000_000) + 'M';
  } else if (value >= 1_000) {
    return formatter.format(value / 1_000) + 'K';
  }

  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value) + ` ${currencySymbol}`;
}

export function formatToItalianNumber(value: number): string {
  return new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

}

export function fadeColor(hex: string | undefined, fadeAmount: number = 0.7): string {
  if (!hex) return '#FFFFFF'
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the new r, g, b values by fading them towards white (255,255,255)
  r += Math.round((255 - r) * fadeAmount);
  g += Math.round((255 - g) * fadeAmount);
  b += Math.round((255 - b) * fadeAmount);

  // Ensure that the values are in the correct range
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert r, g, b back to hex and return the result
  const fadedHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  return fadedHex;
}

export const ignoreUTC = (value: Date, toView?: boolean) => {  
  let date = new Date(+value);
  if (!toView)
    date = addMinutes(date, - date.getTimezoneOffset());
  else
    date = addMinutes(date, + date.getTimezoneOffset());
  return date;
};

interface EmployeeNameWithId {
  id: number,
  fullName: string,
  showId: boolean,
  statusType?: EmployeeToSeasonEntityStatusTypeEnum
}

export const getFullNameWithIdConditionally: (employees: EmployeeEntity[]) => EmployeeNameWithId[] = (employees: EmployeeEntity[]) => {
  const nameCount = new Map();

  employees.forEach(employee => {
    const fullName = `${employee.firstName} ${employee.lastName}`;
    nameCount.set(fullName, (nameCount.get(fullName) || 0) + 1);
  });

  return employees.map(employee => {
    const fullName = `${employee.firstName} ${employee.lastName}`;
    const showId = nameCount.get(fullName) > 1;
    return {
      id: employee.id,
      fullName,
      showId,
      statusType: employee.employeesToSeasons?.at(0)?.statusType
    };
  });
};

export const getDates = (start: Date, end: Date) => {
  if (start <= end) {
    const dates: Date[] = []
    let date = new Date(start);
    while (date.getTime() <= end.getTime()) {
      dates.push(date)
      date = addDays(date, 1);
    }
    return dates;
  }
  return [];
};

export const addLogoImageToReport = async (worksheet: ExcelJS.Worksheet, workbook: ExcelJS.Workbook, width?: number, height?: number) => {
  const imageSrc = "/static/logo/logo_onestaff_new.png";
  const response = await fetch(imageSrc);
  const buffer = await response.arrayBuffer();
  const logo = workbook.addImage({
    buffer,
    extension: 'png',
  });
  worksheet.addImage(logo, {
    tl: { col: 0.3, row: 0.3 },  // Coordinates
    ext: { width: width || 60, height: height || 40 } // Image size
  });
}

export const reportAddTitleRow = (worksheet: ExcelJS.Worksheet, columnsNumber: number, title: string) => {
  const titleRow = worksheet.getRow(1);
  const lastTitleRowCell = titleRow.getCell(columnsNumber - 1);
  lastTitleRowCell.merge(titleRow.getCell(1));
  titleRow.getCell(1).value = title;
  titleRow.height = 30;
  titleRow.font =
  {
    name: 'Arial',
    family: 2,
    size: 14,
    bold: true,
  };
}

export const reportCreateWorkbook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'OneStaff';
  workbook.lastModifiedBy = 'OneStaff';
  workbook.created = new Date();
  return workbook;
}

export const reportCreateWoksheet = (workbook: ExcelJS.Workbook, defaultColWidth?: number, defaultRowHeight?: number) => {
  const worksheet = workbook.addWorksheet(t('turnover.reportName'));
  worksheet.properties.defaultColWidth = defaultColWidth ?? 35;
  worksheet.properties.defaultRowHeight = defaultRowHeight ?? 20;
  return worksheet;
}

export const downloadReport = async (workbook: ExcelJS.Workbook) => {

  workbook.xlsx.writeBuffer().then(function (buffer: ArrayBuffer) {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element
    const downloadLink = document.createElement('a');

    // Use the blob to set the href for the link
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;

    // Set the download attribute for the link to specify the filename
    downloadLink.download = '.report.xlsx';

    // Append the link to the document, trigger a click, and then remove it
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
}
export const getColorFromId = (id: number): string => {
  const colors = [
    "#6C757D", // Cool Gray
    "#17A2B8", // Teal
    "#28A745", // Green
    "#FFC107", // Amber
    "#DC3545", // Red
    "#007BFF", // Blue
    "#6610F2", // Indigo
    "#20C997", // Cyan
    "#E83E8C", // Pink
    "#FD7E14", // Orange
];
  return colors[id % colors.length];
};