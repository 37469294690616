import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';



const AppBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: (theme as any).color,
  }
}));

export default AppBadge;