import { FC, useMemo } from 'react';
import DataTable from './DataTable';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip } from '@mui/material';
import { AddAgreementFormData } from './dataTableV2/AddAgreementModal';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import { decryptData } from 'utils/encryptionDecryptionAgreements';
import { DaysOfTheWeek } from 'components/input-fields/DaysOfWeekPicker';

interface DataTableProps {
  data?: any[];
  clearFilter?: string;
  onFilterChange?: (filters: any) => void;
  handleRowSelect: (rows: []) => void;
  editAgreementCallback: (id: number, data?: AddAgreementFormData | null) => void;
  encryptionKey?: string;
}

const AgreementsDataTable: FC<DataTableProps> = (props) => {
  const { t } = useTranslation();
  const { encryptionKey } = useEncryption();
  const { setPopoverOpen } = useEncryption();
  function handleModfiyAgreement(agreementData: AddAgreementFormData, id: number) {
    props.editAgreementCallback(id, agreementData);
  };

  const getColumnShape = (data: any) => {
    return [
      {
        Header: ' ',
        Cell: ({ row }: any) => {
          const id = Number(row.id) + 1;
          return (<>

            {id}

          </>)
        }
      },

      {
        Header: t('employees.agreements.field.startDate'),
        Cell: ({ row }: any) => {
          const startDate = new Date(row.original.startedOn);
          return startDate.toLocaleDateString('it-IT');
        }
      },

      {
        Header: t('employees.agreements.field.endDate'),
        Cell: ({ row }: any) => {
          if (row.original.endedOn) {
            const endDate = new Date(row.original.endedOn);
            return endDate.toLocaleDateString('it-IT');
          }
          return <div style={{ fontStyle: 'italic' }}>{t('employees.contract.types.unlimited')}</div>;
        }
      },

      {
        Header: t('employees.agreements.field.net'),
        Cell: ({ row }: any) => {
          const agreement = row.original as AddAgreementFormData;

          if (agreement.net_daily_encrypted) {
            const net = decryptData(agreement.net_daily_encrypted, encryptionKey);
            return net !== '' ?
              `${parseFloat(String(net)).toFixed(2)} €`
              :
              <Tooltip title={t('encryption.tooltip')} arrow>
                <Box
                  style={{ width: '50%', cursor: 'pointer' }}
                  onClick={() => {
                    if (encryptionKey === '') {
                      setPopoverOpen(true);
                    }
                  }}
                >
                  <span style={{ filter: 'blur(2px)', opacity: 1 }}>
                    ・・・  €
                  </span>
                </Box>
              </Tooltip>

          }
          return `${parseFloat(String(agreement.net_daily)).toFixed(2)} €`;
        },
      },

      {
        Header: t('employees.agreements.field.daysOff'),
        Cell: ({ row }: any) => {
          const agreement = row.original as AddAgreementFormData;
          
          // To order the days
          const daysOfWeekOrder = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
          const sortedDays = (agreement.freeDays as unknown as DaysOfTheWeek[]).sort((a, b) => {
            return daysOfWeekOrder.indexOf(a) - daysOfWeekOrder.indexOf(b);
          });

          let freeDaysText = ""
          for (const day of sortedDays){
            freeDaysText += t('employees.agreements.day.' + day)
            freeDaysText += ", "
          }
          freeDaysText = freeDaysText.slice(0, -2)
          return (<Tooltip title={freeDaysText}>
            <div>
              {agreement.daysOff}
            </div>
          </Tooltip>
          );
        },
      },
      {
        Header: t('employees.agreements.field.downpayment'),
        Cell: ({ row }: any) => {
          const agreement = row.original as AddAgreementFormData;
          return `${parseFloat(String(agreement.downpayment)).toFixed(2)} €`;
        },
      },


      {
        Header: t('employees.agreements.dataTable.modify'),
        Cell: ({ row }: any) => {
          const agreement = row.original as AddAgreementFormData;
          let isEncrypted = false;
          if (agreement.net_daily_encrypted) {
            const net = decryptData(agreement.net_daily_encrypted, encryptionKey);
            isEncrypted = net === '';
          }
          return (
            <>
              <Button
                disabled={isEncrypted}
                onClick={() => {
                  handleModfiyAgreement(row.original, row.original.id)
                }}
              >
                {t('common.tables.button.editWithItem', {
                  item: t('employees.agreements.name')
                })}
              </Button>
            </>
          );
        },
      },
    ];
  };
  const columns: any = useMemo(() => getColumnShape(props.data), [t, encryptionKey]);

  return DataTable({
    ...props,
    columns,
  });
};

export default AgreementsDataTable;
