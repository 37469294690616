import { Autocomplete, Button, Grid, TextField, styled } from "@mui/material";
import { workPositionsApi } from "api";
import { WorkPositionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { useSeason } from "contexts/SeasonContext";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ModalProps {
    open: boolean;
    shiftId: number;
    departmentId: number;
    onClose: () => void;
    fetchData: () => void;
    onAddResource: (workPositionId: number, resourceNumber: number) => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 700,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AssignPositionToShiftModal: FC<ModalProps> = ({
    fetchData,
    onClose,
    open,
    shiftId,
    departmentId,
    onAddResource
}) => {

    const { t } = useTranslation();

    const [selectedWorkPosition, setSelectedWorkPosition] = useState(0);

    const [resourceNumber, setResourceNumber] = useState(1);

    const [workPositions, setWorkPositions] = useState<WorkPositionEntity[]>([]);

    const [selectError, setSelectError] = useState(false);

    const { seasonId } = useSeason();

    const fetchWorkPostions = async () => {
        setWorkPositions((await workPositionsApi.findAll(departmentId, seasonId)).data);
    }

    const create = async () => {
        if (selectedWorkPosition === 0) {
            setSelectError(true);
            return;
        }
        onAddResource(selectedWorkPosition, resourceNumber);
        setSelectedWorkPosition(0);
        setResourceNumber(1);
        onClose();
    };

    useEffect(() => {
        fetchWorkPostions();
    }, [departmentId])

    return (
        <StyledAppModal
            open={open}
            handleClose={() => {
                setSelectedWorkPosition(0);
                setResourceNumber(1);
                onClose();
            }}
        >

            <H2 marginBottom={2}>
                {t('shift.addResource')}
            </H2>

            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Autocomplete
                        fullWidth
                        onChange={(e, value) => {
                            const numberValue = Number(value?.id)
                            if (!isNaN(numberValue)) {
                                setSelectedWorkPosition(numberValue);
                                setSelectError(false);
                            }
                            else
                                setSelectedWorkPosition(0)
                        }}

                        options={workPositions.map((workPosition) => {
                            return {
                                id: workPosition.id,
                                label: workPosition.name
                            }
                        })}

                        renderInput={(params) => <TextField {...params}

                            helperText={selectError && t('shift.selectWorkPosition')}
                            error={selectError}
                            label={t('workPositions.position')} />}

                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField inputProps={{ type: 'number', min: '1' }} value={resourceNumber} onChange={(value) => {
                        const numberValue = Number(value.target.value)
                        if (!isNaN(numberValue)) {
                            setResourceNumber(numberValue);
                        }
                        else
                            setResourceNumber(1)
                    }}>
                    </TextField>
                </Grid>
            </Grid>
            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button fullWidth size='small' variant='outlined' onClick={() => {
                    onClose();
                }}>
                    {t('common.forms.button.cancel')}
                </Button>
                <Button fullWidth size='small' onClick={create} variant='contained'>
                    {t('common.forms.button.save')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )

}

export default AssignPositionToShiftModal;