import {
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    styled,
    Typography,
    Box,
    Card,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface FutureShiftUpdateModalProps {
    open: boolean;
    selectedDate?: Date;
    onClose: () => void;
    onConfirm: (choice: "single" | "all" | "future") => void;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 520,
    minWidth: 300,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),

    [theme.breakpoints.down(325)]: {
        maxWidth: "100%",
        padding: theme.spacing(3),
    },
}));

const DateHighlight = styled(Box)(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: "1rem",
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    display: "inline-block",
    backgroundColor: theme.palette.action.hover,
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
    "&.Mui-checked": {
        color: theme.palette.primary.main,
    },
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    boxShadow: theme.shadows[1],
    transition: "box-shadow 0.2s",
    "&:hover": {
        boxShadow: theme.shadows[4],
    },
}));

const AttentionText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 600,
    marginTop: theme.spacing(0.5),
    fontSize: "0.85rem",
}));

const FutureShiftUpdateModal: FC<FutureShiftUpdateModalProps> = ({
    open,
    selectedDate,
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<"single" | "all" | "future">("single");

    return (
        <StyledAppModal open={open} handleClose={onClose}>
            <Typography variant="h5" fontWeight={600}>
                {t("shift.editOccurrence")}
            </Typography>

            <StyledRadioGroup
                value={selectedOption}
                onChange={(e) => { setSelectedOption(e.target.value as "single" | "all" | "future") }}
            >
                {/* Option 1 */}
                <StyledCard>
                    <FormControlLabel
                        value="single"
                        control={<StyledRadio />}
                        label={
                            <Box>
                                <Typography variant="body1" fontWeight={600}>
                                    {t("shift.applyCurrentDate")}
                                </Typography>
                                <DateHighlight>
                                    {selectedDate?.toLocaleDateString("en-GB")}
                                </DateHighlight>
                            </Box>
                        }
                    />
                </StyledCard>

                {/* Option 2 */}
                <StyledCard>
                    <FormControlLabel
                        value="all"
                        control={<StyledRadio />}
                        label={
                            <Box>
                                <Typography variant="body1" fontWeight={600}>
                                    {t("shift.applyAllDates")}
                                </Typography>
                                <AttentionText>
                                    {t("shift.attentionImpactPastShifts")}
                                </AttentionText>
                            </Box>
                        }
                    />
                </StyledCard>

                {/* Option 3 */}
                <StyledCard>
                    <FormControlLabel
                        value="future"
                        control={<StyledRadio />}
                        label={
                            <Typography variant="body1" fontWeight={600}>
                                {t("shift.applyFutureDates")}
                            </Typography>
                        }
                    />
                </StyledCard>
            </StyledRadioGroup>

            <FlexBox justifyContent="space-between" gap={2} marginTop={2}>
                <Button
                    fullWidth
                    size="medium"
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                        textTransform: "none",
                        borderRadius: 2,
                        padding: 1.5,
                    }}
                >
                    {t("common.forms.button.cancel")}
                </Button>
                <Button
                    fullWidth
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={() => { onConfirm(selectedOption) }}
                    sx={{
                        textTransform: "none",
                        borderRadius: 2,
                        padding: 1.5,
                    }}
                >
                    {t("common.forms.button.save")}
                </Button>
            </FlexBox>
        </StyledAppModal>
    );
};

export default FutureShiftUpdateModal;
