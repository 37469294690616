import { Alert, Autocomplete, Box, Card, Grid, Tooltip, Typography, useTheme } from "@mui/material"
import { calendarEntriesShiftTemplateApi, departmentsApi, employeesApi, shiftApi, workPositionsApi } from "api"
import { DepartmentEntity, EmployeeEntity, ShiftAvailabilityRequestEntity, ShiftEntity, ShiftTemplateEntity } from "api/generated"
import AppTextField from "components/input-fields/AppTextField"
import CalendarInput from "components/input-fields/CalendarInput"
import { useSeason } from "contexts/SeasonContext"
import EmployeeCards from "page-sections/shifts/employee-card"
import ResourceColumn from "page-sections/shifts/resources"
import WorkPositionColumn from "page-sections/shifts/work-positions"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ignoreUTC } from "utils/utils"
import errorImage from "assets/images/error.png"
import PublishShiftsDatePicker from "page-sections/shifts/publishShiftDatePicker"
import { useNavigate } from "react-router-dom"
import { addDays, isSameDay } from "date-fns"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { checkTokenRole } from "utils/checkToken"
import toast, { Toaster } from "react-hot-toast"
import RequestAviability from "page-sections/shifts/requestAviability"
import { Edit, Send, FactCheck, CalendarMonth } from "@mui/icons-material"
import AddShiftModal from "page-sections/data-table/dataTableV2/AddShiftModal"
import Button from "@mui/material/Button"
import IconButtonMui from "@mui/material/IconButton"

export interface ShiftData {
    id: number
    index: number
    name: string
    width: number
    startHour: string
    endHour: string
    date?: Date
}
export interface Shift {
    id: number
    templateId?: number
    width: number
    name: string
    index: number
    startHour: string
    endHour: string
    date?: Date
}
export interface Position {
    id: number
    workPositionId: number
    firstName?: string
    lastName?: string
    employeeId?: number
    assignmentId?: number
    start?: Date
    end?: Date
    breakStartTime?: Date
    breakEndTime?: Date
    breakPaddingStart?: number
    breakPaddingEnd?: number
    date: Date
    leftPadding: number
    rightPadding: number
}

const FIXED_HEIGHT = 40

const ShiftAssignment: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { seasonId } = useSeason()
    const theme = useTheme()
    const screenWidth = window.innerWidth
    const [shifts, setShifts] = useState<ShiftData[]>([])
    const [selectedDay, setSelectedDay] = useState(ignoreUTC(new Date()))
    const [selectedEndDay, setSelectedEndDay] = useState(addDays(ignoreUTC(new Date()), 7))
    const [workPositionsIds, setWorkPositionsIds] = useState<Array<{ id: number; name: string }>>([])
    const [isWorkPositionVisibleSet, setIsWorkPositionVisibleSet] = useState(false)
    const [isWorkPositionVisible, setIsWorkPositionVisible] = useState<Record<number, boolean>>([])
    const [workPositionMaxDimension, setWorkPositionMaxDimension] = useState<Record<number, number>>([])
    const [resources, setResources] = useState<Record<number, Position[]>>([])
    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number } | null>(null)
    const [departments, setDepartments] = useState<DepartmentEntity[]>([])
    const [columns, setColumns] = useState(12)
    const [selectedPosition, setSelectedPosition] = useState<Position>()
    const [employees, setEmployees] = useState<EmployeeEntity[]>([])
    const [workedHours, setWorkedHours] = useState<Record<number, number>>([])
    const [openSendShift, setOpenSendShift] = useState(false)
    const [openAskForAviability, setOpenAskForAviability] = useState(false)
    const [multipleDays, setMultipleDays] = useState(false)
    const [countAviability, setCountAviability] = useState(true)
    const [isEditShiftModalOpen, setIsEditShiftModalOpen] = useState(false)
    const [shiftTemplateData, setShiftTemplateData] = useState<ShiftTemplateEntity>()
    const [shiftModalData, setShiftModalData] = useState<ShiftEntity>()
    const [selectedShift, setSelectedShift] = useState<Shift>()
    const [requestsHasBeenSent, setRequestsHasBeenSent] = useState(false)
    const [overlappingRequests, setOverlappingRequests] = useState<ShiftAvailabilityRequestEntity[]>([])
    const MIN_WIDTH = multipleDays ? 70 : Math.round(screenWidth / 40)

    const resetData = () => {
        setShifts([])
        setWorkPositionsIds([])
        setWorkPositionMaxDimension({})
        setResources({})
        setColumns(12)
        setIsWorkPositionVisibleSet(false)
    }

    const fetchHours = async (emps: EmployeeEntity[]) => {
        const w: Record<number, number> = {}
        for (const e of emps) {
            w[e.id] = Math.round((await shiftApi.weeklyEmployeeHours(e.id, selectedDay.toISOString(), seasonId)).data)
        }
        return w
    }

    const fetchEmployees = async () => {
        let emps: EmployeeEntity[] = []
        if (selectedPosition) {
            emps = (
                await employeesApi.findAssignableEmployees({
                    params: {
                        seasonId,
                        workPositionId: selectedPosition?.workPositionId ?? 0,
                        shiftDetailId: selectedPosition.id,
                        date: selectedPosition.date.toISOString(),
                        checkAvaibility: countAviability
                    }
                })
            ).data
            if (emps.length === 0) toast.error(t("shift.noEmployees"), { duration: 6000 })
            setEmployees(emps)
        } else {
            setEmployees([])
        }
        setWorkedHours(await fetchHours(emps))
    }

    useEffect(() => {
        setWorkPositionsVisibility()
    }, [workPositionsIds])

    const fetchTemplatesForEdit = async () => {
        if (!selectedDay || !selectedDepartment || !selectedShift) return
        const arr = multipleDays
            ? (
                await calendarEntriesShiftTemplateApi.findByMultipleDates(
                    selectedDay.toISOString(),
                    selectedEndDay.toISOString(),
                    selectedDepartment.id,
                    seasonId
                )
            ).data
            : [
                (
                    await calendarEntriesShiftTemplateApi.findByDate(
                        selectedDay.toISOString(),
                        selectedDepartment.id,
                        seasonId
                    )
                ).data
            ]
        const tmpl = arr.find(a => a.shiftTemplate?.shifts?.find(s => s.id === selectedShift.id))?.shiftTemplate
        if (!tmpl) return
        setShiftTemplateData(tmpl)
        const found = await shiftApi.findByTemplate(tmpl.id, selectedDepartment?.id, seasonId)
        setShiftModalData(found.data.find(f => f.id === selectedShift.id))
    }

    useEffect(() => {
        fetchTemplatesForEdit()
    }, [selectedShift])

    useEffect(() => {
        fetchEmployees()
    }, [selectedPosition, selectedDepartment, seasonId, countAviability])

    const fetchRequestsHasBeenSent = async () => {
        const resp = await shiftApi.hasAvailabilityBeenSent(
            selectedDay.toISOString(),
            multipleDays ? selectedEndDay.toISOString() : selectedDay.toISOString(),
            seasonId,
            selectedDepartment?.id
        )
        if (!resp.data) setCountAviability(false)
        if (multipleDays && !resp.data) {
            setOverlappingRequests(
                (
                    await shiftApi.availabilityRequestsByManager(
                        selectedDay.toISOString(),
                        selectedEndDay.toISOString(),
                        seasonId,
                        selectedDepartment?.id
                    )
                ).data
            )
        } else {
            setOverlappingRequests([])
        }
        setRequestsHasBeenSent(resp.data)
    }

    useEffect(() => {
        fetchRequestsHasBeenSent()
    }, [selectedDay, selectedEndDay, multipleDays, selectedDepartment])

    const setWorkPositionsVisibility = () => {
        if (isWorkPositionVisibleSet) return
        const vis: Record<number, boolean> = {}
        workPositionsIds.forEach(i => {
            vis[i.id] = true
        })
        setIsWorkPositionVisible(vis)
        setIsWorkPositionVisibleSet(true)
    }

    const fetchData = async () => {
        setSelectedPosition(undefined)
        if (!selectedDepartment) return
        let comp: ShiftData[] = []
        let idx = 1
        const rs: Record<number, Position[]> = {}
        const wids: Array<{ id: number; name: string }> = (await workPositionsApi.findAll(selectedDepartment.id, seasonId))
            .data
        const arr = multipleDays
            ? (
                await calendarEntriesShiftTemplateApi.findByMultipleDates(
                    selectedDay.toISOString(),
                    selectedEndDay.toISOString(),
                    selectedDepartment.id,
                    seasonId
                )
            ).data
            : [
                (
                    await calendarEntriesShiftTemplateApi.findByDate(
                        selectedDay.toISOString(),
                        selectedDepartment.id,
                        seasonId
                    )
                ).data
            ]
        // const localMax = { ...workPositionMaxDimension }
        const localMax: Record<number, number> = {};

        for (const a of arr) {
            if (!a.shiftTemplate?.shifts) continue
            comp = comp.concat(
                a.shiftTemplate.shifts.map(shift => {
                    const st = ignoreUTC(new Date(shift.startTime), true)
                    const en = ignoreUTC(new Date(shift.endTime), true)
                    wids.forEach(i => {
                        const f = shift.shiftDetails?.filter(val => val.workPositionId === i.id)
                        if (f) {
                            if (!localMax[i.id]) localMax[i.id] = f.length
                            else if (localMax[i.id] < f.length) localMax[i.id] = f.length
                        }
                        f?.forEach(det => {
                            const stime = det.startTime ? ignoreUTC(new Date(det.startTime), true) : undefined
                            const etime = det.endTime ? ignoreUTC(new Date(det.endTime), true) : undefined
                            const bs = det.breakStart ? ignoreUTC(new Date(det.breakStart), true) : undefined
                            const be = det.breakEnd ? ignoreUTC(new Date(det.breakEnd), true) : undefined
                            let bpS = bs ? bs.getHours() - (stime && +stime > +st ? stime : st).getHours() : undefined
                            let bpE = be ? be.getHours() - (stime && +stime > +st ? stime : st).getHours() : undefined
                            let lp = !stime ? 0 : -(st.getHours() - stime.getHours())
                            let rp = !etime ? 0 : en.getHours() - etime.getHours()
                            if (bpS && bs?.getHours() === stime?.getHours()) bpS++
                            if (bpE && be?.getHours() === etime?.getHours()) bpE--
                            if (etime && en.getHours() < st.getHours() && etime.getHours() > st.getHours()) rp += 24
                            if (stime && en.getHours() < st.getHours() && stime.getHours() < st.getHours() && stime.getHours() < en.getHours()) lp += 24
                            if (bpS === 0) bpS += 0.5
                            if (bpS !== undefined && bpE !== undefined && bpE - bpS < 1) {
                                if (lp > rp && lp > 0) {
                                    lp -= 0.5
                                    bpS -= 0.5
                                } else if (rp > 0) {
                                    if (bpS > 0) {
                                        rp -= 0.5
                                        bpE += 0.5
                                    } else {
                                        rp -= 1
                                        bpE += 1
                                        bpS += 0.5
                                    }
                                } else if (bpS > 1) {
                                    bpS -= 0.5
                                } else {
                                    bpS += 0.5
                                    bpE += 1
                                }
                            }
                            const fname = det.shiftAssignments?.[0]?.employee?.firstName
                            const lname = det.shiftAssignments?.[0]?.employee?.lastName
                            rs[idx]
                                ? rs[idx].push({
                                    id: det.id,
                                    workPositionId: det.workPositionId,
                                    firstName: fname,
                                    lastName: lname,
                                    assignmentId: det.shiftAssignments?.[0]?.id,
                                    employeeId: det.shiftAssignments?.[0]?.employee?.id,
                                    start: stime,
                                    end: etime,
                                    breakStartTime: bs,
                                    breakEndTime: be,
                                    breakPaddingStart: bpS,
                                    breakPaddingEnd: bpE,
                                    date: ignoreUTC(new Date(a.startedOn), false),
                                    leftPadding: lp,
                                    rightPadding: rp
                                })
                                : (rs[idx] = [
                                    {
                                        id: det.id,
                                        workPositionId: det.workPositionId,
                                        firstName: fname,
                                        lastName: lname,
                                        assignmentId: det.shiftAssignments?.[0]?.id,
                                        employeeId: det.shiftAssignments?.[0]?.employee?.id,
                                        start: stime,
                                        end: etime,
                                        breakStartTime: bs,
                                        breakEndTime: be,
                                        breakPaddingStart: bpS,
                                        breakPaddingEnd: bpE,
                                        date: ignoreUTC(new Date(a.startedOn), false),
                                        leftPadding: lp,
                                        rightPadding: rp
                                    }
                                ])
                        })
                    })
                    const w = +en > +st ? en.getHours() - st.getHours() : en.getHours() - st.getHours() + 24
                    return {
                        id: shift.id,
                        templateId: a.shiftTemplate?.id,
                        width: w > 2 ? w : 2,
                        name: shift.name,
                        index: idx++,
                        startHour: st.toLocaleTimeString("it-IT", { hour: "numeric", minute: "numeric" }),
                        endHour: en.toLocaleTimeString("it-IT", { hour: "numeric", minute: "numeric" }),
                        date: ignoreUTC(new Date(a.startedOn), false)
                    }
                })
            )
        }
        if (comp.length === 0) {
            resetData()
            return
        }
        setWorkPositionMaxDimension(localMax)
        setShifts(comp)
        setWorkPositionsIds(wids)
        setResources(rs)
        setWorkedHours(await fetchHours(employees))
    }

    const fetchDepartemnts = async () => {
        const role = checkTokenRole()
        const d =
            role === "DEPCHIEF"
                ? (await departmentsApi.findWhereIsBoss(seasonId)).data
                : (await departmentsApi.findAll()).data
        setDepartments(d)
        setSelectedDepartment(
            d[0]
                ? {
                    id: d[0].id,
                    label: d[0].name
                }
                : null
        )
    }

    useEffect(() => {
        setIsWorkPositionVisibleSet(false)
        fetchData()
    }, [selectedDepartment, selectedDay, selectedEndDay, multipleDays, seasonId])

    useEffect(() => {
        fetchDepartemnts()
    }, [seasonId])

    useEffect(() => {
        setColumns(shifts.map(s => s.width).reduce((a, b) => a + b, 0) + 2)
    }, [shifts])

    const sendShifts = async (startDate: Date, endDate: Date, mode: string) => {
        await shiftApi.sendShifts({
            params: {
                startDate,
                endDate,
                seasonId,
                departmentId: selectedDepartment && mode === "department" ? +selectedDepartment.id : undefined
            }
        })
    }

    const requestAviability = async (startDate: Date, endDate: Date, mode: string) => {
        await shiftApi
            .createAviabilityRequests({
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                seasonId,
                departmentId: selectedDepartment && mode === "department" ? +selectedDepartment.id : undefined
            })
            .then(() => {
                toast.success(t("shift.availabilityRequestSuccess"))
                fetchRequestsHasBeenSent()
            })
            .catch(() => {
                toast.error(t("employees.alerts.error"))
            })
    }

    const getTimeMarkers = (width: number, startH: string, endH: string) => {
        const stNum = Number(startH.split(":")[0])
        let enNum = Number(endH.split(":")[0])
        if (enNum < stNum) enNum += 24
        const inc = width >= 8 ? 2 : 3
        const times: number[] = []
        for (let i = stNum; i <= enNum; i += inc) {
            times.push(i >= 24 ? i - 24 : i)
        }
        return times
    }

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <PublishShiftsDatePicker
                onClose={() => { setOpenSendShift(false) }}
                onSubmit={sendShifts}
                department={selectedDepartment ?? { id: 0, label: "" }}
                open={openSendShift}
            />
            <RequestAviability
                onClose={() => { setOpenAskForAviability(false) }}
                onSubmit={requestAviability}
                department={selectedDepartment ?? { id: 0, label: "" }}
                open={openAskForAviability}
            />
            {shiftTemplateData && (
                <AddShiftModal
                    date={selectedShift?.date}
                    template={shiftTemplateData}
                    data={shiftModalData}
                    fetchData={fetchData}
                    onClose={() => {
                        setIsEditShiftModalOpen(false)
                        setSelectedShift(undefined)
                        fetchData()
                    }}
                    open={isEditShiftModalOpen}
                    setData={setShiftModalData}
                    departmentId={selectedDepartment?.id ?? 0}
                />
            )}
            <Grid mt={6} container spacing={1} alignItems="center" mb={2}>
                <Grid item xs={12} sm={3} display="flex" gap={1}>
                    <Tooltip title={t("common.forms.button.previous")}>
                        <IconButtonMui
                            onClick={() => {
                                setSelectedDay(addDays(selectedDay, -1))
                            }}
                            sx={{
                                color: theme.palette.primary[multipleDays ? "dark" : "main"]
                            }}
                        >
                            <ArrowBackIosIcon />
                        </IconButtonMui>
                    </Tooltip>
                    <Box flex={1}>
                        <CalendarInput
                            format="dd.MM.yyyy"
                            disableFuture={false}
                            localeText={{
                                fieldDayPlaceholder: () => t("DD"),
                                fieldMonthPlaceholder: () => t("MM"),
                                fieldYearPlaceholder: () => t("YYYY")
                            }}
                            value={selectedDay}
                            onChange={newValue => {
                                if (newValue && !isNaN(new Date(newValue).getTime())) {
                                    const utcDate = new Date(
                                        Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate())
                                    )
                                    setSelectedDay(utcDate)
                                }
                            }}
                            label={multipleDays ? t("season.startDate") : t("report.selectDate")}
                        />
                    </Box>
                    <Tooltip title={t("common.forms.button.next")}>
                        <IconButtonMui
                            onClick={() => {
                                setSelectedDay(addDays(selectedDay, 1))
                            }}
                            sx={{
                                color: theme.palette.primary[multipleDays ? "dark" : "main"]
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButtonMui>
                    </Tooltip>
                </Grid>
                {multipleDays && (
                    <Grid item xs={12} sm={3} display="flex" gap={1}>
                        <Tooltip title={t("common.forms.button.previous")}>
                            <IconButtonMui
                                onClick={() => {
                                    setSelectedEndDay(addDays(selectedEndDay, -1))
                                }}
                                sx={{
                                    color: theme.palette.primary.main
                                }}
                            >
                                <ArrowBackIosIcon />
                            </IconButtonMui>
                        </Tooltip>
                        <Box flex={1}>
                            <CalendarInput
                                format="dd.MM.yyyy"
                                disableFuture={false}
                                localeText={{
                                    fieldDayPlaceholder: () => t("DD"),
                                    fieldMonthPlaceholder: () => t("MM"),
                                    fieldYearPlaceholder: () => t("YYYY")
                                }}
                                value={selectedEndDay}
                                onChange={newValue => {
                                    if (newValue && !isNaN(new Date(newValue).getTime())) {
                                        const utcDate = new Date(
                                            Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate())
                                        )
                                        setSelectedEndDay(utcDate)
                                    }
                                }}
                                label={t("season.endDate")}
                            />
                        </Box>
                        <Tooltip title={t("common.forms.button.next")}>
                            <IconButtonMui
                                onClick={() => {
                                    setSelectedEndDay(addDays(selectedEndDay, 1))
                                }}
                                sx={{
                                    color: theme.palette.primary.main
                                }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButtonMui>
                        </Tooltip>
                    </Grid>
                )}
                <Grid item xs={12} sm={3}>
                    <Autocomplete
                        id="department-selector"
                        options={departments?.map(d => ({ label: d.name, id: d.id })) || []}
                        value={selectedDepartment}
                        onChange={(event, val) => {
                            setIsWorkPositionVisibleSet(false)
                            setSelectedDepartment(val ? { label: val.label, id: val.id } : null)
                        }}
                        renderInput={params => (
                            <AppTextField {...params} placeholder={t("department.departmentSelector.select")} label={t("department.itemName")} />
                        )}
                        isOptionEqualToValue={(opt, val) => opt.id === val?.id}
                    />
                </Grid>
                <Grid item sm={1} display="flex" gap={1} justifyContent="flex-end">
                    <Tooltip title={t("shift.multipleDays")}>
                        <IconButtonMui
                            onClick={() => {
                                setMultipleDays(!multipleDays)
                            }}
                            sx={{
                                color: multipleDays ? theme.palette.primary.main : theme.palette.grey[600]
                            }}
                        >
                            <CalendarMonth />
                        </IconButtonMui>
                    </Tooltip>
                    <Tooltip title={t("shift.checkAviability")}>
                        <IconButtonMui
                            onClick={() => {
                                if (requestsHasBeenSent) setCountAviability(!countAviability)
                            }}
                            sx={{
                                color: countAviability && requestsHasBeenSent ?
                                    theme.palette.primary.main : theme.palette.grey[600]
                            }}
                        >
                            <FactCheck />
                        </IconButtonMui>
                    </Tooltip>
                </Grid>
                <Grid item sm display="flex" justifyContent="flex-end" gap={1}>
                    <Tooltip title={t("shift.requestAviability")}>
                        <IconButtonMui
                            onClick={() => {
                                setOpenAskForAviability(true)
                            }}
                            sx={{
                                color: theme.palette.primary.main
                            }}
                        >
                            <FactCheck />
                        </IconButtonMui>
                    </Tooltip>
                    <Tooltip title={t("shift.publish")}>
                        <IconButtonMui
                            onClick={() => {
                                setOpenSendShift(true)
                            }}
                            sx={{
                                color: theme.palette.primary.main
                            }}
                        >
                            <Send />
                        </IconButtonMui>
                    </Tooltip>
                </Grid>
                {overlappingRequests.length > 0 && !requestsHasBeenSent && (
                    <Grid item xs={12}>
                        <Tooltip
                            title={
                                <Box>
                                    {overlappingRequests.map(r => {
                                        const sd = new Date(r.startDate).toLocaleDateString("it-IT")
                                        const ed = new Date(r.endDate).toLocaleDateString("it-IT")
                                        const cb = r.createdBy ? r.createdBy.firstName + " " + r.createdBy.lastName : t("common.deletedUser")
                                        return sd + " - " + ed + " " + t("employees.calendar.entry.createdBy") + " " + cb + " " + r.createdAt
                                    })}
                                </Box>
                            }
                            placement="bottom-start"
                        >
                            <Alert severity="warning">{t("shift.ovelappingRequests")}</Alert>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <Card>
                <Grid container minHeight={700}>
                    {employees.length > 0 && (
                        <Grid item sm={2} xs={12} minWidth={MIN_WIDTH}>
                            <EmployeeCards
                                data={employees.map(e => {
                                    const fullName = `${e.firstName} ${e.lastName}`.trim()
                                    return {
                                        id: e.id,
                                        name: fullName,
                                        workPosition: e.employeesToDepartments?.[0]?.workPosition?.name ?? "",
                                        workedHours: workedHours[e.id] ?? 0,
                                        contractHours: Math.round(
                                            ((e.employeesToSeasons?.[0]?.contract?.workPercentage ?? 100) / 100) * 40
                                        ),
                                        propicPath: e.filePath
                                    }
                                })}
                                selectedShiftDetail={selectedPosition}
                                fetchData={fetchData}
                            />
                        </Grid>
                    )}
                    <Grid item xs={employees.length > 0 ? 10 : 12} overflow="auto">
                        {shifts.length > 0 ? (
                            <Grid padding={1} container alignContent="stretch" columns={columns} minWidth={columns * MIN_WIDTH}>
                                <Grid
                                    item
                                    xs={2}
                                    padding={1}
                                    position="sticky"
                                    left={0}
                                    bgcolor={theme.palette.background.paper}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRight={`1px solid ${theme.palette.grey[300]}`}
                                >
                                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                        {t("menu.management.shift")}
                                    </Typography>
                                </Grid>
                                {shifts.map((shift, index) => {
                                    const hasDivider =
                                        index !== 0 &&
                                        !!shift.date &&
                                        !!shifts[index - 1].date &&
                                        !isSameDay(shift.date, shifts[index - 1].date ?? 0)
                                    const timeMarkers = getTimeMarkers(shift.width, shift.startHour, shift.endHour)
                                    return (
                                        <Grid
                                            item
                                            key={shift.index}
                                            xs={shift.width}
                                            paddingX={1}
                                            paddingY={0.5}
                                            minWidth={MIN_WIDTH}
                                            borderLeft={hasDivider ? 2 : 0}
                                            borderColor={theme.palette.primary[200]}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    padding: "4px 8px",
                                                    borderRadius: "6px",
                                                    boxShadow: `0 1px 3px ${theme.palette.grey[300]}`
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        overflow: "hidden"
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            flex: 1,
                                                            textAlign: "left",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        <Typography variant="body2" sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
                                                            {shift.name}
                                                        </Typography>
                                                    </Box>
                                                    {shift.date && (
                                                        <Box sx={{ flexShrink: 0, marginLeft: "8px", textAlign: "center", color: theme.palette.text.secondary }}>
                                                            <Typography variant="caption" sx={{ fontSize: "0.75rem", whiteSpace: "nowrap" }}>
                                                                {shift.date.toLocaleDateString("it-IT")}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Box sx={{ flexShrink: 0, marginLeft: "16px", textAlign: "right" }}>
                                                        <Typography variant="body2" sx={{ color: theme.palette.text.primary, fontSize: "0.875rem", whiteSpace: "nowrap" }}>
                                                            {shift.startHour} - {shift.endHour}
                                                        </Typography>
                                                    </Box>
                                                    <IconButtonMui
                                                        sx={{ marginLeft: "8px", color: theme.palette.primary.main }}
                                                        onClick={() => {
                                                            setIsEditShiftModalOpen(true)
                                                            setSelectedShift(shift)
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButtonMui>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        marginTop: 1,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        width: "100%",
                                                        overflowX: "hidden",
                                                        paddingX: 2 // Optional: Add some padding for spacing

                                                    }}
                                                >
                                                    {timeMarkers.map(h => (
                                                        <Typography key={h} variant="caption" sx={{ color: theme.palette.text.secondary }}>
                                                            {h.toString().padStart(2, "0")}:00
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                                <WorkPositionColumn
                                    workPositionMaxDimension={workPositionMaxDimension}
                                    workPositionsIds={workPositionsIds}
                                    columns={columns}
                                    FIXED_HEIGHT={FIXED_HEIGHT}
                                    isWorkPositionVisible={isWorkPositionVisible}
                                    setIsWorkPositionVisible={setIsWorkPositionVisible}
                                />
                                {shifts.map((shift, index) => (
                                    <ResourceColumn
                                        key={shift.index}
                                        departmentId={selectedDepartment?.id ?? 0}
                                        columns={columns}
                                        FIXED_HEIGHT={FIXED_HEIGHT}
                                        setSelectedPosition={setSelectedPosition}
                                        width={shift.width}
                                        workPositionMaxDimension={workPositionMaxDimension}
                                        workPositionsIds={workPositionsIds}
                                        resources={resources[shift.index]}
                                        fetchData={fetchData}
                                        selectedPosition={selectedPosition}
                                        isWorkPositionVisible={isWorkPositionVisible}
                                        MIN_WIDTH={MIN_WIDTH}
                                        hasDivider={
                                            index !== 0 &&
                                            !!shift.date &&
                                            !!shifts[index - 1].date &&
                                            !isSameDay(shift.date, shifts[index - 1].date ?? 0)
                                        }
                                        isMultipleDays={multipleDays}
                                        selectedDay={selectedDay}
                                    />
                                ))}
                            </Grid>
                        ) : (
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="60vh" sx={{ gap: 5 }}>
                                <img src={errorImage} alt="Loading" style={{ height: "150px" }} />
                                <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
                                    {t("shift.noShiftAssigned")}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        navigate("/dashboard/shift-template/calendar")
                                    }}
                                    sx={{ fontSize: "larger" }}
                                >
                                    {t("shift.goToCalendar")}
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default ShiftAssignment
