import { useState, FC, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import { dailyNotesApi } from 'api';
import { DailyNoteEntity } from 'api/generated';
import FlexBox from 'components/flexbox/FlexBox';
import { Tiny } from 'components/Typography';
import DeleteIcon from 'icons/DeleteIcon';
import EditIcon from '@mui/icons-material/Edit';
import { CustomDialog } from 'components/CustomDialog';
import CheckIcon from '@mui/icons-material/Check';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useTranslation } from 'react-i18next';
import { checkToken, checkTokenRole } from 'utils/checkToken';

interface NotesBubbleProps {
  noteInstance?: DailyNoteEntity;
  fetchData: () => void;
  editNoteCallback: (note: DailyNoteEntity) => void;
  disableHover?: boolean;
}

const DailyNotesBubble: FC<NotesBubbleProps> = ({ noteInstance, fetchData, editNoteCallback, disableHover = false }) => {
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isResolveDialogOpen, setIsResolveDialogOpen] = useState<boolean>(false);
  const [isUnresolveDialogOpen, setIsUnresolveDialogOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [formattedDateTime, setFormattedDateTime] = useState('');
  const [formattedDateTimeCreatedAt, setFormattedDateTimeCreatedAt] = useState('');
  const [isNoteOk, setIsNoteOk] = useState(false);
  const [text, setText] = useState('');

  const employeeId = checkToken();
  const role = checkTokenRole();

  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleResolveDialogOpen = () => {
    setIsResolveDialogOpen(true);
  };

  const handleResolveDialogClose = () => {
    setIsResolveDialogOpen(false);
  };

  const handleUnresolveDialogOpen = () => {
    setIsUnresolveDialogOpen(true);
  };

  const handleUnresolveDialogClose = () => {
    setIsUnresolveDialogOpen(false);
  };

  const handleResolveNote = () => {
    if (noteInstance?.id) {
      dailyNotesApi.resolve(String(noteInstance?.id)).then(() => {
        fetchData();
      });
    }
    handleResolveDialogClose();
  };

  const handleUnresolveNote = () => {
    if (noteInstance?.id) {
      dailyNotesApi.unresolve(String(noteInstance?.id)).then(() => {
        fetchData();
      });
    }
    handleUnresolveDialogClose();
  };



  const handleDeleteConfirmed = () => {
    deleteNote(noteInstance?.id);
    handleDeleteDialogClose();
  };

  const deleteNote = (id?: number) => {
    if (id) {
      dailyNotesApi.remove(String(id)).then(() => {
        fetchData();
      })
    }
  };

  const parseDate = (date: string) => {
    const dateObject = new Date(date);
    return (dateObject.toLocaleString());
  }

  useEffect(() => {
    const createdAtString = noteInstance?.createdAt;
    if (createdAtString) {
      setFormattedDateTimeCreatedAt(parseDate(createdAtString));
    }

    if (noteInstance?.text !== "") {
      setIsNoteOk(true); // Note in cleartext
    }

    if (noteInstance?.text) {
      setText(noteInstance?.text);
      if (noteInstance?.text === '') {
        setIsNoteOk(false); // Failed decryption
      } else {
        setIsNoteOk(true); // Successful decryption
      }
    }
  }, [noteInstance]);

  useEffect(() => {
    const editedAtString = noteInstance?.editedAt;
    if (editedAtString) {
      setFormattedDateTime(parseDate(editedAtString));
    }
  }, [noteInstance?.editedAt]);

  return (
    <Box
      position="relative"
    >
      <FlexBox gap={1.5} justifyContent={employeeId === noteInstance?.createdById ? 'end' : 'start'} position="relative"
        onMouseEnter={() => { !disableHover && setIsHovered(true) }}
        onMouseLeave={() => { setIsHovered(false) }}
      >
        <Box
          sx={{
            padding: 2,
            borderRadius: '8px',
            backgroundColor: noteInstance?.resolved ? '#076e11' : (isHovered && !disableHover ? 'primary.dark' : 'primary.main'),
          }}
        >
          <Tiny
            display='flex'
            color='white'
            fontWeight={500}
            lineHeight={1.7}
            whiteSpace={'pre-line'}
            style={!isNoteOk ? { filter: 'blur(3px)', opacity: 1 } : {}}
          >
            {noteInstance?.text ? noteInstance.text : text !== "" ? text : "・・・"}
          </Tiny>
        </Box>
        {isHovered && !disableHover && (
          <Box
            position="absolute"
            top={-14}
            right={employeeId === noteInstance?.createdById ? 0 : undefined}
            left={employeeId === noteInstance?.createdById ? undefined : 0}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            p={1}
            borderRadius="8px"
            bgcolor="rgba(92, 84, 112, 1)"
            style={{ cursor: 'pointer' }}
          >
            <DeleteIcon
              style={{ fontSize: '16px', color: 'white' }}
              onClick={handleDeleteDialogOpen}
            />
            {noteInstance && isNoteOk && (
              <EditIcon
                style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                onClick={() => {
                  editNoteCallback(noteInstance);
                }}
              />
            )}
            {!noteInstance?.resolved && role === "OWNER" && (
              <CheckIcon
                style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                onClick={handleResolveDialogOpen}
              />
            )}
            {noteInstance?.resolved && role === "OWNER" && (
              <RemoveDoneIcon
                style={{ fontSize: '16px', color: 'white', marginLeft: '8px' }}
                onClick={handleUnresolveDialogOpen}
              />
            )}


          </Box>
        )}
      </FlexBox>
      {noteInstance?.createdAt !== noteInstance?.editedAt ? (
        <Tooltip
          title={`${t('notes.createdAt')} ${formattedDateTimeCreatedAt}`}
          arrow placement='bottom-end'
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          <div>
            <Tiny mt={1} fontSize={10} fontWeight={500} textAlign={employeeId === noteInstance?.createdById ? 'right' : 'left'}>
              {noteInstance?.resolved ? `<${t('notes.resolved')}> ` : ""}
              {noteInstance?.createdAt !== noteInstance?.editedAt && !noteInstance?.resolved ? `${t('notes.editMessage')} ` : ""}
              {noteInstance?.createdBy ? (noteInstance.createdBy.firstName + ' ' + noteInstance.createdBy.lastName) : t('common.deletedUser')}
              - {formattedDateTime}
            </Tiny>
          </div>
        </Tooltip>
      ) :
        <div>
          <Tiny mt={1} fontSize={10} fontWeight={500} textAlign={employeeId === noteInstance?.createdById ? 'right' : 'left'}>
            {noteInstance?.createdBy ? noteInstance?.createdBy.firstName + ' ' + noteInstance?.createdBy.lastName : t('common.deletedUser')} - {formattedDateTime}
          </Tiny>
        </div>
      }

      <CustomDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirmed}
        title={t('notes.dialog.title')}
        content={t('notes.dialog.content')}
        confirmColor='error'
        cancelColor='primary'
        confirmText={t('notes.dialog.confirmText')}
        icon={<CheckCircleOutlinedIcon />}
      />
      <CustomDialog
        open={isResolveDialogOpen}
        onClose={handleResolveDialogOpen}
        onConfirm={handleResolveNote}
        title={t('notes.resolve.titel')}
        content={t('notes.resolve.content')}
        confirmColor='error'
        cancelColor='primary'
        confirmText={t('notes.resolve.confrimText')}
        icon={<CheckCircleOutlinedIcon />}
      />
      <CustomDialog
        open={isUnresolveDialogOpen}
        onClose={handleUnresolveDialogClose}
        onConfirm={handleUnresolveNote}
        title={t('notes.unresolve.titel')}
        content={t('notes.unresolve.content')}
        confirmColor='error'
        cancelColor='primary'
        confirmText={t('notes.unresolve.confirmText')}
        icon={<CheckCircleOutlinedIcon />}
      />

    </Box>
  );
};

export default DailyNotesBubble;
