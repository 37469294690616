import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  styled,
  useTheme,
  alpha,
  linearProgressClasses,
  Tooltip
} from '@mui/material';
import FlexBox from 'components/flexbox/FlexBox';
import { FC, useEffect, useState } from 'react';
import AvatarBadge from 'components/avatars/AvatarBadge';
import Calendar from '../../page-sections/employees/calendar/Calendar';
import { useLocation, useParams } from 'react-router-dom';
import { departmentsApi, employeesApi, calendarEntriesApi } from '../../api';
import {
  CalendarEntryEntity,
  CalendarEntryEntityTypeEnum,
  DepartmentEntity,
  EmployeeEntity
} from '../../api/generated';
import { useTranslation } from 'react-i18next';
import defaultProfileImage from '../../assets/images/default-profile.png';
import AppAvatar from 'components/avatars/AppAvatar';
import { useSeason } from 'contexts/SeasonContext';
import CustomToast from 'components/CustomToast/customToast';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import { DepartmentAssignment } from './new-employee';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { H6 } from 'components/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getFullNameWithIdConditionally } from 'utils/utils';
import { checkTokenRole } from 'utils/checkToken';
import NotificationsIcon from 'icons/NotificationsIcon';
import PendingRequestsModal from 'page-sections/calendar/PendingRequestsModal';
import AppBadge from 'components/AppBadge';
import { addDays, isBefore, isSameDay, parseISO } from 'date-fns';

export const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  marginTop: 55,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export const CoverPicWrapper = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  height: 125,
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  backgroundColor: theme.palette.background.default,
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  margin: 'auto',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'white',
  backgroundColor: theme.palette.background.default,
}));

export interface GlobalCursorState {
  cursor: any;
  label: string;
  type: CalendarEntryEntityTypeEnum | null;
  color: string;
}

interface EmployeeEntityWithStatus extends EmployeeEntity {
  calendarStatus?: number;
}

const EmployeeCalendar: FC = () => {
  const { seasonId, getSeason } = useSeason();
  const theme = useTheme();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [employeeId, setEmployeeId] = useState<number>();
  const [employee, setEmployee] = useState<EmployeeEntityWithStatus>();
  const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
  const [eventCount, setEventCount] = useState<Record<string, number>>();
  const [displayAvatar, setDisplayAvatar] = useState<string>(defaultProfileImage);
  const [employees, setEmployees] = useState<EmployeeEntityWithStatus[]>([]);
  const [showToast, setShowToast] = useState(false);
  const employeeContext = useEmployeeContext();
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeEntityWithStatus[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPendingRequests, setOpenPendingRequests] = useState(false);
  const [pendingRequests, setPendingRequests] = useState<CalendarEntryEntity[]>([]);
  const { openRequestsModal } = useParams();
  const [employeesFilters, setEmployeesFilters] = useState<string[]>([]);
  const [selectedMonday, setSelectedMonday] = useState(
    addDays(new Date().setUTCHours(0, 0, 0, 0), -new Date().getDay() + 1)
  );
  const [globalCursor, setGlobalCursor] = useState<GlobalCursorState>({
    cursor: 'default',
    label: 'default',
    type: null,
    color: 'black',
  });
  const [selectedColor, setSelectedColor] = useState('');

  const [weekDaysOff, setWeekDaysOff] = useState(0);
  const [expectedDaysOff, setExpectedDaysOff] = useState(0);
  const [isAutomatic, setIsAutomatic] = useState(false);

  const fetchPendingRequests = async () => {
    const requests = (await calendarEntriesApi.findNotAccepted(seasonId)).data;
    setPendingRequests(requests);
  };

  const fetchData = async (id?: number) => {
    let currentEmployeeId: number;
    if (state?.employeeId || employeeContext.employeeId !== -1) {
      if (!id) {
        if (state?.employeeId) {
          currentEmployeeId = state?.employeeId;
        } else {
          currentEmployeeId = employeeContext.employeeId;
        }
      } else {
        currentEmployeeId = id;
      }
      try {
        if (seasonId) {
          const data = filteredEmployees.find((x) => x.id === currentEmployeeId);
          setEmployee(data);
          setEmployeeId(currentEmployeeId);
          if (data?.filePath) {
            setDisplayAvatar(data.filePath);
          } else {
            setDisplayAvatar(defaultProfileImage);
          }
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          setShowToast(true);
        }
        setEmployee(undefined);
      }
    }
  };

  useEffect(() => {
    setEmployee(employees.find(e => e.id === employeeId));
  }, [employeeId])

  useEffect(() => {
    fetchData(employeeId);
  }, [employeeId, filteredEmployees]);

  const fetchAllEmployeesData = async () => {
    const role = checkTokenRole();
    let data: EmployeeEntityWithStatus[] = [];

    if (role === 'DEPCHIEF') {
      data = (await employeesApi.findSubordinates(seasonId)).data;
      const statuses = (
        await calendarEntriesApi.findEmployeeCalendarStatusByWeekOfSubordinates({
          seasonId,
          startDate: selectedMonday.toISOString(),
          endDate: addDays(selectedMonday, 6).toISOString(),
        })
      ).data;
      data.forEach((emp, index) => {
        emp.calendarStatus = statuses[index];
      });
    } else {
      data = (
        await employeesApi.findAll({
          params: {
            includeIsIncomplete: true,
            seasonId,
          },
        })
      ).data.filter((value) => {
        const statusType = value.employeesToSeasons?.at(-1)?.statusType;
        return statusType ? !['CANDIDATE', 'TERMINATED'].includes(statusType) : false;
      });

      const statuses = (
        await calendarEntriesApi.findEmployeeCalendarStatusByWeek({
          employeeIds: data.map((e) => e.id) ?? [],
          seasonId,
          startDate: selectedMonday.toISOString(),
          endDate: addDays(selectedMonday, 6).toISOString(),

        })
      ).data;

      data.forEach((emp, index) => {
        emp.calendarStatus = statuses[index];
      });
    }

    setEmployees(data);
  };

  useEffect(() => {
    fetchAllEmployeesData();
  }, [seasonId, selectedMonday]);

  const handleFiltersChange = () => {
    let filtered = employeesFilters.length > 0
      ? employees.filter((employee: any) =>
        employeesFilters.some((selectedDepartment) =>
          employee.employeesToDepartments.some(
            (assignment: DepartmentAssignment) => assignment.department.name === selectedDepartment
          )
        )
      )
      : employees;
    filtered = filtered.sort((a, b) => {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
      return 0;
    });
    setFilteredEmployees(filtered);
  };

  useEffect(() => {
    handleFiltersChange()
  }, [employees, employeesFilters]);

  const fetchWeekDaysOff = async () => {
    if (!employeeId)
      return;
    const weekEvents: any = (await calendarEntriesApi.findEventsPerPeriodOfTime(selectedMonday.toISOString(), addDays(selectedMonday, 6).toISOString(),
      employeeId, seasonId.toString())).data;
    setWeekDaysOff(weekEvents[CalendarEntryEntityTypeEnum.DayOff]);

    const expectedDaysOff = (await calendarEntriesApi.findWeekFreeDays(employeeId, seasonId, selectedMonday.toISOString())).data as any;
    setExpectedDaysOff(expectedDaysOff.daysOff);
    setIsAutomatic(expectedDaysOff.automatic);
  }


  useEffect(() => {
    fetchWeekDaysOff();
  }, [employeeId, selectedMonday])

  /* const fetchEmployeesStatus = async () => {
     const role = checkTokenRole();
     if (role === 'DEPCHIEF') {
       const statuses = (await calendarEntriesApi.findEmployeeCalendarStatusByWeekOfSubordinates({
         seasonId,
         startDate: selectedMonday.toISOString(),
         endDate: addDays(selectedMonday, 6).toISOString(),
       })).data;
       const [...newEmployees] = employees;
       newEmployees.forEach((e, index) => {
         e.calendarStatus = statuses[index];
       })
       setEmployees(newEmployees);
     }
     else {
       const statuses = (await calendarEntriesApi.findEmployeeCalendarStatusByWeek({
         employeeIds: employees.map((e) => e.id) ?? [],
         seasonId,
         startDate: selectedMonday.toISOString(),
         endDate: addDays(selectedMonday, 6).toISOString(),
 
       })).data;
       const [...newEmployees] = employees;
       newEmployees.forEach((e, index) => {
         e.calendarStatus = statuses[index];
       })
       setEmployees(newEmployees);
     }
   }
  
   useEffect(() => {
     fetchEmployeesStatus();
   }, []) */

  useEffect(() => {
    const fetchAllEmployeesData = async () => {
      const role = checkTokenRole();
      const response =
        role === 'DEPCHIEF'
          ? await employeesApi.findSubordinates(seasonId)
          : await employeesApi.findAll({ params: { seasonId } });

      const employeesData = response.data.filter((value: EmployeeEntity) => {
        const statusType = value.employeesToSeasons?.at(-1)?.statusType;
        return statusType ? !['CANDIDATE', 'TERMINATED'].includes(statusType) : false;
      });
      setEmployees(employeesData);
    };
    fetchAllEmployeesData();
    fetchData();
  }, [seasonId]);

  useEffect(() => {
    const role = checkTokenRole();
    (role === 'DEPCHIEF'
      ? departmentsApi.findWhereIsBoss(seasonId)
      : departmentsApi.findAll()
    ).then((data) => {
      setDepartments(data.data);
    });
  }, []);

  useEffect(() => {
    fetchPendingRequests();
  }, []);

  useEffect(() => {
    if (openRequestsModal === 'true' && pendingRequests.length > 0) {
      setOpenPendingRequests(true);
    }
  }, [pendingRequests, openRequestsModal]);

  const handleChangeCursorClick = (item: GlobalCursorState) => {
    setGlobalCursor({
      cursor: item.cursor,
      label: item.label,
      type: item.type,
      color: item.color,
    });
    setSelectedColor(item.color);
  };

  const items = [
    {
      label: t('employees.calendar.types.half_day'),
      cursor: 'pointer',
      type: CalendarEntryEntityTypeEnum.HalfDay,
      color: '#00eaff',
    },
    {
      label: t('employees.calendar.types.day_off'),
      cursor: 'pointer',
      type: CalendarEntryEntityTypeEnum.DayOff,
      color: theme.palette.warning.main,
    },
    {
      label: t('employees.calendar.types.vacation'),
      cursor: 'pointer',
      type: CalendarEntryEntityTypeEnum.Vacation,
      color: theme.palette.success.main,
    },
    {
      label: t('employees.calendar.types.ill'),
      cursor: 'pointer',
      type: CalendarEntryEntityTypeEnum.Ill,
      color: theme.palette.error.main,
    },
    {
      label: t('employees.calendar.types.unpaid_leave'),
      cursor: 'pointer',
      type: CalendarEntryEntityTypeEnum.UnpaidLeave,
      color: '#6c25be',
    },
  ];

  useEffect(() => {
    document.body.style.cursor = globalCursor.cursor;
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [globalCursor]);

  const handleToastClose = () => {
    setShowToast(false);
  };

  const changeEmployee = (direction: 'next' | 'previous') => {
    if (filteredEmployees && filteredEmployees.length > 0 && employeeId) {
      const employeeIds = filteredEmployees.map((emp) => emp.id);
      const currentIndex = employeeIds.indexOf(employeeId);
      let nextIndex: number;
      if (direction === 'next') {
        nextIndex = (currentIndex + 1) % employeeIds.length;
      } else {
        nextIndex = (currentIndex - 1 + employeeIds.length) % employeeIds.length;
      }
      setEmployeeId(employeeIds[nextIndex]);
    } else {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const convertCalendarStatusToColor = (statusNumber: number) => {
    switch (statusNumber) {
      case 0:
        return theme.palette.error.main;
      case 1:
        return '#fcd703';
      case 2:
        return theme.palette.success.main;
      case 3:
        return theme.palette.primary.purple;
      default:
        return undefined;
    }
  };

  const getSeasonMondays = () => {
    const season = getSeason();
    const result: Date[] = [];
    if (!season) return [];
    const startDate = parseISO(season.startDate);
    const endDate = new Date(season.endDate);
    let monday = addDays(startDate, -startDate.getDay() + 1);
    while (isBefore(monday, endDate)) {
      monday.setUTCHours(0);
      result.push(monday);
      monday = addDays(monday, 7);
    }
    return result;
  };


  return (
    <>
      <Box pt={2} pb={4}>
        {showToast && (
          <CustomToast
            message={t('alerts.employeeNotFoundInSeason')}
            onClose={handleToastClose}
            duration={3000}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={8000}
          onClose={() => {
            handleCloseSnackbar();
          }}
        >
          <Alert severity='error' style={{ borderRadius: '10px' }}>
            {t('alerts.noEmployeesFoundForFilters')}
          </Alert>
        </Snackbar>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <Card sx={{ padding: 3, position: 'relative' }}>
              <CoverPicWrapper>
                <img
                  width='100%'
                  height='100%'
                  alt='Team Member'
                  src='/static/background/user-cover-pic.png'
                  style={{ objectFit: 'cover' }}
                />
              </CoverPicWrapper>
              <ContentWrapper>
                <FlexBox justifyContent='center'>
                  <AvatarBadge>
                    <ImageWrapper>
                      <AppAvatar
                        src={displayAvatar}
                        style={{ width: '100%', height: '100%' }}
                        alt='Team Member'
                        sizes='large'
                      />
                    </ImageWrapper>
                  </AvatarBadge>
                </FlexBox>
                <Box mt={2}>
                  {employeeId && (
                    <Divider style={{ marginBottom: '1rem' }}>
                      {t('account.basicInformation.selectEmployee')}
                    </Divider>
                  )}
                  <Autocomplete
                    id='employee-selector'
                    disableClearable
                    options={
                      filteredEmployees
                        ? [
                          ...getFullNameWithIdConditionally(filteredEmployees).map((emp, index) => ({
                            ...emp,
                            calendarStatus: filteredEmployees[index].calendarStatus,
                          })),
                          {
                            id: 0,
                            fullName: t('account.basicInformation.selectEmployee'),
                            showId: false,
                            calendarStatus: undefined,
                          },
                        ]
                        : []
                    }
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('dashboard.employees.expiringContracts.table.employee')}
                      />
                    )}
                    onChange={(event, value) => {
                      const selectedEmployeeId = value.id;
                      employeeContext.setEmployeeId(selectedEmployeeId);
                      setEmployeeId(selectedEmployeeId);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography component='span'>
                              {option.fullName}
                              {option.showId && (
                                <Typography
                                  component='span'
                                  sx={{
                                    fontStyle: 'italic',
                                    fontSize: '0.6rem',
                                    color: 'gray',
                                  }}
                                >
                                  {' ID ' + option.id + ''}
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <AppBadge
                              theme={theme}
                              sx={{
                                '& .MuiBadge-dot': {
                                  width: '12px',
                                  height: '12px',
                                  borderRadius: '50%',
                                  transform: 'translate(0, -50%)',
                                  backgroundColor:
                                    option.calendarStatus !== undefined
                                      ? convertCalendarStatusToColor(option.calendarStatus)
                                      : 'transparent',
                                },
                              }}
                              variant='dot'
                            />
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    value={
                      employee
                        ? {
                          id: employee.id,
                          fullName: employee.firstName + ' ' + employee.lastName,
                          showId: false,
                          calendarStatus: employee.calendarStatus,
                        }
                        : {
                          id: 0,
                          fullName: t('report.selectEmployee'),
                          showId: false,
                          calendarStatus: undefined,
                        }
                    }
                  />
                  {employeeId && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                      <IconButton
                        onClick={() => {
                          changeEmployee('previous');
                        }}
                        size='small'
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <H6 fontStyle={'italic'} alignContent={'center'}>
                        {t('account.basicInformation.changeEmployee')}
                      </H6>
                      <IconButton
                        onClick={() => {
                          changeEmployee('next');
                        }}
                        size='small'
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </ContentWrapper>
              <Divider style={{ marginTop: '1rem' }}>
                {t('employees.calendar.selectWeek')}
              </Divider>
              <Autocomplete
                renderInput={(params) => <TextField {...params} />}
                options={getSeasonMondays()}
                value={selectedMonday}
                onChange={(e, value) => {
                  if (value) {
                    setSelectedMonday(value);
                  }
                }}
                isOptionEqualToValue={(option, val) => isSameDay(option, val)}
                disableClearable
                getOptionLabel={(value) => {
                  const startDateString = value.toLocaleDateString('it-IT', {
                    month: 'short',
                    day: 'numeric',
                  });
                  const endDateString = addDays(value, 6).toLocaleDateString('it-IT', {
                    month: 'short',
                    day: 'numeric',
                  });
                  return `${startDateString} - ${endDateString}`;
                }}
                renderOption={(props, option) => {
                  const startDateString = option.toLocaleDateString('it-IT', {
                    month: 'short',
                    day: 'numeric',
                  });
                  const endDateString = addDays(option, 6).toLocaleDateString('it-IT', {
                    month: 'short',
                    day: 'numeric',
                  });
                  return (
                    <li {...props}>
                      <Box
                        sx={{
                          p: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                          backgroundColor: alpha(theme.palette.primary.main, 0.05),
                          borderRadius: '4px',
                        }}
                      >
                        <Typography variant='subtitle2' color='textPrimary'>
                          {startDateString} - {endDateString}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
              />
              {employeeId && (
                <>
                  <Divider style={{ marginTop: '1rem' }}>
                    {t('account.basicInformation.filterEmployees')}
                  </Divider>
                  <Autocomplete
                    multiple
                    id='filters'
                    size='small'
                    options={departments.map((value) => value.name)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('account.basicInformation.filterPlaceholder')} />
                    )}
                    onChange={(e, value) => {
                      setEmployeesFilters(value);
                    }}
                    style={{ marginTop: '1rem' }}
                  />
                </>
              )}
            </Card>
            {
              employeeId && (
                <>

                  <Box pt={2}>
                    <Card sx={{ p: 3, position: 'relative', boxShadow: 3 }}>
                      <Typography variant='subtitle1' fontWeight={600} gutterBottom>
                        {t('employees.calendar.daysOffUsageTitle')}
                      </Typography>
                      <Box display='flex' alignItems='center' gap={1}>
                        <Chip
                          label={`${weekDaysOff}/${expectedDaysOff} ${t('employees.report.contract.daysOffGeneralTitle')}`}
                          sx={{
                            bgcolor: alpha(theme.palette.warning.main, 0.1),
                            color: convertCalendarStatusToColor(employee?.calendarStatus ?? 0),
                            fontWeight: 600,
                          }}
                        />
                        {
                          isAutomatic ? (
                            <Tooltip title={
                              t('employees.agreements.info.daysOff')
                            }>
                              <Typography display={'flex'} ml={'auto'} variant='body1' color={theme.palette.success.main}>
                                Auto
                              </Typography>
                            </Tooltip>

                          ) :
                            <Typography display={'flex'} ml={'auto'} variant='body1' color={theme.palette.warning.main}>
                              {t('employees.agreements.manual')}
                            </Typography>
                        }


                      </Box>
                      <Box mt={2}>
                        <LinearProgress
                          variant='determinate'
                          value={(weekDaysOff / expectedDaysOff) * 100 > 100 ? 100 : (weekDaysOff / expectedDaysOff) * 100}
                          sx={{
                            height: 8,
                            borderRadius: 5,
                            [`& .${linearProgressClasses.bar}`]: {
                              borderRadius: 5,
                              backgroundColor: convertCalendarStatusToColor(employee?.calendarStatus ?? 0),
                            },
                          }}
                        />
                      </Box>
                    </Card>
                  </Box>

                  <Box pt={2}>
                    <Card sx={{ padding: 3, position: 'relative' }}>
                      <Box
                        style={{
                          padding: '8px',
                          border: '1px solid',
                          borderColor: theme.palette.grey[300],
                          marginBottom: '8px',
                          borderRadius: '4px',
                        }}
                      >
                        <Grid container>
                          <Grid item xs={10}>
                            {t('employees.calendar.types.work')}:
                          </Grid>
                          <Grid item xs={2}>
                            {eventCount ? eventCount.WORK : ''}
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Box>
                  <Box pt={2}>
                    <Card sx={{ padding: 3, position: 'relative' }}>
                      <FlexBox justifyContent={'center'} flexDirection={'column'}>
                        <span style={{ marginBottom: '8px' }}>
                          {t('employees.calendar.quickSelectionTool')}
                        </span>
                        {items.map((item) => (
                          <Box
                            key={item.label}
                            onClick={() => {
                              handleChangeCursorClick(item);
                            }}
                            style={{
                              cursor: 'pointer',
                              padding: '8px',
                              border: `1px solid ${item.color}`,
                              marginBottom: '8px',
                              borderRadius: '4px',
                              backgroundColor:
                                selectedColor === item.color ? alpha(item.color, 0.2) : 'transparent',
                              transition: 'background-color 0.5s',
                            }}
                          >
                            <Grid container>
                              <Grid item xs={10}>
                                {item.label}:
                              </Grid>
                              <Grid item xs={2}>
                                {eventCount ? eventCount[item.type] : ''}
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                        {globalCursor.cursor !== 'default' && (
                          <Button
                            onClick={() => {
                              setGlobalCursor({
                                cursor: 'default',
                                label: 'default',
                                type: null,
                                color: 'black',
                              });
                              setSelectedColor('');
                            }}
                            variant='contained'
                            color='primary'
                          >
                            {t('employees.calendar.entry.resetTool')}
                          </Button>
                        )}
                      </FlexBox>
                    </Card>
                  </Box>
                </>
              )
            }

          </Grid>
          <Grid item md={9} xs={12}>
            {employee && (
              <Calendar
                employeeId={employee.id}
                cursorType={globalCursor}
                setEventCount={setEventCount}
                userMode={false}
                fetchDaysOffData={() => {
                  fetchWeekDaysOff();
                  fetchAllEmployeesData();
                }}
              />
            )}
          </Grid>
        </Grid>
        {pendingRequests.length > 0 && (
          <Fab
            variant='extended'
            color='primary'
            disabled={pendingRequests.length === 0}
            onClick={() => {
              setOpenPendingRequests(true);
            }}
            style={{ position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3) }}
          >
            <Badge badgeContent={pendingRequests.length} color='primary' sx={{ mr: 1 }}>
              <NotificationsIcon />
            </Badge>
            {t('employees.calendar.request')}
          </Fab>
        )}
        {openPendingRequests && (
          <PendingRequestsModal
            open={openPendingRequests}
            onClose={() => {
              setOpenPendingRequests(false);
            }}
            fetchData={async () => {
              await fetchPendingRequests();
            }}
            requests={pendingRequests}
          />
        )}
      </Box>
    </>
  );
};

export default EmployeeCalendar;
