// positions-accordion.tsx

import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "icons/DeleteIcon";
import AddIcon from "@mui/icons-material/Add";
import { H3 } from "components/Typography";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useSeason } from "contexts/SeasonContext";
import { workPositionsApi } from "api";
import { ShiftDetailLocal } from "page-sections/data-table/dataTableV2/AddShiftModal";
import { ShiftEntity } from "api/generated";
import { ignoreUTC } from "utils/utils";

interface WorkPositionEntity {
    id: number;
    name: string;
}

interface PositionAccordionProps {
    data: ShiftDetailLocal[];
    setData: (details: ShiftDetailLocal[]) => void;
    openAddShiftDetail: () => void;
    turnStart: Date;
    turnEnd: Date;
    departmentId: number;
    assignments: ShiftEntity[];
}

/**
 * We remove "timeZone" from timeOption to avoid local-time shifts. 
 * If you want a specific zone, you can add: { timeZone: "UTC" } or similar.
 */
const timeOption: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC"
};

const PositionAccordion: FC<PositionAccordionProps> = ({
    data,
    setData,
    openAddShiftDetail,
    turnStart,
    turnEnd,
    departmentId,
    assignments
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { seasonId } = useSeason();

    const [workPositions, setWorkPositions] = useState<WorkPositionEntity[]>([]);
    const [editableMap, setEditableMap] = useState<Map<number, boolean>>(new Map());

    // Fetch relevant positions once
    useEffect(() => {
        const fetchPositions = async () => {
            try {
                const positionIds = Array.from(new Set(data.map((d) => d.workPositionId)));
                const allWps = (await workPositionsApi.findAll(departmentId, seasonId)).data;
                setWorkPositions(allWps.filter((wp) => positionIds.includes(wp.id)));
            } catch (err) {
                console.error("Failed to load work positions", err);
            }
        };
        fetchPositions();
    }, [data, departmentId, seasonId]);

    // Ensure we have an editableMap entry for each detail
    useEffect(() => {
        const newMap = new Map(editableMap);
        data.forEach((detail) => {
            if (!newMap.has(detail.id)) {
                newMap.set(detail.id, false);
            }
        });
        setEditableMap(newMap);
    }, [data]);
    const visibleDetails = data.filter((d) => !d._isDeleted);

    const updateDetail = (detailId: number, partial: Partial<ShiftDetailLocal>) => {
        setData(data.map((d) => (d.id === detailId ? { ...d, ...partial } : d)));
    };

    const markDetailDeleted = (detailId: number) => {
        setData(data.map((d) => (d.id === detailId ? { ...d, _isDeleted: true } : d)));
    };

    return (
        <Box maxHeight={"50vh"} overflow={"auto"} paddingTop={2}>
            <Grid container paddingBottom={2} paddingTop={1} spacing={1} direction={"row-reverse"}>
                <Grid item xs={3}>
                    <Button
                        fullWidth
                        endIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => { openAddShiftDetail() }}
                    >
                        {t("shift.addResource")}
                    </Button>
                </Grid>
            </Grid>

            {visibleDetails.length > 0 &&
                workPositions.map((wp) => {
                    const items = visibleDetails.filter((i) => i.workPositionId === wp.id);

                    return (
                        <Accordion key={wp.id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignContent={"center"} justifyContent={"center"} padding={"10px"}>
                                    <Grid item xs={9} sm={11} zeroMinWidth>
                                        <H3
                                            style={{
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word",
                                                overflow: "hidden",
                                                minWidth: "0",
                                                maxWidth: "100%",
                                                display: "flex",
                                                padding: "5px 10px",
                                            }}
                                        >
                                            {wp.name}
                                        </H3>
                                    </Grid>
                                    <Grid item xs={3} sm={1}>
                                        <H3
                                            style={{
                                                textAlign: "center",
                                                display: "inline-block",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderRadius: "150px",
                                                color: "white",
                                                backgroundColor: theme.palette.primary.main,
                                                width: "70%",
                                            }}
                                        >
                                            {items.length}
                                        </H3>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>

                            <Grid
                                item
                                container
                                spacing={1}
                                xs={14}
                                columns={14}
                                alignItems={"center"}
                                minHeight={90}
                                paddingBottom={2}
                                paddingLeft={"2vw"}
                            >
                                <Grid xs={2} item>
                                    {t("shift.resource")}
                                </Grid>
                                <Grid xs={2} item>
                                    {t("shift.startTime")}
                                </Grid>
                                <Grid xs={2} item>
                                    {t("shift.endTime")}
                                </Grid>
                                <Grid xs={2} item>
                                    {t("shift.breakStart")}
                                </Grid>
                                <Grid xs={2} item>
                                    {t("shift.breakEnd")}
                                </Grid>
                                <Grid xs={2} item />
                                <Grid xs={2} item />
                            </Grid>

                            {items.map((item, index) => {
                                const editable = editableMap.get(item.id) ?? false;

                                const assignedEmployee = assignments
                                    .find((a) => a.shiftDetails?.some((detail) => detail.id === item.id))
                                    ?.shiftDetails
                                    ?.find((detail) => detail.id === item.id)
                                    ?.shiftAssignments?.[0]?.employee;

                                return (
                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                        xs={14}
                                        columns={14}
                                        key={item.id}
                                        alignItems={"center"}
                                        minHeight={90}
                                        paddingBottom={2}
                                        sx={{ borderTop: 1, borderColor: "lightgray", paddingLeft: "2vw" }}
                                    >
                                        <Grid xs={2} item>
                                            {assignedEmployee ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 12px",
                                                        borderRadius: "8px",
                                                        fontSize: "0.8rem",

                                                        backgroundColor: "rgba(76, 175, 80, 0.15)", // Light green background for clarity
                                                        color: theme.palette.success.main, // Modern success color for assigned employees
                                                        fontWeight: "bold",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {`${assignedEmployee.firstName} ${assignedEmployee.lastName}`}
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 12px",
                                                        fontSize: "0.8rem",
                                                        borderRadius: "8px",
                                                        backgroundColor: "rgba(244, 67, 54, 0.15)",
                                                        color: theme.palette.error.main,
                                                        fontStyle: "italic",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {`${wp.name} #${index + 1}`}
                                                </Box>
                                            )}
                                        </Grid>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                            <Grid item xs={2}>
                                                {!editable ? (
                                                    <Box
                                                        sx={{
                                                            padding: "8px 12px",
                                                            borderRadius: "8px",
                                                            border: 3,
                                                            borderColor: "rgba(33, 150, 243, 0.15)",
                                                            color: theme.palette.primary.main,
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            minHeight: "40px",
                                                        }}
                                                    >
                                                        {item.startTime
                                                            ? (new Date(item.startTime)).toLocaleTimeString(undefined, timeOption)
                                                            : (ignoreUTC(turnStart)).toLocaleTimeString(undefined, timeOption)}
                                                    </Box>
                                                ) : (
                                                    <TimePicker
                                                        value={
                                                            item.startTime ? ignoreUTC(new Date(item.startTime), true) : turnStart
                                                        }
                                                        onChange={(value) => {
                                                            if (value && !isNaN(+value)) {
                                                                value.setSeconds(0, 0);
                                                                updateDetail(item.id, {
                                                                    startTime: ignoreUTC(value).toISOString(),
                                                                });
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {!editable ? (
                                                    <Box
                                                        sx={{
                                                            padding: "8px 12px",
                                                            borderRadius: "8px",
                                                            border: 3,
                                                            borderColor: "rgba(33, 150, 243, 0.15)",
                                                            color: theme.palette.primary.main,
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            minHeight: "40px",
                                                        }}
                                                    >
                                                        {item.endTime
                                                            ? new Date(item.endTime).toLocaleTimeString(undefined, timeOption)
                                                            : ignoreUTC(turnEnd).toLocaleTimeString(undefined, timeOption)}
                                                    </Box>
                                                ) : (
                                                    <TimePicker
                                                        value={
                                                            item.endTime ? ignoreUTC(new Date(item.endTime), true) : turnEnd
                                                        }
                                                        onChange={(value) => {
                                                            if (value && !isNaN(+value)) {
                                                                value.setSeconds(0, 0);
                                                                updateDetail(item.id, {
                                                                    endTime: ignoreUTC(value).toISOString(),
                                                                });
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {!editable ? (
                                                    <Box
                                                        sx={{
                                                            padding: "8px 12px",
                                                            borderRadius: "8px",
                                                            border: 3,
                                                            borderColor: item.breakStart
                                                                ? "rgba(76, 175, 80, 0.15)" // Light green if value exists
                                                                : "rgba(244, 67, 54, 0.15)", // Light red if value is missing
                                                            color: item.breakStart
                                                                ? theme.palette.success.main
                                                                : theme.palette.error.main,
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            minHeight: "40px",
                                                        }}
                                                    >
                                                        {item.breakStart
                                                            ? new Date(item.breakStart).toLocaleTimeString(undefined, timeOption)
                                                            : "-"}
                                                    </Box>
                                                ) : (
                                                    <TimePicker
                                                        value={
                                                            item.breakStart ? ignoreUTC(new Date(item.breakStart), true) : null
                                                        }
                                                        onChange={(value) => {
                                                            if (value && !isNaN(+value)) {
                                                                value.setSeconds(0, 0);
                                                                value.setFullYear(1970, 0, 1);
                                                                updateDetail(item.id, {
                                                                    breakStart: ignoreUTC(value).toISOString(),
                                                                });
                                                            } else {
                                                                updateDetail(item.id, { breakStart: undefined });
                                                            }
                                                        }}
                                                        slotProps={{ actionBar: { actions: ["clear"] } }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {!editable ? (
                                                    <Box
                                                        sx={{
                                                            padding: "8px 12px",
                                                            borderRadius: "8px",
                                                            border: 3,

                                                            borderColor: item.breakEnd
                                                                ? "rgba(76, 175, 80, 0.15)"
                                                                : "rgba(244, 67, 54, 0.15)",
                                                            color: item.breakEnd
                                                                ? theme.palette.success.main
                                                                : theme.palette.error.main,
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            minHeight: "40px",
                                                        }}
                                                    >
                                                        {item.breakEnd
                                                            ? new Date(item.breakEnd).toLocaleTimeString(undefined, timeOption)
                                                            : "-"}
                                                    </Box>
                                                ) : (
                                                    <TimePicker
                                                        value={
                                                            item.breakEnd ? ignoreUTC(new Date(item.breakEnd), true) : null
                                                        }
                                                        onChange={(value) => {
                                                            if (value && !isNaN(+value)) {
                                                                value.setSeconds(0, 0);
                                                                value.setFullYear(1970, 0, 1);
                                                                updateDetail(item.id, {
                                                                    breakEnd: ignoreUTC(value).toISOString(),
                                                                });
                                                            } else {
                                                                updateDetail(item.id, { breakEnd: undefined });
                                                            }
                                                        }}
                                                        slotProps={{ actionBar: { actions: ["clear"] } }}
                                                    />
                                                )}
                                            </Grid>
                                        </LocalizationProvider>

                                        <Grid item xs={2}>
                                            {editable ? (
                                                <Button
                                                    onClick={() => {
                                                        const newMap = new Map(editableMap);
                                                        newMap.set(item.id, false);
                                                        setEditableMap(newMap);
                                                    }}
                                                >
                                                    {t("account.confirm.button")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        const newMap = new Map(editableMap);
                                                        newMap.set(item.id, true);
                                                        setEditableMap(newMap);
                                                    }}
                                                >
                                                    {t("common.forms.button.edit")}
                                                </Button>
                                            )}
                                        </Grid>

                                        <Grid item xs={2}>
                                            <IconButton
                                                onClick={() => {
                                                    markDetailDeleted(item.id);
                                                }}
                                            >
                                                <DeleteIcon sx={{ alignSelf: "center" }} color="error" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Accordion>
                    );
                })}
        </Box>
    );
};

export default PositionAccordion;
