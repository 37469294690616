/* tslint:disable */
/* eslint-disable */
/**
 * Onestaff
 * The Onestaff API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptAvaibilityData
 */
export interface AcceptAvaibilityData {
    /**
     * 
     * @type {number}
     * @memberof AcceptAvaibilityData
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof AcceptAvaibilityData
     */
    'answer': boolean;
}
/**
 * 
 * @export
 * @interface AcceptAvaibilityDto
 */
export interface AcceptAvaibilityDto {
    /**
     * 
     * @type {Array<AcceptAvaibilityData>}
     * @memberof AcceptAvaibilityDto
     */
    'array': Array<AcceptAvaibilityData>;
}
/**
 * 
 * @export
 * @interface AccountEntity
 */
export interface AccountEntity {
    /**
     * 
     * @type {number}
     * @memberof AccountEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountEntity
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AccountEntity
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AdditionalInformationsEntity
 */
export interface AdditionalInformationsEntity {
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationsEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationsEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof AdditionalInformationsEntity
     */
    'foodRequirments': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInformationsEntity
     */
    'customFoodRequirments': string;
    /**
     * 
     * @type {number}
     * @memberof AdditionalInformationsEntity
     */
    'pantsSize': number;
    /**
     * 
     * @type {object}
     * @memberof AdditionalInformationsEntity
     */
    'shirtSize': object;
    /**
     * 
     * @type {Array<LicenceEntity>}
     * @memberof AdditionalInformationsEntity
     */
    'licences'?: Array<LicenceEntity>;
    /**
     * 
     * @type {Array<EmergencyContactEntity>}
     * @memberof AdditionalInformationsEntity
     */
    'emergencyContacts'?: Array<EmergencyContactEntity>;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof AdditionalInformationsEntity
     */
    'employee'?: EmployeeEntity;
}
/**
 * 
 * @export
 * @interface AgreementEntity
 */
export interface AgreementEntity {
    /**
     * 
     * @type {number}
     * @memberof AgreementEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AgreementEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof AgreementEntity
     */
    'net_daily': number | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementEntity
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementEntity
     */
    'endedOn': string | null;
    /**
     * 
     * @type {number}
     * @memberof AgreementEntity
     */
    'daysOff': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof AgreementEntity
     */
    'freeDays': Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementEntity
     */
    'isLongTheWholeContract': boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreementEntity
     */
    'downpayment': number | null;
    /**
     * 
     * @type {string}
     * @memberof AgreementEntity
     */
    'notes': string | null;
    /**
     * 
     * @type {number}
     * @memberof AgreementEntity
     */
    'contractId': number;
    /**
     * 
     * @type {string}
     * @memberof AgreementEntity
     */
    'net_daily_encrypted': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementEntity
     */
    'createDaysAutomatically': boolean;
}
/**
 * 
 * @export
 * @interface ApplicationEntity
 */
export interface ApplicationEntity {
    /**
     * 
     * @type {number}
     * @memberof ApplicationEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationEntity
     */
    'jobAdvertisementsId': number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationEntity
     */
    'employeeDocumentId': number;
}
/**
 * 
 * @export
 * @interface AssignEmployeesToSeasonsDto
 */
export interface AssignEmployeesToSeasonsDto {
    /**
     * 
     * @type {number}
     * @memberof AssignEmployeesToSeasonsDto
     */
    'seasonId': number;
    /**
     * 
     * @type {number}
     * @memberof AssignEmployeesToSeasonsDto
     */
    'seasonToCopyFromId': number;
    /**
     * 
     * @type {Array<EmployeeOptions>}
     * @memberof AssignEmployeesToSeasonsDto
     */
    'employeesOptions': Array<EmployeeOptions>;
}
/**
 * 
 * @export
 * @interface AssignShiftTemplateDto
 */
export interface AssignShiftTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof AssignShiftTemplateDto
     */
    'templateId': number;
    /**
     * 
     * @type {string}
     * @memberof AssignShiftTemplateDto
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof AssignShiftTemplateDto
     */
    'toDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignShiftTemplateDto
     */
    'daysOfWeek': Array<string>;
}
/**
 * 
 * @export
 * @interface AssignShiftToEmployeeDto
 */
export interface AssignShiftToEmployeeDto {
    /**
     * 
     * @type {number}
     * @memberof AssignShiftToEmployeeDto
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof AssignShiftToEmployeeDto
     */
    'shiftDetailId': number;
    /**
     * 
     * @type {string}
     * @memberof AssignShiftToEmployeeDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof AssignShiftToEmployeeDto
     */
    'seasonId'?: number;
}
/**
 * 
 * @export
 * @interface BankingInformationEntity
 */
export interface BankingInformationEntity {
    /**
     * 
     * @type {number}
     * @memberof BankingInformationEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BankingInformationEntity
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof BankingInformationEntity
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof BankingInformationEntity
     */
    'bicSwift': string;
    /**
     * 
     * @type {number}
     * @memberof BankingInformationEntity
     */
    'employeeId': number;
}
/**
 * 
 * @export
 * @interface BuildingEntity
 */
export interface BuildingEntity {
    /**
     * 
     * @type {number}
     * @memberof BuildingEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingEntity
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof BuildingEntity
     */
    'cost': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingEntity
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BuildingEntity
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface CalendarEntryEntity
 */
export interface CalendarEntryEntity {
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryEntity
     */
    'type': CalendarEntryEntityTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEntryEntity
     */
    'accepted': boolean;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {EmployeeToSeasonEntity}
     * @memberof CalendarEntryEntity
     */
    'employeeToSeason'?: EmployeeToSeasonEntity;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryEntity
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryEntity
     */
    'endedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryEntity
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryEntity
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryEntity
     */
    'updatedById': number;
}

export const CalendarEntryEntityTypeEnum = {
    HalfDay: 'HALF_DAY',
    DayOff: 'DAY_OFF',
    Vacation: 'VACATION',
    Ill: 'ILL',
    UnpaidLeave: 'UNPAID_LEAVE'
} as const;

export type CalendarEntryEntityTypeEnum = typeof CalendarEntryEntityTypeEnum[keyof typeof CalendarEntryEntityTypeEnum];

/**
 * 
 * @export
 * @interface CalendarEntryShiftTemplateEntity
 */
export interface CalendarEntryShiftTemplateEntity {
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'endedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'isAutoGenerated': boolean;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'templateId': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {ShiftTemplateEntity}
     * @memberof CalendarEntryShiftTemplateEntity
     */
    'shiftTemplate'?: ShiftTemplateEntity;
}
/**
 * 
 * @export
 * @interface CommunicationEntity
 */
export interface CommunicationEntity {
    /**
     * 
     * @type {number}
     * @memberof CommunicationEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CommunicationEntity
     */
    'text': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommunicationEntity
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommunicationEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CommunicationEntity
     */
    'senderId': number;
    /**
     * 
     * @type {number}
     * @memberof CommunicationEntity
     */
    'receiverId': number;
    /**
     * 
     * @type {object}
     * @memberof CommunicationEntity
     */
    'sender': object;
}
/**
 * 
 * @export
 * @interface ContractEntity
 */
export interface ContractEntity {
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'legalContractType': ContractEntityLegalContractTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ContractEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'filePath': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractEntity
     */
    'isAllIncluded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractEntity
     */
    'isFoodIncluded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractEntity
     */
    'isPartTime': boolean;
    /**
     * 
     * @type {number}
     * @memberof ContractEntity
     */
    'workPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContractEntity
     */
    'isDraft': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'startedOn': string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'endedOn': string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'probationPeriod': string;
    /**
     * 
     * @type {number}
     * @memberof ContractEntity
     */
    'probationPeriodDays': number;
    /**
     * 
     * @type {string}
     * @memberof ContractEntity
     */
    'levelName': string;
    /**
     * 
     * @type {Array<EmployeeToSeasonEntity>}
     * @memberof ContractEntity
     */
    'employeesToSeasons'?: Array<EmployeeToSeasonEntity>;
}

export const ContractEntityLegalContractTypeEnum = {
    Limited: 'LIMITED',
    Unlimited: 'UNLIMITED',
    Internship: 'INTERNSHIP',
    Call: 'CALL',
    Apprenticeship: 'APPRENTICESHIP'
} as const;

export type ContractEntityLegalContractTypeEnum = typeof ContractEntityLegalContractTypeEnum[keyof typeof ContractEntityLegalContractTypeEnum];

/**
 * 
 * @export
 * @interface ContractTypeEntity
 */
export interface ContractTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof ContractTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContractTypeEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateAdditionalInformationDto
 */
export interface CreateAdditionalInformationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdditionalInformationDto
     */
    'foodRequirments': CreateAdditionalInformationDtoFoodRequirmentsEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAdditionalInformationDto
     */
    'shirtSize'?: CreateAdditionalInformationDtoShirtSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAdditionalInformationDto
     */
    'customFoodRequirments'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdditionalInformationDto
     */
    'pantsSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdditionalInformationDto
     */
    'typeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdditionalInformationDto
     */
    'employeeId': number;
}

export const CreateAdditionalInformationDtoFoodRequirmentsEnum = {
    Gluten: 'GLUTEN',
    Dairy: 'DAIRY',
    Egg: 'EGG',
    Fish: 'FISH',
    Soy: 'SOY',
    Peanut: 'PEANUT',
    Vegeterian: 'VEGETERIAN',
    Vegan: 'VEGAN',
    Halal: 'HALAL',
    Kosher: 'KOSHER',
    Crustacean: 'CRUSTACEAN',
    Nut: 'NUT',
    Celery: 'CELERY',
    Mustard: 'MUSTARD',
    Sesame: 'SESAME',
    Clam: 'CLAM',
    Lupine: 'LUPINE',
    Solfite: 'SOLFITE'
} as const;

export type CreateAdditionalInformationDtoFoodRequirmentsEnum = typeof CreateAdditionalInformationDtoFoodRequirmentsEnum[keyof typeof CreateAdditionalInformationDtoFoodRequirmentsEnum];
export const CreateAdditionalInformationDtoShirtSizeEnum = {
    Xxs: 'XXS',
    Xs: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    Xl: 'XL',
    Xxl: 'XXL',
    Xxxl: 'XXXL'
} as const;

export type CreateAdditionalInformationDtoShirtSizeEnum = typeof CreateAdditionalInformationDtoShirtSizeEnum[keyof typeof CreateAdditionalInformationDtoShirtSizeEnum];

/**
 * 
 * @export
 * @interface CreateAgreementDto
 */
export interface CreateAgreementDto {
    /**
     * 
     * @type {number}
     * @memberof CreateAgreementDto
     */
    'net_daily'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementDto
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementDto
     */
    'endedOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAgreementDto
     */
    'isLongTheWholeContract': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementDto
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAgreementDto
     */
    'downpayment'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateAgreementDto
     */
    'freeDays': Array<object>;
    /**
     * 
     * @type {number}
     * @memberof CreateAgreementDto
     */
    'contractId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementDto
     */
    'net_daily_encrypted'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAgreementDto
     */
    'createDaysAutomatically': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAgreementDto
     */
    'daysOff': number;
}
/**
 * 
 * @export
 * @interface CreateAvailabilityRequestsDto
 */
export interface CreateAvailabilityRequestsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityRequestsDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAvailabilityRequestsDto
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAvailabilityRequestsDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAvailabilityRequestsDto
     */
    'seasonId': number;
}
/**
 * 
 * @export
 * @interface CreateBankingInformationDto
 */
export interface CreateBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBankingInformationDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankingInformationDto
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankingInformationDto
     */
    'bicSwift': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBankingInformationDto
     */
    'employeeId': number;
}
/**
 * 
 * @export
 * @interface CreateBuildingDto
 */
export interface CreateBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBuildingDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildingDto
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBuildingDto
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildingDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuildingDto
     */
    'endDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCalendarEntryDto
 */
export interface CreateCalendarEntryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEntryDto
     */
    'type': CreateCalendarEntryDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEntryDto
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEntryDto
     */
    'endedOn': string;
}

export const CreateCalendarEntryDtoTypeEnum = {
    HalfDay: 'HALF_DAY',
    DayOff: 'DAY_OFF',
    Vacation: 'VACATION',
    Ill: 'ILL',
    UnpaidLeave: 'UNPAID_LEAVE'
} as const;

export type CreateCalendarEntryDtoTypeEnum = typeof CreateCalendarEntryDtoTypeEnum[keyof typeof CreateCalendarEntryDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateCalendarEntryShiftTemplateDto
 */
export interface CreateCalendarEntryShiftTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'startedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'endedOn': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'isAutoGenerated'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'templateId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEntryShiftTemplateDto
     */
    'seasonId': number;
}
/**
 * 
 * @export
 * @interface CreateCommunicationDto
 */
export interface CreateCommunicationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCommunicationDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCommunicationDto
     */
    'senderId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCommunicationDto
     */
    'receiverId': number;
}
/**
 * 
 * @export
 * @interface CreateContractDto
 */
export interface CreateContractDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'legalContractType': CreateContractDtoLegalContractTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'startedOn': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'endedOn': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractDto
     */
    'isAllIncluded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractDto
     */
    'isFoodIncluded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractDto
     */
    'isPartTime': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateContractDto
     */
    'workPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'probationPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateContractDto
     */
    'probationPeriodDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateContractDto
     */
    'employeeId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'levelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContractDto
     */
    'typeName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractDto
     */
    'isDraft': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateContractDto
     */
    'seasonId': number;
}

export const CreateContractDtoLegalContractTypeEnum = {
    Limited: 'LIMITED',
    Unlimited: 'UNLIMITED',
    Internship: 'INTERNSHIP',
    Call: 'CALL',
    Apprenticeship: 'APPRENTICESHIP'
} as const;

export type CreateContractDtoLegalContractTypeEnum = typeof CreateContractDtoLegalContractTypeEnum[keyof typeof CreateContractDtoLegalContractTypeEnum];

/**
 * 
 * @export
 * @interface CreateContractTypeDto
 */
export interface CreateContractTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContractTypeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateDailyNoteDto
 */
export interface CreateDailyNoteDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDailyNoteDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDailyNoteDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface CreateDepartmentDto
 */
export interface CreateDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDepartmentDto
     */
    'segmentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateDepartmentEmployeeDto
 */
export interface CreateDepartmentEmployeeDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDepartmentEmployeeDto
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDepartmentEmployeeDto
     */
    'bossEmployeeId': number | null;
}
/**
 * 
 * @export
 * @interface CreateDepositDto
 */
export interface CreateDepositDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDepositDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepositDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDepositDto
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDepositDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface CreateDocumentTypeDto
 */
export interface CreateDocumentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentTypeDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateEmergencyContactDto
 */
export interface CreateEmergencyContactDto {
    /**
     * 
     * @type {number}
     * @memberof CreateEmergencyContactDto
     */
    'additionalInformationId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactDto
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmergencyContactDto
     */
    'relation': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDocumentDto
 */
export interface CreateEmployeeDocumentDto {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDocumentDto
     */
    'employeeId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDocumentDto
     */
    'typeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDocumentDto
     */
    'expiringDate': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeDocumentDto
     */
    'isVisibleForUser': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDocumentDto
     */
    'documentName': string;
}
/**
 * 
 * @export
 * @interface CreateEmployeeDto
 */
export interface CreateEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'role': CreateEmployeeDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'gender': CreateEmployeeDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'filePath': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'dateOfBirth': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'countryOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'provinceOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'townshipOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'taxcode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeDto
     */
    'lastPayment': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeDto
     */
    'seasonId': number | null;
}

export const CreateEmployeeDtoRoleEnum = {
    User: 'USER',
    Manager: 'MANAGER',
    Owner: 'OWNER',
    Developer: 'DEVELOPER',
    Depchief: 'DEPCHIEF',
    Termsnotaccepted: 'TERMSNOTACCEPTED',
    Controller: 'CONTROLLER'
} as const;

export type CreateEmployeeDtoRoleEnum = typeof CreateEmployeeDtoRoleEnum[keyof typeof CreateEmployeeDtoRoleEnum];
export const CreateEmployeeDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Undefined: 'UNDEFINED'
} as const;

export type CreateEmployeeDtoGenderEnum = typeof CreateEmployeeDtoGenderEnum[keyof typeof CreateEmployeeDtoGenderEnum];

/**
 * 
 * @export
 * @interface CreateExperienceLevelDto
 */
export interface CreateExperienceLevelDto {
    /**
     * 
     * @type {string}
     * @memberof CreateExperienceLevelDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateJobAdDto
 */
export interface CreateJobAdDto {
    /**
     * 
     * @type {string}
     * @memberof CreateJobAdDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobAdDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateJobAdDto
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateJobAdDto
     */
    'departmentId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobAdDto
     */
    'isPublic': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateJobAdDto
     */
    'seasonId': number;
}
/**
 * 
 * @export
 * @interface CreateLicenceDto
 */
export interface CreateLicenceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLicenceDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateMyBankingInformationDto
 */
export interface CreateMyBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMyBankingInformationDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMyBankingInformationDto
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMyBankingInformationDto
     */
    'bicSwift': string;
}
/**
 * 
 * @export
 * @interface CreateNoteDto
 */
export interface CreateNoteDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteDto
     */
    'text_encrypted'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteDto
     */
    'authorId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteDto
     */
    'employeeId': number;
}
/**
 * 
 * @export
 * @interface CreateNotesDto
 */
export interface CreateNotesDto {
    /**
     * 
     * @type {number}
     * @memberof CreateNotesDto
     */
    'roomId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNotesDto
     */
    'seasonId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateNotesDto
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface CreatePaymentMethodDto
 */
export interface CreatePaymentMethodDto {
    /**
     * 
     * @type {object}
     * @memberof CreatePaymentMethodDto
     */
    'type': object;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodDto
     */
    'description': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentMethodDto
     */
    'depositable': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentMethodDto
     */
    'tenantBankingInformationId'?: number;
}
/**
 * 
 * @export
 * @interface CreatePopulatedSeasonDto
 */
export interface CreatePopulatedSeasonDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePopulatedSeasonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePopulatedSeasonDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePopulatedSeasonDto
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePopulatedSeasonDto
     */
    'seasonToCopyFromId': number;
    /**
     * 
     * @type {Array<EmployeeOptions>}
     * @memberof CreatePopulatedSeasonDto
     */
    'employeesOptions': Array<EmployeeOptions>;
}
/**
 * 
 * @export
 * @interface CreateRewardsDto
 */
export interface CreateRewardsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRewardsDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateRewardsDto
     */
    'earnedOn': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRewardsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRewardsDto
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRewardsDto
     */
    'contractId': number;
}
/**
 * 
 * @export
 * @interface CreateRoomDto
 */
export interface CreateRoomDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoomDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomDto
     */
    'buildingId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomDto
     */
    'maxTenants': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomDto
     */
    'floor'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomDto
     */
    'accessMode'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRoomDto
     */
    'bathrooms'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRoomDto
     */
    'hasKitchen'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface CreateSeasonDto
 */
export interface CreateSeasonDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSeasonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSeasonDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSeasonDto
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface CreateSectionDto
 */
export interface CreateSectionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionDto
     */
    'taxPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionDto
     */
    'departmentId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSectionDto
     */
    'onlyToBeShowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSectionDto
     */
    'stillCountsDepositable': boolean;
}
/**
 * 
 * @export
 * @interface CreateSegmentDto
 */
export interface CreateSegmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSegmentDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateShiftDetailDto
 */
export interface CreateShiftDetailDto {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailDto
     */
    'shiftId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailDto
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailDto
     */
    'seasonId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailDto
     */
    'number': number;
}
/**
 * 
 * @export
 * @interface CreateShiftDetailWithChoiceDto
 */
export interface CreateShiftDetailWithChoiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'choice': CreateShiftDetailWithChoiceDtoChoiceEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'shiftId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDetailWithChoiceDto
     */
    'seasonId': number;
}

export const CreateShiftDetailWithChoiceDtoChoiceEnum = {
    Single: 'single',
    All: 'all',
    Future: 'future'
} as const;

export type CreateShiftDetailWithChoiceDtoChoiceEnum = typeof CreateShiftDetailWithChoiceDtoChoiceEnum[keyof typeof CreateShiftDetailWithChoiceDtoChoiceEnum];

/**
 * 
 * @export
 * @interface CreateShiftDto
 */
export interface CreateShiftDto {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'templateId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftDto
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'seasonId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftDto
     */
    'departmentId': number;
}
/**
 * 
 * @export
 * @interface CreateShiftTemplateDto
 */
export interface CreateShiftTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateDto
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateDto
     */
    'repeatStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateDto
     */
    'repeatEnd'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof CreateShiftTemplateDto
     */
    'days'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateDto
     */
    'seasonId'?: number;
}
/**
 * 
 * @export
 * @interface CreateStatusChangeDto
 */
export interface CreateStatusChangeDto {
    /**
     * 
     * @type {number}
     * @memberof CreateStatusChangeDto
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStatusChangeDto
     */
    'seasonId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusChangeDto
     */
    'note'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateStatusChangeDto
     */
    'newStatustype': object;
    /**
     * 
     * @type {object}
     * @memberof CreateStatusChangeDto
     */
    'previousStatusType': object;
    /**
     * 
     * @type {number}
     * @memberof CreateStatusChangeDto
     */
    'newStatusId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStatusChangeDto
     */
    'previousStatusId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusChangeDto
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface CreateStatusDto
 */
export interface CreateStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStatusDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface CreateTenantBankingInformationDto
 */
export interface CreateTenantBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTenantBankingInformationDto
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantBankingInformationDto
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTenantBankingInformationDto
     */
    'bicSwift'?: string;
}
/**
 * 
 * @export
 * @interface CreateTurnoverItemDto
 */
export interface CreateTurnoverItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTurnoverItemDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTurnoverItemDto
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTurnoverItemDto
     */
    'paymentMethodId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTurnoverItemDto
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {object}
     * @memberof CreateTurnoverItemDto
     */
    'status': object;
}
/**
 * 
 * @export
 * @interface CreateWorkPositionDto
 */
export interface CreateWorkPositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkPositionDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkPositionDto
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkPositionDto
     */
    'bossId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkPositionDto
     */
    'desiredResources'?: number | null;
}
/**
 * 
 * @export
 * @interface DailyNoteEntity
 */
export interface DailyNoteEntity {
    /**
     * 
     * @type {string}
     * @memberof DailyNoteEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DailyNoteEntity
     */
    'editedAt': string;
    /**
     * 
     * @type {number}
     * @memberof DailyNoteEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DailyNoteEntity
     */
    'createdById': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof DailyNoteEntity
     */
    'createdBy'?: EmployeeEntity;
    /**
     * 
     * @type {string}
     * @memberof DailyNoteEntity
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof DailyNoteEntity
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof DailyNoteEntity
     */
    'resolved': boolean;
}
/**
 * 
 * @export
 * @interface DateSumEntity
 */
export interface DateSumEntity {
    /**
     * 
     * @type {string}
     * @memberof DateSumEntity
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DateSumEntity
     */
    'sum': number;
}
/**
 * 
 * @export
 * @interface DepartmentEmployeeEntity
 */
export interface DepartmentEmployeeEntity {
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof DepartmentEmployeeEntity
     */
    'employee'?: EmployeeEntity;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'bossDepartmentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'bossEmployeeId': number | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'bossSeasonId': number | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEmployeeEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentEmployeeEntity
     */
    'isDepartmentManager': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentEmployeeEntity
     */
    'canMakeTurnovers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentEmployeeEntity
     */
    'canChangeTurnoversDates': boolean;
}
/**
 * 
 * @export
 * @interface DepartmentEntity
 */
export interface DepartmentEntity {
    /**
     * 
     * @type {number}
     * @memberof DepartmentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentEntity
     */
    'segmentId': number;
    /**
     * 
     * @type {SegmentEntity}
     * @memberof DepartmentEntity
     */
    'segment'?: SegmentEntity;
}
/**
 * 
 * @export
 * @interface DepositEntity
 */
export interface DepositEntity {
    /**
     * 
     * @type {number}
     * @memberof DepositEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DepositEntity
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof DepositEntity
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof DepositEntity
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof DepositEntity
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof DepositEntity
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof DepositEntity
     */
    'updatedById': number;
    /**
     * 
     * @type {string}
     * @memberof DepositEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepositEntity
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface DocumentFileEntity
 */
export interface DocumentFileEntity {
    /**
     * 
     * @type {number}
     * @memberof DocumentFileEntity
     */
    'documentId': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileEntity
     */
    'filePath': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentFileEntity
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface DocumentTypeEntity
 */
export interface DocumentTypeEntity {
    /**
     * 
     * @type {number}
     * @memberof DocumentTypeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTypeEntity
     */
    'name': string;
    /**
     * 
     * @type {Array<UsedDocumentNameEntity>}
     * @memberof DocumentTypeEntity
     */
    'usedDocumentsNames'?: Array<UsedDocumentNameEntity>;
}
/**
 * 
 * @export
 * @interface EmergencyContactEntity
 */
export interface EmergencyContactEntity {
    /**
     * 
     * @type {number}
     * @memberof EmergencyContactEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyContactEntity
     */
    'additionalInformationId': number;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactEntity
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactEntity
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactEntity
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContactEntity
     */
    'relation': string;
}
/**
 * 
 * @export
 * @interface EmployeeDocumentEntity
 */
export interface EmployeeDocumentEntity {
    /**
     * 
     * @type {number}
     * @memberof EmployeeDocumentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDocumentEntity
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDocumentEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDocumentEntity
     */
    'typeName': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDocumentEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeDocumentEntity
     */
    'isVisibleForUser': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDocumentEntity
     */
    'expiringDate': string | null;
    /**
     * 
     * @type {Array<DocumentFileEntity>}
     * @memberof EmployeeDocumentEntity
     */
    'documentFiles'?: Array<DocumentFileEntity>;
}
/**
 * 
 * @export
 * @interface EmployeeEntity
 */
export interface EmployeeEntity {
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'role': EmployeeEntityRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'gender': EmployeeEntityGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof EmployeeEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'filePath': string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'dateOfBirth': string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'countryOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'provinceOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'townshipOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'taxcode': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'phone': string;
    /**
     * 
     * @type {BankingInformationEntity}
     * @memberof EmployeeEntity
     */
    'bankingInformation'?: BankingInformationEntity | null;
    /**
     * 
     * @type {Array<EmployeeDocumentEntity>}
     * @memberof EmployeeEntity
     */
    'documents'?: Array<EmployeeDocumentEntity> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeEntity
     */
    'isIncomplete'?: boolean;
    /**
     * 
     * @type {Array<AgreementEntity>}
     * @memberof EmployeeEntity
     */
    'agreements'?: Array<AgreementEntity> | null;
    /**
     * 
     * @type {Array<NoteEntity>}
     * @memberof EmployeeEntity
     */
    'notes'?: Array<NoteEntity> | null;
    /**
     * 
     * @type {Array<CommunicationEntity>}
     * @memberof EmployeeEntity
     */
    'communications'?: Array<CommunicationEntity> | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeEntity
     */
    'lastPayment': string | null;
    /**
     * 
     * @type {RoomEntity}
     * @memberof EmployeeEntity
     */
    'room'?: RoomEntity;
    /**
     * 
     * @type {number}
     * @memberof EmployeeEntity
     */
    'seasonId': number | null;
    /**
     * 
     * @type {Array<PaymentEntity>}
     * @memberof EmployeeEntity
     */
    'payments'?: Array<PaymentEntity> | null;
    /**
     * 
     * @type {Array<EmployeeToSeasonEntity>}
     * @memberof EmployeeEntity
     */
    'employeesToSeasons'?: Array<EmployeeToSeasonEntity> | null;
    /**
     * 
     * @type {Array<EmployeesToDepartmentsEntity>}
     * @memberof EmployeeEntity
     */
    'employeesToDepartments'?: Array<EmployeesToDepartmentsEntity> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeEntity
     */
    'canSeeDocuments': boolean;
}

export const EmployeeEntityRoleEnum = {
    User: 'USER',
    Manager: 'MANAGER',
    Owner: 'OWNER',
    Developer: 'DEVELOPER',
    Depchief: 'DEPCHIEF',
    Termsnotaccepted: 'TERMSNOTACCEPTED',
    Controller: 'CONTROLLER'
} as const;

export type EmployeeEntityRoleEnum = typeof EmployeeEntityRoleEnum[keyof typeof EmployeeEntityRoleEnum];
export const EmployeeEntityGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Undefined: 'UNDEFINED'
} as const;

export type EmployeeEntityGenderEnum = typeof EmployeeEntityGenderEnum[keyof typeof EmployeeEntityGenderEnum];

/**
 * 
 * @export
 * @interface EmployeeHoursDto
 */
export interface EmployeeHoursDto {
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof EmployeeHoursDto
     */
    'employee': EmployeeEntity;
    /**
     * 
     * @type {number}
     * @memberof EmployeeHoursDto
     */
    'totalMinutes': number;
}
/**
 * 
 * @export
 * @interface EmployeeOptions
 */
export interface EmployeeOptions {
    /**
     * 
     * @type {number}
     * @memberof EmployeeOptions
     */
    'employeeId': number;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOptions
     */
    'room': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOptions
     */
    'position': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeOptions
     */
    'status': boolean;
}
/**
 * 
 * @export
 * @interface EmployeeToSeasonEntity
 */
export interface EmployeeToSeasonEntity {
    /**
     * 
     * @type {string}
     * @memberof EmployeeToSeasonEntity
     */
    'statusType': EmployeeToSeasonEntityStatusTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EmployeeToSeasonEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeToSeasonEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof EmployeeToSeasonEntity
     */
    'employee'?: EmployeeEntity;
    /**
     * 
     * @type {number}
     * @memberof EmployeeToSeasonEntity
     */
    'roomId': number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeToSeasonEntity
     */
    'contractId': number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeToSeasonEntity
     */
    'statusId': number | null;
    /**
     * 
     * @type {ContractEntity}
     * @memberof EmployeeToSeasonEntity
     */
    'contract'?: ContractEntity | null;
    /**
     * 
     * @type {StatusEntity}
     * @memberof EmployeeToSeasonEntity
     */
    'status'?: StatusEntity | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeToSeasonEntity
     */
    'notes': string;
}

export const EmployeeToSeasonEntityStatusTypeEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Terminated: 'TERMINATED',
    Custom: 'CUSTOM'
} as const;

export type EmployeeToSeasonEntityStatusTypeEnum = typeof EmployeeToSeasonEntityStatusTypeEnum[keyof typeof EmployeeToSeasonEntityStatusTypeEnum];

/**
 * 
 * @export
 * @interface EmployeesDepartmentEntity
 */
export interface EmployeesDepartmentEntity {
    /**
     * 
     * @type {number}
     * @memberof EmployeesDepartmentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EmployeesDepartmentEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeesDepartmentEntity
     */
    'segmentId': number;
    /**
     * 
     * @type {SegmentEntity}
     * @memberof EmployeesDepartmentEntity
     */
    'segment'?: SegmentEntity;
}
/**
 * 
 * @export
 * @interface EmployeesToDepartmentsEntity
 */
export interface EmployeesToDepartmentsEntity {
    /**
     * 
     * @type {number}
     * @memberof EmployeesToDepartmentsEntity
     */
    'departmentId'?: number;
    /**
     * 
     * @type {DepartmentEntity}
     * @memberof EmployeesToDepartmentsEntity
     */
    'department'?: DepartmentEntity | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeesToDepartmentsEntity
     */
    'isDepartmentManager': boolean;
    /**
     * 
     * @type {SimpleWorkPositionEntity}
     * @memberof EmployeesToDepartmentsEntity
     */
    'workPosition'?: SimpleWorkPositionEntity | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeesToDepartmentsEntity
     */
    'canMakeTurnovers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeesToDepartmentsEntity
     */
    'canChangeTurnoversDates': boolean;
}
/**
 * 
 * @export
 * @interface ExperienceLevelEntity
 */
export interface ExperienceLevelEntity {
    /**
     * 
     * @type {number}
     * @memberof ExperienceLevelEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ExperienceLevelEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ExtendedCreateSectionDto
 */
export interface ExtendedCreateSectionDto {
    /**
     * 
     * @type {CreateSectionDto}
     * @memberof ExtendedCreateSectionDto
     */
    'sectionData': CreateSectionDto;
    /**
     * 
     * @type {Array<PaymentMethodEntity>}
     * @memberof ExtendedCreateSectionDto
     */
    'paymentMethods': Array<PaymentMethodEntity>;
}
/**
 * 
 * @export
 * @interface ExtendedUpdateSectionDto
 */
export interface ExtendedUpdateSectionDto {
    /**
     * 
     * @type {UpdateSectionDto}
     * @memberof ExtendedUpdateSectionDto
     */
    'sectionData': UpdateSectionDto;
    /**
     * 
     * @type {Array<PaymentMethodEntity>}
     * @memberof ExtendedUpdateSectionDto
     */
    'paymentMethods': Array<PaymentMethodEntity>;
}
/**
 * 
 * @export
 * @interface FileEntity
 */
export interface FileEntity {
    /**
     * 
     * @type {string}
     * @memberof FileEntity
     */
    'filePath': string;
}
/**
 * 
 * @export
 * @interface GenerateTokenDto
 */
export interface GenerateTokenDto {
    /**
     * 
     * @type {string}
     * @memberof GenerateTokenDto
     */
    'language': GenerateTokenDtoLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateTokenDto
     */
    'type': GenerateTokenDtoTypeEnum;
}

export const GenerateTokenDtoLanguageEnum = {
    En: 'EN',
    It: 'IT',
    De: 'DE',
    Fr: 'FR'
} as const;

export type GenerateTokenDtoLanguageEnum = typeof GenerateTokenDtoLanguageEnum[keyof typeof GenerateTokenDtoLanguageEnum];
export const GenerateTokenDtoTypeEnum = {
    CompleteDataRequest: 'COMPLETE_DATA_REQUEST',
    ResetPassword: 'RESET_PASSWORD'
} as const;

export type GenerateTokenDtoTypeEnum = typeof GenerateTokenDtoTypeEnum[keyof typeof GenerateTokenDtoTypeEnum];

/**
 * 
 * @export
 * @interface JobEntity
 */
export interface JobEntity {
    /**
     * 
     * @type {number}
     * @memberof JobEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof JobEntity
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof JobEntity
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof JobEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof JobEntity
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof JobEntity
     */
    'isPublic': boolean;
    /**
     * 
     * @type {number}
     * @memberof JobEntity
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof JobEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof JobEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {Array<EmployeeEntity>}
     * @memberof JobEntity
     */
    'candidates'?: Array<EmployeeEntity>;
}
/**
 * 
 * @export
 * @interface LicenceEntity
 */
export interface LicenceEntity {
    /**
     * 
     * @type {number}
     * @memberof LicenceEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LicenceEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface NetArrayElementEntity
 */
export interface NetArrayElementEntity {
    /**
     * 
     * @type {number}
     * @memberof NetArrayElementEntity
     */
    'amount': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof NetArrayElementEntity
     */
    'employee': EmployeeEntity;
}
/**
 * 
 * @export
 * @interface NoteEntity
 */
export interface NoteEntity {
    /**
     * 
     * @type {number}
     * @memberof NoteEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NoteEntity
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof NoteEntity
     */
    'text_encrypted': string;
    /**
     * 
     * @type {boolean}
     * @memberof NoteEntity
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NoteEntity
     */
    'editedAt': string;
    /**
     * 
     * @type {number}
     * @memberof NoteEntity
     */
    'authorId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteEntity
     */
    'employeeId': number;
}
/**
 * 
 * @export
 * @interface NotificationEntity
 */
export interface NotificationEntity {
    /**
     * 
     * @type {string}
     * @memberof NotificationEntity
     */
    'type': NotificationEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationEntity
     */
    'role': NotificationEntityRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationEntity
     */
    'daysUntilExpiry': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationEntity
     */
    'employeeName': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationEntity
     */
    'receiverEmployeeId': number | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationEntity
     */
    'sentAt': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEntity
     */
    'text': string;
}

export const NotificationEntityTypeEnum = {
    Birthday: 'BIRTHDAY',
    Anniversary: 'ANNIVERSARY',
    Expiring: 'EXPIRING',
    Completedprofile: 'COMPLETEDPROFILE',
    Expiringdocument: 'EXPIRINGDOCUMENT',
    Startingcontract: 'STARTINGCONTRACT',
    Custom: 'CUSTOM',
    Dailyarrival: 'DAILYARRIVAL',
    Roompreparation: 'ROOMPREPARATION',
    Draftcontract: 'DRAFTCONTRACT',
    Turnover: 'TURNOVER',
    Shiftavailability: 'SHIFTAVAILABILITY'
} as const;

export type NotificationEntityTypeEnum = typeof NotificationEntityTypeEnum[keyof typeof NotificationEntityTypeEnum];
export const NotificationEntityRoleEnum = {
    User: 'USER',
    Manager: 'MANAGER',
    Owner: 'OWNER',
    Developer: 'DEVELOPER',
    Depchief: 'DEPCHIEF',
    Termsnotaccepted: 'TERMSNOTACCEPTED',
    Controller: 'CONTROLLER'
} as const;

export type NotificationEntityRoleEnum = typeof NotificationEntityRoleEnum[keyof typeof NotificationEntityRoleEnum];

/**
 * 
 * @export
 * @interface PaymentEntity
 */
export interface PaymentEntity {
    /**
     * 
     * @type {number}
     * @memberof PaymentEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEntity
     */
    'authoriserId': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentEntity
     */
    'authorisationDate': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentEntity
     */
    'paymentDate': string;
    /**
     * 
     * @type {object}
     * @memberof PaymentEntity
     */
    'paid': object;
    /**
     * 
     * @type {string}
     * @memberof PaymentEntity
     */
    'paid_encrypted': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentEntity
     */
    'filePath': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentEntity
     */
    'seasonId': number;
}
/**
 * 
 * @export
 * @interface PaymentMethodEntity
 */
export interface PaymentMethodEntity {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodEntity
     */
    'type': PaymentMethodEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodEntity
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodEntity
     */
    'depositable': boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodEntity
     */
    'tenantBankingInformationId': number;
    /**
     * 
     * @type {TenantBankingInformationEntity}
     * @memberof PaymentMethodEntity
     */
    'tenantBankingInformation'?: TenantBankingInformationEntity;
}

export const PaymentMethodEntityTypeEnum = {
    Cash: 'CASH',
    Debitcard: 'DEBITCARD',
    Creditcard: 'CREDITCARD',
    Bancomat: 'BANCOMAT',
    Check: 'CHECK',
    Transfer: 'TRANSFER',
    Satispay: 'SATISPAY',
    Paypal: 'PAYPAL',
    Foodstamps: 'FOODSTAMPS',
    Crypto: 'CRYPTO',
    Klarna: 'KLARNA',
    Giftcard: 'GIFTCARD',
    Carddownpayment: 'CARDDOWNPAYMENT',
    Wiredownpayment: 'WIREDOWNPAYMENT',
    Cashdownpayment: 'CASHDOWNPAYMENT',
    Paypaldownpayment: 'PAYPALDOWNPAYMENT',
    Checkdownpayment: 'CHECKDOWNPAYMENT'
} as const;

export type PaymentMethodEntityTypeEnum = typeof PaymentMethodEntityTypeEnum[keyof typeof PaymentMethodEntityTypeEnum];

/**
 * 
 * @export
 * @interface PaymentPageDataEntity
 */
export interface PaymentPageDataEntity {
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof PaymentPageDataEntity
     */
    'employee'?: EmployeeEntity;
    /**
     * 
     * @type {string}
     * @memberof PaymentPageDataEntity
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPageDataEntity
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPageDataEntity
     */
    'pageIndex': number;
    /**
     * 
     * @type {Array<PaymentEntity>}
     * @memberof PaymentPageDataEntity
     */
    'payments': Array<PaymentEntity>;
    /**
     * 
     * @type {PaymentEntity}
     * @memberof PaymentPageDataEntity
     */
    'selectedPayment'?: PaymentEntity;
}
/**
 * 
 * @export
 * @interface PendingUserEntity
 */
export interface PendingUserEntity {
    /**
     * 
     * @type {string}
     * @memberof PendingUserEntity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PendingUserEntity
     */
    'issueTime': string;
    /**
     * 
     * @type {object}
     * @memberof PendingUserEntity
     */
    'language': object;
    /**
     * 
     * @type {string}
     * @memberof PendingUserEntity
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PendingUserEntity
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof PendingUserEntity
     */
    'username': string;
    /**
     * 
     * @type {boolean}
     * @memberof PendingUserEntity
     */
    'isValid': boolean;
}
/**
 * 
 * @export
 * @interface PublicDocumentEntity
 */
export interface PublicDocumentEntity {
    /**
     * 
     * @type {number}
     * @memberof PublicDocumentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicDocumentEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicDocumentEntity
     */
    'filePath': string;
    /**
     * 
     * @type {string}
     * @memberof PublicDocumentEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<SegmentEntity>}
     * @memberof PublicDocumentEntity
     */
    'segments'?: Array<SegmentEntity>;
    /**
     * 
     * @type {Array<DepartmentEntity>}
     * @memberof PublicDocumentEntity
     */
    'departments'?: Array<DepartmentEntity>;
    /**
     * 
     * @type {Array<SeasonEntity>}
     * @memberof PublicDocumentEntity
     */
    'seasons'?: Array<SeasonEntity>;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'password': string;
    /**
     * 
     * @type {object}
     * @memberof RegisterDto
     */
    'language': object;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface RemoveShiftDetailWithChoiceDto
 */
export interface RemoveShiftDetailWithChoiceDto {
    /**
     * 
     * @type {string}
     * @memberof RemoveShiftDetailWithChoiceDto
     */
    'choice': RemoveShiftDetailWithChoiceDtoChoiceEnum;
    /**
     * 
     * @type {string}
     * @memberof RemoveShiftDetailWithChoiceDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof RemoveShiftDetailWithChoiceDto
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof RemoveShiftDetailWithChoiceDto
     */
    'seasonId': number;
}

export const RemoveShiftDetailWithChoiceDtoChoiceEnum = {
    Single: 'single',
    All: 'all',
    Future: 'future'
} as const;

export type RemoveShiftDetailWithChoiceDtoChoiceEnum = typeof RemoveShiftDetailWithChoiceDtoChoiceEnum[keyof typeof RemoveShiftDetailWithChoiceDtoChoiceEnum];

/**
 * 
 * @export
 * @interface RewardEntity
 */
export interface RewardEntity {
    /**
     * 
     * @type {number}
     * @memberof RewardEntity
     */
    'id': number;
    /**
     * 
     * @type {object}
     * @memberof RewardEntity
     */
    'amount': object;
    /**
     * 
     * @type {number}
     * @memberof RewardEntity
     */
    'contractId': number;
    /**
     * 
     * @type {string}
     * @memberof RewardEntity
     */
    'earnedOn': string;
    /**
     * 
     * @type {number}
     * @memberof RewardEntity
     */
    'nameId': number;
    /**
     * 
     * @type {string}
     * @memberof RewardEntity
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof RewardEntity
     */
    'recordedAt': string;
}
/**
 * 
 * @export
 * @interface RoomEntity
 */
export interface RoomEntity {
    /**
     * 
     * @type {number}
     * @memberof RoomEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoomEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RoomEntity
     */
    'buildingId': number;
    /**
     * 
     * @type {number}
     * @memberof RoomEntity
     */
    'maxTenants': number;
    /**
     * 
     * @type {number}
     * @memberof RoomEntity
     */
    'floor': number;
    /**
     * 
     * @type {string}
     * @memberof RoomEntity
     */
    'accessMode': string;
    /**
     * 
     * @type {number}
     * @memberof RoomEntity
     */
    'bathrooms': number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomEntity
     */
    'hasKitchen': boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomEntity
     */
    'notes': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof RoomEntity
     */
    'employees'?: Array<object>;
    /**
     * 
     * @type {Array<RoomToSeasonEntity>}
     * @memberof RoomEntity
     */
    'seasonNotes'?: Array<RoomToSeasonEntity>;
}
/**
 * 
 * @export
 * @interface RoomToSeasonEntity
 */
export interface RoomToSeasonEntity {
    /**
     * 
     * @type {string}
     * @memberof RoomToSeasonEntity
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof RoomToSeasonEntity
     */
    'roomId': number;
    /**
     * 
     * @type {number}
     * @memberof RoomToSeasonEntity
     */
    'seasonId': number;
}
/**
 * 
 * @export
 * @interface SeasonEntity
 */
export interface SeasonEntity {
    /**
     * 
     * @type {number}
     * @memberof SeasonEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SeasonEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SeasonEntity
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof SeasonEntity
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface SectionDepositSumEntity
 */
export interface SectionDepositSumEntity {
    /**
     * 
     * @type {number}
     * @memberof SectionDepositSumEntity
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {TurnoverDepositableSumEntitySum}
     * @memberof SectionDepositSumEntity
     */
    '_sum': TurnoverDepositableSumEntitySum;
}
/**
 * 
 * @export
 * @interface SectionEntity
 */
export interface SectionEntity {
    /**
     * 
     * @type {number}
     * @memberof SectionEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SectionEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SectionEntity
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof SectionEntity
     */
    'taxPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof SectionEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {boolean}
     * @memberof SectionEntity
     */
    'onlyToBeShowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionEntity
     */
    'stillCountsDepositable': boolean;
    /**
     * 
     * @type {DepartmentEntity}
     * @memberof SectionEntity
     */
    'departemnt'?: DepartmentEntity;
    /**
     * 
     * @type {Array<TurnoverItemEntity>}
     * @memberof SectionEntity
     */
    'turnoverItems'?: Array<TurnoverItemEntity>;
    /**
     * 
     * @type {Array<PaymentMethodEntity>}
     * @memberof SectionEntity
     */
    'paymentMethods'?: Array<PaymentMethodEntity>;
}
/**
 * 
 * @export
 * @interface SegmentEntity
 */
export interface SegmentEntity {
    /**
     * 
     * @type {number}
     * @memberof SegmentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SegmentEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SegmentEntity
     */
    'employeesNumber'?: number;
}
/**
 * 
 * @export
 * @interface SetLanguageDto
 */
export interface SetLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof SetLanguageDto
     */
    'language': SetLanguageDtoLanguageEnum;
    /**
     * 
     * @type {number}
     * @memberof SetLanguageDto
     */
    'id': number;
}

export const SetLanguageDtoLanguageEnum = {
    En: 'EN',
    It: 'IT',
    De: 'DE',
    Fr: 'FR'
} as const;

export type SetLanguageDtoLanguageEnum = typeof SetLanguageDtoLanguageEnum[keyof typeof SetLanguageDtoLanguageEnum];

/**
 * 
 * @export
 * @interface SetSeasonToDisplayDto
 */
export interface SetSeasonToDisplayDto {
    /**
     * 
     * @type {number}
     * @memberof SetSeasonToDisplayDto
     */
    'seasonId': number;
    /**
     * 
     * @type {number}
     * @memberof SetSeasonToDisplayDto
     */
    'employeeId': number;
}
/**
 * 
 * @export
 * @interface ShiftAssignmentEntity
 */
export interface ShiftAssignmentEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftAssignmentEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftAssignmentEntity
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ShiftAssignmentEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof ShiftAssignmentEntity
     */
    'employee'?: EmployeeEntity;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftAssignmentEntity
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftAssignmentEntity
     */
    'published': boolean;
    /**
     * 
     * @type {number}
     * @memberof ShiftAssignmentEntity
     */
    'shiftDetailId': number;
}
/**
 * 
 * @export
 * @interface ShiftAvailabilityEntity
 */
export interface ShiftAvailabilityEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityEntity
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftAvailabilityEntity
     */
    'confirmed': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShiftAvailabilityEntity
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityEntity
     */
    'shiftId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftAvailabilityEntity
     */
    'answer': boolean;
    /**
     * 
     * @type {ShiftEntity}
     * @memberof ShiftAvailabilityEntity
     */
    'shift'?: ShiftEntity;
}
/**
 * 
 * @export
 * @interface ShiftAvailabilityRequestEntity
 */
export interface ShiftAvailabilityRequestEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof ShiftAvailabilityRequestEntity
     */
    'createdBy'?: EmployeeEntity;
}
/**
 * 
 * @export
 * @interface ShiftDetailEntity
 */
export interface ShiftDetailEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftDetailEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftDetailEntity
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftDetailEntity
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftDetailEntity
     */
    'breakStart': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftDetailEntity
     */
    'breakEnd': string;
    /**
     * 
     * @type {number}
     * @memberof ShiftDetailEntity
     */
    'shiftId': number;
    /**
     * 
     * @type {number}
     * @memberof ShiftDetailEntity
     */
    'workPositionId': number;
    /**
     * 
     * @type {WorkPositionEntity}
     * @memberof ShiftDetailEntity
     */
    'workPosition'?: WorkPositionEntity;
    /**
     * 
     * @type {Array<ShiftAssignmentEntity>}
     * @memberof ShiftDetailEntity
     */
    'shiftAssignments'?: Array<ShiftAssignmentEntity>;
}
/**
 * 
 * @export
 * @interface ShiftEntity
 */
export interface ShiftEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ShiftEntity
     */
    'templateId': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftEntity
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftEntity
     */
    'endTime': string;
    /**
     * 
     * @type {Array<ShiftDetailEntity>}
     * @memberof ShiftEntity
     */
    'shiftDetails'?: Array<ShiftDetailEntity>;
}
/**
 * 
 * @export
 * @interface ShiftTemplateEntity
 */
export interface ShiftTemplateEntity {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplateEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplateEntity
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplateEntity
     */
    'repeatStart': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplateEntity
     */
    'repeatEnd': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof ShiftTemplateEntity
     */
    'days': Array<object>;
    /**
     * 
     * @type {Array<ShiftEntity>}
     * @memberof ShiftTemplateEntity
     */
    'shifts'?: Array<ShiftEntity>;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateEntity
     */
    'generatedFromShiftTemplateId': number;
}
/**
 * 
 * @export
 * @interface SimpleWorkPositionEntity
 */
export interface SimpleWorkPositionEntity {
    /**
     * 
     * @type {string}
     * @memberof SimpleWorkPositionEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SimpleWorkPositionEntity
     */
    'workPositionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleWorkPositionEntity
     */
    'bossId'?: number | null;
}
/**
 * 
 * @export
 * @interface StatusChangeEntity
 */
export interface StatusChangeEntity {
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {string}
     * @memberof StatusChangeEntity
     */
    'timeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof StatusChangeEntity
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof StatusChangeEntity
     */
    'applied': boolean;
    /**
     * 
     * @type {string}
     * @memberof StatusChangeEntity
     */
    'note': string;
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'authorId': number;
    /**
     * 
     * @type {object}
     * @memberof StatusChangeEntity
     */
    'newStatustype': object;
    /**
     * 
     * @type {object}
     * @memberof StatusChangeEntity
     */
    'previousStatusType': object;
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'newStatusId': number;
    /**
     * 
     * @type {number}
     * @memberof StatusChangeEntity
     */
    'previousStatusId': number;
    /**
     * 
     * @type {string}
     * @memberof StatusChangeEntity
     */
    'filePath': string;
    /**
     * 
     * @type {StatusEntity}
     * @memberof StatusChangeEntity
     */
    'previusStatus'?: StatusEntity;
    /**
     * 
     * @type {StatusEntity}
     * @memberof StatusChangeEntity
     */
    'newStatus'?: StatusEntity;
}
/**
 * 
 * @export
 * @interface StatusEntity
 */
export interface StatusEntity {
    /**
     * 
     * @type {number}
     * @memberof StatusEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StatusEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StatusEntity
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface TenantBankingInformationEntity
 */
export interface TenantBankingInformationEntity {
    /**
     * 
     * @type {number}
     * @memberof TenantBankingInformationEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TenantBankingInformationEntity
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof TenantBankingInformationEntity
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantBankingInformationEntity
     */
    'bicSwift': string;
}
/**
 * 
 * @export
 * @interface TenantConfigEntity
 */
export interface TenantConfigEntity {
    /**
     * 
     * @type {number}
     * @memberof TenantConfigEntity
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'isEncryptionMandatory': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'isPasswordSet': boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantConfigEntity
     */
    'encryptedPassword': string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfigEntity
     */
    'testPasswordValue': string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfigEntity
     */
    'testBackupPasswordValue': string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'emailsToEmployees': boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantConfigEntity
     */
    'housekeepingManagerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof TenantConfigEntity
     */
    'monthlyMailReport': string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'sendHouseKeepingEMail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'sendMontlhyMailReport': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'sendDailyMails': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'sendTurnoverMails': boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantConfigEntity
     */
    'daysToConfirmAvailability': number;
    /**
     * 
     * @type {object}
     * @memberof TenantConfigEntity
     */
    'shiftInhertianceBehaviour': object;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'requireContractForShifts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'isHRModuleActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigEntity
     */
    'isTurnoverModuleActive': boolean;
}
/**
 * 
 * @export
 * @interface TokensEntity
 */
export interface TokensEntity {
    /**
     * 
     * @type {string}
     * @memberof TokensEntity
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TokensEntity
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface TurnoverDepositableSumEntity
 */
export interface TurnoverDepositableSumEntity {
    /**
     * 
     * @type {number}
     * @memberof TurnoverDepositableSumEntity
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {TurnoverDepositableSumEntitySum}
     * @memberof TurnoverDepositableSumEntity
     */
    '_sum': TurnoverDepositableSumEntitySum;
}
/**
 * 
 * @export
 * @interface TurnoverDepositableSumEntitySum
 */
export interface TurnoverDepositableSumEntitySum {
    /**
     * 
     * @type {number}
     * @memberof TurnoverDepositableSumEntitySum
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface TurnoverItemEntity
 */
export interface TurnoverItemEntity {
    /**
     * 
     * @type {string}
     * @memberof TurnoverItemEntity
     */
    'status': TurnoverItemEntityStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TurnoverItemEntity
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'paymentMethodId': number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'paymentSectionId': number;
    /**
     * 
     * @type {SectionEntity}
     * @memberof TurnoverItemEntity
     */
    'paymentSection'?: SectionEntity;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'updatedById': number;
    /**
     * 
     * @type {string}
     * @memberof TurnoverItemEntity
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TurnoverItemEntity
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'employeeId': number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverItemEntity
     */
    'controllerId': number;
    /**
     * 
     * @type {EmployeeEntity}
     * @memberof TurnoverItemEntity
     */
    'controller'?: EmployeeEntity;
}

export const TurnoverItemEntityStatusEnum = {
    Cashed: 'CASHED',
    Received: 'RECEIVED',
    Confirmed: 'CONFIRMED'
} as const;

export type TurnoverItemEntityStatusEnum = typeof TurnoverItemEntityStatusEnum[keyof typeof TurnoverItemEntityStatusEnum];

/**
 * 
 * @export
 * @interface UpdateAccountDto
 */
export interface UpdateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAdditionalInformationDto
 */
export interface UpdateAdditionalInformationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdditionalInformationDto
     */
    'foodRequirments'?: UpdateAdditionalInformationDtoFoodRequirmentsEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdditionalInformationDto
     */
    'shirtSize'?: UpdateAdditionalInformationDtoShirtSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdditionalInformationDto
     */
    'customFoodRequirments'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdditionalInformationDto
     */
    'pantsSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdditionalInformationDto
     */
    'typeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdditionalInformationDto
     */
    'employeeId'?: number;
}

export const UpdateAdditionalInformationDtoFoodRequirmentsEnum = {
    Gluten: 'GLUTEN',
    Dairy: 'DAIRY',
    Egg: 'EGG',
    Fish: 'FISH',
    Soy: 'SOY',
    Peanut: 'PEANUT',
    Vegeterian: 'VEGETERIAN',
    Vegan: 'VEGAN',
    Halal: 'HALAL',
    Kosher: 'KOSHER',
    Crustacean: 'CRUSTACEAN',
    Nut: 'NUT',
    Celery: 'CELERY',
    Mustard: 'MUSTARD',
    Sesame: 'SESAME',
    Clam: 'CLAM',
    Lupine: 'LUPINE',
    Solfite: 'SOLFITE'
} as const;

export type UpdateAdditionalInformationDtoFoodRequirmentsEnum = typeof UpdateAdditionalInformationDtoFoodRequirmentsEnum[keyof typeof UpdateAdditionalInformationDtoFoodRequirmentsEnum];
export const UpdateAdditionalInformationDtoShirtSizeEnum = {
    Xxs: 'XXS',
    Xs: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    Xl: 'XL',
    Xxl: 'XXL',
    Xxxl: 'XXXL'
} as const;

export type UpdateAdditionalInformationDtoShirtSizeEnum = typeof UpdateAdditionalInformationDtoShirtSizeEnum[keyof typeof UpdateAdditionalInformationDtoShirtSizeEnum];

/**
 * 
 * @export
 * @interface UpdateAgreementDto
 */
export interface UpdateAgreementDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateAgreementDto
     */
    'net_daily'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgreementDto
     */
    'startedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgreementDto
     */
    'endedOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgreementDto
     */
    'isLongTheWholeContract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgreementDto
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAgreementDto
     */
    'downpayment'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateAgreementDto
     */
    'freeDays'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof UpdateAgreementDto
     */
    'contractId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgreementDto
     */
    'net_daily_encrypted'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgreementDto
     */
    'createDaysAutomatically'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAgreementDto
     */
    'daysOff'?: number;
}
/**
 * 
 * @export
 * @interface UpdateBankingInformationDto
 */
export interface UpdateBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBankingInformationDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankingInformationDto
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankingInformationDto
     */
    'bicSwift'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBankingInformationDto
     */
    'employeeId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateBuildingDto
 */
export interface UpdateBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBuildingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBuildingDto
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBuildingDto
     */
    'cost'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBuildingDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBuildingDto
     */
    'endDate'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateContractDto
 */
export interface UpdateContractDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'legalContractType'?: UpdateContractDtoLegalContractTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'startedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'endedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractDto
     */
    'isAllIncluded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractDto
     */
    'isFoodIncluded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractDto
     */
    'isPartTime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractDto
     */
    'workPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'probationPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractDto
     */
    'probationPeriodDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractDto
     */
    'employeeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'levelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractDto
     */
    'typeName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractDto
     */
    'isDraft'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractDto
     */
    'seasonId'?: number;
}

export const UpdateContractDtoLegalContractTypeEnum = {
    Limited: 'LIMITED',
    Unlimited: 'UNLIMITED',
    Internship: 'INTERNSHIP',
    Call: 'CALL',
    Apprenticeship: 'APPRENTICESHIP'
} as const;

export type UpdateContractDtoLegalContractTypeEnum = typeof UpdateContractDtoLegalContractTypeEnum[keyof typeof UpdateContractDtoLegalContractTypeEnum];

/**
 * 
 * @export
 * @interface UpdateContractTypeDto
 */
export interface UpdateContractTypeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContractTypeDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDailyNoteDto
 */
export interface UpdateDailyNoteDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDailyNoteDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDailyNoteDto
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDepartmentDto
 */
export interface UpdateDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDepartmentDto
     */
    'segmentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDepartmentEmployeeDto
 */
export interface UpdateDepartmentEmployeeDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDepartmentEmployeeDto
     */
    'workPositionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDepartmentEmployeeDto
     */
    'bossEmployeeId'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateDepositDto
 */
export interface UpdateDepositDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDepositDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepositDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDepositDto
     */
    'paymentSectionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDepositDto
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDocumentTypeDto
 */
export interface UpdateDocumentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentTypeDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeDocumentDto
 */
export interface UpdateEmployeeDocumentDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeDocumentDto
     */
    'employeeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDocumentDto
     */
    'typeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDocumentDto
     */
    'expiringDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeDocumentDto
     */
    'isVisibleForUser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDocumentDto
     */
    'documentName'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeDto
 */
export interface UpdateEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'role'?: UpdateEmployeeDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'gender'?: UpdateEmployeeDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'filePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'countryOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'provinceOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'townshipOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'taxcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeDto
     */
    'lastPayment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeeDto
     */
    'seasonId'?: number | null;
}

export const UpdateEmployeeDtoRoleEnum = {
    User: 'USER',
    Manager: 'MANAGER',
    Owner: 'OWNER',
    Developer: 'DEVELOPER',
    Depchief: 'DEPCHIEF',
    Termsnotaccepted: 'TERMSNOTACCEPTED',
    Controller: 'CONTROLLER'
} as const;

export type UpdateEmployeeDtoRoleEnum = typeof UpdateEmployeeDtoRoleEnum[keyof typeof UpdateEmployeeDtoRoleEnum];
export const UpdateEmployeeDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Undefined: 'UNDEFINED'
} as const;

export type UpdateEmployeeDtoGenderEnum = typeof UpdateEmployeeDtoGenderEnum[keyof typeof UpdateEmployeeDtoGenderEnum];

/**
 * 
 * @export
 * @interface UpdateExperienceLevelDto
 */
export interface UpdateExperienceLevelDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateExperienceLevelDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateJobAdDto
 */
export interface UpdateJobAdDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobAdDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobAdDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateJobAdDto
     */
    'workPositionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateJobAdDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobAdDto
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateJobAdDto
     */
    'seasonId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateLicenceDto
 */
export interface UpdateLicenceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLicenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMyBankingInformationDto
 */
export interface UpdateMyBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyBankingInformationDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyBankingInformationDto
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyBankingInformationDto
     */
    'bicSwift'?: string;
}
/**
 * 
 * @export
 * @interface UpdateNoteDto
 */
export interface UpdateNoteDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteDto
     */
    'text_encrypted'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNoteDto
     */
    'authorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNoteDto
     */
    'employeeId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateNotesDto
 */
export interface UpdateNotesDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateNotesDto
     */
    'roomId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotesDto
     */
    'seasonId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotesDto
     */
    'notes': string;
}
/**
 * 
 * @export
 * @interface UpdatePaymentMethodDto
 */
export interface UpdatePaymentMethodDto {
    /**
     * 
     * @type {object}
     * @memberof UpdatePaymentMethodDto
     */
    'type'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentMethodDto
     */
    'depositable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdatePaymentMethodDto
     */
    'tenantBankingInformationId'?: number;
}
/**
 * 
 * @export
 * @interface UpdatePublicDocumentDto
 */
export interface UpdatePublicDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePublicDocumentDto
     */
    'departmentIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePublicDocumentDto
     */
    'segmentIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatePublicDocumentDto
     */
    'seasonIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateRoomDto
 */
export interface UpdateRoomDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomDto
     */
    'maxTenants'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomDto
     */
    'floor'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomDto
     */
    'accessMode'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRoomDto
     */
    'bathrooms'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRoomDto
     */
    'hasKitchen'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoomDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSeasonDto
 */
export interface UpdateSeasonDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSeasonDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSeasonDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSeasonDto
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSectionDto
 */
export interface UpdateSectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionDto
     */
    'taxPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSectionDto
     */
    'onlyToBeShowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSectionDto
     */
    'stillCountsDepositable'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateShiftAndDetailsWithChoiceDto
 */
export interface UpdateShiftAndDetailsWithChoiceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'choice': UpdateShiftAndDetailsWithChoiceDtoChoiceEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'seasonId': number;
    /**
     * 
     * @type {UpdateShiftDto}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'updateShiftDto': UpdateShiftDto;
    /**
     * 
     * @type {Array<UpdateShiftDetailLocalDto>}
     * @memberof UpdateShiftAndDetailsWithChoiceDto
     */
    'updateShiftDetails': Array<UpdateShiftDetailLocalDto>;
}

export const UpdateShiftAndDetailsWithChoiceDtoChoiceEnum = {
    Single: 'single',
    All: 'all',
    Future: 'future'
} as const;

export type UpdateShiftAndDetailsWithChoiceDtoChoiceEnum = typeof UpdateShiftAndDetailsWithChoiceDtoChoiceEnum[keyof typeof UpdateShiftAndDetailsWithChoiceDtoChoiceEnum];

/**
 * 
 * @export
 * @interface UpdateShiftDetailDto
 */
export interface UpdateShiftDetailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailDto
     */
    'startTime': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailDto
     */
    'endTime': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailDto
     */
    'breakStart': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailDto
     */
    'breakEnd': string | null;
}
/**
 * 
 * @export
 * @interface UpdateShiftDetailLocalDto
 */
export interface UpdateShiftDetailLocalDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailLocalDto
     */
    'startTime': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailLocalDto
     */
    'endTime': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailLocalDto
     */
    'breakStart': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailLocalDto
     */
    'breakEnd': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDetailLocalDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDetailLocalDto
     */
    'workPositionId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateShiftDetailLocalDto
     */
    '_isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateShiftDetailWithChoiceDto
 */
export interface UpdateShiftDetailWithChoiceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailWithChoiceDto
     */
    'choice': UpdateShiftDetailWithChoiceDtoChoiceEnum;
    /**
     * 
     * @type {UpdateShiftDetailDto}
     * @memberof UpdateShiftDetailWithChoiceDto
     */
    'updateShiftDetailDto': UpdateShiftDetailDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDetailWithChoiceDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDetailWithChoiceDto
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDetailWithChoiceDto
     */
    'seasonId': number;
}

export const UpdateShiftDetailWithChoiceDtoChoiceEnum = {
    Single: 'single',
    All: 'all',
    Future: 'future'
} as const;

export type UpdateShiftDetailWithChoiceDtoChoiceEnum = typeof UpdateShiftDetailWithChoiceDtoChoiceEnum[keyof typeof UpdateShiftDetailWithChoiceDtoChoiceEnum];

/**
 * 
 * @export
 * @interface UpdateShiftDto
 */
export interface UpdateShiftDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDto
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDto
     */
    'seasonId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftDto
     */
    'departmentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateShiftTemplateDto
 */
export interface UpdateShiftTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateDto
     */
    'repeatStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateDto
     */
    'repeatEnd'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateShiftTemplateDto
     */
    'days'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateDto
     */
    'seasonId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateShiftWithChoiceDto
 */
export interface UpdateShiftWithChoiceDto {
    /**
     * How the update should be applied: single day, all occurrences, or from this day onward
     * @type {string}
     * @memberof UpdateShiftWithChoiceDto
     */
    'choice': UpdateShiftWithChoiceDtoChoiceEnum;
    /**
     * 
     * @type {UpdateShiftWithChoiceDtoUpdateShiftDto}
     * @memberof UpdateShiftWithChoiceDto
     */
    'updateShiftDto': UpdateShiftWithChoiceDtoUpdateShiftDto;
    /**
     * The specific date from which changes should apply
     * @type {string}
     * @memberof UpdateShiftWithChoiceDto
     */
    'date': string;
    /**
     * Department ID for security checks
     * @type {number}
     * @memberof UpdateShiftWithChoiceDto
     */
    'departmentId': number;
    /**
     * Season ID if needed for additional validation or logic
     * @type {number}
     * @memberof UpdateShiftWithChoiceDto
     */
    'seasonId': number;
}

export const UpdateShiftWithChoiceDtoChoiceEnum = {
    Single: 'single',
    All: 'all',
    Future: 'future'
} as const;

export type UpdateShiftWithChoiceDtoChoiceEnum = typeof UpdateShiftWithChoiceDtoChoiceEnum[keyof typeof UpdateShiftWithChoiceDtoChoiceEnum];

/**
 * The updated shift data
 * @export
 * @interface UpdateShiftWithChoiceDtoUpdateShiftDto
 */
export interface UpdateShiftWithChoiceDtoUpdateShiftDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'seasonId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftWithChoiceDtoUpdateShiftDto
     */
    'departmentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateTenantBankingInformationDto
 */
export interface UpdateTenantBankingInformationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantBankingInformationDto
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantBankingInformationDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantBankingInformationDto
     */
    'bicSwift'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTenantConfigDto
 */
export interface UpdateTenantConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantConfigDto
     */
    'isPasswordSet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigDto
     */
    'encryptedPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigDto
     */
    'testPasswordValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigDto
     */
    'testBackupPasswordValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigDto
     */
    'housekeepingManagerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantConfigDto
     */
    'monthlyMailReport'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantConfigDto
     */
    'sendHouseKeepingEMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantConfigDto
     */
    'sendMontlhyMailReport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantConfigDto
     */
    'sendDailyMails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantConfigDto
     */
    'sendTurnoverMails'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTenantOptions
 */
export interface UpdateTenantOptions {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantOptions
     */
    'emailsToEmployees': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantOptions
     */
    'housekeepingManagerEmail': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantOptions
     */
    'monthlyMailReport': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantOptions
     */
    'sendHouseKeepingEMail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantOptions
     */
    'sendMontlhyMailReport': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateTenantOptions
     */
    'daysToConfirmAvailability': number;
}
/**
 * 
 * @export
 * @interface UpdateTurnoverItemDto
 */
export interface UpdateTurnoverItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTurnoverItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTurnoverItemDto
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTurnoverItemDto
     */
    'paymentMethodId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTurnoverItemDto
     */
    'paymentSectionId'?: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateTurnoverItemDto
     */
    'status'?: object;
}
/**
 * 
 * @export
 * @interface UpdateWorkPositionDto
 */
export interface UpdateWorkPositionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkPositionDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkPositionDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkPositionDto
     */
    'bossId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkPositionDto
     */
    'desiredResources'?: number | null;
}
/**
 * 
 * @export
 * @interface UploadedZipEntity
 */
export interface UploadedZipEntity {
    /**
     * 
     * @type {number}
     * @memberof UploadedZipEntity
     */
    'count': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadedZipEntity
     */
    'badFiles': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadedZipEntity
     */
    'badPayments': Array<string>;
    /**
     * 
     * @type {Array<NetArrayElementEntity>}
     * @memberof UploadedZipEntity
     */
    'netArray': Array<NetArrayElementEntity>;
}
/**
 * 
 * @export
 * @interface UsedDocumentNameEntity
 */
export interface UsedDocumentNameEntity {
    /**
     * 
     * @type {number}
     * @memberof UsedDocumentNameEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UsedDocumentNameEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UsedDocumentNameEntity
     */
    'documentTypeId': number;
}
/**
 * 
 * @export
 * @interface UsedRewardNameEntity
 */
export interface UsedRewardNameEntity {
    /**
     * 
     * @type {number}
     * @memberof UsedRewardNameEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UsedRewardNameEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'username': string;
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'password': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'provider': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'providerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'refreshToken': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'lastIp': string | null;
    /**
     * 
     * @type {object}
     * @memberof UserEntity
     */
    'language': object;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'lastTenantName': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'privacyAcceptedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'termsAcceptedAt': string;
}
/**
 * 
 * @export
 * @interface WorkPositionEntity
 */
export interface WorkPositionEntity {
    /**
     * 
     * @type {number}
     * @memberof WorkPositionEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WorkPositionEntity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof WorkPositionEntity
     */
    'departmentId': number;
    /**
     * 
     * @type {DepartmentEntity}
     * @memberof WorkPositionEntity
     */
    'department'?: DepartmentEntity;
    /**
     * 
     * @type {number}
     * @memberof WorkPositionEntity
     */
    'bossId': number | null;
    /**
     * 
     * @type {Array<WorkPositionToSeasonEntity>}
     * @memberof WorkPositionEntity
     */
    'workPositionToSeason'?: Array<WorkPositionToSeasonEntity> | null;
}
/**
 * 
 * @export
 * @interface WorkPositionToSeasonEntity
 */
export interface WorkPositionToSeasonEntity {
    /**
     * 
     * @type {number}
     * @memberof WorkPositionToSeasonEntity
     */
    'seasonId': number;
    /**
     * 
     * @type {number}
     * @memberof WorkPositionToSeasonEntity
     */
    'workPositionId': number;
    /**
     * 
     * @type {number}
     * @memberof WorkPositionToSeasonEntity
     */
    'desiredResources': number;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isPasswordSet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/isPasswordSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (updateAccountDto: UpdateAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountDto' is not null or undefined
            assertParamExists('update', 'updateAccountDto', updateAccountDto)
            const localVarPath = `/api/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isPasswordSet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isPasswordSet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find(options?: any): AxiosPromise<AccountEntity> {
            return localVarFp.find(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isPasswordSet(options?: any): AxiosPromise<boolean> {
            return localVarFp.isPasswordSet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(options?: any): AxiosPromise<AccountEntity> {
            return localVarFp.remove(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(updateAccountDto: UpdateAccountDto, options?: any): AxiosPromise<AccountEntity> {
            return localVarFp.update(updateAccountDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public find(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).find(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public isPasswordSet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).isPasswordSet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public remove(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).remove(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountDto} updateAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public update(updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).update(updateAccountDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdditionalInformationApi - axios parameter creator
 * @export
 */
export const AdditionalInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createAdditionalInformationDto: CreateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdditionalInformationDto' is not null or undefined
            assertParamExists('create', 'createAdditionalInformationDto', createAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByMyself: async (createAdditionalInformationDto: CreateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdditionalInformationDto' is not null or undefined
            assertParamExists('createByMyself', 'createAdditionalInformationDto', createAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information/createByMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergencyContact: async (createEmergencyContactDto: CreateEmergencyContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmergencyContactDto' is not null or undefined
            assertParamExists('createEmergencyContact', 'createEmergencyContactDto', createEmergencyContactDto)
            const localVarPath = `/api/v1/additional-information/emergencyContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmergencyContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergencyContactWithToken: async (tenant: string, createEmergencyContactDto: CreateEmergencyContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createEmergencyContactWithToken', 'tenant', tenant)
            // verify required parameter 'createEmergencyContactDto' is not null or undefined
            assertParamExists('createEmergencyContactWithToken', 'createEmergencyContactDto', createEmergencyContactDto)
            const localVarPath = `/api/v1/additional-information/emergencyContactWithToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmergencyContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithToken: async (tenant: string, createAdditionalInformationDto: CreateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createWithToken', 'tenant', tenant)
            // verify required parameter 'createAdditionalInformationDto' is not null or undefined
            assertParamExists('createWithToken', 'createAdditionalInformationDto', createAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information/withToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/additional-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmergencyContactByAdditionalInfomrationsID: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findEmergencyContactByAdditionalInfomrationsID', 'id', id)
            const localVarPath = `/api/v1/additional-information/emergencyContacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmergencyContactByAdditionalInfomrationsIDWithToken: async (id: number, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findEmergencyContactByAdditionalInfomrationsIDWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('findEmergencyContactByAdditionalInfomrationsIDWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/additional-information/emergencyContactsWithToken/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByEmployee: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneByEmployee', 'id', id)
            const localVarPath = `/api/v1/additional-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByMyself: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/additional-information/findOneByMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTokenAndEmployee: async (id: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByTokenAndEmployee', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getByTokenAndEmployee', 'tenant', tenant)
            const localVarPath = `/api/v1/additional-information/{id}/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/additional-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmergencyContact: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmergencyContact', 'id', id)
            const localVarPath = `/api/v1/additional-information/contact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmergencyContactWithToken: async (id: number, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmergencyContactWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeEmergencyContactWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/additional-information/contactWithToken/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateAdditionalInformationDto' is not null or undefined
            assertParamExists('update', 'updateAdditionalInformationDto', updateAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateByMyself: async (updateAdditionalInformationDto: UpdateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAdditionalInformationDto' is not null or undefined
            assertParamExists('updateByMyself', 'updateAdditionalInformationDto', updateAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information/updateByMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken: async (id: string, tenant: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateWithToken', 'tenant', tenant)
            // verify required parameter 'updateAdditionalInformationDto' is not null or undefined
            assertParamExists('updateWithToken', 'updateAdditionalInformationDto', updateAdditionalInformationDto)
            const localVarPath = `/api/v1/additional-information/{id}/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdditionalInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdditionalInformationApi - functional programming interface
 * @export
 */
export const AdditionalInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdditionalInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createByMyself(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createByMyself(createAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmergencyContact(createEmergencyContactDto: CreateEmergencyContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmergencyContact(createEmergencyContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmergencyContactWithToken(tenant: string, createEmergencyContactDto: CreateEmergencyContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmergencyContactWithToken(tenant, createEmergencyContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithToken(tenant: string, createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithToken(tenant, createAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalInformationsEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmergencyContactByAdditionalInfomrationsID(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmergencyContactEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmergencyContactByAdditionalInfomrationsID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmergencyContactByAdditionalInfomrationsIDWithToken(id: number, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmergencyContactEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmergencyContactByAdditionalInfomrationsIDWithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneByEmployee(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneByEmployee(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneByMyself(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneByMyself(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByTokenAndEmployee(id: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByTokenAndEmployee(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmergencyContact(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmergencyContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmergencyContactWithToken(id: number, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyContactEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmergencyContactWithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateByMyself(updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateByMyself(updateAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithToken(id: string, tenant: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalInformationsEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithToken(id, tenant, updateAdditionalInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdditionalInformationApi - factory interface
 * @export
 */
export const AdditionalInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdditionalInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.create(createAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByMyself(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.createByMyself(createAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergencyContact(createEmergencyContactDto: CreateEmergencyContactDto, options?: any): AxiosPromise<EmergencyContactEntity> {
            return localVarFp.createEmergencyContact(createEmergencyContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmergencyContactDto} createEmergencyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergencyContactWithToken(tenant: string, createEmergencyContactDto: CreateEmergencyContactDto, options?: any): AxiosPromise<EmergencyContactEntity> {
            return localVarFp.createEmergencyContactWithToken(tenant, createEmergencyContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithToken(tenant: string, createAdditionalInformationDto: CreateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.createWithToken(tenant, createAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<AdditionalInformationsEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmergencyContactByAdditionalInfomrationsID(id: number, options?: any): AxiosPromise<Array<EmergencyContactEntity>> {
            return localVarFp.findEmergencyContactByAdditionalInfomrationsID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmergencyContactByAdditionalInfomrationsIDWithToken(id: number, tenant: string, options?: any): AxiosPromise<Array<EmergencyContactEntity>> {
            return localVarFp.findEmergencyContactByAdditionalInfomrationsIDWithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByEmployee(id: string, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.findOneByEmployee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByMyself(options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.findOneByMyself(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTokenAndEmployee(id: string, tenant: string, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.getByTokenAndEmployee(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmergencyContact(id: number, options?: any): AxiosPromise<EmergencyContactEntity> {
            return localVarFp.removeEmergencyContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmergencyContactWithToken(id: number, tenant: string, options?: any): AxiosPromise<EmergencyContactEntity> {
            return localVarFp.removeEmergencyContactWithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.update(id, updateAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateByMyself(updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.updateByMyself(updateAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken(id: string, tenant: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: any): AxiosPromise<AdditionalInformationsEntity> {
            return localVarFp.updateWithToken(id, tenant, updateAdditionalInformationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdditionalInformationApi - object-oriented interface
 * @export
 * @class AdditionalInformationApi
 * @extends {BaseAPI}
 */
export class AdditionalInformationApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public create(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).create(createAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public createByMyself(createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).createByMyself(createAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmergencyContactDto} createEmergencyContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public createEmergencyContact(createEmergencyContactDto: CreateEmergencyContactDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).createEmergencyContact(createEmergencyContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {CreateEmergencyContactDto} createEmergencyContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public createEmergencyContactWithToken(tenant: string, createEmergencyContactDto: CreateEmergencyContactDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).createEmergencyContactWithToken(tenant, createEmergencyContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {CreateAdditionalInformationDto} createAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public createWithToken(tenant: string, createAdditionalInformationDto: CreateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).createWithToken(tenant, createAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public findEmergencyContactByAdditionalInfomrationsID(id: number, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).findEmergencyContactByAdditionalInfomrationsID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public findEmergencyContactByAdditionalInfomrationsIDWithToken(id: number, tenant: string, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).findEmergencyContactByAdditionalInfomrationsIDWithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public findOneByEmployee(id: string, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).findOneByEmployee(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public findOneByMyself(options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).findOneByMyself(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public getByTokenAndEmployee(id: string, tenant: string, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).getByTokenAndEmployee(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public removeEmergencyContact(id: number, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).removeEmergencyContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public removeEmergencyContactWithToken(id: number, tenant: string, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).removeEmergencyContactWithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public update(id: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).update(id, updateAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public updateByMyself(updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).updateByMyself(updateAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {UpdateAdditionalInformationDto} updateAdditionalInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdditionalInformationApi
     */
    public updateWithToken(id: string, tenant: string, updateAdditionalInformationDto: UpdateAdditionalInformationDto, options?: AxiosRequestConfig) {
        return AdditionalInformationApiFp(this.configuration).updateWithToken(id, tenant, updateAdditionalInformationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgreementsApi - axios parameter creator
 * @export
 */
export const AgreementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAgreementDto} createAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createAgreementDto: CreateAgreementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgreementDto' is not null or undefined
            assertParamExists('create', 'createAgreementDto', createAgreementDto)
            const localVarPath = `/api/v1/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgreementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {number} [contractId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId?: number, seasonId?: number, contractId?: number, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEncryptionKeySet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agreements/isEncryptionKeySet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAgreementDto} updateAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateAgreementDto: UpdateAgreementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateAgreementDto' is not null or undefined
            assertParamExists('update', 'updateAgreementDto', updateAgreementDto)
            const localVarPath = `/api/v1/agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgreementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementsApi - functional programming interface
 * @export
 */
export const AgreementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAgreementDto} createAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createAgreementDto: CreateAgreementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createAgreementDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {number} [contractId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId?: number, seasonId?: number, contractId?: number, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgreementEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, seasonId, contractId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isEncryptionKeySet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEncryptionKeySet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAgreementDto} updateAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateAgreementDto: UpdateAgreementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateAgreementDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementsApi - factory interface
 * @export
 */
export const AgreementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAgreementDto} createAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createAgreementDto: CreateAgreementDto, options?: any): AxiosPromise<AgreementEntity> {
            return localVarFp.create(createAgreementDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {number} [contractId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId?: number, seasonId?: number, contractId?: number, departmentId?: number, options?: any): AxiosPromise<Array<AgreementEntity>> {
            return localVarFp.findAll(employeeId, seasonId, contractId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<AgreementEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEncryptionKeySet(options?: any): AxiosPromise<boolean> {
            return localVarFp.isEncryptionKeySet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<AgreementEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAgreementDto} updateAgreementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateAgreementDto: UpdateAgreementDto, options?: any): AxiosPromise<AgreementEntity> {
            return localVarFp.update(id, updateAgreementDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgreementsApi - object-oriented interface
 * @export
 * @class AgreementsApi
 * @extends {BaseAPI}
 */
export class AgreementsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAgreementDto} createAgreementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public create(createAgreementDto: CreateAgreementDto, options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).create(createAgreementDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employeeId] 
     * @param {number} [seasonId] 
     * @param {number} [contractId] 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public findAll(employeeId?: number, seasonId?: number, contractId?: number, departmentId?: number, options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).findAll(employeeId, seasonId, contractId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public isEncryptionKeySet(options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).isEncryptionKeySet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAgreementDto} updateAgreementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementsApi
     */
    public update(id: string, updateAgreementDto: UpdateAgreementDto, options?: AxiosRequestConfig) {
        return AgreementsApiFp(this.configuration).update(id, updateAgreementDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTerms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/acceptTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmUser', 'token', token)
            const localVarPath = `/api/v1/auth/confirmUser/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/facebook/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/facebook/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/google/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/google/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('isTokenValid', 'token', token)
            const localVarPath = `/api/v1/auth/isTokenValid/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('login', 'loginDto', loginDto)
            const localVarPath = `/api/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerDto: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            assertParamExists('register', 'registerDto', registerDto)
            const localVarPath = `/api/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectTenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTerms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokensEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTerms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUser(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUser(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facebookCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facebookCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facebookLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facebookLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isTokenValid(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendingUserEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isTokenValid(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokensEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokensEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerDto: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectTenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokensEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectTenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tenants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTerms(options?: any): AxiosPromise<TokensEntity> {
            return localVarFp.acceptTerms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser(token: string, options?: any): AxiosPromise<UserEntity> {
            return localVarFp.confirmUser(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookCallback(options?: any): AxiosPromise<object> {
            return localVarFp.facebookCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookLogin(options?: any): AxiosPromise<void> {
            return localVarFp.facebookLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCallback(options?: any): AxiosPromise<object> {
            return localVarFp.googleCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin(options?: any): AxiosPromise<void> {
            return localVarFp.googleLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid(token: string, options?: any): AxiosPromise<PendingUserEntity> {
            return localVarFp.isTokenValid(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<TokensEntity> {
            return localVarFp.login(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any): AxiosPromise<TokensEntity> {
            return localVarFp.refresh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerDto: RegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.register(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectTenant(options?: any): AxiosPromise<TokensEntity> {
            return localVarFp.selectTenant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenants(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.tenants(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public acceptTerms(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).acceptTerms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmUser(token: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).confirmUser(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public facebookCallback(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).facebookCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public facebookLogin(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).facebookLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleCallback(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleLogin(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public isTokenValid(token: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).isTokenValid(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refresh(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(registerDto: RegisterDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).register(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public selectTenant(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).selectTenant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public tenants(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).tenants(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankingInformationApi - axios parameter creator
 * @export
 */
export const BankingInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createBankingInformationDto: CreateBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankingInformationDto' is not null or undefined
            assertParamExists('create', 'createBankingInformationDto', createBankingInformationDto)
            const localVarPath = `/api/v1/banking-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMyBankingInformationDto} createMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyself: async (createMyBankingInformationDto: CreateMyBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMyBankingInformationDto' is not null or undefined
            assertParamExists('createMyself', 'createMyBankingInformationDto', createMyBankingInformationDto)
            const localVarPath = `/api/v1/banking-information/createMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMyBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} token 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithToken: async (tenant: string, token: string, createBankingInformationDto: CreateBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createWithToken', 'tenant', tenant)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('createWithToken', 'token', token)
            // verify required parameter 'createBankingInformationDto' is not null or undefined
            assertParamExists('createWithToken', 'createBankingInformationDto', createBankingInformationDto)
            const localVarPath = `/api/v1/banking-information/withToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banking-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesWithoutIban: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banking-information/no-iban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateBankingInformationDto: UpdateBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateBankingInformationDto' is not null or undefined
            assertParamExists('update', 'updateBankingInformationDto', updateBankingInformationDto)
            const localVarPath = `/api/v1/banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMyBankingInformationDto} updateMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyself: async (id: number, updateMyBankingInformationDto: UpdateMyBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMyself', 'id', id)
            // verify required parameter 'updateMyBankingInformationDto' is not null or undefined
            assertParamExists('updateMyself', 'updateMyBankingInformationDto', updateMyBankingInformationDto)
            const localVarPath = `/api/v1/banking-information/{id}/updateMyself`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {string} token 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken: async (id: string, tenant: string, token: string, updateBankingInformationDto: UpdateBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateWithToken', 'tenant', tenant)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateWithToken', 'token', token)
            // verify required parameter 'updateBankingInformationDto' is not null or undefined
            assertParamExists('updateWithToken', 'updateBankingInformationDto', updateBankingInformationDto)
            const localVarPath = `/api/v1/banking-information/{id}/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankingInformationApi - functional programming interface
 * @export
 */
export const BankingInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankingInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createBankingInformationDto: CreateBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMyBankingInformationDto} createMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMyself(createMyBankingInformationDto: CreateMyBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMyself(createMyBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} token 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithToken(tenant: string, token: string, createBankingInformationDto: CreateBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWithToken(tenant, token, createBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankingInformationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeesWithoutIban(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeesWithoutIban(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMyBankingInformationDto} updateMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyself(id: number, updateMyBankingInformationDto: UpdateMyBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyself(id, updateMyBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {string} token 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithToken(id: string, tenant: string, token: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithToken(id, tenant, token, updateBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankingInformationApi - factory interface
 * @export
 */
export const BankingInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankingInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createBankingInformationDto: CreateBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.create(createBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMyBankingInformationDto} createMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMyself(createMyBankingInformationDto: CreateMyBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.createMyself(createMyBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} token 
         * @param {CreateBankingInformationDto} createBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithToken(tenant: string, token: string, createBankingInformationDto: CreateBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.createWithToken(tenant, token, createBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<BankingInformationEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesWithoutIban(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getEmployeesWithoutIban(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.update(id, updateBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMyBankingInformationDto} updateMyBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyself(id: number, updateMyBankingInformationDto: UpdateMyBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.updateMyself(id, updateMyBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {string} token 
         * @param {UpdateBankingInformationDto} updateBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken(id: string, tenant: string, token: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: any): AxiosPromise<BankingInformationEntity> {
            return localVarFp.updateWithToken(id, tenant, token, updateBankingInformationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankingInformationApi - object-oriented interface
 * @export
 * @class BankingInformationApi
 * @extends {BaseAPI}
 */
export class BankingInformationApi extends BaseAPI {
    /**
     * 
     * @param {CreateBankingInformationDto} createBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public create(createBankingInformationDto: CreateBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).create(createBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMyBankingInformationDto} createMyBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public createMyself(createMyBankingInformationDto: CreateMyBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).createMyself(createMyBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} token 
     * @param {CreateBankingInformationDto} createBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public createWithToken(tenant: string, token: string, createBankingInformationDto: CreateBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).createWithToken(tenant, token, createBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public getEmployeesWithoutIban(options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).getEmployeesWithoutIban(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBankingInformationDto} updateBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public update(id: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).update(id, updateBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateMyBankingInformationDto} updateMyBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public updateMyself(id: number, updateMyBankingInformationDto: UpdateMyBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).updateMyself(id, updateMyBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {string} token 
     * @param {UpdateBankingInformationDto} updateBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingInformationApi
     */
    public updateWithToken(id: string, tenant: string, token: string, updateBankingInformationDto: UpdateBankingInformationDto, options?: AxiosRequestConfig) {
        return BankingInformationApiFp(this.configuration).updateWithToken(id, tenant, token, updateBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingsApi - axios parameter creator
 * @export
 */
export const BuildingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBuildingDto} createBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createBuildingDto: CreateBuildingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBuildingDto' is not null or undefined
            assertParamExists('create', 'createBuildingDto', createBuildingDto)
            const localVarPath = `/api/v1/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBuildingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [seasonId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (seasonId?: number, all?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBuildingDto} updateBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateBuildingDto: UpdateBuildingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateBuildingDto' is not null or undefined
            assertParamExists('update', 'updateBuildingDto', updateBuildingDto)
            const localVarPath = `/api/v1/buildings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBuildingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingsApi - functional programming interface
 * @export
 */
export const BuildingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBuildingDto} createBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createBuildingDto: CreateBuildingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createBuildingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [seasonId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(seasonId?: number, all?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(seasonId, all, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBuildingDto} updateBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateBuildingDto: UpdateBuildingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateBuildingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingsApi - factory interface
 * @export
 */
export const BuildingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBuildingDto} createBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createBuildingDto: CreateBuildingDto, options?: any): AxiosPromise<BuildingEntity> {
            return localVarFp.create(createBuildingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [seasonId] 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(seasonId?: number, all?: boolean, options?: any): AxiosPromise<Array<BuildingEntity>> {
            return localVarFp.findAll(seasonId, all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<BuildingEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<BuildingEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBuildingDto} updateBuildingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateBuildingDto: UpdateBuildingDto, options?: any): AxiosPromise<BuildingEntity> {
            return localVarFp.update(id, updateBuildingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingsApi - object-oriented interface
 * @export
 * @class BuildingsApi
 * @extends {BaseAPI}
 */
export class BuildingsApi extends BaseAPI {
    /**
     * 
     * @param {CreateBuildingDto} createBuildingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public create(createBuildingDto: CreateBuildingDto, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).create(createBuildingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [seasonId] 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public findAll(seasonId?: number, all?: boolean, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).findAll(seasonId, all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBuildingDto} updateBuildingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingsApi
     */
    public update(id: string, updateBuildingDto: UpdateBuildingDto, options?: AxiosRequestConfig) {
        return BuildingsApiFp(this.configuration).update(id, updateBuildingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarEntryShiftTemplateApi - axios parameter creator
 * @export
 */
export const CalendarEntryShiftTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} allowOverwrite 
         * @param {number} seasonId 
         * @param {AssignShiftTemplateDto} assignShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignShiftTemplate: async (allowOverwrite: string, seasonId: number, assignShiftTemplateDto: AssignShiftTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allowOverwrite' is not null or undefined
            assertParamExists('assignShiftTemplate', 'allowOverwrite', allowOverwrite)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('assignShiftTemplate', 'seasonId', seasonId)
            // verify required parameter 'assignShiftTemplateDto' is not null or undefined
            assertParamExists('assignShiftTemplate', 'assignShiftTemplateDto', assignShiftTemplateDto)
            const localVarPath = `/api/v1/calendar-entry-shift-template/assignShiftTemplate/{allowOverwrite}/{seasonId}`
                .replace(`{${"allowOverwrite"}}`, encodeURIComponent(String(allowOverwrite)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignShiftTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCalendarEntryShiftTemplateDto} createCalendarEntryShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createCalendarEntryShiftTemplateDto: CreateCalendarEntryShiftTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarEntryShiftTemplateDto' is not null or undefined
            assertParamExists('create', 'createCalendarEntryShiftTemplateDto', createCalendarEntryShiftTemplateDto)
            const localVarPath = `/api/v1/calendar-entry-shift-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarEntryShiftTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAll', 'seasonId', seasonId)
            const localVarPath = `/api/v1/calendar-entry-shift-template/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWhereIsBoss: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllWhereIsBoss', 'seasonId', seasonId)
            const localVarPath = `/api/v1/calendar-entry-shift-template/whereIsBoss/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDate: async (date: string, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('findByDate', 'date', date)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findByDate', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByDate', 'seasonId', seasonId)
            const localVarPath = `/api/v1/calendar-entry-shift-template/byDate/{date}/{departmentId}/{seasonId}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByMultipleDates: async (start: string, end: string, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('findByMultipleDates', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('findByMultipleDates', 'end', end)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findByMultipleDates', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByMultipleDates', 'seasonId', seasonId)
            const localVarPath = `/api/v1/calendar-entry-shift-template/byMultipleDates/{start}/{end}/{departmentId}/{seasonId}`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('remove', 'seasonId', seasonId)
            const localVarPath = `/api/v1/calendar-entry-shift-template/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEntryShiftTemplateApi - functional programming interface
 * @export
 */
export const CalendarEntryShiftTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarEntryShiftTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} allowOverwrite 
         * @param {number} seasonId 
         * @param {AssignShiftTemplateDto} assignShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignShiftTemplate(allowOverwrite: string, seasonId: number, assignShiftTemplateDto: AssignShiftTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignShiftTemplate(allowOverwrite, seasonId, assignShiftTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCalendarEntryShiftTemplateDto} createCalendarEntryShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createCalendarEntryShiftTemplateDto: CreateCalendarEntryShiftTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createCalendarEntryShiftTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryShiftTemplateEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWhereIsBoss(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryShiftTemplateEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWhereIsBoss(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByDate(date: string, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByDate(date, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByMultipleDates(start: string, end: string, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryShiftTemplateEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByMultipleDates(start, end, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarEntryShiftTemplateApi - factory interface
 * @export
 */
export const CalendarEntryShiftTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarEntryShiftTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} allowOverwrite 
         * @param {number} seasonId 
         * @param {AssignShiftTemplateDto} assignShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignShiftTemplate(allowOverwrite: string, seasonId: number, assignShiftTemplateDto: AssignShiftTemplateDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignShiftTemplate(allowOverwrite, seasonId, assignShiftTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCalendarEntryShiftTemplateDto} createCalendarEntryShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createCalendarEntryShiftTemplateDto: CreateCalendarEntryShiftTemplateDto, options?: any): AxiosPromise<CalendarEntryShiftTemplateEntity> {
            return localVarFp.create(createCalendarEntryShiftTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(seasonId: number, options?: any): AxiosPromise<Array<CalendarEntryShiftTemplateEntity>> {
            return localVarFp.findAll(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWhereIsBoss(seasonId: number, options?: any): AxiosPromise<Array<CalendarEntryShiftTemplateEntity>> {
            return localVarFp.findAllWhereIsBoss(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDate(date: string, departmentId: number, seasonId: number, options?: any): AxiosPromise<CalendarEntryShiftTemplateEntity> {
            return localVarFp.findByDate(date, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByMultipleDates(start: string, end: string, departmentId: number, seasonId: number, options?: any): AxiosPromise<Array<CalendarEntryShiftTemplateEntity>> {
            return localVarFp.findByMultipleDates(start, end, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, seasonId: number, options?: any): AxiosPromise<CalendarEntryShiftTemplateEntity> {
            return localVarFp.remove(id, seasonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarEntryShiftTemplateApi - object-oriented interface
 * @export
 * @class CalendarEntryShiftTemplateApi
 * @extends {BaseAPI}
 */
export class CalendarEntryShiftTemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} allowOverwrite 
     * @param {number} seasonId 
     * @param {AssignShiftTemplateDto} assignShiftTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public assignShiftTemplate(allowOverwrite: string, seasonId: number, assignShiftTemplateDto: AssignShiftTemplateDto, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).assignShiftTemplate(allowOverwrite, seasonId, assignShiftTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCalendarEntryShiftTemplateDto} createCalendarEntryShiftTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public create(createCalendarEntryShiftTemplateDto: CreateCalendarEntryShiftTemplateDto, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).create(createCalendarEntryShiftTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public findAll(seasonId: number, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).findAll(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public findAllWhereIsBoss(seasonId: number, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).findAllWhereIsBoss(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public findByDate(date: string, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).findByDate(date, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public findByMultipleDates(start: string, end: string, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).findByMultipleDates(start, end, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEntryShiftTemplateApi
     */
    public remove(id: number, seasonId: number, options?: AxiosRequestConfig) {
        return CalendarEntryShiftTemplateApiFp(this.configuration).remove(id, seasonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunicationsApi - axios parameter creator
 * @export
 */
export const CommunicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCommunicationDto} createCommunicationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createCommunicationDto: CreateCommunicationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunicationDto' is not null or undefined
            assertParamExists('create', 'createCommunicationDto', createCommunicationDto)
            const localVarPath = `/api/v1/communications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunicationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('find', 'id', id)
            const localVarPath = `/api/v1/communications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunicationsApi - functional programming interface
 * @export
 */
export const CommunicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCommunicationDto} createCommunicationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createCommunicationDto: CreateCommunicationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createCommunicationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async find(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.find(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunicationsApi - factory interface
 * @export
 */
export const CommunicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCommunicationDto} createCommunicationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createCommunicationDto: CreateCommunicationDto, options?: any): AxiosPromise<object> {
            return localVarFp.create(createCommunicationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        find(id: number, options?: any): AxiosPromise<Array<CommunicationEntity>> {
            return localVarFp.find(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunicationsApi - object-oriented interface
 * @export
 * @class CommunicationsApi
 * @extends {BaseAPI}
 */
export class CommunicationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateCommunicationDto} createCommunicationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApi
     */
    public create(createCommunicationDto: CreateCommunicationDto, options?: AxiosRequestConfig) {
        return CommunicationsApiFp(this.configuration).create(createCommunicationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApi
     */
    public find(id: number, options?: AxiosRequestConfig) {
        return CommunicationsApiFp(this.configuration).find(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractTypesApi - axios parameter creator
 * @export
 */
export const ContractTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateContractTypeDto} createContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createContractTypeDto: CreateContractTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContractTypeDto' is not null or undefined
            assertParamExists('create', 'createContractTypeDto', createContractTypeDto)
            const localVarPath = `/api/v1/contract-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contract-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/contract-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/contract-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractTypeDto} updateContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateContractTypeDto: UpdateContractTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateContractTypeDto' is not null or undefined
            assertParamExists('update', 'updateContractTypeDto', updateContractTypeDto)
            const localVarPath = `/api/v1/contract-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractTypesApi - functional programming interface
 * @export
 */
export const ContractTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateContractTypeDto} createContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createContractTypeDto: CreateContractTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createContractTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractTypeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractTypeDto} updateContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateContractTypeDto: UpdateContractTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateContractTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractTypesApi - factory interface
 * @export
 */
export const ContractTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateContractTypeDto} createContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createContractTypeDto: CreateContractTypeDto, options?: any): AxiosPromise<ContractTypeEntity> {
            return localVarFp.create(createContractTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<ContractTypeEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<ContractTypeEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<ContractTypeEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractTypeDto} updateContractTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateContractTypeDto: UpdateContractTypeDto, options?: any): AxiosPromise<ContractTypeEntity> {
            return localVarFp.update(id, updateContractTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractTypesApi - object-oriented interface
 * @export
 * @class ContractTypesApi
 * @extends {BaseAPI}
 */
export class ContractTypesApi extends BaseAPI {
    /**
     * 
     * @param {CreateContractTypeDto} createContractTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTypesApi
     */
    public create(createContractTypeDto: CreateContractTypeDto, options?: AxiosRequestConfig) {
        return ContractTypesApiFp(this.configuration).create(createContractTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTypesApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return ContractTypesApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTypesApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return ContractTypesApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTypesApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return ContractTypesApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateContractTypeDto} updateContractTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractTypesApi
     */
    public update(id: string, updateContractTypeDto: UpdateContractTypeDto, options?: AxiosRequestConfig) {
        return ContractTypesApiFp(this.configuration).update(id, updateContractTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractsApi - axios parameter creator
 * @export
 */
export const ContractsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateContractDto} createContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createContractDto: CreateContractDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContractDto' is not null or undefined
            assertParamExists('create', 'createContractDto', createContractDto)
            const localVarPath = `/api/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFile', 'id', id)
            const localVarPath = `/api/v1/contracts/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContract', 'id', id)
            const localVarPath = `/api/v1/contracts/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId?: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, employeeId?: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/contracts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateContractDto: UpdateContractDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateContractDto' is not null or undefined
            assertParamExists('update', 'updateContractDto', updateContractDto)
            const localVarPath = `/api/v1/contracts/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractsApi - functional programming interface
 * @export
 */
export const ContractsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateContractDto} createContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createContractDto: CreateContractDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContract(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContract(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId?: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, employeeId?: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateContractDto: UpdateContractDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractsApi - factory interface
 * @export
 */
export const ContractsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateContractDto} createContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createContractDto: CreateContractDto, options?: any): AxiosPromise<ContractEntity> {
            return localVarFp.create(createContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(id: string, options?: any): AxiosPromise<FileEntity> {
            return localVarFp.createFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContract(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContract(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId?: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.findAll(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [employeeId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, employeeId?: number, seasonId?: number, options?: any): AxiosPromise<ContractEntity> {
            return localVarFp.findOne(id, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContractDto} updateContractDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateContractDto: UpdateContractDto, options?: any): AxiosPromise<ContractEntity> {
            return localVarFp.update(id, updateContractDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractsApi - object-oriented interface
 * @export
 * @class ContractsApi
 * @extends {BaseAPI}
 */
export class ContractsApi extends BaseAPI {
    /**
     * 
     * @param {CreateContractDto} createContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public create(createContractDto: CreateContractDto, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).create(createContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public createFile(id: string, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).createFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public deleteContract(id: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).deleteContract(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employeeId] 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public findAll(employeeId?: number, seasonId?: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).findAll(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [employeeId] 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public findOne(id: string, employeeId?: number, seasonId?: number, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).findOne(id, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateContractDto} updateContractDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractsApi
     */
    public update(id: string, updateContractDto: UpdateContractDto, options?: AxiosRequestConfig) {
        return ContractsApiFp(this.configuration).update(id, updateContractDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DailyNotesApi - axios parameter creator
 * @export
 */
export const DailyNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDailyNoteDto} createDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createDailyNoteDto: CreateDailyNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDailyNoteDto' is not null or undefined
            assertParamExists('create', 'createDailyNoteDto', createDailyNoteDto)
            const localVarPath = `/api/v1/daily-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDailyNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/daily-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/daily-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/daily-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolve: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resolve', 'id', id)
            const localVarPath = `/api/v1/daily-notes/resolve/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unresolve: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unresolve', 'id', id)
            const localVarPath = `/api/v1/daily-notes/unresolve/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDailyNoteDto} updateDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateDailyNoteDto: UpdateDailyNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateDailyNoteDto' is not null or undefined
            assertParamExists('update', 'updateDailyNoteDto', updateDailyNoteDto)
            const localVarPath = `/api/v1/daily-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDailyNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DailyNotesApi - functional programming interface
 * @export
 */
export const DailyNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DailyNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDailyNoteDto} createDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createDailyNoteDto: CreateDailyNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createDailyNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyNoteEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resolve(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resolve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unresolve(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unresolve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDailyNoteDto} updateDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateDailyNoteDto: UpdateDailyNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyNoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateDailyNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DailyNotesApi - factory interface
 * @export
 */
export const DailyNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DailyNotesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDailyNoteDto} createDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createDailyNoteDto: CreateDailyNoteDto, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.create(createDailyNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<DailyNoteEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resolve(id: string, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.resolve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unresolve(id: string, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.unresolve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDailyNoteDto} updateDailyNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateDailyNoteDto: UpdateDailyNoteDto, options?: any): AxiosPromise<DailyNoteEntity> {
            return localVarFp.update(id, updateDailyNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DailyNotesApi - object-oriented interface
 * @export
 * @class DailyNotesApi
 * @extends {BaseAPI}
 */
export class DailyNotesApi extends BaseAPI {
    /**
     * 
     * @param {CreateDailyNoteDto} createDailyNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public create(createDailyNoteDto: CreateDailyNoteDto, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).create(createDailyNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public resolve(id: string, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).resolve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public unresolve(id: string, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).unresolve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDailyNoteDto} updateDailyNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DailyNotesApi
     */
    public update(id: string, updateDailyNoteDto: UpdateDailyNoteDto, options?: AxiosRequestConfig) {
        return DailyNotesApiFp(this.configuration).update(id, updateDailyNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createDepartmentDto: CreateDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentDto' is not null or undefined
            assertParamExists('create', 'createDepartmentDto', createDepartmentDto)
            const localVarPath = `/api/v1/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRole: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByRole', 'seasonId', seasonId)
            const localVarPath = `/api/v1/departments/byRole/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySection: async (seasonId: number, sectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findBySection', 'seasonId', seasonId)
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('findBySection', 'sectionId', sectionId)
            const localVarPath = `/api/v1/departments/bySection/{seasonId}/{sectionId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWhereIsBoss: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findWhereIsBoss', 'seasonId', seasonId)
            const localVarPath = `/api/v1/departments/whereBossId/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDepartmentDto} updateDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateDepartmentDto: UpdateDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateDepartmentDto' is not null or undefined
            assertParamExists('update', 'updateDepartmentDto', updateDepartmentDto)
            const localVarPath = `/api/v1/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createDepartmentDto: CreateDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByRole(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByRole(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBySection(seasonId: number, sectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBySection(seasonId, sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWhereIsBoss(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWhereIsBoss(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDepartmentDto} updateDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateDepartmentDto: UpdateDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDepartmentDto} createDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createDepartmentDto: CreateDepartmentDto, options?: any): AxiosPromise<DepartmentEntity> {
            return localVarFp.create(createDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<DepartmentEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRole(seasonId: number, options?: any): AxiosPromise<Array<DepartmentEntity>> {
            return localVarFp.findByRole(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySection(seasonId: number, sectionId: number, options?: any): AxiosPromise<DepartmentEntity> {
            return localVarFp.findBySection(seasonId, sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<DepartmentEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWhereIsBoss(seasonId: number, options?: any): AxiosPromise<Array<DepartmentEntity>> {
            return localVarFp.findWhereIsBoss(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDepartmentDto} updateDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateDepartmentDto: UpdateDepartmentDto, options?: any): AxiosPromise<DepartmentEntity> {
            return localVarFp.update(id, updateDepartmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDepartmentDto} createDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public create(createDepartmentDto: CreateDepartmentDto, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).create(createDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public findByRole(seasonId: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).findByRole(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {number} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public findBySection(seasonId: number, sectionId: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).findBySection(seasonId, sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public findWhereIsBoss(seasonId: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).findWhereIsBoss(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDepartmentDto} updateDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public update(id: string, updateDepartmentDto: UpdateDepartmentDto, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).update(id, updateDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsEmployeesApi - axios parameter creator
 * @export
 */
export const DepartmentsEmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanChangeDate: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('assignCanChangeDate', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('assignCanChangeDate', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/assignCanChangeDate/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanMakeTurnover: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('assignCanMakeTurnover', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('assignCanMakeTurnover', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/assignCanMakeTurnover/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignIsManager: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('assignIsManager', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('assignIsManager', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/assignIsManager/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canChangeTurnoversDate: async (departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('canChangeTurnoversDate', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('canChangeTurnoversDate', 'seasonId', seasonId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/canChangeTurnoversDate/{seasonId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {CreateDepartmentEmployeeDto} createDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (departmentId: string, id: string, createDepartmentEmployeeDto: CreateDepartmentEmployeeDto, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('create', 'departmentId', departmentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('create', 'id', id)
            // verify required parameter 'createDepartmentEmployeeDto' is not null or undefined
            assertParamExists('create', 'createDepartmentEmployeeDto', createDepartmentEmployeeDto)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/{id}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {boolean} excludeEmployeesWithoutContract 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (departmentId: string, excludeEmployeesWithoutContract: boolean, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findAll', 'departmentId', departmentId)
            // verify required parameter 'excludeEmployeesWithoutContract' is not null or undefined
            assertParamExists('findAll', 'excludeEmployeesWithoutContract', excludeEmployeesWithoutContract)
            const localVarPath = `/api/v1/departments/{departmentId}/employees`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"excludeEmployeesWithoutContract"}}`, encodeURIComponent(String(excludeEmployeesWithoutContract)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (departmentId: string, id: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findOne', 'departmentId', departmentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/{id}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDepchief: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('isDepchief', 'seasonId', seasonId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/isDepchief/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (departmentId: string, id: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('remove', 'departmentId', departmentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/{id}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeCanChangeDate: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('revokeCanChangeDate', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('revokeCanChangeDate', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/revokeCanChangeDate/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeCanMakeTurnover: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('revokeCanMakeTurnover', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('revokeCanMakeTurnover', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/revokeCanMakeTurnover/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeIsManager: async (departmentId: number, employeeId: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('revokeIsManager', 'departmentId', departmentId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('revokeIsManager', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/revokeIsManager/{employeeId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {UpdateDepartmentEmployeeDto} updateDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (departmentId: string, id: string, updateDepartmentEmployeeDto: UpdateDepartmentEmployeeDto, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('update', 'departmentId', departmentId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateDepartmentEmployeeDto' is not null or undefined
            assertParamExists('update', 'updateDepartmentEmployeeDto', updateDepartmentEmployeeDto)
            const localVarPath = `/api/v1/departments/{departmentId}/employees/{id}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsEmployeesApi - functional programming interface
 * @export
 */
export const DepartmentsEmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsEmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCanChangeDate(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignCanMakeTurnover(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignIsManager(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canChangeTurnoversDate(departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canChangeTurnoversDate(departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {CreateDepartmentEmployeeDto} createDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(departmentId: string, id: string, createDepartmentEmployeeDto: CreateDepartmentEmployeeDto, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(departmentId, id, createDepartmentEmployeeDto, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {boolean} excludeEmployeesWithoutContract 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(departmentId: string, excludeEmployeesWithoutContract: boolean, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentEmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(departmentId, excludeEmployeesWithoutContract, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(departmentId: string, id: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(departmentId, id, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDepchief(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDepchief(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(departmentId: string, id: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(departmentId, id, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeCanChangeDate(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeCanMakeTurnover(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeIsManager(departmentId, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {UpdateDepartmentEmployeeDto} updateDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(departmentId: string, id: string, updateDepartmentEmployeeDto: UpdateDepartmentEmployeeDto, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentEmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(departmentId, id, updateDepartmentEmployeeDto, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsEmployeesApi - factory interface
 * @export
 */
export const DepartmentsEmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsEmployeesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.assignCanChangeDate(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.assignCanMakeTurnover(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.assignIsManager(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canChangeTurnoversDate(departmentId: number, seasonId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.canChangeTurnoversDate(departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {CreateDepartmentEmployeeDto} createDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(departmentId: string, id: string, createDepartmentEmployeeDto: CreateDepartmentEmployeeDto, seasonId?: number, options?: any): AxiosPromise<DepartmentEmployeeEntity> {
            return localVarFp.create(departmentId, id, createDepartmentEmployeeDto, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {boolean} excludeEmployeesWithoutContract 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(departmentId: string, excludeEmployeesWithoutContract: boolean, seasonId?: number, options?: any): AxiosPromise<Array<DepartmentEmployeeEntity>> {
            return localVarFp.findAll(departmentId, excludeEmployeesWithoutContract, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(departmentId: string, id: string, seasonId?: number, options?: any): AxiosPromise<DepartmentEmployeeEntity> {
            return localVarFp.findOne(departmentId, id, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDepchief(seasonId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.isDepchief(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(departmentId: string, id: string, seasonId?: number, options?: any): AxiosPromise<DepartmentEmployeeEntity> {
            return localVarFp.remove(departmentId, id, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.revokeCanChangeDate(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.revokeCanMakeTurnover(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.revokeIsManager(departmentId, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {string} id 
         * @param {UpdateDepartmentEmployeeDto} updateDepartmentEmployeeDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(departmentId: string, id: string, updateDepartmentEmployeeDto: UpdateDepartmentEmployeeDto, seasonId?: number, options?: any): AxiosPromise<DepartmentEmployeeEntity> {
            return localVarFp.update(departmentId, id, updateDepartmentEmployeeDto, seasonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsEmployeesApi - object-oriented interface
 * @export
 * @class DepartmentsEmployeesApi
 * @extends {BaseAPI}
 */
export class DepartmentsEmployeesApi extends BaseAPI {
    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public assignCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).assignCanChangeDate(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public assignCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).assignCanMakeTurnover(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public assignIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).assignIsManager(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public canChangeTurnoversDate(departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).canChangeTurnoversDate(departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {string} id 
     * @param {CreateDepartmentEmployeeDto} createDepartmentEmployeeDto 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public create(departmentId: string, id: string, createDepartmentEmployeeDto: CreateDepartmentEmployeeDto, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).create(departmentId, id, createDepartmentEmployeeDto, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {boolean} excludeEmployeesWithoutContract 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public findAll(departmentId: string, excludeEmployeesWithoutContract: boolean, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).findAll(departmentId, excludeEmployeesWithoutContract, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {string} id 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public findOne(departmentId: string, id: string, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).findOne(departmentId, id, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public isDepchief(seasonId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).isDepchief(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {string} id 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public remove(departmentId: string, id: string, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).remove(departmentId, id, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public revokeCanChangeDate(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).revokeCanChangeDate(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public revokeCanMakeTurnover(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).revokeCanMakeTurnover(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public revokeIsManager(departmentId: number, employeeId: number, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).revokeIsManager(departmentId, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {string} id 
     * @param {UpdateDepartmentEmployeeDto} updateDepartmentEmployeeDto 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesApi
     */
    public update(departmentId: string, id: string, updateDepartmentEmployeeDto: UpdateDepartmentEmployeeDto, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesApiFp(this.configuration).update(departmentId, id, updateDepartmentEmployeeDto, seasonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsEmployeesCalendarEntriesApi - axios parameter creator
 * @export
 */
export const DepartmentsEmployeesCalendarEntriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accept: async (id: string, seasonId: number, employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accept', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('accept', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('accept', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('create', 'employeeId', employeeId)
            // verify required parameter 'createCalendarEntryDto' is not null or undefined
            assertParamExists('create', 'createCalendarEntryDto', createCalendarEntryDto)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarEntryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByMyself: async (employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createByMyself', 'employeeId', employeeId)
            // verify required parameter 'createCalendarEntryDto' is not null or undefined
            assertParamExists('createByMyself', 'createCalendarEntryDto', createCalendarEntryDto)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/createByMyself`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarEntryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/createMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAll', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByMyself: async (employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAllByMyself', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findAllByMyself`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} seasonId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEvents: async (employeeId: string, seasonId: string, month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAllEvents', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllEvents', 'seasonId', seasonId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('findAllEvents', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('findAllEvents', 'year', year)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/{seasonId}/{month}/{year}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEventsByMyself: async (employeeId: string, month: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAllEventsByMyself', 'employeeId', employeeId)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('findAllEventsByMyself', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('findAllEventsByMyself', 'year', year)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findAllEventsByMyself/{seasonId}/{month}/{year}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmployeeCalendarStatusByWeek: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('findEmployeeCalendarStatusByWeek', 'body', body)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findEmployeeCalendarStasus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmployeeCalendarStatusByWeekOfSubordinates: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('findEmployeeCalendarStatusByWeekOfSubordinates', 'body', body)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findEmployeeCalendarStatusByWeekOfSubordinates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} employeeId 
         * @param {string} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEventsPerPeriodOfTime: async (startDate: string, endDate: string, employeeId: number, seasonId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('findEventsPerPeriodOfTime', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('findEventsPerPeriodOfTime', 'endDate', endDate)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findEventsPerPeriodOfTime', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/eventsPerPeriodOfTime`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNotAccepted: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findNotAccepted', 'seasonId', seasonId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findNotAccepted/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, employeeId: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findOne', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByMyself: async (id: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneByMyself', 'id', id)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findOneByMyself', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/findOneByMyself/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {string} monday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWeekFreeDays: async (employeeId: number, seasonId: number, monday: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findWeekFreeDays', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findWeekFreeDays', 'seasonId', seasonId)
            // verify required parameter 'monday' is not null or undefined
            assertParamExists('findWeekFreeDays', 'monday', monday)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/weekFreeDays/{seasonId}/{monday}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"monday"}}`, encodeURIComponent(String(monday)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuse: async (id: string, seasonId: number, employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refuse', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('refuse', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('refuse', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/refuse/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, seasonId: number, employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('remove', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('remove', 'employeeId', employeeId)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/api/v1/departments/employees/{employeeId}/calendar-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsEmployeesCalendarEntriesApi - functional programming interface
 * @export
 */
export const DepartmentsEmployeesCalendarEntriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsEmployeesCalendarEntriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accept(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accept(id, seasonId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(employeeId, createCalendarEntryDto, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createByMyself(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createByMyself(employeeId, createCalendarEntryDto, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByMyself(employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByMyself(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} seasonId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEvents(employeeId: string, seasonId: string, month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEvents(employeeId, seasonId, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEventsByMyself(employeeId: string, month: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEventsByMyself(employeeId, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmployeeCalendarStatusByWeek(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmployeeCalendarStatusByWeek(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmployeeCalendarStatusByWeekOfSubordinates(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmployeeCalendarStatusByWeekOfSubordinates(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} employeeId 
         * @param {string} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEventsPerPeriodOfTime(startDate: string, endDate: string, employeeId: number, seasonId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEventsPerPeriodOfTime(startDate, endDate, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNotAccepted(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntryEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNotAccepted(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, employeeId: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneByMyself(id: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneByMyself(id, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {string} monday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWeekFreeDays(employeeId: number, seasonId: number, monday: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWeekFreeDays(employeeId, seasonId, monday, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuse(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refuse(id, seasonId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, seasonId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsEmployeesCalendarEntriesApi - factory interface
 * @export
 */
export const DepartmentsEmployeesCalendarEntriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsEmployeesCalendarEntriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accept(id: string, seasonId: number, employeeId: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.accept(id, seasonId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.create(employeeId, createCalendarEntryDto, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {CreateCalendarEntryDto} createCalendarEntryDto 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByMyself(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.createByMyself(employeeId, createCalendarEntryDto, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMany(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.createMany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId: string, seasonId?: number, options?: any): AxiosPromise<Array<CalendarEntryEntity>> {
            return localVarFp.findAll(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByMyself(employeeId: string, options?: any): AxiosPromise<Array<CalendarEntryEntity>> {
            return localVarFp.findAllByMyself(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {string} seasonId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEvents(employeeId: string, seasonId: string, month: number, year: number, options?: any): AxiosPromise<object> {
            return localVarFp.findAllEvents(employeeId, seasonId, month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employeeId 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEventsByMyself(employeeId: string, month: number, year: number, options?: any): AxiosPromise<object> {
            return localVarFp.findAllEventsByMyself(employeeId, month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmployeeCalendarStatusByWeek(body: object, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.findEmployeeCalendarStatusByWeek(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmployeeCalendarStatusByWeekOfSubordinates(body: object, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.findEmployeeCalendarStatusByWeekOfSubordinates(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} employeeId 
         * @param {string} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEventsPerPeriodOfTime(startDate: string, endDate: string, employeeId: number, seasonId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.findEventsPerPeriodOfTime(startDate, endDate, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNotAccepted(seasonId: number, options?: any): AxiosPromise<Array<CalendarEntryEntity>> {
            return localVarFp.findNotAccepted(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, employeeId: string, seasonId?: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.findOne(id, employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByMyself(id: string, employeeId: string, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.findOneByMyself(id, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {string} monday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWeekFreeDays(employeeId: number, seasonId: number, monday: string, options?: any): AxiosPromise<void> {
            return localVarFp.findWeekFreeDays(employeeId, seasonId, monday, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuse(id: string, seasonId: number, employeeId: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.refuse(id, seasonId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, seasonId: number, employeeId: number, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.remove(id, seasonId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: object, options?: any): AxiosPromise<CalendarEntryEntity> {
            return localVarFp.update(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsEmployeesCalendarEntriesApi - object-oriented interface
 * @export
 * @class DepartmentsEmployeesCalendarEntriesApi
 * @extends {BaseAPI}
 */
export class DepartmentsEmployeesCalendarEntriesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public accept(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).accept(id, seasonId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {CreateCalendarEntryDto} createCalendarEntryDto 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public create(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).create(employeeId, createCalendarEntryDto, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {CreateCalendarEntryDto} createCalendarEntryDto 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public createByMyself(employeeId: string, createCalendarEntryDto: CreateCalendarEntryDto, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).createByMyself(employeeId, createCalendarEntryDto, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public createMany(options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).createMany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findAll(employeeId: string, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findAll(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findAllByMyself(employeeId: string, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findAllByMyself(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {string} seasonId 
     * @param {number} month 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findAllEvents(employeeId: string, seasonId: string, month: number, year: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findAllEvents(employeeId, seasonId, month, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employeeId 
     * @param {number} month 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findAllEventsByMyself(employeeId: string, month: number, year: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findAllEventsByMyself(employeeId, month, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findEmployeeCalendarStatusByWeek(body: object, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findEmployeeCalendarStatusByWeek(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findEmployeeCalendarStatusByWeekOfSubordinates(body: object, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findEmployeeCalendarStatusByWeekOfSubordinates(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} employeeId 
     * @param {string} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findEventsPerPeriodOfTime(startDate: string, endDate: string, employeeId: number, seasonId?: string, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findEventsPerPeriodOfTime(startDate, endDate, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findNotAccepted(seasonId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findNotAccepted(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} employeeId 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findOne(id: string, employeeId: string, seasonId?: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findOne(id, employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findOneByMyself(id: string, employeeId: string, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findOneByMyself(id, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {number} seasonId 
     * @param {string} monday 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public findWeekFreeDays(employeeId: number, seasonId: number, monday: string, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).findWeekFreeDays(employeeId, seasonId, monday, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public refuse(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).refuse(id, seasonId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public remove(id: string, seasonId: number, employeeId: number, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).remove(id, seasonId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsEmployeesCalendarEntriesApi
     */
    public update(id: string, body: object, options?: AxiosRequestConfig) {
        return DepartmentsEmployeesCalendarEntriesApiFp(this.configuration).update(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepositsApi - axios parameter creator
 * @export
 */
export const DepositsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateDepositDto} createDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (seasonId: number, createDepositDto: CreateDepositDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('create', 'seasonId', seasonId)
            // verify required parameter 'createDepositDto' is not null or undefined
            assertParamExists('create', 'createDepositDto', createDepositDto)
            const localVarPath = `/api/v1/deposits/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepositDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySeasons: async (sectionIds: Array<string>, dateTimeStamp: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionIds' is not null or undefined
            assertParamExists('findBySeasons', 'sectionIds', sectionIds)
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('findBySeasons', 'dateTimeStamp', dateTimeStamp)
            const localVarPath = `/api/v1/deposits/bySeasons/{dateTimeStamp}`
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionIds) {
                localVarQueryParameter['sectionIds'] = sectionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique: async (sectionId: number, dateTimeStamp: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('findUnique', 'sectionId', sectionId)
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('findUnique', 'dateTimeStamp', dateTimeStamp)
            const localVarPath = `/api/v1/deposits/{sectionId}:/{dateTimeStamp}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)))
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateSum: async (dateTimeStamp: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('getDateSum', 'dateTimeStamp', dateTimeStamp)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getDateSum', 'seasonId', seasonId)
            const localVarPath = `/api/v1/deposits/dateSum/{dateTimeStamp}/{seasonId}`
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this is implemented as POST instead of GET in order to avoid     errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateSums: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getDateSums', 'seasonId', seasonId)
            const localVarPath = `/api/v1/deposits/dateSums/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSum: async (sectionId: number, dateTimeStamp: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('getSum', 'sectionId', sectionId)
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('getSum', 'dateTimeStamp', dateTimeStamp)
            const localVarPath = `/api/v1/deposits/sum/{sectionId}/{dateTimeStamp}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)))
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSums: async (sectionIds: Array<string>, dateTimeStamp: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionIds' is not null or undefined
            assertParamExists('getSums', 'sectionIds', sectionIds)
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('getSums', 'dateTimeStamp', dateTimeStamp)
            const localVarPath = `/api/v1/deposits/sums/{dateTimeStamp}`
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionIds) {
                localVarQueryParameter['sectionIds'] = sectionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/deposits/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDepositDto} updateDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateDepositDto: UpdateDepositDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateDepositDto' is not null or undefined
            assertParamExists('update', 'updateDepositDto', updateDepositDto)
            const localVarPath = `/api/v1/deposits/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepositDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositsApi - functional programming interface
 * @export
 */
export const DepositsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateDepositDto} createDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(seasonId: number, createDepositDto: CreateDepositDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(seasonId, createDepositDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepositEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBySeasons(sectionIds: Array<string>, dateTimeStamp: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepositEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBySeasons(sectionIds, dateTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnique(sectionId: number, dateTimeStamp: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnique(sectionId, dateTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateSum(dateTimeStamp: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateSum(dateTimeStamp, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this is implemented as POST instead of GET in order to avoid     errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateSums(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DateSumEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateSums(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSum(sectionId: number, dateTimeStamp: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSum(sectionId, dateTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSums(sectionIds: Array<string>, dateTimeStamp: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDepositSumEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSums(sectionIds, dateTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDepositDto} updateDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateDepositDto: UpdateDepositDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateDepositDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepositsApi - factory interface
 * @export
 */
export const DepositsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateDepositDto} createDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(seasonId: number, createDepositDto: CreateDepositDto, options?: any): AxiosPromise<DepositEntity> {
            return localVarFp.create(seasonId, createDepositDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<DepositEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBySeasons(sectionIds: Array<string>, dateTimeStamp: number, options?: any): AxiosPromise<Array<DepositEntity>> {
            return localVarFp.findBySeasons(sectionIds, dateTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique(sectionId: number, dateTimeStamp: number, options?: any): AxiosPromise<DepositEntity> {
            return localVarFp.findUnique(sectionId, dateTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateSum(dateTimeStamp: number, seasonId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getDateSum(dateTimeStamp, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * this is implemented as POST instead of GET in order to avoid     errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateSums(seasonId: number, options?: any): AxiosPromise<Array<DateSumEntity>> {
            return localVarFp.getDateSums(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} sectionId 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSum(sectionId: number, dateTimeStamp: number, options?: any): AxiosPromise<number> {
            return localVarFp.getSum(sectionId, dateTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} sectionIds 
         * @param {number} dateTimeStamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSums(sectionIds: Array<string>, dateTimeStamp: number, options?: any): AxiosPromise<Array<SectionDepositSumEntity>> {
            return localVarFp.getSums(sectionIds, dateTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<DepositEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateDepositDto} updateDepositDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateDepositDto: UpdateDepositDto, options?: any): AxiosPromise<DepositEntity> {
            return localVarFp.update(id, updateDepositDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositsApi - object-oriented interface
 * @export
 * @class DepositsApi
 * @extends {BaseAPI}
 */
export class DepositsApi extends BaseAPI {
    /**
     * 
     * @param {number} seasonId 
     * @param {CreateDepositDto} createDepositDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public create(seasonId: number, createDepositDto: CreateDepositDto, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).create(seasonId, createDepositDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} sectionIds 
     * @param {number} dateTimeStamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public findBySeasons(sectionIds: Array<string>, dateTimeStamp: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).findBySeasons(sectionIds, dateTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} sectionId 
     * @param {number} dateTimeStamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public findUnique(sectionId: number, dateTimeStamp: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).findUnique(sectionId, dateTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dateTimeStamp 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public getDateSum(dateTimeStamp: number, seasonId: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).getDateSum(dateTimeStamp, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this is implemented as POST instead of GET in order to avoid     errors when dateTimeStamps gets too long to be included in a QUERY
     * @summary 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public getDateSums(seasonId: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).getDateSums(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} sectionId 
     * @param {number} dateTimeStamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public getSum(sectionId: number, dateTimeStamp: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).getSum(sectionId, dateTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} sectionIds 
     * @param {number} dateTimeStamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public getSums(sectionIds: Array<string>, dateTimeStamp: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).getSums(sectionIds, dateTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateDepositDto} updateDepositDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositsApi
     */
    public update(id: number, updateDepositDto: UpdateDepositDto, options?: AxiosRequestConfig) {
        return DepositsApiFp(this.configuration).update(id, updateDepositDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentTypesApi - axios parameter creator
 * @export
 */
export const DocumentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDocumentTypeDto} createDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createDocumentTypeDto: CreateDocumentTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentTypeDto' is not null or undefined
            assertParamExists('create', 'createDocumentTypeDto', createDocumentTypeDto)
            const localVarPath = `/api/v1/document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithToken: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('findAllWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/document-types/withToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/document-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('findOneByName', 'name', name)
            const localVarPath = `/api/v1/document-types/byName/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/document-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentTypeDto} updateDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateDocumentTypeDto: UpdateDocumentTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateDocumentTypeDto' is not null or undefined
            assertParamExists('update', 'updateDocumentTypeDto', updateDocumentTypeDto)
            const localVarPath = `/api/v1/document-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTypesApi - functional programming interface
 * @export
 */
export const DocumentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentTypeDto} createDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createDocumentTypeDto: CreateDocumentTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createDocumentTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTypeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithToken(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTypeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithToken(tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentTypeDto} updateDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateDocumentTypeDto: UpdateDocumentTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTypeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateDocumentTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTypesApi - factory interface
 * @export
 */
export const DocumentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentTypeDto} createDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createDocumentTypeDto: CreateDocumentTypeDto, options?: any): AxiosPromise<DocumentTypeEntity> {
            return localVarFp.create(createDocumentTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<DocumentTypeEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithToken(tenant: string, options?: any): AxiosPromise<Array<DocumentTypeEntity>> {
            return localVarFp.findAllWithToken(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<DocumentTypeEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneByName(name: string, options?: any): AxiosPromise<DocumentTypeEntity> {
            return localVarFp.findOneByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocumentTypeDto} updateDocumentTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateDocumentTypeDto: UpdateDocumentTypeDto, options?: any): AxiosPromise<DocumentTypeEntity> {
            return localVarFp.update(id, updateDocumentTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTypesApi - object-oriented interface
 * @export
 * @class DocumentTypesApi
 * @extends {BaseAPI}
 */
export class DocumentTypesApi extends BaseAPI {
    /**
     * 
     * @param {CreateDocumentTypeDto} createDocumentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public create(createDocumentTypeDto: CreateDocumentTypeDto, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).create(createDocumentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public findAllWithToken(tenant: string, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).findAllWithToken(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public findOneByName(name: string, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).findOneByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDocumentTypeDto} updateDocumentTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTypesApi
     */
    public update(id: string, updateDocumentTypeDto: UpdateDocumentTypeDto, options?: AxiosRequestConfig) {
        return DocumentTypesApiFp(this.configuration).update(id, updateDocumentTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeeDocumentApi - axios parameter creator
 * @export
 */
export const EmployeeDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createEmployeeDocumentDto: CreateEmployeeDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDocumentDto' is not null or undefined
            assertParamExists('create', 'createEmployeeDocumentDto', createEmployeeDocumentDto)
            const localVarPath = `/api/v1/employee-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {string} jobAdvertisementsId 
         * @param {string} company 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (seasonId: number, jobAdvertisementsId: string, company: string, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('createApplication', 'seasonId', seasonId)
            // verify required parameter 'jobAdvertisementsId' is not null or undefined
            assertParamExists('createApplication', 'jobAdvertisementsId', jobAdvertisementsId)
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createApplication', 'company', company)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('createApplication', 'fileName', fileName)
            const localVarPath = `/api/v1/employee-document/createApplication/{seasonId}/{jobAdvertisementsId}/{company}/{fileName}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"jobAdvertisementsId"}}`, encodeURIComponent(String(jobAdvertisementsId)))
                .replace(`{${"company"}}`, encodeURIComponent(String(company)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (id: string, sendEmail: boolean, attachFile: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFile', 'id', id)
            // verify required parameter 'sendEmail' is not null or undefined
            assertParamExists('createFile', 'sendEmail', sendEmail)
            // verify required parameter 'attachFile' is not null or undefined
            assertParamExists('createFile', 'attachFile', attachFile)
            const localVarPath = `/api/v1/employee-document/{id}/{sendEmail}/{attachFile}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"sendEmail"}}`, encodeURIComponent(String(sendEmail)))
                .replace(`{${"attachFile"}}`, encodeURIComponent(String(attachFile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileIfTaxCode: async (category: string, isVisible: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('createFileIfTaxCode', 'category', category)
            // verify required parameter 'isVisible' is not null or undefined
            assertParamExists('createFileIfTaxCode', 'isVisible', isVisible)
            // verify required parameter 'lookForNet' is not null or undefined
            assertParamExists('createFileIfTaxCode', 'lookForNet', lookForNet)
            // verify required parameter 'sendEmail' is not null or undefined
            assertParamExists('createFileIfTaxCode', 'sendEmail', sendEmail)
            // verify required parameter 'attachFile' is not null or undefined
            assertParamExists('createFileIfTaxCode', 'attachFile', attachFile)
            const localVarPath = `/api/v1/employee-document/createFilesIfTaxCode/{category}/{isVisible}/{lookForNet}/{sendEmail}/{attachFile}`
                .replace(`{${"category"}}`, encodeURIComponent(String(category)))
                .replace(`{${"isVisible"}}`, encodeURIComponent(String(isVisible)))
                .replace(`{${"lookForNet"}}`, encodeURIComponent(String(lookForNet)))
                .replace(`{${"sendEmail"}}`, encodeURIComponent(String(sendEmail)))
                .replace(`{${"attachFile"}}`, encodeURIComponent(String(attachFile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {string} prefix 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileIfTaxCodePrefix: async (category: string, isVisible: string, prefix: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'category', category)
            // verify required parameter 'isVisible' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'isVisible', isVisible)
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'prefix', prefix)
            // verify required parameter 'lookForNet' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'lookForNet', lookForNet)
            // verify required parameter 'sendEmail' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'sendEmail', sendEmail)
            // verify required parameter 'attachFile' is not null or undefined
            assertParamExists('createFileIfTaxCodePrefix', 'attachFile', attachFile)
            const localVarPath = `/api/v1/employee-document/createFilesIfTaxCode/{category}/{isVisible}/{prefix}/{lookForNet}/{sendEmail}/{attachFile}`
                .replace(`{${"category"}}`, encodeURIComponent(String(category)))
                .replace(`{${"isVisible"}}`, encodeURIComponent(String(isVisible)))
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)))
                .replace(`{${"lookForNet"}}`, encodeURIComponent(String(lookForNet)))
                .replace(`{${"sendEmail"}}`, encodeURIComponent(String(sendEmail)))
                .replace(`{${"attachFile"}}`, encodeURIComponent(String(attachFile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileWithToken: async (id: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFileWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createFileWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/employee-document/{id}/file/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createwithToken: async (tenant: string, createEmployeeDocumentDto: CreateEmployeeDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createwithToken', 'tenant', tenant)
            // verify required parameter 'createEmployeeDocumentDto' is not null or undefined
            assertParamExists('createwithToken', 'createEmployeeDocumentDto', createEmployeeDocumentDto)
            const localVarPath = `/api/v1/employee-document/withToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithToken: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('findAllWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/employee-document/withToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyself: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-document/findMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/employee-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnewithToken: async (id: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOnewithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('findOnewithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/employee-document/{id}/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/employee-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFile', 'id', id)
            const localVarPath = `/api/v1/employee-document/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFileWithToken: async (id: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFileWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeFileWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/employee-document/{id}/deleteWithToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail: async (id: number, attachFile: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendEmail', 'id', id)
            // verify required parameter 'attachFile' is not null or undefined
            assertParamExists('sendEmail', 'attachFile', attachFile)
            const localVarPath = `/api/v1/employee-document/sendEmail/{id}/{attachFile}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachFile"}}`, encodeURIComponent(String(attachFile)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateEmployeeDocumentDto' is not null or undefined
            assertParamExists('update', 'updateEmployeeDocumentDto', updateEmployeeDocumentDto)
            const localVarPath = `/api/v1/employee-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateByMyself: async (id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateByMyself', 'id', id)
            // verify required parameter 'updateEmployeeDocumentDto' is not null or undefined
            assertParamExists('updateByMyself', 'updateEmployeeDocumentDto', updateEmployeeDocumentDto)
            const localVarPath = `/api/v1/employee-document/{id}/updateByMyself`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken: async (id: string, tenant: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateWithToken', 'tenant', tenant)
            // verify required parameter 'updateEmployeeDocumentDto' is not null or undefined
            assertParamExists('updateWithToken', 'updateEmployeeDocumentDto', updateEmployeeDocumentDto)
            const localVarPath = `/api/v1/employee-document/updateWithToken/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeDocumentApi - functional programming interface
 * @export
 */
export const EmployeeDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createEmployeeDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {string} jobAdvertisementsId 
         * @param {string} company 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(seasonId: number, jobAdvertisementsId: string, company: string, fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(seasonId, jobAdvertisementsId, company, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(id: string, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(id, sendEmail, attachFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileIfTaxCode(category: string, isVisible: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedZipEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileIfTaxCode(category, isVisible, lookForNet, sendEmail, attachFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {string} prefix 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileIfTaxCodePrefix(category: string, isVisible: string, prefix: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedZipEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileIfTaxCodePrefix(category, isVisible, prefix, lookForNet, sendEmail, attachFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileWithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createwithToken(tenant: string, createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createwithToken(tenant, createEmployeeDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDocumentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithToken(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDocumentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithToken(tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyself(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDocumentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyself(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOnewithToken(id: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOnewithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFileEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentFileEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFileWithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(id: number, attachFile: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(id, attachFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateEmployeeDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateByMyself(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateByMyself(id, updateEmployeeDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithToken(id: string, tenant: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithToken(id, tenant, updateEmployeeDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeDocumentApi - factory interface
 * @export
 */
export const EmployeeDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.create(createEmployeeDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {string} jobAdvertisementsId 
         * @param {string} company 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(seasonId: number, jobAdvertisementsId: string, company: string, fileName: string, options?: any): AxiosPromise<ApplicationEntity> {
            return localVarFp.createApplication(seasonId, jobAdvertisementsId, company, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(id: string, sendEmail: boolean, attachFile: boolean, options?: any): AxiosPromise<Array<DocumentFileEntity>> {
            return localVarFp.createFile(id, sendEmail, attachFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileIfTaxCode(category: string, isVisible: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: any): AxiosPromise<UploadedZipEntity> {
            return localVarFp.createFileIfTaxCode(category, isVisible, lookForNet, sendEmail, attachFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} category 
         * @param {string} isVisible 
         * @param {string} prefix 
         * @param {boolean} lookForNet 
         * @param {boolean} sendEmail 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileIfTaxCodePrefix(category: string, isVisible: string, prefix: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: any): AxiosPromise<UploadedZipEntity> {
            return localVarFp.createFileIfTaxCodePrefix(category, isVisible, prefix, lookForNet, sendEmail, attachFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileWithToken(id: string, tenant: string, options?: any): AxiosPromise<Array<DocumentFileEntity>> {
            return localVarFp.createFileWithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createwithToken(tenant: string, createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.createwithToken(tenant, createEmployeeDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<EmployeeDocumentEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithToken(tenant: string, options?: any): AxiosPromise<Array<EmployeeDocumentEntity>> {
            return localVarFp.findAllWithToken(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyself(options?: any): AxiosPromise<Array<EmployeeDocumentEntity>> {
            return localVarFp.findMyself(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOnewithToken(id: string, tenant: string, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.findOnewithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFile(id: string, options?: any): AxiosPromise<DocumentFileEntity> {
            return localVarFp.removeFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFileWithToken(id: string, tenant: string, options?: any): AxiosPromise<DocumentFileEntity> {
            return localVarFp.removeFileWithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} attachFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(id: number, attachFile: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmail(id, attachFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.update(id, updateEmployeeDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateByMyself(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.updateByMyself(id, updateEmployeeDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken(id: string, tenant: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: any): AxiosPromise<EmployeeDocumentEntity> {
            return localVarFp.updateWithToken(id, tenant, updateEmployeeDocumentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeDocumentApi - object-oriented interface
 * @export
 * @class EmployeeDocumentApi
 * @extends {BaseAPI}
 */
export class EmployeeDocumentApi extends BaseAPI {
    /**
     * 
     * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public create(createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).create(createEmployeeDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {string} jobAdvertisementsId 
     * @param {string} company 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createApplication(seasonId: number, jobAdvertisementsId: string, company: string, fileName: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createApplication(seasonId, jobAdvertisementsId, company, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} sendEmail 
     * @param {boolean} attachFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createFile(id: string, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createFile(id, sendEmail, attachFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} category 
     * @param {string} isVisible 
     * @param {boolean} lookForNet 
     * @param {boolean} sendEmail 
     * @param {boolean} attachFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createFileIfTaxCode(category: string, isVisible: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createFileIfTaxCode(category, isVisible, lookForNet, sendEmail, attachFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} category 
     * @param {string} isVisible 
     * @param {string} prefix 
     * @param {boolean} lookForNet 
     * @param {boolean} sendEmail 
     * @param {boolean} attachFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createFileIfTaxCodePrefix(category: string, isVisible: string, prefix: string, lookForNet: boolean, sendEmail: boolean, attachFile: boolean, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createFileIfTaxCodePrefix(category, isVisible, prefix, lookForNet, sendEmail, attachFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createFileWithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {CreateEmployeeDocumentDto} createEmployeeDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public createwithToken(tenant: string, createEmployeeDocumentDto: CreateEmployeeDocumentDto, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).createwithToken(tenant, createEmployeeDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public findAllWithToken(tenant: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).findAllWithToken(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public findMyself(options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).findMyself(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public findOnewithToken(id: string, tenant: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).findOnewithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public removeFile(id: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).removeFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public removeFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).removeFileWithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} attachFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public sendEmail(id: number, attachFile: boolean, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).sendEmail(id, attachFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public update(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).update(id, updateEmployeeDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public updateByMyself(id: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).updateByMyself(id, updateEmployeeDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {UpdateEmployeeDocumentDto} updateEmployeeDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeDocumentApi
     */
    public updateWithToken(id: string, tenant: string, updateEmployeeDocumentDto: UpdateEmployeeDocumentDto, options?: AxiosRequestConfig) {
        return EmployeeDocumentApiFp(this.configuration).updateWithToken(id, tenant, updateEmployeeDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeeNoteApi - axios parameter creator
 * @export
 */
export const EmployeeNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNoteDto} createNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createNoteDto: CreateNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNoteDto' is not null or undefined
            assertParamExists('create', 'createNoteDto', createNoteDto)
            const localVarPath = `/api/v1/employee-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/employee-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNoteDto} updateNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateNoteDto: UpdateNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateNoteDto' is not null or undefined
            assertParamExists('update', 'updateNoteDto', updateNoteDto)
            const localVarPath = `/api/v1/employee-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeNoteApi - functional programming interface
 * @export
 */
export const EmployeeNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNoteDto} createNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createNoteDto: CreateNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNoteDto} updateNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateNoteDto: UpdateNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeNoteApi - factory interface
 * @export
 */
export const EmployeeNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateNoteDto} createNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createNoteDto: CreateNoteDto, options?: any): AxiosPromise<NoteEntity> {
            return localVarFp.create(createNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId?: number, options?: any): AxiosPromise<Array<NoteEntity>> {
            return localVarFp.findAll(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateNoteDto} updateNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateNoteDto: UpdateNoteDto, options?: any): AxiosPromise<NoteEntity> {
            return localVarFp.update(id, updateNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeNoteApi - object-oriented interface
 * @export
 * @class EmployeeNoteApi
 * @extends {BaseAPI}
 */
export class EmployeeNoteApi extends BaseAPI {
    /**
     * 
     * @param {CreateNoteDto} createNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeNoteApi
     */
    public create(createNoteDto: CreateNoteDto, options?: AxiosRequestConfig) {
        return EmployeeNoteApiFp(this.configuration).create(createNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employeeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeNoteApi
     */
    public findAll(employeeId?: number, options?: AxiosRequestConfig) {
        return EmployeeNoteApiFp(this.configuration).findAll(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeNoteApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return EmployeeNoteApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateNoteDto} updateNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeNoteApi
     */
    public update(id: number, updateNoteDto: UpdateNoteDto, options?: AxiosRequestConfig) {
        return EmployeeNoteApiFp(this.configuration).update(id, updateNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeePaymentsApi - axios parameter creator
 * @export
 */
export const EmployeePaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/v1/employee-payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-payments/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAll', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAll', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employee-payments/{employeeId}/{seasonId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInPdf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employee-payments/findInPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPayment: async (paymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('uploadPayment', 'paymentId', paymentId)
            const localVarPath = `/api/v1/employee-payments/updateDocument/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeePaymentsApi - functional programming interface
 * @export
 */
export const EmployeePaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeePaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findInPdf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentPageDataEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findInPdf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPayment(paymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPayment(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeePaymentsApi - factory interface
 * @export
 */
export const EmployeePaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeePaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(options?: any): AxiosPromise<object> {
            return localVarFp.create(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId: number, seasonId: number, options?: any): AxiosPromise<Array<PaymentEntity>> {
            return localVarFp.findAll(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findInPdf(options?: any): AxiosPromise<Array<PaymentPageDataEntity>> {
            return localVarFp.findInPdf(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPayment(paymentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.uploadPayment(paymentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeePaymentsApi - object-oriented interface
 * @export
 * @class EmployeePaymentsApi
 * @extends {BaseAPI}
 */
export class EmployeePaymentsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentsApi
     */
    public _delete(id: number, options?: AxiosRequestConfig) {
        return EmployeePaymentsApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentsApi
     */
    public create(options?: AxiosRequestConfig) {
        return EmployeePaymentsApiFp(this.configuration).create(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentsApi
     */
    public findAll(employeeId: number, seasonId: number, options?: AxiosRequestConfig) {
        return EmployeePaymentsApiFp(this.configuration).findAll(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentsApi
     */
    public findInPdf(options?: AxiosRequestConfig) {
        return EmployeePaymentsApiFp(this.configuration).findInPdf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeePaymentsApi
     */
    public uploadPayment(paymentId: number, options?: AxiosRequestConfig) {
        return EmployeePaymentsApiFp(this.configuration).uploadPayment(paymentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoom: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/assignRoom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSeason: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/assignSeason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/assignStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canEmployeeBeRemoved: async (employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('canEmployeeBeRemoved', 'employeeId', employeeId)
            const localVarPath = `/api/v1/employees/canBeRemoved/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createEmployeeDto: CreateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEmployeeDto' is not null or undefined
            assertParamExists('create', 'createEmployeeDto', createEmployeeDto)
            const localVarPath = `/api/v1/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFile', 'id', id)
            const localVarPath = `/api/v1/employees/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileWithToken: async (id: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFileWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createFileWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/employees/{id}/file/withToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllDepartments: async (id: string, seasonId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllDepartments', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllDepartments', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employees/{id}/departments/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {boolean} findAuthorizedUsers 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllDepchief: async (seasonId: number, findAuthorizedUsers: boolean, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllDepchief', 'seasonId', seasonId)
            // verify required parameter 'findAuthorizedUsers' is not null or undefined
            assertParamExists('findAllDepchief', 'findAuthorizedUsers', findAuthorizedUsers)
            const localVarPath = `/api/v1/employees/depchief/{seasonId}/{findAuthorizedUsers}/{departmentId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }

            if (findAuthorizedUsers !== undefined) {
                localVarQueryParameter['findAuthorizedUsers'] = findAuthorizedUsers;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignableEmployees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/assignable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {boolean} includeDepartments 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIds: async (includeDepartments: boolean, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeDepartments' is not null or undefined
            assertParamExists('findByIds', 'includeDepartments', includeDepartments)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('findByIds', 'requestBody', requestBody)
            const localVarPath = `/api/v1/employees/byIds/{includeDepartments}`
                .replace(`{${"includeDepartments"}}`, encodeURIComponent(String(includeDepartments)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyselfWithoutSeaoson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMyselfWithoutSeaoson', 'id', id)
            const localVarPath = `/api/v1/employees/findMyselfWithoutSeason/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNonUnlimitedEmployees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/limited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithExpiredToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithExpiredToken', 'id', id)
            const localVarPath = `/api/v1/employees/{id}/withExpiredToken`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithToken', 'id', id)
            const localVarPath = `/api/v1/employees/getWithToken/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithoutSeaoson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOneWithoutSeaoson', 'id', id)
            const localVarPath = `/api/v1/employees/findOneWithoutSeason/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRemovedFromTurnovers: async (seasonId: number, fromDate: string, toDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findRemovedFromTurnovers', 'seasonId', seasonId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('findRemovedFromTurnovers', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('findRemovedFromTurnovers', 'toDate', toDate)
            const localVarPath = `/api/v1/employees/removedFromTurnovers/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubordinates: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findSubordinates', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employees/subordinates/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnlimitedEmployees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/unlimited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/allEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} taxCodes 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesByTaxcode: async (taxCodes: Array<string>, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxCodes' is not null or undefined
            assertParamExists('getEmployeesByTaxcode', 'taxCodes', taxCodes)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getEmployeesByTaxcode', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employees/byTaxCode/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taxCodes) {
                localVarQueryParameter['taxCodes'] = taxCodes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIfCommunications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/getIfCommunications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIfNoCommunications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/getIfNoCommunications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySelf: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/getMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is realized as a POST since the userIds can be a very long array that might exceed the practical limit of 2000 chars in a URL.    For further information on why this endpoint is not implemented as GET please refer to this post: https://stackoverflow.com/a/4203849/18487494
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportForAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/employees/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeason: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSeason', 'id', id)
            const localVarPath = `/api/v1/employees/seasonToDisplay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getStats', 'employeeId', employeeId)
            const localVarPath = `/api/v1/employees/longevity/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithBuildingsInfo: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getWithBuildingsInfo', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employees/getWithBuildingsInfo/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetSeasonToDisplayDto} setSeasonToDisplayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSeasonToDisplay: async (setSeasonToDisplayDto: SetSeasonToDisplayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setSeasonToDisplayDto' is not null or undefined
            assertParamExists('setSeasonToDisplay', 'setSeasonToDisplayDto', setSeasonToDisplayDto)
            const localVarPath = `/api/v1/employees/setSeasonToDisplay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSeasonToDisplayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignSeason: async (employeeId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('unassignSeason', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('unassignSeason', 'seasonId', seasonId)
            const localVarPath = `/api/v1/employees/unassignSeason/{employeeId}/{seasonId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateEmployeeDto: UpdateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateEmployeeDto' is not null or undefined
            assertParamExists('update', 'updateEmployeeDto', updateEmployeeDto)
            const localVarPath = `/api/v1/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMySelf: async (updateEmployeeDto: UpdateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEmployeeDto' is not null or undefined
            assertParamExists('updateMySelf', 'updateEmployeeDto', updateEmployeeDto)
            const localVarPath = `/api/v1/employees/updateMyself`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken: async (id: string, tenant: string, updateEmployeeDto: UpdateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithToken', 'id', id)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateWithToken', 'tenant', tenant)
            // verify required parameter 'updateEmployeeDto' is not null or undefined
            assertParamExists('updateWithToken', 'updateEmployeeDto', updateEmployeeDto)
            const localVarPath = `/api/v1/employees/updateWithToken/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRoom(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRoom(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignSeason(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignSeason(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canEmployeeBeRemoved(employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canEmployeeBeRemoved(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileWithToken(id, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllDepartments(id: string, seasonId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeesDepartmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllDepartments(id, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {boolean} findAuthorizedUsers 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllDepchief(seasonId: number, findAuthorizedUsers: boolean, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllDepchief(seasonId, findAuthorizedUsers, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAssignableEmployees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAssignableEmployees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {boolean} includeDepartments 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIds(includeDepartments: boolean, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByIds(includeDepartments, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyselfWithoutSeaoson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyselfWithoutSeaoson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNonUnlimitedEmployees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNonUnlimitedEmployees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithExpiredToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithExpiredToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOneWithoutSeaoson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOneWithoutSeaoson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRemovedFromTurnovers(seasonId: number, fromDate: string, toDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRemovedFromTurnovers(seasonId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubordinates(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSubordinates(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnlimitedEmployees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnlimitedEmployees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmployees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmployees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} taxCodes 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeesByTaxcode(taxCodes: Array<string>, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeesByTaxcode(taxCodes, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIfCommunications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIfCommunications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIfNoCommunications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIfNoCommunications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySelf(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is realized as a POST since the userIds can be a very long array that might exceed the practical limit of 2000 chars in a URL.    For further information on why this endpoint is not implemented as GET please refer to this post: https://stackoverflow.com/a/4203849/18487494
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportForAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportForAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeason(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeason(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithBuildingsInfo(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeToSeasonEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithBuildingsInfo(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetSeasonToDisplayDto} setSeasonToDisplayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSeasonToDisplay(setSeasonToDisplayDto: SetSeasonToDisplayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSeasonToDisplay(setSeasonToDisplayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignSeason(employeeId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignSeason(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMySelf(updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMySelf(updateEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithToken(id: string, tenant: string, updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithToken(id, tenant, updateEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoom(options?: any): AxiosPromise<void> {
            return localVarFp.assignRoom(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignSeason(options?: any): AxiosPromise<void> {
            return localVarFp.assignSeason(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignStatus(options?: any): AxiosPromise<void> {
            return localVarFp.assignStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canEmployeeBeRemoved(employeeId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.canEmployeeBeRemoved(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createEmployeeDto: CreateEmployeeDto, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.create(createEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(id: string, options?: any): AxiosPromise<FileEntity> {
            return localVarFp.createFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileWithToken(id: string, tenant: string, options?: any): AxiosPromise<FileEntity> {
            return localVarFp.createFileWithToken(id, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllDepartments(id: string, seasonId: string, options?: any): AxiosPromise<Array<EmployeesDepartmentEntity>> {
            return localVarFp.findAllDepartments(id, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {boolean} findAuthorizedUsers 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllDepchief(seasonId: number, findAuthorizedUsers: boolean, departmentId?: number, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findAllDepchief(seasonId, findAuthorizedUsers, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignableEmployees(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findAssignableEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {boolean} includeDepartments 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIds(includeDepartments: boolean, requestBody: Array<string>, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findByIds(includeDepartments, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyselfWithoutSeaoson(id: string, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.findMyselfWithoutSeaoson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNonUnlimitedEmployees(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findNonUnlimitedEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: number, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithExpiredToken(id: string, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.findOneWithExpiredToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithToken(id: string, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.findOneWithToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOneWithoutSeaoson(id: string, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.findOneWithoutSeaoson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
         * @summary 
         * @param {number} seasonId 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRemovedFromTurnovers(seasonId: number, fromDate: string, toDate: string, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findRemovedFromTurnovers(seasonId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubordinates(seasonId: number, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findSubordinates(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnlimitedEmployees(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findUnlimitedEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.getAllEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} taxCodes 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeesByTaxcode(taxCodes: Array<string>, seasonId: number, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.getEmployeesByTaxcode(taxCodes, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIfCommunications(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.getIfCommunications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIfNoCommunications(options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.getIfNoCommunications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySelf(options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.getMySelf(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is realized as a POST since the userIds can be a very long array that might exceed the practical limit of 2000 chars in a URL.    For further information on why this endpoint is not implemented as GET please refer to this post: https://stackoverflow.com/a/4203849/18487494
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportForAllUsers(options?: any): AxiosPromise<object> {
            return localVarFp.getReportForAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeason(id: number, options?: any): AxiosPromise<SeasonEntity> {
            return localVarFp.getSeason(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(employeeId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getStats(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithBuildingsInfo(seasonId: number, options?: any): AxiosPromise<Array<EmployeeToSeasonEntity>> {
            return localVarFp.getWithBuildingsInfo(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetSeasonToDisplayDto} setSeasonToDisplayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSeasonToDisplay(setSeasonToDisplayDto: SetSeasonToDisplayDto, options?: any): AxiosPromise<void> {
            return localVarFp.setSeasonToDisplay(setSeasonToDisplayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignSeason(employeeId: number, seasonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unassignSeason(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateEmployeeDto: UpdateEmployeeDto, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.update(id, updateEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMySelf(updateEmployeeDto: UpdateEmployeeDto, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.updateMySelf(updateEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenant 
         * @param {UpdateEmployeeDto} updateEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken(id: string, tenant: string, updateEmployeeDto: UpdateEmployeeDto, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.updateWithToken(id, tenant, updateEmployeeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public assignRoom(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).assignRoom(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public assignSeason(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).assignSeason(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public assignStatus(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).assignStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public canEmployeeBeRemoved(employeeId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).canEmployeeBeRemoved(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEmployeeDto} createEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public create(createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).create(createEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public createFile(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).createFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public createFileWithToken(id: string, tenant: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).createFileWithToken(id, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findAllDepartments(id: string, seasonId: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findAllDepartments(id, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {boolean} findAuthorizedUsers 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findAllDepchief(seasonId: number, findAuthorizedUsers: boolean, departmentId?: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findAllDepchief(seasonId, findAuthorizedUsers, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findAssignableEmployees(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findAssignableEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
     * @summary 
     * @param {boolean} includeDepartments 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findByIds(includeDepartments: boolean, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findByIds(includeDepartments, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findMyselfWithoutSeaoson(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findMyselfWithoutSeaoson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findNonUnlimitedEmployees(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findNonUnlimitedEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findOne(id: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findOneWithExpiredToken(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findOneWithExpiredToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findOneWithToken(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findOneWithToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findOneWithoutSeaoson(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findOneWithoutSeaoson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this is implemented as POST instead of GET in order to avoid   errors when dateTimeStamps gets too long to be included in a QUERY
     * @summary 
     * @param {number} seasonId 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findRemovedFromTurnovers(seasonId: number, fromDate: string, toDate: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findRemovedFromTurnovers(seasonId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findSubordinates(seasonId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findSubordinates(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public findUnlimitedEmployees(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).findUnlimitedEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getAllEmployees(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getAllEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} taxCodes 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getEmployeesByTaxcode(taxCodes: Array<string>, seasonId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getEmployeesByTaxcode(taxCodes, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getIfCommunications(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getIfCommunications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getIfNoCommunications(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getIfNoCommunications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getMySelf(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getMySelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is realized as a POST since the userIds can be a very long array that might exceed the practical limit of 2000 chars in a URL.    For further information on why this endpoint is not implemented as GET please refer to this post: https://stackoverflow.com/a/4203849/18487494
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getReportForAllUsers(options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getReportForAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getSeason(id: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getSeason(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getStats(employeeId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getStats(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public getWithBuildingsInfo(seasonId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).getWithBuildingsInfo(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetSeasonToDisplayDto} setSeasonToDisplayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public setSeasonToDisplay(setSeasonToDisplayDto: SetSeasonToDisplayDto, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).setSeasonToDisplay(setSeasonToDisplayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public unassignSeason(employeeId: number, seasonId: number, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).unassignSeason(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateEmployeeDto} updateEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public update(id: string, updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).update(id, updateEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateEmployeeDto} updateEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public updateMySelf(updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).updateMySelf(updateEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenant 
     * @param {UpdateEmployeeDto} updateEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeesApi
     */
    public updateWithToken(id: string, tenant: string, updateEmployeeDto: UpdateEmployeeDto, options?: AxiosRequestConfig) {
        return EmployeesApiFp(this.configuration).updateWithToken(id, tenant, updateEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExperienceLevelApi - axios parameter creator
 * @export
 */
export const ExperienceLevelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateExperienceLevelDto} createExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createExperienceLevelDto: CreateExperienceLevelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExperienceLevelDto' is not null or undefined
            assertParamExists('create', 'createExperienceLevelDto', createExperienceLevelDto)
            const localVarPath = `/api/v1/experience-level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExperienceLevelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/experience-level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/experience-level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/experience-level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExperienceLevelDto} updateExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateExperienceLevelDto: UpdateExperienceLevelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateExperienceLevelDto' is not null or undefined
            assertParamExists('update', 'updateExperienceLevelDto', updateExperienceLevelDto)
            const localVarPath = `/api/v1/experience-level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperienceLevelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperienceLevelApi - functional programming interface
 * @export
 */
export const ExperienceLevelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperienceLevelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateExperienceLevelDto} createExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createExperienceLevelDto: CreateExperienceLevelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceLevelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createExperienceLevelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExperienceLevelEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceLevelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExperienceLevelDto} updateExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateExperienceLevelDto: UpdateExperienceLevelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceLevelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateExperienceLevelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExperienceLevelApi - factory interface
 * @export
 */
export const ExperienceLevelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperienceLevelApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateExperienceLevelDto} createExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createExperienceLevelDto: CreateExperienceLevelDto, options?: any): AxiosPromise<ExperienceLevelEntity> {
            return localVarFp.create(createExperienceLevelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<ExperienceLevelEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<ExperienceLevelEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateExperienceLevelDto} updateExperienceLevelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateExperienceLevelDto: UpdateExperienceLevelDto, options?: any): AxiosPromise<ExperienceLevelEntity> {
            return localVarFp.update(id, updateExperienceLevelDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExperienceLevelApi - object-oriented interface
 * @export
 * @class ExperienceLevelApi
 * @extends {BaseAPI}
 */
export class ExperienceLevelApi extends BaseAPI {
    /**
     * 
     * @param {CreateExperienceLevelDto} createExperienceLevelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceLevelApi
     */
    public create(createExperienceLevelDto: CreateExperienceLevelDto, options?: AxiosRequestConfig) {
        return ExperienceLevelApiFp(this.configuration).create(createExperienceLevelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceLevelApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return ExperienceLevelApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceLevelApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return ExperienceLevelApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceLevelApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return ExperienceLevelApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateExperienceLevelDto} updateExperienceLevelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienceLevelApi
     */
    public update(id: string, updateExperienceLevelDto: UpdateExperienceLevelDto, options?: AxiosRequestConfig) {
        return ExperienceLevelApiFp(this.configuration).update(id, updateExperienceLevelDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobAdvertisementsApi - axios parameter creator
 * @export
 */
export const JobAdvertisementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateJobAdDto} createJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createJobAdDto: CreateJobAdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createJobAdDto' is not null or undefined
            assertParamExists('create', 'createJobAdDto', createJobAdDto)
            const localVarPath = `/api/v1/job-advertisements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createJobAdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/job-advertisements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBySeason: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllBySeason', 'seasonId', seasonId)
            const localVarPath = `/api/v1/job-advertisements/findAllBySeason/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCandidates: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findCandidates', 'id', id)
            const localVarPath = `/api/v1/job-advertisements/findCandidates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (company: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('findOne', 'company', company)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/job-advertisements/findOne/{company}/{id}`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCandidate: async (company: string, id: string, seasonId: number, createEmployeeDto: CreateEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('newCandidate', 'company', company)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newCandidate', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('newCandidate', 'seasonId', seasonId)
            // verify required parameter 'createEmployeeDto' is not null or undefined
            assertParamExists('newCandidate', 'createEmployeeDto', createEmployeeDto)
            const localVarPath = `/api/v1/job-advertisements/newCandidate/{company}/{id}/{seasonId}`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/job-advertisements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateJobAdDto} updateJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateJobAdDto: UpdateJobAdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateJobAdDto' is not null or undefined
            assertParamExists('update', 'updateJobAdDto', updateJobAdDto)
            const localVarPath = `/api/v1/job-advertisements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJobAdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobAdvertisementsApi - functional programming interface
 * @export
 */
export const JobAdvertisementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobAdvertisementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateJobAdDto} createJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createJobAdDto: CreateJobAdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createJobAdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllBySeason(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllBySeason(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCandidates(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCandidates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(company: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(company, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCandidate(company: string, id: string, seasonId: number, createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newCandidate(company, id, seasonId, createEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateJobAdDto} updateJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateJobAdDto: UpdateJobAdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateJobAdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobAdvertisementsApi - factory interface
 * @export
 */
export const JobAdvertisementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobAdvertisementsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateJobAdDto} createJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createJobAdDto: CreateJobAdDto, options?: any): AxiosPromise<JobEntity> {
            return localVarFp.create(createJobAdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<JobEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllBySeason(seasonId: number, options?: any): AxiosPromise<Array<JobEntity>> {
            return localVarFp.findAllBySeason(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCandidates(id: number, options?: any): AxiosPromise<Array<EmployeeEntity>> {
            return localVarFp.findCandidates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(company: string, id: string, options?: any): AxiosPromise<JobEntity> {
            return localVarFp.findOne(company, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {CreateEmployeeDto} createEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCandidate(company: string, id: string, seasonId: number, createEmployeeDto: CreateEmployeeDto, options?: any): AxiosPromise<EmployeeEntity> {
            return localVarFp.newCandidate(company, id, seasonId, createEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateJobAdDto} updateJobAdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateJobAdDto: UpdateJobAdDto, options?: any): AxiosPromise<JobEntity> {
            return localVarFp.update(id, updateJobAdDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobAdvertisementsApi - object-oriented interface
 * @export
 * @class JobAdvertisementsApi
 * @extends {BaseAPI}
 */
export class JobAdvertisementsApi extends BaseAPI {
    /**
     * 
     * @param {CreateJobAdDto} createJobAdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public create(createJobAdDto: CreateJobAdDto, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).create(createJobAdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public findAllBySeason(seasonId: number, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).findAllBySeason(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public findCandidates(id: number, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).findCandidates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public findOne(company: string, id: string, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).findOne(company, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {string} id 
     * @param {number} seasonId 
     * @param {CreateEmployeeDto} createEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public newCandidate(company: string, id: string, seasonId: number, createEmployeeDto: CreateEmployeeDto, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).newCandidate(company, id, seasonId, createEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateJobAdDto} updateJobAdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAdvertisementsApi
     */
    public update(id: string, updateJobAdDto: UpdateJobAdDto, options?: AxiosRequestConfig) {
        return JobAdvertisementsApiFp(this.configuration).update(id, updateJobAdDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenceApi - axios parameter creator
 * @export
 */
export const LicenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLicence: async (licenceId: number, additionalInformationsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenceId' is not null or undefined
            assertParamExists('assignLicence', 'licenceId', licenceId)
            // verify required parameter 'additionalInformationsId' is not null or undefined
            assertParamExists('assignLicence', 'additionalInformationsId', additionalInformationsId)
            const localVarPath = `/api/v1/licence/assignLicence/{licenceId}/{additionalInformationsId}`
                .replace(`{${"licenceId"}}`, encodeURIComponent(String(licenceId)))
                .replace(`{${"additionalInformationsId"}}`, encodeURIComponent(String(additionalInformationsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLicenceDto} createLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createLicenceDto: CreateLicenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLicenceDto' is not null or undefined
            assertParamExists('create', 'createLicenceDto', createLicenceDto)
            const localVarPath = `/api/v1/licence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLicenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/licence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/licence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignment: async (licenceId: number, additionalInformationsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenceId' is not null or undefined
            assertParamExists('removeAssignment', 'licenceId', licenceId)
            // verify required parameter 'additionalInformationsId' is not null or undefined
            assertParamExists('removeAssignment', 'additionalInformationsId', additionalInformationsId)
            const localVarPath = `/api/v1/licence/removeAssignment/{licenceId}/{additionalInformationsId}`
                .replace(`{${"licenceId"}}`, encodeURIComponent(String(licenceId)))
                .replace(`{${"additionalInformationsId"}}`, encodeURIComponent(String(additionalInformationsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLicenceDto} updateLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateLicenceDto: UpdateLicenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateLicenceDto' is not null or undefined
            assertParamExists('update', 'updateLicenceDto', updateLicenceDto)
            const localVarPath = `/api/v1/licence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLicenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenceApi - functional programming interface
 * @export
 */
export const LicenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignLicence(licenceId: number, additionalInformationsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignLicence(licenceId, additionalInformationsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateLicenceDto} createLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createLicenceDto: CreateLicenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createLicenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LicenceEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAssignment(licenceId: number, additionalInformationsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAssignment(licenceId, additionalInformationsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLicenceDto} updateLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateLicenceDto: UpdateLicenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenceEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateLicenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenceApi - factory interface
 * @export
 */
export const LicenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLicence(licenceId: number, additionalInformationsId: number, options?: any): AxiosPromise<void> {
            return localVarFp.assignLicence(licenceId, additionalInformationsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateLicenceDto} createLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createLicenceDto: CreateLicenceDto, options?: any): AxiosPromise<LicenceEntity> {
            return localVarFp.create(createLicenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<LicenceEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<LicenceEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} licenceId 
         * @param {number} additionalInformationsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignment(licenceId: number, additionalInformationsId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeAssignment(licenceId, additionalInformationsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLicenceDto} updateLicenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateLicenceDto: UpdateLicenceDto, options?: any): AxiosPromise<LicenceEntity> {
            return localVarFp.update(id, updateLicenceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenceApi - object-oriented interface
 * @export
 * @class LicenceApi
 * @extends {BaseAPI}
 */
export class LicenceApi extends BaseAPI {
    /**
     * 
     * @param {number} licenceId 
     * @param {number} additionalInformationsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public assignLicence(licenceId: number, additionalInformationsId: number, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).assignLicence(licenceId, additionalInformationsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateLicenceDto} createLicenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public create(createLicenceDto: CreateLicenceDto, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).create(createLicenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} licenceId 
     * @param {number} additionalInformationsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public removeAssignment(licenceId: number, additionalInformationsId: number, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).removeAssignment(licenceId, additionalInformationsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLicenceDto} updateLicenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenceApi
     */
    public update(id: string, updateLicenceDto: UpdateLicenceDto, options?: AxiosRequestConfig) {
        return LicenceApiFp(this.configuration).update(id, updateLicenceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/old`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadNotification: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('setReadNotification', 'body', body)
            const localVarPath = `/api/v1/notifications/setRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOldNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOldNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReadNotification(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setReadNotification(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewNotifications(options?: any): AxiosPromise<Array<NotificationEntity>> {
            return localVarFp.getNewNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldNotifications(options?: any): AxiosPromise<Array<NotificationEntity>> {
            return localVarFp.getOldNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadNotification(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.setReadNotification(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNewNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNewNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getOldNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getOldNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public setReadNotification(body: object, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).setReadNotification(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentMethodsApi - axios parameter creator
 * @export
 */
export const PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPaymentMethodDto: CreatePaymentMethodDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentMethodDto' is not null or undefined
            assertParamExists('create', 'createPaymentMethodDto', createPaymentMethodDto)
            const localVarPath = `/api/v1/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAvaible: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment-methods/avaible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePaymentMethodDto} updatePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updatePaymentMethodDto: UpdatePaymentMethodDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePaymentMethodDto' is not null or undefined
            assertParamExists('update', 'updatePaymentMethodDto', updatePaymentMethodDto)
            const localVarPath = `/api/v1/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentMethodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodsApi - functional programming interface
 * @export
 */
export const PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPaymentMethodDto: CreatePaymentMethodDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPaymentMethodDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethodEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAvaible(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAvaible(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePaymentMethodDto} updatePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updatePaymentMethodDto: UpdatePaymentMethodDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePaymentMethodDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentMethodsApi - factory interface
 * @export
 */
export const PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentMethodDto} createPaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPaymentMethodDto: CreatePaymentMethodDto, options?: any): AxiosPromise<PaymentMethodEntity> {
            return localVarFp.create(createPaymentMethodDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<PaymentMethodEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAvaible(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.findAllAvaible(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<PaymentMethodEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePaymentMethodDto} updatePaymentMethodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updatePaymentMethodDto: UpdatePaymentMethodDto, options?: any): AxiosPromise<PaymentMethodEntity> {
            return localVarFp.update(id, updatePaymentMethodDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentMethodsApi - object-oriented interface
 * @export
 * @class PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class PaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @param {CreatePaymentMethodDto} createPaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public create(createPaymentMethodDto: CreatePaymentMethodDto, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).create(createPaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public findAllAvaible(options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).findAllAvaible(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePaymentMethodDto} updatePaymentMethodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public update(id: string, updatePaymentMethodDto: UpdatePaymentMethodDto, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).update(id, updatePaymentMethodDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicDocumentsApi - axios parameter creator
 * @export
 */
export const PublicDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public-documents/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public-documents/byUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/public-documents/findOne/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/public-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePublicDocumentDto} updatePublicDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePublicDocumentDto: UpdatePublicDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePublicDocumentDto' is not null or undefined
            assertParamExists('update', 'updatePublicDocumentDto', updatePublicDocumentDto)
            const localVarPath = `/api/v1/public-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublicDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicDocumentsApi - functional programming interface
 * @export
 */
export const PublicDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicDocumentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicDocumentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicDocumentEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePublicDocumentDto} updatePublicDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePublicDocumentDto: UpdatePublicDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePublicDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicDocumentsApi - factory interface
 * @export
 */
export const PublicDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(options?: any): AxiosPromise<void> {
            return localVarFp.create(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<PublicDocumentEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUser(options?: any): AxiosPromise<Array<PublicDocumentEntity>> {
            return localVarFp.findByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: number, options?: any): AxiosPromise<PublicDocumentEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePublicDocumentDto} updatePublicDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePublicDocumentDto: UpdatePublicDocumentDto, options?: any): AxiosPromise<void> {
            return localVarFp.update(id, updatePublicDocumentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicDocumentsApi - object-oriented interface
 * @export
 * @class PublicDocumentsApi
 * @extends {BaseAPI}
 */
export class PublicDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public create(options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).create(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public findByUser(options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).findByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public findOne(id: number, options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePublicDocumentDto} updatePublicDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDocumentsApi
     */
    public update(id: number, updatePublicDocumentDto: UpdatePublicDocumentDto, options?: AxiosRequestConfig) {
        return PublicDocumentsApiFp(this.configuration).update(id, updatePublicDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RewardsApi - axios parameter creator
 * @export
 */
export const RewardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRewardsDto} createRewardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRewards: async (createRewardsDto: CreateRewardsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRewardsDto' is not null or undefined
            assertParamExists('createRewards', 'createRewardsDto', createRewardsDto)
            const localVarPath = `/api/v1/rewards/{id}/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRewardsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRewards: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRewards', 'id', id)
            const localVarPath = `/api/v1/rewards/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rewards/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rewards/all-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByContract: async (contractId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('findByContract', 'contractId', contractId)
            const localVarPath = `/api/v1/rewards/byContract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RewardsApi - functional programming interface
 * @export
 */
export const RewardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RewardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRewardsDto} createRewardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRewards(createRewardsDto: CreateRewardsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRewards(createRewardsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRewards(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRewards(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsedRewardNameEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByContract(contractId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RewardEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByContract(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RewardsApi - factory interface
 * @export
 */
export const RewardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RewardsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRewardsDto} createRewardsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRewards(createRewardsDto: CreateRewardsDto, options?: any): AxiosPromise<void> {
            return localVarFp.createRewards(createRewardsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRewards(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRewards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<RewardEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllNames(options?: any): AxiosPromise<Array<UsedRewardNameEntity>> {
            return localVarFp.findAllNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByContract(contractId: number, options?: any): AxiosPromise<Array<RewardEntity>> {
            return localVarFp.findByContract(contractId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RewardsApi - object-oriented interface
 * @export
 * @class RewardsApi
 * @extends {BaseAPI}
 */
export class RewardsApi extends BaseAPI {
    /**
     * 
     * @param {CreateRewardsDto} createRewardsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public createRewards(createRewardsDto: CreateRewardsDto, options?: AxiosRequestConfig) {
        return RewardsApiFp(this.configuration).createRewards(createRewardsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public deleteRewards(id: number, options?: AxiosRequestConfig) {
        return RewardsApiFp(this.configuration).deleteRewards(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return RewardsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public findAllNames(options?: AxiosRequestConfig) {
        return RewardsApiFp(this.configuration).findAllNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RewardsApi
     */
    public findByContract(contractId: number, options?: AxiosRequestConfig) {
        return RewardsApiFp(this.configuration).findByContract(contractId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomsApi - axios parameter creator
 * @export
 */
export const RoomsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoomDto} createRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createRoomDto: CreateRoomDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoomDto' is not null or undefined
            assertParamExists('create', 'createRoomDto', createRoomDto)
            const localVarPath = `/api/v1/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoomDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNotesDto} createNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotes: async (createNotesDto: CreateNotesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotesDto' is not null or undefined
            assertParamExists('createNotes', 'createNotesDto', createNotesDto)
            const localVarPath = `/api/v1/rooms/createNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (buildingId?: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roomId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (roomId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getNotes', 'roomId', roomId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getNotes', 'seasonId', seasonId)
            const localVarPath = `/api/v1/rooms/{roomId}/notes`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoomDto} updateRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateRoomDto: UpdateRoomDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateRoomDto' is not null or undefined
            assertParamExists('update', 'updateRoomDto', updateRoomDto)
            const localVarPath = `/api/v1/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoomDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNotesDto} updateNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotes: async (updateNotesDto: UpdateNotesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNotesDto' is not null or undefined
            assertParamExists('updateNotes', 'updateNotesDto', updateNotesDto)
            const localVarPath = `/api/v1/rooms/assignNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomsApi - functional programming interface
 * @export
 */
export const RoomsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoomDto} createRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createRoomDto: CreateRoomDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRoomDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateNotesDto} createNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotes(createNotesDto: CreateNotesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotes(createNotesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(buildingId?: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(buildingId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roomId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(roomId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomToSeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotes(roomId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoomDto} updateRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateRoomDto: UpdateRoomDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateRoomDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNotesDto} updateNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotes(updateNotesDto: UpdateNotesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomToSeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotes(updateNotesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomsApi - factory interface
 * @export
 */
export const RoomsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoomDto} createRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createRoomDto: CreateRoomDto, options?: any): AxiosPromise<RoomEntity> {
            return localVarFp.create(createRoomDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateNotesDto} createNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotes(createNotesDto: CreateNotesDto, options?: any): AxiosPromise<void> {
            return localVarFp.createNotes(createNotesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(buildingId?: number, seasonId?: number, options?: any): AxiosPromise<Array<RoomEntity>> {
            return localVarFp.findAll(buildingId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, seasonId?: number, options?: any): AxiosPromise<RoomEntity> {
            return localVarFp.findOne(id, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roomId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(roomId: number, seasonId: number, options?: any): AxiosPromise<RoomToSeasonEntity> {
            return localVarFp.getNotes(roomId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoomDto} updateRoomDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateRoomDto: UpdateRoomDto, options?: any): AxiosPromise<RoomEntity> {
            return localVarFp.update(id, updateRoomDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNotesDto} updateNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotes(updateNotesDto: UpdateNotesDto, options?: any): AxiosPromise<RoomToSeasonEntity> {
            return localVarFp.updateNotes(updateNotesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomsApi - object-oriented interface
 * @export
 * @class RoomsApi
 * @extends {BaseAPI}
 */
export class RoomsApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoomDto} createRoomDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public create(createRoomDto: CreateRoomDto, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).create(createRoomDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateNotesDto} createNotesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public createNotes(createNotesDto: CreateNotesDto, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).createNotes(createNotesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public findAll(buildingId?: number, seasonId?: number, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).findAll(buildingId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public findOne(id: string, seasonId?: number, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).findOne(id, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roomId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getNotes(roomId: number, seasonId: number, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).getNotes(roomId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRoomDto} updateRoomDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public update(id: string, updateRoomDto: UpdateRoomDto, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).update(id, updateRoomDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNotesDto} updateNotesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public updateNotes(updateNotesDto: UpdateNotesDto, options?: AxiosRequestConfig) {
        return RoomsApiFp(this.configuration).updateNotes(updateNotesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SeasonsApi - axios parameter creator
 * @export
 */
export const SeasonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssignEmployeesToSeasonsDto} assignEmployeesToSeasonsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployeesToSeason: async (assignEmployeesToSeasonsDto: AssignEmployeesToSeasonsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignEmployeesToSeasonsDto' is not null or undefined
            assertParamExists('assignEmployeesToSeason', 'assignEmployeesToSeasonsDto', assignEmployeesToSeasonsDto)
            const localVarPath = `/api/v1/seasons/assignEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignEmployeesToSeasonsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSeasonDto} createSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSeasonDto: CreateSeasonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSeasonDto' is not null or undefined
            assertParamExists('create', 'createSeasonDto', createSeasonDto)
            const localVarPath = `/api/v1/seasons/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSeasonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePopulatedSeasonDto} createPopulatedSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPopulated: async (createPopulatedSeasonDto: CreatePopulatedSeasonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPopulatedSeasonDto' is not null or undefined
            assertParamExists('createPopulated', 'createPopulatedSeasonDto', createPopulatedSeasonDto)
            const localVarPath = `/api/v1/seasons/createPopulated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPopulatedSeasonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/seasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIfNotEmployee: async (employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAllIfNotEmployee', 'employeeId', employeeId)
            const localVarPath = `/api/v1/seasons/findAllIfNotEmployee/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMySeasonInProgress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/seasons/mySeasonsInProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUnique', 'id', id)
            const localVarPath = `/api/v1/seasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSeasonActive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/seasons/isActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/seasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSeasonDto} updateSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateSeasonDto: UpdateSeasonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateSeasonDto' is not null or undefined
            assertParamExists('update', 'updateSeasonDto', updateSeasonDto)
            const localVarPath = `/api/v1/seasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSeasonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeasonsApi - functional programming interface
 * @export
 */
export const SeasonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeasonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AssignEmployeesToSeasonsDto} assignEmployeesToSeasonsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEmployeesToSeason(assignEmployeesToSeasonsDto: AssignEmployeesToSeasonsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEmployeesToSeason(assignEmployeesToSeasonsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSeasonDto} createSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSeasonDto: CreateSeasonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSeasonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePopulatedSeasonDto} createPopulatedSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPopulated(createPopulatedSeasonDto: CreatePopulatedSeasonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPopulated(createPopulatedSeasonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeasonEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllIfNotEmployee(employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeasonEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllIfNotEmployee(employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMySeasonInProgress(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeasonEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMySeasonInProgress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnique(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnique(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isSeasonActive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isSeasonActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSeasonDto} updateSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateSeasonDto: UpdateSeasonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeasonEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateSeasonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeasonsApi - factory interface
 * @export
 */
export const SeasonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeasonsApiFp(configuration)
    return {
        /**
         * 
         * @param {AssignEmployeesToSeasonsDto} assignEmployeesToSeasonsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployeesToSeason(assignEmployeesToSeasonsDto: AssignEmployeesToSeasonsDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignEmployeesToSeason(assignEmployeesToSeasonsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSeasonDto} createSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSeasonDto: CreateSeasonDto, options?: any): AxiosPromise<SeasonEntity> {
            return localVarFp.create(createSeasonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePopulatedSeasonDto} createPopulatedSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPopulated(createPopulatedSeasonDto: CreatePopulatedSeasonDto, options?: any): AxiosPromise<SeasonEntity> {
            return localVarFp.createPopulated(createPopulatedSeasonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<SeasonEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIfNotEmployee(employeeId: number, options?: any): AxiosPromise<Array<SeasonEntity>> {
            return localVarFp.findAllIfNotEmployee(employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMySeasonInProgress(options?: any): AxiosPromise<Array<SeasonEntity>> {
            return localVarFp.findMySeasonInProgress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique(id: string, options?: any): AxiosPromise<SeasonEntity> {
            return localVarFp.findUnique(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSeasonActive(options?: any): AxiosPromise<boolean> {
            return localVarFp.isSeasonActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSeasonDto} updateSeasonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateSeasonDto: UpdateSeasonDto, options?: any): AxiosPromise<SeasonEntity> {
            return localVarFp.update(id, updateSeasonDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeasonsApi - object-oriented interface
 * @export
 * @class SeasonsApi
 * @extends {BaseAPI}
 */
export class SeasonsApi extends BaseAPI {
    /**
     * 
     * @param {AssignEmployeesToSeasonsDto} assignEmployeesToSeasonsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public assignEmployeesToSeason(assignEmployeesToSeasonsDto: AssignEmployeesToSeasonsDto, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).assignEmployeesToSeason(assignEmployeesToSeasonsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSeasonDto} createSeasonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public create(createSeasonDto: CreateSeasonDto, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).create(createSeasonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePopulatedSeasonDto} createPopulatedSeasonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public createPopulated(createPopulatedSeasonDto: CreatePopulatedSeasonDto, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).createPopulated(createPopulatedSeasonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public findAllIfNotEmployee(employeeId: number, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).findAllIfNotEmployee(employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public findMySeasonInProgress(options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).findMySeasonInProgress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public findUnique(id: string, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).findUnique(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public isSeasonActive(options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).isSeasonActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateSeasonDto} updateSeasonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeasonsApi
     */
    public update(id: string, updateSeasonDto: UpdateSeasonDto, options?: AxiosRequestConfig) {
        return SeasonsApiFp(this.configuration).update(id, updateSeasonDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionsApi - axios parameter creator
 * @export
 */
export const SectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExtendedCreateSectionDto} extendedCreateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (extendedCreateSectionDto: ExtendedCreateSectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendedCreateSectionDto' is not null or undefined
            assertParamExists('create', 'extendedCreateSectionDto', extendedCreateSectionDto)
            const localVarPath = `/api/v1/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendedCreateSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAll', 'seasonId', seasonId)
            const localVarPath = `/api/v1/sections/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByDepartment: async (departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findAllByDepartment', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllByDepartment', 'seasonId', seasonId)
            const localVarPath = `/api/v1/sections/{departmentId}/{seasonId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnabledSections: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findEnabledSections', 'seasonId', seasonId)
            const localVarPath = `/api/v1/sections/enabled-sections/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ExtendedUpdateSectionDto} extendedUpdateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, extendedUpdateSectionDto: ExtendedUpdateSectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'extendedUpdateSectionDto' is not null or undefined
            assertParamExists('update', 'extendedUpdateSectionDto', extendedUpdateSectionDto)
            const localVarPath = `/api/v1/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendedUpdateSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionsApi - functional programming interface
 * @export
 */
export const SectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExtendedCreateSectionDto} extendedCreateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(extendedCreateSectionDto: ExtendedCreateSectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(extendedCreateSectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByDepartment(departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByDepartment(departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEnabledSections(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEnabledSections(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ExtendedUpdateSectionDto} extendedUpdateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, extendedUpdateSectionDto: ExtendedUpdateSectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, extendedUpdateSectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionsApi - factory interface
 * @export
 */
export const SectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {ExtendedCreateSectionDto} extendedCreateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(extendedCreateSectionDto: ExtendedCreateSectionDto, options?: any): AxiosPromise<SectionEntity> {
            return localVarFp.create(extendedCreateSectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(seasonId: number, options?: any): AxiosPromise<Array<SectionEntity>> {
            return localVarFp.findAll(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByDepartment(departmentId: number, seasonId: number, options?: any): AxiosPromise<Array<SectionEntity>> {
            return localVarFp.findAllByDepartment(departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnabledSections(seasonId: number, options?: any): AxiosPromise<Array<SectionEntity>> {
            return localVarFp.findEnabledSections(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<SectionEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<SectionEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ExtendedUpdateSectionDto} extendedUpdateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, extendedUpdateSectionDto: ExtendedUpdateSectionDto, options?: any): AxiosPromise<SectionEntity> {
            return localVarFp.update(id, extendedUpdateSectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionsApi - object-oriented interface
 * @export
 * @class SectionsApi
 * @extends {BaseAPI}
 */
export class SectionsApi extends BaseAPI {
    /**
     * 
     * @param {ExtendedCreateSectionDto} extendedCreateSectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public create(extendedCreateSectionDto: ExtendedCreateSectionDto, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).create(extendedCreateSectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public findAll(seasonId: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).findAll(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public findAllByDepartment(departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).findAllByDepartment(departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public findEnabledSections(seasonId: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).findEnabledSections(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ExtendedUpdateSectionDto} extendedUpdateSectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public update(id: string, extendedUpdateSectionDto: ExtendedUpdateSectionDto, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).update(id, extendedUpdateSectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SegmentApi - axios parameter creator
 * @export
 */
export const SegmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/v1/segment/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSegmentDto: CreateSegmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSegmentDto' is not null or undefined
            assertParamExists('create', 'createSegmentDto', createSegmentDto)
            const localVarPath = `/api/v1/segment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/segment/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithCount: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllWithCount', 'seasonId', seasonId)
            const localVarPath = `/api/v1/segment/allWithCount/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUser: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByUser', 'seasonId', seasonId)
            const localVarPath = `/api/v1/segment/byUser/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentApi - functional programming interface
 * @export
 */
export const SegmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSegmentDto: CreateSegmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSegmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithCount(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithCount(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByUser(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByUser(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SegmentApi - factory interface
 * @export
 */
export const SegmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSegmentDto: CreateSegmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.create(createSegmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<SegmentEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithCount(seasonId: number, options?: any): AxiosPromise<Array<SegmentEntity>> {
            return localVarFp.findAllWithCount(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUser(seasonId: number, options?: any): AxiosPromise<Array<SegmentEntity>> {
            return localVarFp.findByUser(seasonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SegmentApi - object-oriented interface
 * @export
 * @class SegmentApi
 * @extends {BaseAPI}
 */
export class SegmentApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public _delete(id: number, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSegmentDto} createSegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public create(createSegmentDto: CreateSegmentDto, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).create(createSegmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public findAllWithCount(seasonId: number, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).findAllWithCount(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentApi
     */
    public findByUser(seasonId: number, options?: AxiosRequestConfig) {
        return SegmentApiFp(this.configuration).findByUser(seasonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShiftApi - axios parameter creator
 * @export
 */
export const ShiftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssignShiftToEmployeeDto} assignShiftToEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployee: async (assignShiftToEmployeeDto: AssignShiftToEmployeeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignShiftToEmployeeDto' is not null or undefined
            assertParamExists('assignEmployee', 'assignShiftToEmployeeDto', assignShiftToEmployeeDto)
            const localVarPath = `/api/v1/shift/assignEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignShiftToEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityRequestsByManager: async (startDate: string, endDate: string, seasonId: number, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('availabilityRequestsByManager', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('availabilityRequestsByManager', 'endDate', endDate)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('availabilityRequestsByManager', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/requestsByManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AcceptAvaibilityDto} acceptAvaibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRequests: async (acceptAvaibilityDto: AcceptAvaibilityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptAvaibilityDto' is not null or undefined
            assertParamExists('confirmRequests', 'acceptAvaibilityDto', acceptAvaibilityDto)
            const localVarPath = `/api/v1/shift/confirmRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptAvaibilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createShiftDto: CreateShiftDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftDto' is not null or undefined
            assertParamExists('create', 'createShiftDto', createShiftDto)
            const localVarPath = `/api/v1/shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAvailabilityRequestsDto} createAvailabilityRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAviabilityRequests: async (createAvailabilityRequestsDto: CreateAvailabilityRequestsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAvailabilityRequestsDto' is not null or undefined
            assertParamExists('createAviabilityRequests', 'createAvailabilityRequestsDto', createAvailabilityRequestsDto)
            const localVarPath = `/api/v1/shift/createAviabilityRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAvailabilityRequestsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShiftDetailWithChoiceDto} createShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetailWithChoice: async (createShiftDetailWithChoiceDto: CreateShiftDetailWithChoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftDetailWithChoiceDto' is not null or undefined
            assertParamExists('createDetailWithChoice', 'createShiftDetailWithChoiceDto', createShiftDetailWithChoiceDto)
            const localVarPath = `/api/v1/shift/createDetailWithChoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftDetailWithChoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateShiftDetailDto} createShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShiftDetails: async (createShiftDetailDto: CreateShiftDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftDetailDto' is not null or undefined
            assertParamExists('createShiftDetails', 'createShiftDetailDto', createShiftDetailDto)
            const localVarPath = `/api/v1/shift/shiftDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignments: async (id: number, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAssignments', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findAssignments', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAssignments', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/findAssignments/{id}/{departmentId}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTemplate: async (id: number, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findByTemplate', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findByTemplate', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByTemplate', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/{id}/{departmentId}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyRequests: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findShiftDetails: async (id: number, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findShiftDetails', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('findShiftDetails', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findShiftDetails', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/findShiftDetails/{id}/{departmentId}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserAssignments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAvailabilityBeenSent: async (startDate: string, endDate: string, seasonId: number, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('hasAvailabilityBeenSent', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('hasAvailabilityBeenSent', 'endDate', endDate)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('hasAvailabilityBeenSent', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/availabilityForTheDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoursForPeriod: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('hoursForPeriod', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('hoursForPeriod', 'endDate', endDate)
            const localVarPath = `/api/v1/shift/hoursForPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift/isUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('remove', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('remove', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/{id}/{departmentId}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDetail: async (id: number, departmentId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeDetail', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('removeDetail', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('removeDetail', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift/deleteDetail/{id}/{departmentId}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RemoveShiftDetailWithChoiceDto} removeShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDetailWithChoice: async (id: number, removeShiftDetailWithChoiceDto: RemoveShiftDetailWithChoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeDetailWithChoice', 'id', id)
            // verify required parameter 'removeShiftDetailWithChoiceDto' is not null or undefined
            assertParamExists('removeDetailWithChoice', 'removeShiftDetailWithChoiceDto', removeShiftDetailWithChoiceDto)
            const localVarPath = `/api/v1/shift/removeDetailWithChoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeShiftDetailWithChoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployeeAssingment: async (id: number, departmentId: number, seasonId: number, employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeEmployeeAssingment', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('removeEmployeeAssingment', 'departmentId', departmentId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('removeEmployeeAssingment', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('removeEmployeeAssingment', 'employeeId', employeeId)
            const localVarPath = `/api/v1/shift/deleteEmployeeAssignment/{id}/{departmentId}/{seasonId}/{employeeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendShifts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift/sendShifts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDto} updateShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateShiftDto: UpdateShiftDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateShiftDto' is not null or undefined
            assertParamExists('update', 'updateShiftDto', updateShiftDto)
            const localVarPath = `/api/v1/shift/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDetailWithChoiceDto} updateShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetailWithChoice: async (id: number, updateShiftDetailWithChoiceDto: UpdateShiftDetailWithChoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDetailWithChoice', 'id', id)
            // verify required parameter 'updateShiftDetailWithChoiceDto' is not null or undefined
            assertParamExists('updateDetailWithChoice', 'updateShiftDetailWithChoiceDto', updateShiftDetailWithChoiceDto)
            const localVarPath = `/api/v1/shift/updateDetailWithChoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftDetailWithChoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrDuplicate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift/updateOrDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftAndDetailsWithChoiceDto} updateShiftAndDetailsWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShiftAndDetailsWithChoice: async (id: number, updateShiftAndDetailsWithChoiceDto: UpdateShiftAndDetailsWithChoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateShiftAndDetailsWithChoice', 'id', id)
            // verify required parameter 'updateShiftAndDetailsWithChoiceDto' is not null or undefined
            assertParamExists('updateShiftAndDetailsWithChoice', 'updateShiftAndDetailsWithChoiceDto', updateShiftAndDetailsWithChoiceDto)
            const localVarPath = `/api/v1/shift/updateShiftAndDetailsWithChoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftAndDetailsWithChoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {UpdateShiftDetailDto} updateShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShiftDetails: async (id: number, departmentId: number, updateShiftDetailDto: UpdateShiftDetailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateShiftDetails', 'id', id)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateShiftDetails', 'departmentId', departmentId)
            // verify required parameter 'updateShiftDetailDto' is not null or undefined
            assertParamExists('updateShiftDetails', 'updateShiftDetailDto', updateShiftDetailDto)
            const localVarPath = `/api/v1/shift/updateShiftDetail/{id}/{departmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftDetailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftWithChoiceDto} updateShiftWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithChoice: async (id: number, updateShiftWithChoiceDto: UpdateShiftWithChoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWithChoice', 'id', id)
            // verify required parameter 'updateShiftWithChoiceDto' is not null or undefined
            assertParamExists('updateWithChoice', 'updateShiftWithChoiceDto', updateShiftWithChoiceDto)
            const localVarPath = `/api/v1/shift/updateWithChoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftWithChoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {string} date 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weeklyEmployeeHours: async (employeeId: number, date: string, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('weeklyEmployeeHours', 'employeeId', employeeId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('weeklyEmployeeHours', 'date', date)
            const localVarPath = `/api/v1/shift/weeklyHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShiftApi - functional programming interface
 * @export
 */
export const ShiftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShiftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AssignShiftToEmployeeDto} assignShiftToEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEmployee(assignShiftToEmployeeDto: AssignShiftToEmployeeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEmployee(assignShiftToEmployeeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabilityRequestsByManager(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftAvailabilityRequestEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabilityRequestsByManager(startDate, endDate, seasonId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AcceptAvaibilityDto} acceptAvaibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmRequests(acceptAvaibilityDto: AcceptAvaibilityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmRequests(acceptAvaibilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createShiftDto: CreateShiftDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createShiftDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAvailabilityRequestsDto} createAvailabilityRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAviabilityRequests(createAvailabilityRequestsDto: CreateAvailabilityRequestsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAviabilityRequests(createAvailabilityRequestsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShiftDetailWithChoiceDto} createShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDetailWithChoice(createShiftDetailWithChoiceDto: CreateShiftDetailWithChoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDetailWithChoice(createShiftDetailWithChoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateShiftDetailDto} createShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShiftDetails(createShiftDetailDto: CreateShiftDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetailEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShiftDetails(createShiftDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAssignments(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftAssignmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAssignments(id, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByTemplate(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByTemplate(id, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyRequests(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftAvailabilityEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findShiftDetails(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftDetailEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findShiftDetails(id, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserAssignments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftAssignmentEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserAssignments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAvailabilityBeenSent(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasAvailabilityBeenSent(startDate, endDate, seasonId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hoursForPeriod(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeHoursDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hoursForPeriod(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDetail(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDetail(id, departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RemoveShiftDetailWithChoiceDto} removeShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDetailWithChoice(id: number, removeShiftDetailWithChoiceDto: RemoveShiftDetailWithChoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDetailWithChoice(id, removeShiftDetailWithChoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEmployeeAssingment(id: number, departmentId: number, seasonId: number, employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEmployeeAssingment(id, departmentId, seasonId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendShifts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendShifts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDto} updateShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateShiftDto: UpdateShiftDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateShiftDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDetailWithChoiceDto} updateShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDetailWithChoice(id: number, updateShiftDetailWithChoiceDto: UpdateShiftDetailWithChoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDetailWithChoice(id, updateShiftDetailWithChoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrDuplicate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrDuplicate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftAndDetailsWithChoiceDto} updateShiftAndDetailsWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShiftAndDetailsWithChoice(id: number, updateShiftAndDetailsWithChoiceDto: UpdateShiftAndDetailsWithChoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShiftAndDetailsWithChoice(id, updateShiftAndDetailsWithChoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {UpdateShiftDetailDto} updateShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShiftDetails(id: number, departmentId: number, updateShiftDetailDto: UpdateShiftDetailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShiftDetails(id, departmentId, updateShiftDetailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftWithChoiceDto} updateShiftWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithChoice(id: number, updateShiftWithChoiceDto: UpdateShiftWithChoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithChoice(id, updateShiftWithChoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {string} date 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weeklyEmployeeHours(employeeId: number, date: string, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weeklyEmployeeHours(employeeId, date, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShiftApi - factory interface
 * @export
 */
export const ShiftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShiftApiFp(configuration)
    return {
        /**
         * 
         * @param {AssignShiftToEmployeeDto} assignShiftToEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEmployee(assignShiftToEmployeeDto: AssignShiftToEmployeeDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignEmployee(assignShiftToEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityRequestsByManager(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: any): AxiosPromise<Array<ShiftAvailabilityRequestEntity>> {
            return localVarFp.availabilityRequestsByManager(startDate, endDate, seasonId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AcceptAvaibilityDto} acceptAvaibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmRequests(acceptAvaibilityDto: AcceptAvaibilityDto, options?: any): AxiosPromise<void> {
            return localVarFp.confirmRequests(acceptAvaibilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShiftDto} createShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createShiftDto: CreateShiftDto, options?: any): AxiosPromise<ShiftEntity> {
            return localVarFp.create(createShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAvailabilityRequestsDto} createAvailabilityRequestsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAviabilityRequests(createAvailabilityRequestsDto: CreateAvailabilityRequestsDto, options?: any): AxiosPromise<void> {
            return localVarFp.createAviabilityRequests(createAvailabilityRequestsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShiftDetailWithChoiceDto} createShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetailWithChoice(createShiftDetailWithChoiceDto: CreateShiftDetailWithChoiceDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.createDetailWithChoice(createShiftDetailWithChoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateShiftDetailDto} createShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShiftDetails(createShiftDetailDto: CreateShiftDetailDto, options?: any): AxiosPromise<Array<ShiftDetailEntity>> {
            return localVarFp.createShiftDetails(createShiftDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignments(id: number, departmentId: number, seasonId: number, options?: any): AxiosPromise<Array<ShiftAssignmentEntity>> {
            return localVarFp.findAssignments(id, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTemplate(id: number, departmentId: number, seasonId: number, options?: any): AxiosPromise<Array<ShiftEntity>> {
            return localVarFp.findByTemplate(id, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyRequests(options?: any): AxiosPromise<Array<ShiftAvailabilityEntity>> {
            return localVarFp.findMyRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findShiftDetails(id: number, departmentId: number, seasonId: number, options?: any): AxiosPromise<Array<ShiftDetailEntity>> {
            return localVarFp.findShiftDetails(id, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserAssignments(options?: any): AxiosPromise<Array<ShiftAssignmentEntity>> {
            return localVarFp.findUserAssignments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} seasonId 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAvailabilityBeenSent(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.hasAvailabilityBeenSent(startDate, endDate, seasonId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hoursForPeriod(startDate: string, endDate: string, options?: any): AxiosPromise<Array<EmployeeHoursDto>> {
            return localVarFp.hoursForPeriod(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUpdate(options?: any): AxiosPromise<object> {
            return localVarFp.isUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, departmentId: number, seasonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDetail(id: number, departmentId: number, seasonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeDetail(id, departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RemoveShiftDetailWithChoiceDto} removeShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDetailWithChoice(id: number, removeShiftDetailWithChoiceDto: RemoveShiftDetailWithChoiceDto, options?: any): AxiosPromise<object> {
            return localVarFp.removeDetailWithChoice(id, removeShiftDetailWithChoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEmployeeAssingment(id: number, departmentId: number, seasonId: number, employeeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeEmployeeAssingment(id, departmentId, seasonId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendShifts(options?: any): AxiosPromise<void> {
            return localVarFp.sendShifts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDto} updateShiftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateShiftDto: UpdateShiftDto, options?: any): AxiosPromise<ShiftEntity> {
            return localVarFp.update(id, updateShiftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftDetailWithChoiceDto} updateShiftDetailWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDetailWithChoice(id: number, updateShiftDetailWithChoiceDto: UpdateShiftDetailWithChoiceDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateDetailWithChoice(id, updateShiftDetailWithChoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrDuplicate(options?: any): AxiosPromise<ShiftEntity> {
            return localVarFp.updateOrDuplicate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftAndDetailsWithChoiceDto} updateShiftAndDetailsWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShiftAndDetailsWithChoice(id: number, updateShiftAndDetailsWithChoiceDto: UpdateShiftAndDetailsWithChoiceDto, options?: any): AxiosPromise<object> {
            return localVarFp.updateShiftAndDetailsWithChoice(id, updateShiftAndDetailsWithChoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} departmentId 
         * @param {UpdateShiftDetailDto} updateShiftDetailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShiftDetails(id: number, departmentId: number, updateShiftDetailDto: UpdateShiftDetailDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateShiftDetails(id, departmentId, updateShiftDetailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateShiftWithChoiceDto} updateShiftWithChoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithChoice(id: number, updateShiftWithChoiceDto: UpdateShiftWithChoiceDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithChoice(id, updateShiftWithChoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {string} date 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weeklyEmployeeHours(employeeId: number, date: string, seasonId?: number, options?: any): AxiosPromise<number> {
            return localVarFp.weeklyEmployeeHours(employeeId, date, seasonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShiftApi - object-oriented interface
 * @export
 * @class ShiftApi
 * @extends {BaseAPI}
 */
export class ShiftApi extends BaseAPI {
    /**
     * 
     * @param {AssignShiftToEmployeeDto} assignShiftToEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public assignEmployee(assignShiftToEmployeeDto: AssignShiftToEmployeeDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).assignEmployee(assignShiftToEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} seasonId 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public availabilityRequestsByManager(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).availabilityRequestsByManager(startDate, endDate, seasonId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AcceptAvaibilityDto} acceptAvaibilityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public confirmRequests(acceptAvaibilityDto: AcceptAvaibilityDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).confirmRequests(acceptAvaibilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShiftDto} createShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public create(createShiftDto: CreateShiftDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).create(createShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAvailabilityRequestsDto} createAvailabilityRequestsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public createAviabilityRequests(createAvailabilityRequestsDto: CreateAvailabilityRequestsDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).createAviabilityRequests(createAvailabilityRequestsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShiftDetailWithChoiceDto} createShiftDetailWithChoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public createDetailWithChoice(createShiftDetailWithChoiceDto: CreateShiftDetailWithChoiceDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).createDetailWithChoice(createShiftDetailWithChoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateShiftDetailDto} createShiftDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public createShiftDetails(createShiftDetailDto: CreateShiftDetailDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).createShiftDetails(createShiftDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public findAssignments(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).findAssignments(id, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public findByTemplate(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).findByTemplate(id, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public findMyRequests(options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).findMyRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public findShiftDetails(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).findShiftDetails(id, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public findUserAssignments(options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).findUserAssignments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} seasonId 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public hasAvailabilityBeenSent(startDate: string, endDate: string, seasonId: number, departmentId?: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).hasAvailabilityBeenSent(startDate, endDate, seasonId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public hoursForPeriod(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).hoursForPeriod(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public isUpdate(options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).isUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public remove(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).remove(id, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public removeDetail(id: number, departmentId: number, seasonId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).removeDetail(id, departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RemoveShiftDetailWithChoiceDto} removeShiftDetailWithChoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public removeDetailWithChoice(id: number, removeShiftDetailWithChoiceDto: RemoveShiftDetailWithChoiceDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).removeDetailWithChoice(id, removeShiftDetailWithChoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public removeEmployeeAssingment(id: number, departmentId: number, seasonId: number, employeeId: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).removeEmployeeAssingment(id, departmentId, seasonId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public sendShifts(options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).sendShifts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateShiftDto} updateShiftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public update(id: number, updateShiftDto: UpdateShiftDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).update(id, updateShiftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateShiftDetailWithChoiceDto} updateShiftDetailWithChoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public updateDetailWithChoice(id: number, updateShiftDetailWithChoiceDto: UpdateShiftDetailWithChoiceDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).updateDetailWithChoice(id, updateShiftDetailWithChoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public updateOrDuplicate(options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).updateOrDuplicate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateShiftAndDetailsWithChoiceDto} updateShiftAndDetailsWithChoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public updateShiftAndDetailsWithChoice(id: number, updateShiftAndDetailsWithChoiceDto: UpdateShiftAndDetailsWithChoiceDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).updateShiftAndDetailsWithChoice(id, updateShiftAndDetailsWithChoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} departmentId 
     * @param {UpdateShiftDetailDto} updateShiftDetailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public updateShiftDetails(id: number, departmentId: number, updateShiftDetailDto: UpdateShiftDetailDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).updateShiftDetails(id, departmentId, updateShiftDetailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateShiftWithChoiceDto} updateShiftWithChoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public updateWithChoice(id: number, updateShiftWithChoiceDto: UpdateShiftWithChoiceDto, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).updateWithChoice(id, updateShiftWithChoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {string} date 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public weeklyEmployeeHours(employeeId: number, date: string, seasonId?: number, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).weeklyEmployeeHours(employeeId, date, seasonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShiftTemplateApi - axios parameter creator
 * @export
 */
export const ShiftTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateShiftTemplateDto} createShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createShiftTemplateDto: CreateShiftTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShiftTemplateDto' is not null or undefined
            assertParamExists('create', 'createShiftTemplateDto', createShiftTemplateDto)
            const localVarPath = `/api/v1/shift-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShiftTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromTemplate: async (templateId: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('createFromTemplate', 'templateId', templateId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createFromTemplate', 'name', name)
            const localVarPath = `/api/v1/shift-template/copyFromTemplate/{templateId}/{name}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (departmentId?: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shift-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWhereIsBoss: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllWhereIsBoss', 'seasonId', seasonId)
            const localVarPath = `/api/v1/shift-template/whereIsBoss/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/shift-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {UpdateShiftTemplateDto} updateShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, seasonId: number, updateShiftTemplateDto: UpdateShiftTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('update', 'seasonId', seasonId)
            // verify required parameter 'updateShiftTemplateDto' is not null or undefined
            assertParamExists('update', 'updateShiftTemplateDto', updateShiftTemplateDto)
            const localVarPath = `/api/v1/shift-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShiftTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShiftTemplateApi - functional programming interface
 * @export
 */
export const ShiftTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShiftTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateShiftTemplateDto} createShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createShiftTemplateDto: CreateShiftTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createShiftTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFromTemplate(templateId: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFromTemplate(templateId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(departmentId?: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftTemplateEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWhereIsBoss(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShiftTemplateEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWhereIsBoss(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {UpdateShiftTemplateDto} updateShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, seasonId: number, updateShiftTemplateDto: UpdateShiftTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShiftTemplateEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, seasonId, updateShiftTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShiftTemplateApi - factory interface
 * @export
 */
export const ShiftTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShiftTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateShiftTemplateDto} createShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createShiftTemplateDto: CreateShiftTemplateDto, options?: any): AxiosPromise<ShiftTemplateEntity> {
            return localVarFp.create(createShiftTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromTemplate(templateId: number, name: string, options?: any): AxiosPromise<ShiftTemplateEntity> {
            return localVarFp.createFromTemplate(templateId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(departmentId?: number, seasonId?: number, options?: any): AxiosPromise<Array<ShiftTemplateEntity>> {
            return localVarFp.findAll(departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWhereIsBoss(seasonId: number, options?: any): AxiosPromise<Array<ShiftTemplateEntity>> {
            return localVarFp.findAllWhereIsBoss(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} seasonId 
         * @param {UpdateShiftTemplateDto} updateShiftTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, seasonId: number, updateShiftTemplateDto: UpdateShiftTemplateDto, options?: any): AxiosPromise<ShiftTemplateEntity> {
            return localVarFp.update(id, seasonId, updateShiftTemplateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShiftTemplateApi - object-oriented interface
 * @export
 * @class ShiftTemplateApi
 * @extends {BaseAPI}
 */
export class ShiftTemplateApi extends BaseAPI {
    /**
     * 
     * @param {CreateShiftTemplateDto} createShiftTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public create(createShiftTemplateDto: CreateShiftTemplateDto, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).create(createShiftTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public createFromTemplate(templateId: number, name: string, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).createFromTemplate(templateId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public findAll(departmentId?: number, seasonId?: number, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).findAll(departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public findAllWhereIsBoss(seasonId: number, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).findAllWhereIsBoss(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} seasonId 
     * @param {UpdateShiftTemplateDto} updateShiftTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftTemplateApi
     */
    public update(id: number, seasonId: number, updateShiftTemplateDto: UpdateShiftTemplateDto, options?: AxiosRequestConfig) {
        return ShiftTemplateApiFp(this.configuration).update(id, seasonId, updateShiftTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createStatusDto: CreateStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStatusDto' is not null or undefined
            assertParamExists('create', 'createStatusDto', createStatusDto)
            const localVarPath = `/api/v1/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createStatusDto: CreateStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStatusDto} createStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createStatusDto: CreateStatusDto, options?: any): AxiosPromise<StatusEntity> {
            return localVarFp.create(createStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<StatusEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {CreateStatusDto} createStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public create(createStatusDto: CreateStatusDto, options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).create(createStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusChangeApi - axios parameter creator
 * @export
 */
export const StatusChangeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStatusChangeDto} createStatusChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createStatusChangeDto: CreateStatusChangeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStatusChangeDto' is not null or undefined
            assertParamExists('create', 'createStatusChangeDto', createStatusChangeDto)
            const localVarPath = `/api/v1/status-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStatusChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (employeeId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findAll', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAll', 'seasonId', seasonId)
            const localVarPath = `/api/v1/status-change/byEmployee/{employeeId}/{seasonId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status-change/byDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/status-change/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/api/v1/status-change/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusChangeApi - functional programming interface
 * @export
 */
export const StatusChangeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusChangeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStatusChangeDto} createStatusChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createStatusChangeDto: CreateStatusChangeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusChangeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createStatusChangeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(employeeId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusChangeEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusChangeEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusChangeApi - factory interface
 * @export
 */
export const StatusChangeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusChangeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStatusChangeDto} createStatusChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createStatusChangeDto: CreateStatusChangeDto, options?: any): AxiosPromise<StatusChangeEntity> {
            return localVarFp.create(createStatusChangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(employeeId: number, seasonId: number, options?: any): AxiosPromise<Array<StatusChangeEntity>> {
            return localVarFp.findAll(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByDate(options?: any): AxiosPromise<void> {
            return localVarFp.findByDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, options?: any): AxiosPromise<StatusChangeEntity> {
            return localVarFp.update(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusChangeApi - object-oriented interface
 * @export
 * @class StatusChangeApi
 * @extends {BaseAPI}
 */
export class StatusChangeApi extends BaseAPI {
    /**
     * 
     * @param {CreateStatusChangeDto} createStatusChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusChangeApi
     */
    public create(createStatusChangeDto: CreateStatusChangeDto, options?: AxiosRequestConfig) {
        return StatusChangeApiFp(this.configuration).create(createStatusChangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusChangeApi
     */
    public findAll(employeeId: number, seasonId: number, options?: AxiosRequestConfig) {
        return StatusChangeApiFp(this.configuration).findAll(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusChangeApi
     */
    public findByDate(options?: AxiosRequestConfig) {
        return StatusChangeApiFp(this.configuration).findByDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusChangeApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return StatusChangeApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusChangeApi
     */
    public update(id: number, options?: AxiosRequestConfig) {
        return StatusChangeApiFp(this.configuration).update(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantBankingInformationApi - axios parameter creator
 * @export
 */
export const TenantBankingInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTenantBankingInformationDto} createTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createTenantBankingInformationDto: CreateTenantBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTenantBankingInformationDto' is not null or undefined
            assertParamExists('create', 'createTenantBankingInformationDto', createTenantBankingInformationDto)
            const localVarPath = `/api/v1/tenant-banking-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenant-banking-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignable: async (paymentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentType' is not null or undefined
            assertParamExists('findAssignable', 'paymentType', paymentType)
            const localVarPath = `/api/v1/tenant-banking-information/assignable/{paymentType}`
                .replace(`{${"paymentType"}}`, encodeURIComponent(String(paymentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/tenant-banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/tenant-banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantBankingInformationDto} updateTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateTenantBankingInformationDto: UpdateTenantBankingInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateTenantBankingInformationDto' is not null or undefined
            assertParamExists('update', 'updateTenantBankingInformationDto', updateTenantBankingInformationDto)
            const localVarPath = `/api/v1/tenant-banking-information/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantBankingInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantBankingInformationApi - functional programming interface
 * @export
 */
export const TenantBankingInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantBankingInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTenantBankingInformationDto} createTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createTenantBankingInformationDto: CreateTenantBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantBankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createTenantBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantBankingInformationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAssignable(paymentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantBankingInformationEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAssignable(paymentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantBankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantBankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantBankingInformationDto} updateTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateTenantBankingInformationDto: UpdateTenantBankingInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantBankingInformationEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateTenantBankingInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantBankingInformationApi - factory interface
 * @export
 */
export const TenantBankingInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantBankingInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTenantBankingInformationDto} createTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createTenantBankingInformationDto: CreateTenantBankingInformationDto, options?: any): AxiosPromise<TenantBankingInformationEntity> {
            return localVarFp.create(createTenantBankingInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(options?: any): AxiosPromise<Array<TenantBankingInformationEntity>> {
            return localVarFp.findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignable(paymentType: string, options?: any): AxiosPromise<Array<TenantBankingInformationEntity>> {
            return localVarFp.findAssignable(paymentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: number, options?: any): AxiosPromise<TenantBankingInformationEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<TenantBankingInformationEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantBankingInformationDto} updateTenantBankingInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateTenantBankingInformationDto: UpdateTenantBankingInformationDto, options?: any): AxiosPromise<TenantBankingInformationEntity> {
            return localVarFp.update(id, updateTenantBankingInformationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantBankingInformationApi - object-oriented interface
 * @export
 * @class TenantBankingInformationApi
 * @extends {BaseAPI}
 */
export class TenantBankingInformationApi extends BaseAPI {
    /**
     * 
     * @param {CreateTenantBankingInformationDto} createTenantBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public create(createTenantBankingInformationDto: CreateTenantBankingInformationDto, options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).create(createTenantBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public findAll(options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public findAssignable(paymentType: string, options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).findAssignable(paymentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public findOne(id: number, options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTenantBankingInformationDto} updateTenantBankingInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantBankingInformationApi
     */
    public update(id: number, updateTenantBankingInformationDto: UpdateTenantBankingInformationDto, options?: AxiosRequestConfig) {
        return TenantBankingInformationApiFp(this.configuration).update(id, updateTenantBankingInformationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantConfigApi - axios parameter creator
 * @export
 */
export const TenantConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encryptionKeyRecovery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenant-config/encryptionKeyRecovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenant-config/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEncryptionKeySet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tenant-config/isEncryptionKeySet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTenantConfigDto} updateTenantConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateTenantConfigDto: UpdateTenantConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateTenantConfigDto' is not null or undefined
            assertParamExists('update', 'updateTenantConfigDto', updateTenantConfigDto)
            const localVarPath = `/api/v1/tenant-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantOptions} updateTenantOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (id: number, updateTenantOptions: UpdateTenantOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettings', 'id', id)
            // verify required parameter 'updateTenantOptions' is not null or undefined
            assertParamExists('updateSettings', 'updateTenantOptions', updateTenantOptions)
            const localVarPath = `/api/v1/tenant-config/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantConfigApi - functional programming interface
 * @export
 */
export const TenantConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async encryptionKeyRecovery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.encryptionKeyRecovery(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConfigEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isEncryptionKeySet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEncryptionKeySet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTenantConfigDto} updateTenantConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateTenantConfigDto: UpdateTenantConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConfigEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateTenantConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantOptions} updateTenantOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(id: number, updateTenantOptions: UpdateTenantOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(id, updateTenantOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantConfigApi - factory interface
 * @export
 */
export const TenantConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        encryptionKeyRecovery(options?: any): AxiosPromise<void> {
            return localVarFp.encryptionKeyRecovery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConfigs(options?: any): AxiosPromise<TenantConfigEntity> {
            return localVarFp.findConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEncryptionKeySet(options?: any): AxiosPromise<void> {
            return localVarFp.isEncryptionKeySet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTenantConfigDto} updateTenantConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateTenantConfigDto: UpdateTenantConfigDto, options?: any): AxiosPromise<TenantConfigEntity> {
            return localVarFp.update(id, updateTenantConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTenantOptions} updateTenantOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(id: number, updateTenantOptions: UpdateTenantOptions, options?: any): AxiosPromise<void> {
            return localVarFp.updateSettings(id, updateTenantOptions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantConfigApi - object-oriented interface
 * @export
 * @class TenantConfigApi
 * @extends {BaseAPI}
 */
export class TenantConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConfigApi
     */
    public encryptionKeyRecovery(options?: AxiosRequestConfig) {
        return TenantConfigApiFp(this.configuration).encryptionKeyRecovery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConfigApi
     */
    public findConfigs(options?: AxiosRequestConfig) {
        return TenantConfigApiFp(this.configuration).findConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConfigApi
     */
    public isEncryptionKeySet(options?: AxiosRequestConfig) {
        return TenantConfigApiFp(this.configuration).isEncryptionKeySet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateTenantConfigDto} updateTenantConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConfigApi
     */
    public update(id: string, updateTenantConfigDto: UpdateTenantConfigDto, options?: AxiosRequestConfig) {
        return TenantConfigApiFp(this.configuration).update(id, updateTenantConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTenantOptions} updateTenantOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantConfigApi
     */
    public updateSettings(id: number, updateTenantOptions: UpdateTenantOptions, options?: AxiosRequestConfig) {
        return TenantConfigApiFp(this.configuration).updateSettings(id, updateTenantOptions, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken: async (generateTokenDto: GenerateTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateTokenDto' is not null or undefined
            assertParamExists('generateToken', 'generateTokenDto', generateTokenDto)
            const localVarPath = `/api/v1/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenWithExpiredToken: async (generateTokenDto: GenerateTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateTokenDto' is not null or undefined
            assertParamExists('generateTokenWithExpiredToken', 'generateTokenDto', generateTokenDto)
            const localVarPath = `/api/v1/token/withExpiredToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid: async (token: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('isTokenValid', 'token', token)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('isTokenValid', 'tenant', tenant)
            const localVarPath = `/api/v1/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken: async (token: string, tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateWithToken', 'token', token)
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateWithToken', 'tenant', tenant)
            const localVarPath = `/api/v1/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (tenant !== undefined) {
                localVarQueryParameter['tenant'] = tenant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateToken(generateTokenDto: GenerateTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateToken(generateTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenWithExpiredToken(generateTokenDto: GenerateTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenWithExpiredToken(generateTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isTokenValid(token: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isTokenValid(token, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithToken(token: string, tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithToken(token, tenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken(generateTokenDto: GenerateTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.generateToken(generateTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateTokenDto} generateTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenWithExpiredToken(generateTokenDto: GenerateTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.generateTokenWithExpiredToken(generateTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid(token: string, tenant: string, options?: any): AxiosPromise<void> {
            return localVarFp.isTokenValid(token, tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithToken(token: string, tenant: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateWithToken(token, tenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @param {GenerateTokenDto} generateTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public generateToken(generateTokenDto: GenerateTokenDto, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).generateToken(generateTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateTokenDto} generateTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public generateTokenWithExpiredToken(generateTokenDto: GenerateTokenDto, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).generateTokenWithExpiredToken(generateTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public isTokenValid(token: string, tenant: string, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).isTokenValid(token, tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public updateWithToken(token: string, tenant: string, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).updateWithToken(token, tenant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TurnoverItemsApi - axios parameter creator
 * @export
 */
export const TurnoverItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {CreateTurnoverItemDto} createTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (seasonId: number, employeeId: number, createTurnoverItemDto: CreateTurnoverItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('create', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('create', 'employeeId', employeeId)
            // verify required parameter 'createTurnoverItemDto' is not null or undefined
            assertParamExists('create', 'createTurnoverItemDto', createTurnoverItemDto)
            const localVarPath = `/api/v1/turnover-items/{seasonId}/{employeeId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTurnoverItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAcceptedByController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/turnover-items/findAcceptedByController`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (exludeOnlyToShowSections: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exludeOnlyToShowSections' is not null or undefined
            assertParamExists('findAll', 'exludeOnlyToShowSections', exludeOnlyToShowSections)
            const localVarPath = `/api/v1/turnover-items/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exludeOnlyToShowSections !== undefined) {
                localVarQueryParameter['exludeOnlyToShowSections'] = exludeOnlyToShowSections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithoutEmployee: async (dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('findAllWithoutEmployee', 'dateTimeStamp', dateTimeStamp)
            // verify required parameter 'paymentSectionId' is not null or undefined
            assertParamExists('findAllWithoutEmployee', 'paymentSectionId', paymentSectionId)
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('findAllWithoutEmployee', 'paymentMethodId', paymentMethodId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findAllWithoutEmployee', 'seasonId', seasonId)
            const localVarPath = `/api/v1/turnover-items/{dateTimeStamp}/{paymentSectionId}/{paymentMethodId}/{seasonId}`
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)))
                .replace(`{${"paymentSectionId"}}`, encodeURIComponent(String(paymentSectionId)))
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique: async (dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, employeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('findUnique', 'dateTimeStamp', dateTimeStamp)
            // verify required parameter 'paymentSectionId' is not null or undefined
            assertParamExists('findUnique', 'paymentSectionId', paymentSectionId)
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('findUnique', 'paymentMethodId', paymentMethodId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findUnique', 'seasonId', seasonId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findUnique', 'employeeId', employeeId)
            const localVarPath = `/api/v1/turnover-items/{dateTimeStamp}/{paymentSectionId}/{paymentMethodId}/{seasonId}/{employeeId}`
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)))
                .replace(`{${"paymentSectionId"}}`, encodeURIComponent(String(paymentSectionId)))
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} dateTimeStamp 
         * @param {Array<string>} sectionIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositableSums: async (seasonId: number, dateTimeStamp: number, sectionIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getDepositableSums', 'seasonId', seasonId)
            // verify required parameter 'dateTimeStamp' is not null or undefined
            assertParamExists('getDepositableSums', 'dateTimeStamp', dateTimeStamp)
            // verify required parameter 'sectionIds' is not null or undefined
            assertParamExists('getDepositableSums', 'sectionIds', sectionIds)
            const localVarPath = `/api/v1/turnover-items/depositableSums/{seasonId}/{dateTimeStamp}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"dateTimeStamp"}}`, encodeURIComponent(String(dateTimeStamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionIds) {
                localVarQueryParameter['sectionIds'] = sectionIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewForWidget: async (seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('getOverviewForWidget', 'seasonId', seasonId)
            const localVarPath = `/api/v1/turnover-items/overviewForWidget/{seasonId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grouped: async (exludeOnlyToShowSections: boolean, fromDate: string, toDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exludeOnlyToShowSections' is not null or undefined
            assertParamExists('grouped', 'exludeOnlyToShowSections', exludeOnlyToShowSections)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('grouped', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('grouped', 'toDate', toDate)
            const localVarPath = `/api/v1/turnover-items/grouped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exludeOnlyToShowSections !== undefined) {
                localVarQueryParameter['exludeOnlyToShowSections'] = exludeOnlyToShowSections;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} timeStamp 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfDay: async (timeStamp: number, seasonId: number, sectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeStamp' is not null or undefined
            assertParamExists('itemOfDay', 'timeStamp', timeStamp)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('itemOfDay', 'seasonId', seasonId)
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('itemOfDay', 'sectionId', sectionId)
            const localVarPath = `/api/v1/turnover-items/itemOfDay/{timeStamp}/{seasonId}/{sectionId}`
                .replace(`{${"timeStamp"}}`, encodeURIComponent(String(timeStamp)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfToday: async (seasonId: number, sectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('itemOfToday', 'seasonId', seasonId)
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('itemOfToday', 'sectionId', sectionId)
            const localVarPath = `/api/v1/turnover-items/itemOfToday/{seasonId}/{sectionId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfYesterday: async (seasonId: number, sectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('itemOfYesterday', 'seasonId', seasonId)
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('itemOfYesterday', 'sectionId', sectionId)
            const localVarPath = `/api/v1/turnover-items/itemOfYesterday/{seasonId}/{sectionId}`
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)))
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTurnovers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/turnover-items/moveTurnovers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/turnover-items/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAcceptedByController: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAcceptedByController', 'id', id)
            const localVarPath = `/api/v1/turnover-items/acceptedByController/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} controllerId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAcceptedByOwner: async (controllerId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controllerId' is not null or undefined
            assertParamExists('setAcceptedByOwner', 'controllerId', controllerId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('setAcceptedByOwner', 'date', date)
            const localVarPath = `/api/v1/turnover-items/acceptedByOwner/{controllerId}`
                .replace(`{${"controllerId"}}`, encodeURIComponent(String(controllerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTurnoverItemDto} updateTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateTurnoverItemDto: UpdateTurnoverItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateTurnoverItemDto' is not null or undefined
            assertParamExists('update', 'updateTurnoverItemDto', updateTurnoverItemDto)
            const localVarPath = `/api/v1/turnover-items/{id}/{seasonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTurnoverItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TurnoverItemsApi - functional programming interface
 * @export
 */
export const TurnoverItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TurnoverItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {CreateTurnoverItemDto} createTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(seasonId: number, employeeId: number, createTurnoverItemDto: CreateTurnoverItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnoverItemEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(seasonId, employeeId, createTurnoverItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAcceptedByController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TurnoverItemEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAcceptedByController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(exludeOnlyToShowSections: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TurnoverItemEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(exludeOnlyToShowSections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllWithoutEmployee(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllWithoutEmployee(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnique(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, employeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnoverItemEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnique(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} dateTimeStamp 
         * @param {Array<string>} sectionIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositableSums(seasonId: number, dateTimeStamp: number, sectionIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TurnoverDepositableSumEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositableSums(seasonId, dateTimeStamp, sectionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverviewForWidget(seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverviewForWidget(seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grouped(exludeOnlyToShowSections: boolean, fromDate: string, toDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grouped(exludeOnlyToShowSections, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} timeStamp 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemOfDay(timeStamp: number, seasonId: number, sectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemOfDay(timeStamp, seasonId, sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemOfToday(seasonId: number, sectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemOfToday(seasonId, sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemOfYesterday(seasonId: number, sectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemOfYesterday(seasonId, sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveTurnovers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveTurnovers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnoverItemEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAcceptedByController(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnoverItemEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAcceptedByController(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} controllerId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAcceptedByOwner(controllerId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAcceptedByOwner(controllerId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTurnoverItemDto} updateTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateTurnoverItemDto: UpdateTurnoverItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TurnoverItemEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateTurnoverItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TurnoverItemsApi - factory interface
 * @export
 */
export const TurnoverItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TurnoverItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {CreateTurnoverItemDto} createTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(seasonId: number, employeeId: number, createTurnoverItemDto: CreateTurnoverItemDto, options?: any): AxiosPromise<TurnoverItemEntity> {
            return localVarFp.create(seasonId, employeeId, createTurnoverItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAcceptedByController(options?: any): AxiosPromise<Array<TurnoverItemEntity>> {
            return localVarFp.findAcceptedByController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(exludeOnlyToShowSections: boolean, options?: any): AxiosPromise<Array<TurnoverItemEntity>> {
            return localVarFp.findAll(exludeOnlyToShowSections, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllWithoutEmployee(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.findAllWithoutEmployee(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dateTimeStamp 
         * @param {number} paymentSectionId 
         * @param {number} paymentMethodId 
         * @param {number} seasonId 
         * @param {number} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnique(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, employeeId: number, options?: any): AxiosPromise<TurnoverItemEntity> {
            return localVarFp.findUnique(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} dateTimeStamp 
         * @param {Array<string>} sectionIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositableSums(seasonId: number, dateTimeStamp: number, sectionIds: Array<string>, options?: any): AxiosPromise<Array<TurnoverDepositableSumEntity>> {
            return localVarFp.getDepositableSums(seasonId, dateTimeStamp, sectionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverviewForWidget(seasonId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getOverviewForWidget(seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} exludeOnlyToShowSections 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grouped(exludeOnlyToShowSections: boolean, fromDate: string, toDate: string, options?: any): AxiosPromise<object> {
            return localVarFp.grouped(exludeOnlyToShowSections, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} timeStamp 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfDay(timeStamp: number, seasonId: number, sectionId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.itemOfDay(timeStamp, seasonId, sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfToday(seasonId: number, sectionId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.itemOfToday(seasonId, sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} seasonId 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemOfYesterday(seasonId: number, sectionId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.itemOfYesterday(seasonId, sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTurnovers(options?: any): AxiosPromise<object> {
            return localVarFp.moveTurnovers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: number, options?: any): AxiosPromise<TurnoverItemEntity> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAcceptedByController(id: string, options?: any): AxiosPromise<TurnoverItemEntity> {
            return localVarFp.setAcceptedByController(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} controllerId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAcceptedByOwner(controllerId: string, date: string, options?: any): AxiosPromise<object> {
            return localVarFp.setAcceptedByOwner(controllerId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTurnoverItemDto} updateTurnoverItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateTurnoverItemDto: UpdateTurnoverItemDto, options?: any): AxiosPromise<TurnoverItemEntity> {
            return localVarFp.update(id, updateTurnoverItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TurnoverItemsApi - object-oriented interface
 * @export
 * @class TurnoverItemsApi
 * @extends {BaseAPI}
 */
export class TurnoverItemsApi extends BaseAPI {
    /**
     * 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {CreateTurnoverItemDto} createTurnoverItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public create(seasonId: number, employeeId: number, createTurnoverItemDto: CreateTurnoverItemDto, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).create(seasonId, employeeId, createTurnoverItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public findAcceptedByController(options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).findAcceptedByController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} exludeOnlyToShowSections 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public findAll(exludeOnlyToShowSections: boolean, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).findAll(exludeOnlyToShowSections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dateTimeStamp 
     * @param {number} paymentSectionId 
     * @param {number} paymentMethodId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public findAllWithoutEmployee(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).findAllWithoutEmployee(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dateTimeStamp 
     * @param {number} paymentSectionId 
     * @param {number} paymentMethodId 
     * @param {number} seasonId 
     * @param {number} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public findUnique(dateTimeStamp: number, paymentSectionId: number, paymentMethodId: number, seasonId: number, employeeId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).findUnique(dateTimeStamp, paymentSectionId, paymentMethodId, seasonId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {number} dateTimeStamp 
     * @param {Array<string>} sectionIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public getDepositableSums(seasonId: number, dateTimeStamp: number, sectionIds: Array<string>, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).getDepositableSums(seasonId, dateTimeStamp, sectionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public getOverviewForWidget(seasonId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).getOverviewForWidget(seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} exludeOnlyToShowSections 
     * @param {string} fromDate 
     * @param {string} toDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public grouped(exludeOnlyToShowSections: boolean, fromDate: string, toDate: string, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).grouped(exludeOnlyToShowSections, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} timeStamp 
     * @param {number} seasonId 
     * @param {number} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public itemOfDay(timeStamp: number, seasonId: number, sectionId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).itemOfDay(timeStamp, seasonId, sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {number} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public itemOfToday(seasonId: number, sectionId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).itemOfToday(seasonId, sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} seasonId 
     * @param {number} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public itemOfYesterday(seasonId: number, sectionId: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).itemOfYesterday(seasonId, sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public moveTurnovers(options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).moveTurnovers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public remove(id: number, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public setAcceptedByController(id: string, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).setAcceptedByController(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} controllerId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public setAcceptedByOwner(controllerId: string, date: string, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).setAcceptedByOwner(controllerId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateTurnoverItemDto} updateTurnoverItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoverItemsApi
     */
    public update(id: string, updateTurnoverItemDto: UpdateTurnoverItemDto, options?: AxiosRequestConfig) {
        return TurnoverItemsApiFp(this.configuration).update(id, updateTurnoverItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLanguage', 'id', id)
            const localVarPath = `/api/v1/users/getLanguage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLanguage: async (setLanguageDto: SetLanguageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setLanguageDto' is not null or undefined
            assertParamExists('setLanguage', 'setLanguageDto', setLanguageDto)
            const localVarPath = `/api/v1/users/setLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLanguageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLanguage(setLanguageDto: SetLanguageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLanguage(setLanguageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguage(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getLanguage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLanguage(setLanguageDto: SetLanguageDto, options?: any): AxiosPromise<void> {
            return localVarFp.setLanguage(setLanguageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getLanguage(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getLanguage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetLanguageDto} setLanguageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setLanguage(setLanguageDto: SetLanguageDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setLanguage(setLanguageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkPositionsApi - axios parameter creator
 * @export
 */
export const WorkPositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateWorkPositionDto} createWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (seasonId: number, createWorkPositionDto: CreateWorkPositionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('create', 'seasonId', seasonId)
            // verify required parameter 'createWorkPositionDto' is not null or undefined
            assertParamExists('create', 'createWorkPositionDto', createWorkPositionDto)
            const localVarPath = `/api/v1/work-positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkPositionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (departmentId?: number, seasonId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/work-positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmployee: async (employeeId: number, seasonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('findByEmployee', 'employeeId', employeeId)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('findByEmployee', 'seasonId', seasonId)
            const localVarPath = `/api/v1/work-positions/byEmployee/{employeeId}/{seasonId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"seasonId"}}`, encodeURIComponent(String(seasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOne', 'id', id)
            const localVarPath = `/api/v1/work-positions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/api/v1/work-positions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {UpdateWorkPositionDto} updateWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, seasonId: number, updateWorkPositionDto: UpdateWorkPositionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'seasonId' is not null or undefined
            assertParamExists('update', 'seasonId', seasonId)
            // verify required parameter 'updateWorkPositionDto' is not null or undefined
            assertParamExists('update', 'updateWorkPositionDto', updateWorkPositionDto)
            const localVarPath = `/api/v1/work-positions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkPositionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkPositionsApi - functional programming interface
 * @export
 */
export const WorkPositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkPositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateWorkPositionDto} createWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(seasonId: number, createWorkPositionDto: CreateWorkPositionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPositionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(seasonId, createWorkPositionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(departmentId?: number, seasonId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkPositionEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(departmentId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEmployee(employeeId: number, seasonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkPositionEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEmployee(employeeId, seasonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPositionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {UpdateWorkPositionDto} updateWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, seasonId: number, updateWorkPositionDto: UpdateWorkPositionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkPositionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, seasonId, updateWorkPositionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkPositionsApi - factory interface
 * @export
 */
export const WorkPositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkPositionsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} seasonId 
         * @param {CreateWorkPositionDto} createWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(seasonId: number, createWorkPositionDto: CreateWorkPositionDto, options?: any): AxiosPromise<WorkPositionEntity> {
            return localVarFp.create(seasonId, createWorkPositionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {number} [seasonId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(departmentId?: number, seasonId?: number, options?: any): AxiosPromise<Array<WorkPositionEntity>> {
            return localVarFp.findAll(departmentId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employeeId 
         * @param {number} seasonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmployee(employeeId: number, seasonId: number, options?: any): AxiosPromise<Array<WorkPositionEntity>> {
            return localVarFp.findByEmployee(employeeId, seasonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOne(id: string, options?: any): AxiosPromise<WorkPositionEntity> {
            return localVarFp.findOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} seasonId 
         * @param {UpdateWorkPositionDto} updateWorkPositionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, seasonId: number, updateWorkPositionDto: UpdateWorkPositionDto, options?: any): AxiosPromise<WorkPositionEntity> {
            return localVarFp.update(id, seasonId, updateWorkPositionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkPositionsApi - object-oriented interface
 * @export
 * @class WorkPositionsApi
 * @extends {BaseAPI}
 */
export class WorkPositionsApi extends BaseAPI {
    /**
     * 
     * @param {number} seasonId 
     * @param {CreateWorkPositionDto} createWorkPositionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public create(seasonId: number, createWorkPositionDto: CreateWorkPositionDto, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).create(seasonId, createWorkPositionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {number} [seasonId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public findAll(departmentId?: number, seasonId?: number, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).findAll(departmentId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employeeId 
     * @param {number} seasonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public findByEmployee(employeeId: number, seasonId: number, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).findByEmployee(employeeId, seasonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public findOne(id: string, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).findOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} seasonId 
     * @param {UpdateWorkPositionDto} updateWorkPositionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkPositionsApi
     */
    public update(id: string, seasonId: number, updateWorkPositionDto: UpdateWorkPositionDto, options?: AxiosRequestConfig) {
        return WorkPositionsApiFp(this.configuration).update(id, seasonId, updateWorkPositionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


