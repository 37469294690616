import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, Switch, Typography, styled, useMediaQuery } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import AppTextField from 'components/input-fields/AppTextField';
import { H2 } from 'components/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DocumentFileEntity, DocumentTypeEntity } from 'api/generated';
import { IDFormData } from 'page-sections/employees/IDDocuments';
import AppSelectField from 'components/input-fields/MultipleChoiceField';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FC, useState } from 'react';
import { documentTypesApi, idDocumentsApi } from 'api';
import CalendarInput from 'components/input-fields/CalendarInput';
import FileUploadInput from 'components/input-fields/FileUploadInput';

// component props interface
interface ModalProps {
    documentTypes: DocumentTypeEntity[];
    open: boolean;
    onClose: () => void;
    addNewDocument: (newDocument: IDFormData) => Promise<IDFormData[]>;
    updateDocument: (document: IDFormData, index: number) => void;
    setOpenDocumentFileModal: (open: boolean) => void;
    setModalFiles: (files: DocumentFileEntity[]) => void;
    setCurrentDocumentIndex: (index: number) => void;
    setFileModalDocumentId: (id: number) => void;
    setIsLoading: (arg: boolean) => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const AddDocumentTypeModal: FC<ModalProps> = ({ open, onClose, documentTypes, addNewDocument, setCurrentDocumentIndex,
    setModalFiles, setOpenDocumentFileModal, setFileModalDocumentId, updateDocument, setIsLoading }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:499px)');
    const [typeSelected, setTypeSelected] = useState(false);
    const [usedNames, setUsedNames] = useState<string[]>([]);
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const [stateDocumentsArray, setStateDocumentsArray] = useState<IDFormData[]>();
    const [sendEmail, setSendEmail] = useState(false);
    const [attachFile, setAttachFile] = useState(false);
    const initialValues: IDFormData = {
        documentName: '',
        typeName: '',
        isVisibleForUser: false,
        expiringDate: null,
    };

    const resetAll = () => {
        values.typeName = "";
        values.documentName = "";
        values.expiringDate = null;
        values.isVisibleForUser = false;
        values.filePath = "";
        setTypeSelected(false);
        setUsedNames([]);
    }

    const fetchUsedNames = async (typeName: string) => {
        const names = (await documentTypesApi.findOneByName(typeName)).data.usedDocumentsNames?.map(usedName => usedName.name);
        if (names)
            setUsedNames(names);
        else
            setUsedNames([]);
    }

    const handleTypeChange = async (e: any) => {
        values.typeName = e.target.value;
        if (values.typeName !== "") {
            setTypeSelected(true);
            await fetchUsedNames(values.typeName);
        }
        else
            setTypeSelected(false);

    }

    /* When the upload files button gets clicked the document gets created 
    to have a document to link to files uploaded, the button should be active
    only if the document type and name are defined */

    const openFilesModal = async () => {
        let documentsArray: IDFormData[] = stateDocumentsArray ?? [];
        if (!hasBeenSubmitted) {
            documentsArray = await addNewDocument(values);
            setHasBeenSubmitted(true);
            setStateDocumentsArray(documentsArray)
        }
        documentsArray.at(documentsArray.length - 1)?.documentFiles ?
            // documentsArray gets checked on previous line
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setModalFiles(documentsArray.at(documentsArray.length - 1)!.documentFiles!)
            : setModalFiles([]);
        if (documentsArray.at(documentsArray.length - 1)?.id)
            // documentsArray gets checked on previous line
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setFileModalDocumentId(documentsArray[documentsArray.length - 1].id!)
        setCurrentDocumentIndex(documentsArray.length - 1)
        setOpenDocumentFileModal(true);

    }

    const handleNameChange = (name: string | null) => {
        setFieldValue('documentName', name, true);
    }

    const addDocumentFile = async (files: File[]) => {
        const formData = new FormData();
        files.forEach(file => { formData.append('files', file) });
        setIsLoading(true);
        const documentsArray: IDFormData[] = await addNewDocument(values);
        setHasBeenSubmitted(true);
        setStateDocumentsArray(documentsArray);
        const newDocument = await idDocumentsApi.createFile(
            String(documentsArray.at(documentsArray.length - 1)?.id),
            sendEmail,
            attachFile,
            {
                data: formData
            }).then((data) => {
                setIsLoading(false);
                // setShowToast({ show: true, message: t('alerts.fileEntred'), type: 'Created' });
                return data;
            })
        // if documentFiles is defined concat newDocument, otherwise assing a single item array of newDocument

        documentsArray.at(documentsArray.length - 1)?.documentFiles ?
            // documentsArray gets checked on previous line
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            documentsArray.at(documentsArray.length - 1)!.documentFiles = documentsArray.at(documentsArray.length - 1)!.documentFiles?.concat(newDocument.data) :
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            documentsArray.at(documentsArray.length - 1)!.documentFiles = newDocument.data;
    }


    const fieldValidationSchema = Yup.object().shape({
        documentName: Yup.string().required(
            // TODO control german translation
            t('common.forms.field.required', {
                field: t('employees.id.documentName'),
            })
        ),
        file: Yup.mixed<File>(),
        filePath: Yup.string(),
        typeName: Yup.string().required(
            t('common.forms.field.required', {
                field: t('employees.id.documentTypes.label'),
            })
        ),
    });

    const { values, errors, handleSubmit, touched, resetForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            setTypeSelected(false);
            setUsedNames([]);
            // if document entity has already been created update it instead of trying to create another one
            if (!hasBeenSubmitted)
                addNewDocument(values);
            else if (stateDocumentsArray) {
                const documentId = stateDocumentsArray.at(stateDocumentsArray.length - 1)?.id;
                if (sendEmail && documentId)
                    await idDocumentsApi.sendEmail(documentId, attachFile);
                const index = stateDocumentsArray.length;
                updateDocument(values, index - 1);
            }
            

            setHasBeenSubmitted(false);
            onClose();
            resetForm();
        },
    });

    return (
        <StyledAppModal open={open} handleClose={() => {
            resetAll();
            onClose();
            resetForm();
        }}>
            <H2 mb={2}>
                {t('common.tables.button.addItem', { item: t('employees.contract.contractFile'), })}
            </H2>

            <form onSubmit={handleSubmit}>
                <Grid container
                    spacing={3}
                    textAlign={isMobile ? "left" : "center"}
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <AppSelectField
                            InputLabelProps={{ shrink: true }}
                            select ={true}
                            name='typeName'
                            variant='outlined'
                            placeholder=''
                            SelectProps={{
                                native: true,
                                IconComponent: KeyboardArrowDown,
                            }}
                            onChange={handleTypeChange}
                            error={Boolean((errors.typeName))}
                            helperText={(errors.typeName) as string}
                        >
                            <option value={''}>
                                {t('employees.id.documentTypes.default')}
                            </option>

                            {documentTypes.map((documentType) => (
                                <option key={documentType.id} value={documentType.name}>
                                    {documentType.name}
                                </option>
                            ))}

                        </AppSelectField>
                    </Grid>
                    <Grid item xs={6}>
                        {hasBeenSubmitted ? (<Button
                            disabled={!typeSelected || (values.documentName === '' || !values.documentName)}
                            onClick={openFilesModal}
                            sx={{ textAlign: 'center' }}
                            fullWidth
                        >
                            {t('employees.id.showFiles')}
                        </Button>) : (
                            <FileUploadInput
                                resetOnChange
                                multiple
                                disabled={!typeSelected || (values.documentName === '' || !values.documentName)}
                                maxDimension={100}
                                handleOnChange={(e) => {
                                    if (e.target.files)
                                        addDocumentFile(Array.from(e.target.files));
                                }} />)}
                    </Grid>
                    <Grid item xs={6}>
                            <FormControlLabel
                                label={t('employees.document.sendEmail')}
                                control={
                                    <Switch
                                        checked={sendEmail && hasBeenSubmitted}
                                        onChange={() => { setSendEmail(!sendEmail) }}
                                        disabled={!hasBeenSubmitted}

                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                label={t('employees.document.attachFile')}
                                control={
                                    <Checkbox
                                        checked={attachFile && sendEmail && hasBeenSubmitted}
                                        onChange={() => { setAttachFile(!attachFile) }}
                                        disabled={!hasBeenSubmitted || !sendEmail}
                                    />
                                }
                            />
                        </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={usedNames}
                            onChange={(e, value) => {
                                handleNameChange(value);
                            }}
                            disabled={!typeSelected}
                            renderInput={(params) => {
                                return (
                                    <AppTextField
                                        {...params}
                                        label={t('employees.id.documentName')}
                                        fullWidth
                                        size='small'
                                        name='documentName'
                                        placeholder='Nome'
                                        value={values.documentName}
                                        onChange={(e) => {
                                            handleNameChange(e.target.value);
                                        }}
                                        error={Boolean(errors.documentName && touched.documentName)}
                                        helperText={(touched.documentName && errors.documentName) as string}
                                    />
                                )
                            }}

                        />



                    </Grid>

                    <Grid item xs={6} justifyContent={'left'}>
                        <FormControlLabel
                            disabled={!typeSelected}
                            sx={{ minWidth: '0px' }}
                            label={<Typography textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.id.documentTypes.isVisible')}</Typography>}
                            labelPlacement='start'
                            control={
                                <Switch name='isVisibleForUser' value={values.isVisibleForUser} onChange={(e) => {
                                    values.isVisibleForUser = e.target.checked
                                }}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CalendarInput
                            disabled={!typeSelected}
                            format='dd.MM.yyyy'
                            label={t('employees.id.documents.expirationDate')}
                            disableFuture={false}
                            localeText={{
                                fieldDayPlaceholder: () => t("DD"),
                                fieldMonthPlaceholder: () => t("MM"),
                                fieldYearPlaceholder: () => t("YYYY"),
                            }}
                            value={values.expiringDate ? new Date(values.expiringDate) : null}
                            onChange={(newValue) => {
                                if (newValue && !isNaN(new Date(newValue).getTime())) {
                                    const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                    values.expiringDate = utcDate.toISOString();
                                }
                            }}
                            slotProps={{
                                textField: {
                                    helperText: t("DD") + "." + t("MM") + "." + t("YYYY")
                                },
                            }}
                        />
                    </Grid>
                </Grid>


                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        resetAll();
                        resetForm();
                        onClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained' disabled={!typeSelected}>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal>
    );
};

export default AddDocumentTypeModal;