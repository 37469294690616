import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import { Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import DataTable from "page-sections/data-table/DataTable";

interface DataTableProps<T extends Base> {
    selectedData: T[];
    setSelectedData: (data: T[]) => void;
    data: T[];
    allOptionsSelected: boolean;
    selectedSegmentIds?: number[];
    header: string
}

export interface Base {
    id: number,
    name: string,
    segmentId?: number
}


const SelectTable: FC<DataTableProps<any>> = <T extends Base,>(props: DataTableProps<T>) => {
    const { t } = useTranslation();

    const getColumnShape = (data: T[]) => {
        return [
            {
                Header: props.header,
                Cell: ({ row }: any) => {
                    return (
                        <FlexBox >
                            <FlexBox ml={1.2} alignItems='baseline'>
                                <Small mb={0.5} >{row.original.name}</Small>
                                {
                                    row.original.segment &&
                                    <Tiny mb={0.5}  fontSize={10} ml={1}>{row.original.segment?.name}</Tiny>
                                }

                            </FlexBox>
                        </FlexBox>
                    );

                }
            },
            {
                Header: ' ',
                Cell: ({ row }: any) => {


                    const isSelected = row.original.id === 0 && props.allOptionsSelected
                        || props.selectedData?.find(value => value.id === row.original.id)
                        || props.selectedSegmentIds?.includes(row.original.segmentId)
                        ;
                    return (
                        <Checkbox
                            key={row.original.id}
                            defaultChecked={!!isSelected}
                            onClick={(e) => {
                                const newSelectedItem = isSelected && row.original.id !== 0 ?
                                    props.selectedData?.filter(item => item.id !== row.original.id) :
                                    props.selectedData.concat([row.original]);
                                props.setSelectedData(newSelectedItem);
                            }}
                            disabled={row.original.id !== 0 && props.allOptionsSelected}
                        />
                    )
                }
            }
        ];
    };





    const columns: any = useMemo(() => getColumnShape(props.data), [props.selectedData, t, props.data, props.selectedSegmentIds]);
    return DataTable({
        data: props.data,
        columns,
        notSelectableRows: true
    });
};

export default SelectTable;