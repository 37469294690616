import { Autocomplete, Box, Button, Grid, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import AppModal from 'components/AppModal';
import { H2, H4 } from 'components/Typography';
import FlexBox from 'components/flexbox/FlexBox';
import * as Yup from 'yup';
import { isValidDate } from 'utils/dateValidator';
import { shiftTemplateApi, calendarEntriesShiftTemplateApi } from 'api';
import { useFormik } from 'formik';
import CalendarInput from 'components/input-fields/CalendarInput';
import { ShiftTemplateEntity } from 'api/generated';
import AppTextField from 'components/input-fields/AppTextField';
import { useTranslation } from 'react-i18next';
import DaysOfWeekPicker, { DaysOfTheWeek } from 'components/input-fields/DaysOfWeekPicker';
import CustomToast from 'components/CustomToast/customToast';

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

interface ModalProps {
    originalSelectedDepartment: { label: string; id: number; } | null;
    open: boolean;
    onClose: () => void;
    fetchCalendarData: () => void;
    seasonId: number;
    allowOverwrite: boolean;
}

const AssignTemplateModalShift: FC<ModalProps> = ({
    open,
    onClose,
    fetchCalendarData,
    seasonId,
    allowOverwrite,
    originalSelectedDepartment
}) => {
    const { t } = useTranslation();
    const [shiftTemplates, setShiftTemplates] = useState<ShiftTemplateEntity[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<{ label: string; id: number } | null>(null);
    const [selectedDays, setSelectedDays] = useState<DaysOfTheWeek[]>([]);
    const [openToast, setOpenToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');


    const { values, errors, touched, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: {
            templateId: 0,
            fromDate: new Date(),
            toDate: new Date(),
            daysOfWeek: []
        },
        validationSchema: Yup.object().shape({
            templateId: Yup.number().moreThan(0, t('common.forms.field.required')),
            fromDate: Yup.date().required().test('is-valid-date', 'Invalid date', (value) => {
                if (value) return isValidDate(value);
                return true;
            }),
            toDate: Yup.date().required().test('is-valid-date', 'Invalid date', (value) => {
                if (value) return isValidDate(value);
                return true;
            }),
            daysOfWeek: Yup.array().min(1, t('common.forms.field.required'))
        }),
        onSubmit: async (formValues) => {
            const payload = {
                templateId: formValues.templateId,
                fromDate: formValues.fromDate?.toISOString().split("T").at(0) ?? '',
                toDate: formValues.toDate?.toISOString().split("T").at(0) ?? '',
                daysOfWeek: formValues.daysOfWeek
            };
            if (!payload.fromDate || !payload.toDate) {
                setToastMessage(t('common.errors.default'));
                setOpenToast(true);
                return;
            }
                    await calendarEntriesShiftTemplateApi
                .assignShiftTemplate(String(allowOverwrite), seasonId, payload)
                .then(() => {
                    setSelectedDays([]);
                    setSelectedTemplate(null);
                    onClose();
                    resetForm();
                    fetchCalendarData();
                })
                .catch((error) => {
                    onClose();
                    resetForm();
                    setSelectedDays([]);
                    setSelectedTemplate(null);
                    setToastMessage(error.response?.data?.message || t('common.errors.default'));
                    setOpenToast(true);
                });
        }
    });

    useEffect(() => {
        if (open) resetForm();
    }, [open]);


    useEffect(() => {
        shiftTemplateApi.findAll(originalSelectedDepartment?.id)
            .then(({ data }) => {
                setShiftTemplates(data.filter((shiftTemplate) => shiftTemplate.generatedFromShiftTemplateId === -1));
                
            })
            .catch(() => { });
    }, [originalSelectedDepartment]);

    return (
        <>
            <StyledAppModal
                open={open}
                handleClose={() => {
                    onClose();
                    resetForm();
                }}
            >
                <H2 mb={2}>{t('shiftTemplate.assignMultiple')}</H2>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box>
                                <H4>
                                    {t('departments.itemName')}:
                                    <span style={{ marginLeft: '8px', fontWeight: 'normal' }}>
                                        {originalSelectedDepartment?.label}
                                    </span>
                                </H4>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={shiftTemplates?.map((t) => ({ label: t.name, id: t.id })) || []}
                                value={selectedTemplate}
                                onChange={(event, value) => {
                                    setSelectedTemplate(value ? { label: value.label, id: value.id } : null);
                                    setFieldValue('templateId', value?.id || 0);
                                }}
                                renderInput={(params) => (
                                    <AppTextField
                                        {...params}
                                        placeholder={t('shiftTemplate.templateSelector.select')}
                                        label={t('shiftTemplate.itemName')}
                                        error={Boolean(errors.templateId && touched.templateId)}
                                        helperText={(touched.templateId && errors.templateId) as string}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CalendarInput
                                format='dd.MM.yyyy'
                                label={t('season.startDate')}
                                value={values.fromDate}
                                onChange={(dateValue) => {
                                    if (dateValue && dateValue instanceof Date && !isNaN(+dateValue)) {
                                        setFieldValue('fromDate', dateValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CalendarInput
                                format='dd.MM.yyyy'
                                label={t('season.endDate')}
                                value={values.toDate}
                                onChange={(dateValue) => {
                                    if (dateValue && dateValue instanceof Date && !isNaN(+dateValue)) {
                                        setFieldValue('toDate', dateValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DaysOfWeekPicker
                                selectedDays={selectedDays}
                                onChange={(days) => {
                                    setSelectedDays(days);
                                    setFieldValue('daysOfWeek', days);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                        <Button
                            fullWidth
                            size='small'
                            variant='outlined'
                            onClick={() => {
                                onClose();
                                resetForm();
                            }}
                        >
                            {t('common.forms.button.cancel')}
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            type='submit'
                            variant='contained'
                        >
                            {t('common.forms.button.save')}
                        </Button>
                    </FlexBox>
                </form>
            </StyledAppModal>
            {openToast && (
                <CustomToast
                    duration={4000}
                    onClose={() => {
                        setOpenToast(false);
                    }}
                    message={toastMessage}
                />
            )}
        </>
    );
};

export default AssignTemplateModalShift;
