
import { documentTypesApi, idDocumentsApi } from '../../api';
import React, { type FC, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Grid, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  CreateEmployeeDocumentDto,
  DocumentFileEntity,
  DocumentTypeEntity,
  EmployeeDocumentEntity
} from '../../api/generated';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TabBase, { TabResourceProps } from './TabBase';
import AddDocumentTypeModal from 'page-sections/data-table/dataTableV2/AddDocumentTypeModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'icons/DeleteIcon';
import { v4 as uuidv4 } from 'uuid';
import UploadingOverlay from 'components/UploadOverlay';
import AddDocumentModal from 'page-sections/data-table/dataTableV2/AddDocumentModal';
import AppTextField from 'components/input-fields/AppTextField';
import { H3, H5, Small } from 'components/Typography';
import SearchInput from 'components/input-fields/SearchInput';
import { searchDocumentByName } from 'utils/utils';
import CustomToast from 'components/CustomToast/customToast';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CalendarInput from 'components/input-fields/CalendarInput';
import DocumentFilesModal from 'page-sections/documents/DocumentFIlesModal';
import { AxiosResponse } from 'axios';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ExpandMore, FileUploadOutlined, Send, DeleteForever } from '@mui/icons-material';
import { format } from 'date-fns';
import { CustomDialog } from 'components/CustomDialog';
// import { ErrorSharp, TouchAppRounded } from '@mui/icons-material';

export type IDFormData = Omit<CreateEmployeeDocumentDto, 'employeeId'> & {
  id?: number;
  tempKey?: string;
  filePath?: string | null;
  isVisibleForUser?: boolean;
  index?: number;
  documentName?: string;
  expiringDate: string | null;
  documentFiles?: DocumentFileEntity[]
};


const IDDocument: FC<TabResourceProps<IDFormData[]>> = ({
  data,
  onSubmit,
  isProfileCompletition = false,
  onNewDocument,
  buttonFontSize,
  userMode
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const tenant = urlParams.get('tenant');

  const { t } = useTranslation();
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([]);
  const [isViewing, setIsViewing] = useState(!!data && !isProfileCompletition);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openDocumentTypeModal, setOpenDocumentTypeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const initialValues: IDFormData[] = data ?? [];
  const [showToast, setShowToast] = useState({ show: false, message: '', type: '' });
  const [usedNames, setUsedNames] = useState<Record<string, string[]>>({});
  const [openDocumentFileModal, setOpenDocumentFileModal] = useState(false);
  const [modalFiles, setModalFiles] = useState<DocumentFileEntity[]>([])
  const [fileModalDocumentId, setFileModalDocumentId] = useState<number>();
  const [fileModalDocumentName, setFileModalDocumentName] = useState<string>();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>();


  const submitWithSpinner = (data: IDFormData[]) => {
    onSubmit(data, setIsLoading);
  }

  const validationSchema = Yup.array().of(
    Yup.object({
      tempKey: Yup.string(),
      isVisibleForUser: Yup.boolean().required(""),
      documentName: Yup.string().required(
        t('common.forms.field.required', {
          field: t('employees.id.documentName'),
        })
      ),

      expiringDate: !userMode && !isProfileCompletition ? Yup.string().nullable() :
        Yup.string().required(
          t('common.forms.field.required', {
            field: t('employees.id.documents.expirationDate'),
          })
        ),
      filePath: Yup.string().notRequired(),
      typeName: Yup.string().required(
        t('common.forms.field.required', {
          field: t('employees.id.documentTypes.label'),
        })
      ),
    }),
  ).required();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitWithSpinner
  });

  const [filteredItem, setFilteredItem] = useState(formik.values);

  const closeFileModal = () => {
    setOpenDocumentFileModal(false);
    // update document files in local datas
    if (currentDocumentIndex !== undefined && formik?.values?.at(currentDocumentIndex))
      formik.values[currentDocumentIndex].documentFiles = modalFiles;
  }

  useEffect(() => {
    const result = searchDocumentByName(formik.values, searchValue);
    setFilteredItem(result);
  }, [searchValue, formik.values]);

  const handleNameChange = (index: number, name: string | null) => {
    formik.setFieldValue(`[${index}].documentName`, name, true);
  };

  const { handleSubmit } =
    formik;

  const addDocumentFile = async (files: File[], documentId: number, index: number) => {
    const formData = new FormData();

    files.forEach(file => { formData.append('files', file) });

    if (setIsLoading) setIsLoading(true);

    const handleApiResponse = async (apiFunc: Promise<AxiosResponse<DocumentFileEntity[], any>>
    ) => {
      try {
        const response = await apiFunc;
        setShowToast({ show: true, message: t('alerts.fileEntred'), type: 'Created' });
        return response;
      } catch (error) {
        setShowToast({ show: true, message: t('alerts.fileFailed'), type: 'Error' });
        throw error;
      } finally {
        if (setIsLoading) setIsLoading(false);
      }
    };

    const apiFunction = token && tenant
      ? idDocumentsApi.createFileWithToken(String(documentId), tenant, { data: formData, params: { token } })
      : idDocumentsApi.createFile(String(documentId), false, false, { data: formData });

    const newDocument = await handleApiResponse(apiFunction);

    if (!newDocument) {
      setShowToast({ show: true, message: t('alerts.fileFailed'), type: 'Error' });
      return;
    }
    const currentDocumentFiles = formik.values[index].documentFiles || [];

    const updatedFiles = currentDocumentFiles.concat(newDocument.data);
    formik.values[index].documentFiles = updatedFiles;

  };

  const fetchDocumentTypes = () => {
    const handleResponse = ({ data }: any) => {
      setDocumentTypes(data);
      fetchUsedNames(data);
    }


    if (token && tenant) {
      documentTypesApi.findAllWithToken(tenant, { params: { token } })
        .then(handleResponse)
    } else {
      documentTypesApi.findAll()
        .then(handleResponse)
    }


  };

  const fetchUsedNames = (documentTypes: DocumentTypeEntity[]) => {
    const nameRecord: Record<string, string[]> = {};

    for (const documentType of documentTypes) {
      if (documentType.usedDocumentsNames)
        nameRecord[documentType.name] = documentType.usedDocumentsNames?.map(data => data.name);
    }
    setUsedNames(nameRecord);
  }

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    formik.setFieldValue(`[${index}].isVisibleForUser`, (event.target.checked), true)
  }


  const addNewDocumentFromModal = async (newDocument: IDFormData) => {
    newDocument.tempKey = uuidv4()
    if (onNewDocument)
      newDocument = await onNewDocument(newDocument) as EmployeeDocumentEntity;
    // saving index to reference the newDocument in the formik array when filtering
    newDocument.index = formik.values.length;
    const newArray = [...formik.values, newDocument];
    formik.setValues(newArray);
    setShowToast({ show: true, message: t('alerts.document'), type: 'Created' })
    return newArray;
  }

  const updateDocumentFromModal = (document: IDFormData, index: number) => {
    formik.setFieldValue(`[${index}].documentName`, document.documentName, true);
    formik.setFieldValue(`[${index}].isVisibleForUser`, document.isVisibleForUser, true);
    formik.setFieldValue(`[${index}].typeName`, document.typeName, true);
    formik.setFieldValue(`[${index}].expiringDate`, document.expiringDate, true);
  }

  const removeDocument = (indexToRemove: number) => {
    formik.setValues([
      ...formik.values.slice(0, indexToRemove),
      ...formik.values.slice(indexToRemove + 1)
    ]);
  };

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      if (token && tenant) {
        documentTypesApi.findAllWithToken(tenant, { params: { token } }).then(({ data }) => { setDocumentTypes(data); fetchUsedNames(data) });
      } else {
        documentTypesApi.findAll().then(({ data }) => { setDocumentTypes(data); fetchUsedNames(data) });
      }
    };

    fetchDocumentTypes();

  }, []);

  const handleCloseToast = () => {
    setShowToast({ show: false, message: '', type: '' });
  }


  const [deletingDocumentId, setDeletingDocumentId] = useState<number>();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleCloseDeleteConfirmation = () => {
    setDeletingDocumentId(undefined);
    setDeleteConfirmationOpen(false);
  }

  const handleConfirmDelete = async () => {
    if (deletingDocumentId !== undefined)
      removeDocument(deletingDocumentId);

    handleCloseDeleteConfirmation();

  }

  const handleOpenconfirmDelete = (id: number) => {
    setDeletingDocumentId(id);
    setDeleteConfirmationOpen(true);
  }


  const IdTable = (types: DocumentTypeEntity[]) => {
    return types.map((type) => {


      const filtered = filteredItem.filter((value) => {
        return value.typeName === type.name
      })

      if (filtered.length > 0) {
        return (<Accordion
          disableGutters
          sx={{
            '&:before': {
              display: 'none',
            },
            margin: 2
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <H3>{type.name}</H3>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><H5>{t('employees.id.documentName')}</H5></TableCell>
                  <TableCell><H5>{t('employees.id.documents.expirationDate')}</H5></TableCell>
                  <TableCell align='center'><H5>{t('employees.id.showFiles')}</H5></TableCell>
                  <TableCell align='center'><H5>{t('employees.id.documentTypes.isVisible')}</H5></TableCell>
                  <TableCell align='center'><H5>{t('employees.id.sendEmail')}</H5></TableCell>
                  <TableCell align='center'><H5>{t('employees.id.delete')}</H5></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered.map((item) => {
                  return <TableRow>
                    <TableCell align='left'>
                      {
                        isProfileCompletition || isViewing || userMode ?
                          <Small>{item.documentName}</Small> :
                          <Autocomplete
                            freeSolo
                            options={usedNames[item.typeName]}
                            disabled={isProfileCompletition || isViewing || userMode}
                            value={item.documentName}
                            onChange={(e, value) => {
                              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                              handleNameChange(item.index!, value)
                            }}
                            disableClearable
                            renderInput={(params) => {
                              return (
                                <AppTextField
                                  {...params}
                                  fullWidth
                                  variant='standard'
                                  disabled={userMode}
                                  size='small'
                                  name='documentName'
                                  onChange={(e) => {
                                    if (e.target.value !== "")
                                      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                      handleNameChange(item.index!, e.target.value)
                                    else
                                      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                      handleNameChange(item.index!, null);
                                  }}

                                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                  error={Boolean(formik.errors[item.index!]?.documentName)}
                                  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                  helperText={formik.errors[item.index!]?.documentName}

                                  InputProps={{
                                    style: {
                                      fontSize: 13,
                                      fontWeight: 500,
                                      lineHeight: 1.6
                                    }
                                  }}
                                />
                              )
                            }}
                          />

                      }
                    </TableCell>
                    <TableCell align='left'>
                      {
                        isViewing ? <Small>{item.expiringDate ? format(new Date(item.expiringDate), 'dd.MM.yyy') : '-'}</Small> :
                          <CalendarInput
                            disabled={isViewing}
                            format='dd.MM.yyyy'
                            disableFuture={false}
                            localeText={{
                              fieldDayPlaceholder: () => t("DD"),
                              fieldMonthPlaceholder: () => t("MM"),
                              fieldYearPlaceholder: () => t("YYYY"),
                            }}
                            value={item.expiringDate ? new Date(item.expiringDate) : null}
                            onChange={(newValue) => {
                              if (newValue && !isNaN(new Date(newValue).getTime())) {
                                const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                formik.setFieldValue(`[${item.index}].expiringDate`, (utcDate.toISOString()), true)
                              }
                              else
                                formik.setFieldValue(`[${item.index}].expiringDate`, null, true)
                            }}
                            slotProps={{
                              textField: {
                                variant: 'standard',
                                size: 'small',
                                error: Boolean(
                                  item.index !== undefined &&
                                  formik.touched[item.index]?.expiringDate &&
                                  formik.errors[item.index]?.expiringDate
                                ),
                                inputProps: {
                                  disableUnderline: true,
                                  style: {
                                    fontSize: 13,
                                    fontWeight: 500,
                                    lineHeight: 1.6
                                  }
                                }
                              },
                              actionBar: {
                                actions: ['clear']
                              }

                            }}
                          />
                      }
                    </TableCell>
                    <TableCell align='center'>
                      { // if at least a file is linked to this document show document modal, otherwise show upload file button
                        item.documentFiles && item.documentFiles.length > 0 ?
                          (<IconButton
                            size='small'
                            onClick={() => {
                              // open modal
                              setOpenDocumentFileModal(true);
                              if (item.documentFiles)
                                // set modal files with current document files
                                setModalFiles(item.documentFiles)
                              // set id with current document id
                              if (item.id)
                                setFileModalDocumentId(item.id)
                              // null index check or if it's 0 (to avoid to evaluate 0 as false)
                              if (item.index || item.index === 0)
                                setCurrentDocumentIndex(item.index)
                              // set document name
                              setFileModalDocumentName(item.documentName);
                            }}>
                            <FindInPageIcon />
                          </IconButton>) : (
                            <IconButton
                              component="label"
                              size='small'
                              disabled={isViewing}
                            >
                              <FileUploadOutlined />
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  if (e.target.files && item.id !== undefined && item.index !== undefined) {
                                    addDocumentFile(Array.from(e.target.files), item.id, item.index)
                                  }
                                }}
                                multiple
                              />
                            </IconButton>
                          )
                      }
                    </TableCell>
                    <TableCell align='center'>
                      {(!isProfileCompletition && !userMode &&
                        <Switch
                          disabled={isViewing}
                          checked={item.isVisibleForUser}
                          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                          onChange={(e) => { handleCheckedChange(e, item.index!) }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      )}

                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        size='small'
                        disabled={!item.isVisibleForUser}
                        onClick={async () => {
                          if (item.id) {
                            const result = await idDocumentsApi.sendEmail(item.id, true);
                            if (result.status === 201) {
                              setShowToast({ show: true, message: t('alerts.fileSent'), type: 'Created' });
                            } else {
                              setShowToast({ show: true, message: t('alerts.error'), type: 'Error' });
                            }
                          }
                        }}
                      >
                        <Send />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      {(!isProfileCompletition && !userMode &&
                        <IconButton
                          size='small'
                          color='error'
                          disabled={isViewing}
                          onClick={() => {
                            if (!isViewing)
                              if (!isViewing)
                                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                                handleOpenconfirmDelete(item.index!)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                })
                }
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>)
      }
      return <></>;
    })
  }

  return (
    <TabBase
      title={t('employees.id.title')}
      setIsViewing={setIsViewing}
      isViewing={isViewing}
      handleSubmit={handleSubmit}
      isProfileCompletition={isProfileCompletition}
    >


      {showToast.show &&
        <CustomToast
          duration={3000}
          onClose={handleCloseToast}
          message={showToast.message}
          icon={showToast.type === 'Created' ? <CheckCircleOutlineRoundedIcon /> : ''}
        />
      }

      {isLoading &&
        <UploadingOverlay />
      }

      {fileModalDocumentId && <DocumentFilesModal
        onClose={closeFileModal}
        onDocumentRemove={(id, index) => {
          // remove file from database
          isProfileCompletition ? (tenant && token) && idDocumentsApi.removeFileWithToken(String(id), tenant, { params: { token } }) : idDocumentsApi.removeFile(String(id))
          // remove file from local datas
          if (currentDocumentIndex !== undefined && formik?.values?.at(currentDocumentIndex)) {
            setModalFiles(
              [
                ...modalFiles.slice(0, index),
                ...modalFiles.slice(index + 1)
              ]
            )
          }
        }}
        isCompiledByEmployee={!!(token && tenant)}
        open={openDocumentFileModal}
        data={modalFiles}
        setData={setModalFiles}
        documentId={fileModalDocumentId}
        documentName={fileModalDocumentName ?? ''}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setShowToastCallback={setShowToast}
        userMode={userMode}
      />
      }
      {!userMode && <AddDocumentModal
        addNewDocument={addNewDocumentFromModal}
        updateDocument={updateDocumentFromModal}
        open={openDocumentModal}
        documentTypes={documentTypes}
        setIsLoading={setIsLoading}
        onClose={() => {
          setOpenDocumentModal(false);
        }}
        setCurrentDocumentIndex={setCurrentDocumentIndex}
        setFileModalDocumentId={setFileModalDocumentId}
        setModalFiles={setModalFiles}
        setOpenDocumentFileModal={setOpenDocumentFileModal}
      />}

      <CustomDialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        title={t('dialog.title', {
          item: t('documents.itemName').toLocaleLowerCase(),
        })}
        content={t('dialog.document.delete')}
        icon={<DeleteForever />}
        confirmText={t('dialog.confirm')}
        confirmColor='error'
        cancelColor='primary'
      />

      <AddDocumentTypeModal
        open={openDocumentTypeModal}
        onClose={() => {
          setOpenDocumentTypeModal(false);
          fetchDocumentTypes();
        }}
        setShowToastCallback={setShowToast}
      />

      {!userMode && <SearchInput
        bordered={false}
        placeholder={t('employees.id.search')}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      }

      {IdTable(documentTypes)}

      {!isProfileCompletition && !userMode && (
        <Grid container spacing={2} marginTop={2} borderTop={1} borderColor={'lightgray'}>

          <Grid item xs={5} sm={5}>
            <Button
              sx={{ textAlign: 'left', fontSize: buttonFontSize }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenDocumentModal(true)
              }}
            >

              {t('employees.contract.contractFile')}

            </Button>
          </Grid>

          <Grid item xs={1} sm={0} />

          <Grid item xs={5} sm={5} >
            <Button
              sx={{ textAlign: 'left', fontSize: buttonFontSize }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenDocumentTypeModal(true)
              }}
            >
              {t('employees.id.documentTypes.add')}

            </Button>
          </Grid>
        </Grid>
      )}
    </TabBase>
  );
};

export default IDDocument;
