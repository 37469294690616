import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from "@mui/material";
import { DepartmentEntity, PublicDocumentEntity, SeasonEntity, SegmentEntity } from "api/generated";
import { FC, useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from "react-i18next";
import { H3 } from "components/Typography";
import DeleteIcon from "icons/DeleteIcon";
import { CustomDialog } from "components/CustomDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { publicDocumentsApi } from "api";
import RecordAccordion from "./recordAccordion";

interface AccordionData {
    departments: DepartmentEntity[],
    segments: SegmentEntity[],
    seasons: SeasonEntity[],
    documents: PublicDocumentEntity[],
    isManager: boolean,
    fetchDataCallBack: () => void,
    updateCallBack: (data: PublicDocumentEntity) => void
}


const PublicDocumentAccordion: FC<AccordionData> = ({ segments, departments, seasons, documents, isManager, fetchDataCallBack, updateCallBack }) => {

    const [departmentRecords, setDepartmentRecords] = useState<Record<number, PublicDocumentEntity[]>>([]);
    const [segmentRecords, setSegmentRecords] = useState<Record<number, PublicDocumentEntity[]>>([]);
    const [seasonRecords, setSeasonRecords] = useState<Record<number, PublicDocumentEntity[]>>([]);
    const [rest, setRest] = useState<PublicDocumentEntity[]>([]);
    const { t } = useTranslation();

    const [deletingDocumentId, setDeletingDocumentId] = useState<number>();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleCloseDeleteConfirmation = () => {
        setDeletingDocumentId(undefined);
        setDeleteConfirmationOpen(false);
    }

    const handleConfirmDelete = async () => {
        if (deletingDocumentId)
            publicDocumentsApi.remove(deletingDocumentId).then(() => {
                handleCloseDeleteConfirmation();
                fetchDataCallBack();
            });
    }

    const handleOpenconfirmDelete = (id: number) => {
        setDeletingDocumentId(id);
        setDeleteConfirmationOpen(true);
    }

    const setRecords = () => {
        const departmentRecords: Record<number, PublicDocumentEntity[]> = [];
        const segmentRecords: Record<number, PublicDocumentEntity[]> = [];
        const seasonRecords: Record<number, PublicDocumentEntity[]> = [];
        const rest: PublicDocumentEntity[] = [];
        documents.forEach(document => {
            if (document.departments) {
                document.departments.forEach((department) => {
                    if (!departmentRecords[department.id]) {
                        departmentRecords[department.id] = [document];
                    }
                    else {
                        departmentRecords[department.id] = departmentRecords[department.id].concat(document);
                    }
                })
            }
            if (document.segments) {
                document.segments.forEach((segment) => {
                    if (!segmentRecords[segment.id]) {
                        segmentRecords[segment.id] = [document];
                    }
                    else {
                        segmentRecords[segment.id] = segmentRecords[segment.id].concat(document);
                    }
                })
            }
            if (document.seasons) {
                document.seasons.forEach((season) => {
                    if (!seasonRecords[season.id]) {
                        seasonRecords[season.id] = [document];
                    }
                    else {
                        seasonRecords[season.id] = seasonRecords[season.id].concat(document);
                    }
                })
            }
            if (document.departments?.length === 0 && document.seasons?.length === 0 && document.segments?.length === 0) {
                rest.push(document);

            }

        });
        setDepartmentRecords(departmentRecords);
        setSegmentRecords(segmentRecords);
        setSeasonRecords(seasonRecords);
        setRest(rest);
    }

    useEffect(() => {
        setRecords();
    }, [departments, seasons, segments, documents])


    return (
        <>
            <CustomDialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                onConfirm={handleConfirmDelete}
                title={t('dialog.title', {
                    item: t('documents.itemName').toLocaleLowerCase(),
                })}
                content={t('dialog.document.delete')}
                icon={<DeleteForeverIcon />}
                confirmText={t('dialog.confirm')}
                confirmColor='error'
                cancelColor='primary'
            />

            <RecordAccordion
                sections={departments}
                sectionRecords={departmentRecords}
                handleOpenconfirmDeleteCallback={handleOpenconfirmDelete}
                isManager={isManager}
                updateCallBack={updateCallBack}
            />

            <RecordAccordion
                sections={segments}
                sectionRecords={segmentRecords}
                handleOpenconfirmDeleteCallback={handleOpenconfirmDelete}
                isManager={isManager}
                updateCallBack={updateCallBack}
            />

            <RecordAccordion
                sections={seasons}
                sectionRecords={seasonRecords}
                handleOpenconfirmDeleteCallback={handleOpenconfirmDelete}
                isManager={isManager}
                updateCallBack={updateCallBack}
            />

            {rest.length > 0 &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls={'all-content'}
                        id={'all-header'}
                    >
                        <Grid container alignContent={'center'} padding={'3px'} >

                            <Grid item zeroMinWidth >
                                <H3
                                    style={{
                                        textOverflow: 'ellipsis',
                                        wordBreak: 'break-word',
                                        overflow: 'hidden',
                                        minWidth: '0',
                                        maxWidth: '100%', // Ensures the text does not exceed the parent width
                                        display: 'flex',
                                        padding: '5px 10px',
                                    }}>
                                    {t('common.all')}
                                </H3>
                            </Grid>

                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {
                                rest.map((record) => {
                                    return (
                                        <Grid container item xs={12} paddingY={1} sx={{ borderTop: 1, borderColor: "lightgray", }}>
                                            <Grid item xs={7} sm={2}>
                                                <a
                                                    style={{ paddingLeft: '20px', paddingTop: '5px' }}
                                                    href={record.filePath}>
                                                    {record.name}
                                                </a>
                                            </Grid>
                                            {
                                                isManager &&
                                                <>
                                                    <Grid item xs={3}>
                                                        <Button onClick={() => {
                                                            updateCallBack(record)
                                                        }}>
                                                            {t('publicDocuments.update')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <DeleteIcon
                                                            sx={{ alignSelf: 'center', display: 'flex' }}
                                                            color="error"
                                                            onClick={() => {

                                                                handleOpenconfirmDelete(record.id);
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',

                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion >
            }
        </>
    )


}

export default PublicDocumentAccordion;