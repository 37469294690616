import React, { useState, useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export enum DaysOfTheWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}

const DAYS = [
    {
        key: DaysOfTheWeek.SUNDAY,
        label: 'SU',
    },
    {
        key: DaysOfTheWeek.MONDAY,
        label: 'MO',
    },
    {
        key: DaysOfTheWeek.TUESDAY,
        label: 'TU',
    },
    {
        key: DaysOfTheWeek.WEDNESDAY,
        label: 'WE',
    },
    {
        key: DaysOfTheWeek.THURSDAY,
        label: 'TH',
    },
    {
        key: DaysOfTheWeek.FRIDAY,
        label: 'FR',
    },
    {
        key: DaysOfTheWeek.SATURDAY,
        label: 'SA',
    },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(2),
        borderColor: '#1976d2',
        borderRadius: '50%',
        '&:not(:first-of-type)': {
            borderRadius: '50%',
            border: '1px solid'
        },
        "&:first-child": {
            borderRadius: "50%",
            border: '1px solid',
        }
    },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: '#1976d2',
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#1976d2',
    },
    '&:hover': {
        borderColor: '#E5F3FD',
        backgroundColor: '#E5F3FD',
    },
    '&.Mui-selected:hover': {
        borderColor: '#E5F3FD',
        backgroundColor: '#E5F3FD',
        color: 'black'
    },
    minWidth: 40,
    maxWidth: 40,
    height: 40,
    textTransform: 'unset',
    fontSize: '0.75rem',
}));

interface DaysOfWeekPickerProps {
    selectedDays: DaysOfTheWeek[];
    onChange: (selectedDays: DaysOfTheWeek[]) => void;
    disabled?: boolean
}

const DaysOfWeekPicker: React.FC<DaysOfWeekPickerProps> = ({ selectedDays, onChange, disabled }) => {
    const [days, setDays] = useState<number[]>([]);

    useEffect(() => {
        // Converts the elements of the selectedDays array to numbers corresponding to the indices of the elements in DAYS
        const convertedDays = selectedDays.map(day => DAYS.findIndex(d => d.key === day));
        setDays(convertedDays);
    }, [selectedDays]);

    const handleDaysChange = (event: React.MouseEvent<HTMLElement>, value: number[]) => {
        const selectedDays = value.map(index => DAYS[index].key);
        onChange(selectedDays);
    };

    return (
        <StyledToggleButtonGroup
            size="small"
            aria-label="Days of the week"
            value={days}
            disabled={disabled}
            onChange={handleDaysChange}
        >
            {DAYS.map((day, index) => (
                <StyledToggleButton key={day.key} value={index} aria-label={day.key}>
                    {day.label}
                </StyledToggleButton>
            ))}
        </StyledToggleButtonGroup>
    );
};

export default DaysOfWeekPicker;
