import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, styled, Switch, useMediaQuery } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2 } from 'components/Typography';
import { useTranslation } from 'react-i18next';

import { FC, useState } from 'react';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import { employeePaymentsApi } from 'api';
import { PaymentPageDataEntity } from 'api/generated';
import { FindInPage, Delete, Preview } from '@mui/icons-material';
import { PDFDocument } from 'pdf-lib';
import toast from 'react-hot-toast';
import AppTextField from 'components/input-fields/AppTextField';
import { format } from 'date-fns';
import { useEncryption } from 'contexts/EncryptionKeyContext';
import { decryptData } from 'utils/encryptionDecryptionAgreements';

// component props interface
interface UploadPdfProps {
    open: boolean;
    onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const UploadPdfModal: FC<UploadPdfProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:499px)');
    const [selectedFile, setSelectedFile] = useState<File>();
    const [pagesData, setPagesData] = useState<PaymentPageDataEntity[]>([]);
    const [notValidPagesData, setNotValidPagesData] = useState<PaymentPageDataEntity[]>([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [attachFile, setAttachFile] = useState(false);
    const { encryptionKey } = useEncryption();

    const resetAll = () => {
        setSelectedFile(undefined);
        setPagesData([]);
    }

    const fetchPdf = async () => {
        if (!selectedFile)
            return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
            const result = (await employeePaymentsApi.findInPdf({ data: formData })).data;
            if (result.length > 0) {
                const valid = result.filter((page) => page.employee && page.payments.length > 0);
                if (valid.length > 0) {
                    setPagesData(result.filter((page) => page.employee));
                    setNotValidPagesData(result.filter((page) => !page.employee));
                    setOpenPaymentModal(true);
                } else {
                    toast.error(t('uploadPdf.noCompatiblePayments'));
                }
            } else {
                toast.error(t('uploadPdf.noPages'));
            }
        } catch {
            toast.error(t('uploadPdf.noPages'));
        }
    }

    return (
        <>
            <StyledAppModal open={open} handleClose={() => {
                resetAll();
                onClose();
            }}>
                <H2 mb={2}>
                    {t('table.uploadPdf')}
                </H2>

                <p>
                    {t('uploadPdf.description')}
                </p>

                <form>
                    <Grid container mt={2}
                        spacing={3}
                        textAlign={isMobile ? "left" : "center"}
                        alignItems="center"
                        justifyItems={"center"}
                        justifyContent={"center"}
                    >
                        <Grid item xs={6}>
                            <FileUploadInput
                                handleOnChange={(e) => {
                                    if (e.target.files) {
                                        setSelectedFile(Array.from(e.target.files)[0]);
                                    }
                                }}
                                resetOnChange={false}
                                disabled={false}
                                multiple={false}
                            />
                        </Grid>
                    </Grid>


                    <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                        <Button fullWidth variant='outlined' onClick={() => {
                            resetAll();
                            onClose();
                        }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                        <Button fullWidth
                            disabled={!selectedFile}
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={async () => {
                                await fetchPdf();
                                setOpenPaymentModal(true);
                            }}
                        >
                            {t('common.forms.button.upload')}
                        </Button>
                    </FlexBox>
                </form>
            </StyledAppModal>
            <StyledAppModal open={openPaymentModal && pagesData.length > 0 && selectedFile !== undefined} handleClose={() => {
                resetAll();
                onClose();
                setOpenPaymentModal(false);
            }}>
                <H2 mb={2}>
                    {t('uploadPdf.payments')}
                </H2>
                {
                    pagesData.map((page) => {
                        return page.employee === undefined ? <></> : <Grid
                            container key={page.pageIndex}
                            justifyContent={'space-between'}
                            alignContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid item xs={1}>
                                <IconButton onClick={() => {
                                    setPagesData(pagesData.filter((data) => {
                                        return data.pageIndex !== page.pageIndex;
                                    }))
                                }}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                            <Grid item xs={3}>
                                {page.employee.firstName + ' ' + page.employee.lastName}
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={page.payments}
                                    value={page.selectedPayment}
                                    onChange={(event, value) => {
                                        setPagesData(pagesData.map((data) => {
                                            return data.pageIndex === page.pageIndex ? { ...data, selectedPayment: value ?? undefined } : data
                                        }))
                                    }}
                                    getOptionLabel={(option) => {
                                        const value = encryptionKey && option.paid_encrypted ?
                                            Number(decryptData(option.paid_encrypted, encryptionKey)) :
                                            Number(option.paid)
                                        if (option) {
                                            return format(new Date(option.paymentDate), 'dd/MM/yyy') + ' - ' + value + ' €';
                                        } else {
                                            return '';
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <AppTextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            label={t('report.selectPayment')}
                                            error={!page.selectedPayment}
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={async () => {
                                    if (selectedFile) {
                                        const pdfDoc = await PDFDocument.load(await selectedFile.arrayBuffer());

                                        const newPdfDoc = await PDFDocument.create();
                                        const copiedPage = await newPdfDoc.copyPages(pdfDoc, [page.pageIndex]);
                                        newPdfDoc.addPage(copiedPage[0]);
                                        const pdfBytes = await newPdfDoc.save();

                                        const blob = new Blob([pdfBytes]);

                                        const downloadLink = document.createElement('a');

                                        const url = window.URL.createObjectURL(blob);
                                        downloadLink.href = url;

                                        downloadLink.download = 'page.pdf';

                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);
                                    }
                                }}>
                                    <FindInPage />
                                </IconButton>
                            </Grid>
                        </Grid>
                    })
                }
                {
                    notValidPagesData.length > 0 &&
                    <Box>
                        <Alert
                            sx={{ margin: 1 }}
                            severity="warning"
                            action={
                                <IconButton sx={{ alignSelf: 'flex-start' }} color="inherit" onClick={() => {
                                }}>
                                    <Preview fontSize='inherit' />
                                </IconButton>
                            }
                        >
                            <AlertTitle>{t('common.warning')}</AlertTitle>
                            {t(
                                'uploadPdf.notAssignedPages',
                                { items: notValidPagesData.map((page) => page.pageIndex + 1).toString() }
                            )}
                        </Alert>
                    </Box>
                }
                <Grid container margin={2} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label={t('employees.document.sendEmail')}
                            control={
                                <Switch
                                    checked={sendEmail}
                                    onChange={() => { setSendEmail(!sendEmail) }}
                                    disabled={pagesData.length === 0}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label={t('employees.document.attachFile')}
                            control={
                                <Checkbox
                                    checked={attachFile && sendEmail}
                                    onChange={() => { setAttachFile(!attachFile) }}
                                    disabled={!sendEmail || pagesData.length === 0}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth variant='outlined' onClick={() => {
                        resetAll();
                        onClose();
                        setOpenPaymentModal(false);
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth
                        disabled={
                            pagesData.length === 0 || pagesData.find((page) => !page.selectedPayment) !== undefined
                        }
                        size='small'
                        type='button'
                        variant='contained'
                        onClick={async () => {
                            if (!selectedFile) return;

                            for (const page of pagesData) {
                                if (!page.selectedPayment) continue;

                                const formData = new FormData();
                                const pdfDoc = await PDFDocument.load(await selectedFile.arrayBuffer());
                                const newPdfDoc = await PDFDocument.create();
                                const copiedPage = await newPdfDoc.copyPages(pdfDoc, [page.pageIndex]);
                                newPdfDoc.addPage(copiedPage[0]);
                                const pdfBytes = await newPdfDoc.save();

                                formData.append('file', new File([pdfBytes], 'payment.pdf'));

                                employeePaymentsApi.uploadPayment(page.selectedPayment?.id,
                                    {
                                        data: formData,
                                    }
                                )
                            }
                        }}
                    >
                        {t('confirm')}
                    </Button>
                </FlexBox>
            </StyledAppModal>
        </>
    );
};

export default UploadPdfModal;