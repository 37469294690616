import { depositsApi } from "api";
import { DepartmentEntity, DepositEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from "api/generated";
import { format, isSameDay } from "date-fns";
import * as ExcelJS from 'exceljs';
import { t } from "i18next";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { addLogoImageToReport, downloadReport, getDates, ignoreUTC, reportAddTitleRow } from "utils/utils";

const getColumnLetter = (columnIndex: number) => {
    let letter = '';
    let temp = columnIndex;
    while (temp > 0) {
        const remainder = (temp - 1) % 26;
        letter = String.fromCharCode(65 + remainder) + letter;
        temp = Math.floor((temp - 1) / 26);
    }
    return letter;
}

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'OneStaff';
    workbook.lastModifiedBy = 'OneStaff';
    workbook.created = new Date();
    return workbook;
}

const createWoksheet = (workbook: ExcelJS.Workbook, customTitle?: string) => {
    const worksheet = workbook.addWorksheet(customTitle ?? t('turnover.reportName'));
    worksheet.properties.defaultColWidth = 35;
    worksheet.properties.defaultRowHeight = 20;
    return worksheet;
}

const reportStyleWorksheet = (worksheet: ExcelJS.Worksheet, rowsNumber: number, columnsNumber: number) => {
    for (let i = 1; i < rowsNumber; i++) {
        for (let j = 1; j < columnsNumber; j++) {
            const cell = worksheet.getCell(i, j);
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.numFmt = '0.00'
            if (i % 2 === 0) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd6eaf8' }
                };
            } else {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'aed6f1' }
                };
            }
        }
    }
}

const addHeaderRows = (
    worksheet: ExcelJS.Worksheet,
    rowsNumber: number,
    departments: DepartmentEntity[],
    allSections: SectionEntity[],
    allPaymentMethods: PaymentMethodEntity[],
    pmLength: number,
    excludeDownPayments?: boolean
) => {
    const departmentsRow = worksheet.getRow(2);
    const sectionsRow = worksheet.getRow(3);
    const methodsRow = worksheet.getRow(4);

    let sections: SectionEntity[] = [];
    let paymentMethods: PaymentMethodEntity[] = [];

    if (excludeDownPayments) {

        paymentMethods = allPaymentMethods.filter(pm => !pm.type.toLowerCase().includes('downpayment'))
        sections = allSections.filter(s => !s.onlyToBeShowed);
    }

    else {
        sections = allSections;
        paymentMethods = allPaymentMethods;
    }

    departmentsRow.height = 25;
    departmentsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    sectionsRow.height = 25;
    sectionsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    methodsRow.height = 25;
    methodsRow.font =
    {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };

    // Fill paymentMethods header row
    let precedentCells = 0;
    let downPaymentColumns = 0;
    for (let i = 0; i < sections.length; i++) {
        let sectionWithDownPayment = false;

        const pms = !excludeDownPayments ?
            sections[i].paymentMethods :
            sections[i].paymentMethods?.filter(pm => !pm.type.toLowerCase().includes('downpayment'))

        if (pms) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            for (let j = 0; j < pms.length; j++) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (pms[j].type.toLowerCase().includes('downpayment')) {
                    sectionWithDownPayment = true;
                }

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                methodsRow.getCell(precedentCells + 2).value = translatePaymentTypes(pms[j].type, t);
                precedentCells = precedentCells + 1;

            }
        }
        methodsRow.getCell(precedentCells + 2).value = t('turnover.report.totSection');
        precedentCells = precedentCells + 1;

        if (sectionWithDownPayment && !excludeDownPayments) {
            methodsRow.getCell(precedentCells + 2).value = t('turnover.report.downpaymentSection');

            precedentCells = precedentCells + 1;
            downPaymentColumns = downPaymentColumns + 1;
        }

    }
    const totHeaderCell = departmentsRow.getCell(pmLength + 2 + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + 2 + downPaymentColumns + sections.length).merge(totHeaderCell);
    sectionsRow.getCell(pmLength + 2 + downPaymentColumns + sections.length).merge(totHeaderCell);

    totHeaderCell.value = t('turnover.report.total');
    totHeaderCell.border = {
        left: {
            style: 'thin',
            color: { argb: 'FF000000' },
        },
        right: {
            style: 'thin',
            color: { argb: 'FF000000' },
        }
    }

    if (!excludeDownPayments) {
        const totDownPaymentHeaderCell = departmentsRow.getCell(pmLength + 3 + downPaymentColumns + sections.length);
        methodsRow.getCell(pmLength + 3 + downPaymentColumns + sections.length).merge(totDownPaymentHeaderCell);
        sectionsRow.getCell(pmLength + 3 + downPaymentColumns + sections.length).merge(totDownPaymentHeaderCell);

        totDownPaymentHeaderCell.value = t('turnover.report.totalDownpayment');
        totDownPaymentHeaderCell.border = {
            right: {
                style: 'thin',
                color: { argb: 'FF000000' },
            }
        }
    }

    let rowStart = excludeDownPayments ? 3 : 4;

    for (let i = 0; i < paymentMethods.length; i++) {
        const cell = departmentsRow.getCell(pmLength + rowStart + i + downPaymentColumns + sections.length);
        methodsRow.getCell(pmLength + rowStart + i + downPaymentColumns + sections.length).merge(cell);
        cell.value = 'TOT\n ' + translatePaymentTypes(paymentMethods[i].type, t);
    }

    const depositsHeaderCell = departmentsRow.getCell(pmLength + paymentMethods.length + rowStart + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + paymentMethods.length + rowStart + downPaymentColumns + sections.length).merge(depositsHeaderCell);
    sectionsRow.getCell(pmLength + paymentMethods.length + rowStart + downPaymentColumns + sections.length).merge(depositsHeaderCell);
    depositsHeaderCell.value = t('turnover.report.deposits');
    depositsHeaderCell.border = {
        left: {
            style: 'thin',
            color: { argb: 'FF000000' }
        }
    }

    ++rowStart;


    const toBePaidHeaderCell = departmentsRow.getCell(pmLength + paymentMethods.length + rowStart + downPaymentColumns + sections.length);
    methodsRow.getCell(pmLength + paymentMethods.length + rowStart + downPaymentColumns + sections.length).merge(toBePaidHeaderCell);
    toBePaidHeaderCell.value = t('depositable').toUpperCase().replace(' - ', '\n');

    // Fill and merge cells in sections header row
    precedentCells = 0;
    for (let i = 0; i < sections.length; i++) {
        const pms = !excludeDownPayments ?
            sections[i].paymentMethods :
            sections[i].paymentMethods?.filter(pm => !pm.type.toLowerCase().includes('downpayment'));

        if (pms) {
            let sectionWithDownPayment = false;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            for (const pm of pms) {
                if (!excludeDownPayments && pm.type.toLowerCase().includes('downpayment')) {
                    sectionWithDownPayment = true;
                }
            }
            const cell = sectionsRow.getCell(precedentCells + 2);
            cell.value = sections[i].onlyToBeShowed ? sections[i].name + ' - ' + t('paymentSection.onlyToBeShowed') : sections[i].name;
            const columnIndex = precedentCells + 2 + ((pms ?? []).length + (sectionWithDownPayment ? 1 : 0))
            sectionsRow.getCell(columnIndex).merge(cell);
            for (let j = sectionsRow.number; j < rowsNumber; j++) {
                worksheet.getCell(j, columnIndex).border = {
                    right: {
                        style: 'thin',
                        color: { argb: 'FF000000' }
                    }
                };
            }
            precedentCells = columnIndex - 1;
        }
    }

    // Fill departments header row and merge cells
    precedentCells = 0;
    for (let i = 0; i < departments.length; i++) {
        const depSections = sections.filter((sec) => {
            return sec.departmentId === departments[i].id;
        })
        const depSectionsWithDownPayment = excludeDownPayments ? [] : depSections.filter((sec) => {
            return sec.paymentMethods?.find((pm) => {
                return pm.type.toLowerCase().includes('downpayment');
            })
        })
        const c1 = departmentsRow.getCell(2 + precedentCells);
        c1.value = departments[i].name;

        let depSectionsLength = 0;
        for (const depSection of depSections) {
            const pms = !excludeDownPayments ? depSection.paymentMethods : depSection.paymentMethods?.filter(pm => !pm.type.toLowerCase().includes('downpayment'));
            if (pms)
                depSectionsLength = depSectionsLength + pms.length;
        }
        const c2 = departmentsRow.getCell(1 + precedentCells + depSectionsLength + depSections.length + depSectionsWithDownPayment.length);
        precedentCells = precedentCells + depSectionsLength + depSections.length + depSectionsWithDownPayment.length;
        c2.merge(c1);

        for (let j = departmentsRow.number; j < rowsNumber; j++) {
            worksheet.getCell(j, 1 + precedentCells).border = {
                right: {
                    style: 'thin',
                    color: { argb: 'FF000000' }
                }
            };
        }
    }
}

const addDatesRows = async (
    worksheet: ExcelJS.Worksheet,
    allSections: SectionEntity[],
    allPaymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    dates: Date[],
    pmLength: number,
    columnsNumber: number,
    seasonId: number,
    excludeDownPayments?: boolean
) => {

    let sections: SectionEntity[] = [];
    let paymentMethods: PaymentMethodEntity[] = [];


    if (excludeDownPayments) {

        paymentMethods = allPaymentMethods.filter(pm => !pm.type.toLowerCase().includes('downpayment'))
        sections = allSections.filter(s => !s.onlyToBeShowed);

    }

    else {
        sections = allSections;
        paymentMethods = allPaymentMethods;
    }


    if (!dates || dates.length === 0) {
        return;
    }
    const toBePaidValues = (await depositsApi.getDateSums(seasonId, {
        data: {
            dateTimeStamps: dates.map((date) => { return date.getTime().toString() })
        }
    })).data;

    for (let i = 0; i < dates.length; i++) {
        const totPaymentMethods = new Map<number, number>();
        const dateRow = worksheet.getRow(i + 5);
        const dateCell = dateRow.getCell(1);
        dateCell.value = format(dates[i], 'dd/MM/yyyy');
        let totDownPayment = 0;
        let totDate = 0;
        let precedentCells = 1;
        let downPaymentColumns = 0;
        for (let j = 0; j < sections.length; j++) {
            let totSection = 0;
            let totSectionDownPayment = 0;
            let sectionWithDownPayment = false;
            const pms = !excludeDownPayments ?
                sections[j].paymentMethods :
                sections[j].paymentMethods?.filter(pm => !pm.type.toLowerCase().includes('downpayment'))

            if (pms) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                for (let k = 0; k < pms.length; k++) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const pm = pms[k];
                    const cell = dateRow.getCell(precedentCells + 1);
                    precedentCells = precedentCells + 1;
                    let value = 0;
                    const items = turnoverItems.filter((item) => {
                        return (
                            item.paymentSectionId === sections[j].id &&
                            item.paymentMethodId === pm.id &&
                            isSameDay(new Date(item.date), ignoreUTC(dates[i]))
                        );
                    });
                    for (const item of items) {
                        value = value + item.value;
                    }
                    cell.value = value;

                    if (pm.type.toLowerCase().includes('downpayment')) {
                        sectionWithDownPayment = true;
                        totDownPayment = totDownPayment + value;
                        totSectionDownPayment = totSectionDownPayment + value;
                    }
                    // add to section total only if it is not a downpayment
                    else {
                        totSection = totSection + value;
                        // add to date total only if it is not a downpayment and section is to be counted
                        if (!sections[j].onlyToBeShowed)
                            totDate = totDate + value;
                    }

                    // add to date total only if section is to be counted
                    if (!sections[j].onlyToBeShowed)
                        totPaymentMethods.set(pm.id, (totPaymentMethods.get(pm.id) ?? 0) + value);
                }
            }
            const sectionTotCell = dateRow.getCell(precedentCells + 1);
            sectionTotCell.value = totSection;
            precedentCells = precedentCells + 1;

            if (sectionWithDownPayment) {
                downPaymentColumns = downPaymentColumns + 1;
                const sectionDownPaymentCell = dateRow.getCell(precedentCells + 1);
                sectionDownPaymentCell.value = totSectionDownPayment;
                precedentCells = precedentCells + 1;
            }
        }
        // Fill the cell with total turnover of the date
        const totCell = dateRow.getCell(2 + pmLength + sections.length + downPaymentColumns);
        totCell.value = totDate;
        totCell.border = {
            left: {
                style: 'thin',
                color: { argb: 'FF000000' }
            },
            right: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        };

        if (!excludeDownPayments) {
            const downPayment = dateRow.getCell(3 + pmLength + sections.length + downPaymentColumns);
            downPayment.value = totDownPayment;
            downPayment.border = {
                right: {
                    style: 'thin',
                    color: { argb: 'FF000000' }
                }
            };

        }

        let rowStart = excludeDownPayments ? 3 : 4;

        // Fill the cells with total turnover by payment method
        for (let j = 0; j < paymentMethods.length; j++) {
            const value = totPaymentMethods.get(paymentMethods[j].id);
            const cell = dateRow.getCell(rowStart + j + pmLength + sections.length + downPaymentColumns);
            cell.value = value ?? 0
        }

        // Fill the cell with total deposit of the date
        const depositsCell = dateRow.getCell(rowStart + pmLength + paymentMethods.length + sections.length + downPaymentColumns);
        const dateDeposits = deposits.filter((deposit) => {
            return isSameDay(new Date(deposit.date), ignoreUTC(dates[i]));
        })
        let depositValue = 0;
        for (const deposit of dateDeposits) {
            depositValue = depositValue + deposit.value;
        }
        depositsCell.value = depositValue;
        depositsCell.border = {
            left: {
                style: 'thin',
                color: { argb: 'FF000000' }
            }
        };

        rowStart++;

        // Fill the cell with cumulative to be paid import of the date
        const toBePaidCell = dateRow.getCell(rowStart + pmLength + paymentMethods.length + sections.length + downPaymentColumns);
        const toBePaidValue = toBePaidValues.find((value) => { return isSameDay(new Date(value.date), dates[i]) })?.sum || 0;
        toBePaidCell.value = toBePaidValue;
    }
    const totRow = worksheet.getRow(5 + dates.length)
    totRow.getCell(1).value = 'TOT';
    totRow.getCell(1).border = {
        top: {
            style: 'thin',
            color: { argb: 'FF000000' }
        }
    }

    for (let i = 2; i < columnsNumber - 1; i++) {
        const cell = totRow.getCell(i);
        const cellColumn = getColumnLetter(Number(cell.col));
        cell.value = {
            formula: 'SUM(' + cellColumn + '5:' + cellColumn + String(4 + dates.length) + ')',
            result: undefined,
            date1904: false
        };
        cell.border = {
            top: {
                style: 'thin',
                color: { argb: 'FF000000' }
            },
            // on deposit total cell put a left border
            left: (i === columnsNumber - 2) ? {
                style: 'thin',
                color: { argb: 'FF000000' }
            } : undefined
        }
    }

    totRow.getCell(columnsNumber - 1).border = {
        top: {
            style: 'thin',
            color: { argb: 'FF000000' }
        }
    }

}

const createReport = async (
    departments: DepartmentEntity[],
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    startDate: Date,
    endDate: Date,
    seasonId: number
) => {
    const dates = getDates(startDate, endDate);

    let pmLength = 0;
    let pmLenghtWithoutDownPayments = 0;

    const sectionsWithDownPayment = sections.filter((sec) => {
        return sec.paymentMethods?.find((pm) => {
            return pm.type.toLowerCase().includes("downpayment");
        })
    })

    for (const section of sections) {
        if (section.paymentMethods) {
            pmLength = pmLength + section.paymentMethods?.length;
            if (!section.onlyToBeShowed) {
                pmLenghtWithoutDownPayments += section.paymentMethods.filter((method) => {
                    return !method.type.toLowerCase().includes("downpayment")
                }).length;
            }
        }
    };

    const columnsNumber = 6 + pmLength + paymentMethods.length + sections.length + sectionsWithDownPayment.length;

    const secondSheetColumns = 5 + pmLenghtWithoutDownPayments +
        paymentMethods.filter(pm => !pm.type.toLowerCase().includes("downpayment")).length +
        sections.filter(s => !s.onlyToBeShowed).length;

    const rowsNumber = dates.length + 6;

    const workbook = createWorkbook();
    const worksheet = createWoksheet(workbook);

    const secondWorkSheet = createWoksheet(workbook, t('turnover.reportNameWithoutDownPayment'));

    reportAddTitleRow(worksheet, columnsNumber, t('turnover.reportName'));

    reportAddTitleRow(secondWorkSheet, secondSheetColumns, t('turnover.reportName'));

    reportStyleWorksheet(worksheet, rowsNumber, columnsNumber);

    reportStyleWorksheet(secondWorkSheet, rowsNumber, secondSheetColumns);

    addHeaderRows(worksheet, rowsNumber, departments, sections, paymentMethods, pmLength);

    addHeaderRows(secondWorkSheet, rowsNumber, departments, sections.slice(), paymentMethods, pmLenghtWithoutDownPayments, true);

    await addDatesRows(worksheet, sections, paymentMethods, turnoverItems, deposits, dates, pmLength, columnsNumber, seasonId);

    await addDatesRows(secondWorkSheet, sections.slice(), paymentMethods, turnoverItems, deposits, dates, pmLenghtWithoutDownPayments, secondSheetColumns, seasonId, true);

    await addLogoImageToReport(worksheet, workbook);

    return workbook;
}

export const turnoversReport = async (
    departments: DepartmentEntity[],
    sections: SectionEntity[],
    paymentMethods: PaymentMethodEntity[],
    turnoverItems: TurnoverItemEntity[],
    deposits: DepositEntity[],
    startDate: Date,
    endDate: Date,
    seasonId: number
) => {
    const filteredSections = sections.filter((sec) => { return sec.paymentMethods && sec.paymentMethods.length > 0 })

    const workbook = await createReport(
        [...departments].sort((a, b) => a.id - b.id).filter((dep) => {
            return filteredSections.find((sec) => {
                return sec.departmentId === dep.id;
            })
        }),
        filteredSections.sort((a, b) => a.departmentId - b.departmentId),
        paymentMethods,
        [...turnoverItems], // This is no longer requested .filter((item) => item.status === 'CONFIRMED'),
        deposits,
        startDate,
        endDate,
        seasonId
    );

    downloadReport(workbook);
}