import {
    Box,
    Card,
    useTheme,
  } from '@mui/material';
  import FlexBox from 'components/flexbox/FlexBox';
  import FlexRowAlign from 'components/flexbox/FlexRowAlign';
  import { H2, H4, H6 } from 'components/Typography';
  import { FC, CSSProperties } from 'react';
  
  interface DataProps {
    id: number;
    title: string;
    color: string;
    text: string | number;
    fraction?: string;
    Icon: any;
    contentStyle?: CSSProperties;
  }
  
  const CustomCard: FC<DataProps> = (props) => {
    const { id, title, color, text, fraction, Icon } = props;
    const theme = useTheme();
  
    return (
      <Card sx={{ paddingLeft:1, paddingTop:3, paddingBottom:3, paddingRight:-0.5, height: '100%', width: '100%', display: 'flex' }}>
        <FlexBox
          key={id}
          gap={1.5}
          alignItems="center"
          sx={{
            [theme.breakpoints.down('sm')]: {
              marginLeft: 0,
              marginBottom: '1.5rem !important',
              '&:last-of-type': { marginBottom: '0 !important' },
            },
          }}
        >
          <FlexRowAlign
            flexShrink={0}
            sx={{
              width: 'clamp(0.5rem, 2vw, 2.5rem)',
              height: 'clamp(0.5rem, 2vw, 2.5rem)',
              borderRadius: 2,
              backgroundColor: color,
              display: 'flex',
            }}
          >
            <Icon
              sx={{
                color: 'common.white',
                width: '70%',
                height: '70%',
              }}
            />
          </FlexRowAlign>
  
          <Box sx={props.contentStyle}>
            <Box>
              <H6
                lineHeight={1.2}
                mb={0.4}
                sx={{
                  fontSize: 'clamp(0.8rem, 0.8vw, 0.8rem)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {title}
              </H6>
              <H2
                color={color}
                lineHeight={1}
                sx={{
                  fontSize: 'clamp(1rem, 0.8vw, 1.5rem)',
                  whiteSpace: 'nowrap',
                  display: 'inline-flex',
                  alignItems: 'baseline',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {text}
                {fraction && (
                  <H4
                    display="inline"
                    color={color}
                    sx={{
                      marginLeft: 0.2,
                      fontSize: 'clamp(0.8rem, 1.5vw, 1.25rem)',
                      lineHeight: 1,
                    }}
                  >
                    {fraction}
                  </H4>
                )}
              </H2>
            </Box>
          </Box>
        </FlexBox>
      </Card>
    );
  };
  
  export default CustomCard;
  