import { Autocomplete, Button, Grid, styled } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppModal from 'components/AppModal';
import { H2 } from 'components/Typography';
import FlexBox from 'components/flexbox/FlexBox';
import * as Yup from 'yup';
import { isValidDate } from 'utils/dateValidator';
import { calendarEntriesShiftTemplateApi, departmentsApi, shiftTemplateApi } from 'api';
import { useFormik } from 'formik';
import CalendarInput from 'components/input-fields/CalendarInput';
import { DepartmentEntity, ShiftTemplateEntity } from 'api/generated';
import AppTextField from 'components/input-fields/AppTextField';
import { EventInput } from '@fullcalendar/core';
import { checkTokenRole } from 'utils/checkToken';
import { useSeason } from 'contexts/SeasonContext';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    fetchCalendarData: () => void;
    date?: Date;
    events?: EventInput[];
    originalSelectedDepartment: { label: string; id: number; } | null;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const AddEventModalShift: FC<ModalProps> = ({ open, onClose, fetchCalendarData, date, originalSelectedDepartment }) => {
    const { t } = useTranslation();
    const [shiftTemplate, setShiftTemplate] = useState<ShiftTemplateEntity[]>([]);
    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number; } | null>(originalSelectedDepartment);
    const [selectedTemplate, setSelectedTemplate] = useState<{ label: string; id: number; } | null>(null);
    const { seasonId } = useSeason();
    const role = checkTokenRole();


    const initialValues = {
        startedOn: date ? new Date(date) : null,
        templateId: -1,
    };

    const fieldValidationSchema = Yup.object().shape({
        startedOn: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(
                t('common.forms.field.required', {
                    field: t('employees.calendar.entry.startedOn'),
                }),
            )
            .test('is-valid-date', 'Invalid date', (value) => {
                if (value) {
                    return isValidDate(value);
                }
                return true;
            }),
        templateId: Yup.number().notOneOf([-1], t('common.forms.field.required', {
            field: t('department.itemName'),
        }),
        )
    });


    const { values, errors, touched, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values: any) => {
            const payload = {
                startedOn: values.startedOn.toISOString(),
                endedOn: values.startedOn.toISOString(),
                isAutoGenerated: false,
                departmentId: selectedDepartment?.id,
                seasonId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                templateId: selectedTemplate!.id
            }
            await calendarEntriesShiftTemplateApi
                .create(payload)
                .then(() => {
                    onClose();
                    resetForm();
                    fetchCalendarData();
                });
        }
    });
    useEffect(() => {
        
        (role === 'DEPCHIEF' ? departmentsApi.findWhereIsBoss(seasonId) : departmentsApi.findAll()).then(({ data }) => {
            setDepartments(data);
        }).catch((error) => {
            console.error(error);
        });

    }, []);

    useEffect(() => {
        shiftTemplateApi.findAll()
            .then(({ data }) => {
                setShiftTemplate(data.filter((shiftTemplate) => shiftTemplate.generatedFromShiftTemplateId === -1));
            })
            .catch((error) => {
                console.error(error);
            });
        /*
                shiftTemplateApi.findAll()
                    .then(({ data }) => {
                        if(events){
                            const departmentIdsInEvents = events.map(event => event.departmentId);
                            const filteredData = data.filter(template => {
                                return !departmentIdsInEvents.includes(template.departmentxId);
                            }) as ExtendedShiftTemplateEntity[];
                            setShiftTemplate(filteredData);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                    */
    }, []);
    useEffect(() => {
        role === 'DEPCHIEF' ?
        shiftTemplateApi.findAll(selectedDepartment?.id, seasonId).then(({ data }) => {
            setShiftTemplate(data.filter((shiftTemplate) => shiftTemplate.generatedFromShiftTemplateId === -1));
        }).catch((error) => {
            console.error(error);
        })
        : shiftTemplateApi.findAll()
            .then(({ data }) => {
                if (selectedDepartment) {
                    setShiftTemplate(data.filter((item) => item.departmentId === selectedDepartment?.id).filter((shiftTemplate) => shiftTemplate.generatedFromShiftTemplateId === -1));
                } else {
                    setShiftTemplate(data.filter((shiftTemplate) => shiftTemplate.generatedFromShiftTemplateId === -1));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [selectedDepartment]);

    return (
        <StyledAppModal open={open} handleClose={() => {
            onClose();
            resetForm();
        }}>
            <H2 mb={2}> {t('employees.calendar.entry.title')}</H2>
            <form onSubmit={handleSubmit}>
                <Grid item xs={12}>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="template-selector"
                                options={departments?.map((department) => ({
                                    label: department.name,
                                    id: department.id
                                })) || []}
                                value={selectedDepartment}
                                disabled={!!originalSelectedDepartment}
                                onChange={(event, value) => {
                                    setSelectedDepartment(value ? { label: value.label, id: value.id } : null);
                                }}
                                renderInput={(params) =>
                                    <AppTextField
                                        {...params}
                                        placeholder={t('shiftTemplate.templateSelector.selectDepartment')}
                                        label={t('departments.itemName')}
                                        error={Boolean(errors.templateId && touched.templateId)}
                                        helperText={(touched.templateId && errors.templateId) as string}
                                    />
                                }
                                isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                            />
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <Autocomplete
                                id="template-selector"
                                options={shiftTemplate?.map((template) => ({
                                    label: template.name,
                                    id: template.id
                                })) || []}
                                value={selectedTemplate}
                                onChange={(event, value) => {
                                    setSelectedTemplate(value ? { label: value.label, id: value.id } : null);
                                    setFieldValue('templateId', value?.id ?? -1);
                                }}
                                renderInput={(params) =>
                                    <AppTextField
                                        {...params}
                                        placeholder={t('shiftTemplate.templateSelector.select')}
                                        label={t('shiftTemplate.itemName')}
                                        error={Boolean(errors.templateId && touched.templateId)}
                                        helperText={(touched.templateId && errors.templateId) as string}
                                        disabled={role === 'DEPCHIEF' && !selectedDepartment}
                                    />
                                }
                                isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                            />
                        </Grid>
                        <Grid item xs={6} mt={4}>
                            <CalendarInput
                                disabled
                                format='dd.MM.yyyy'
                                onChange={(date) => {
                                    if (date && date instanceof Date && !isNaN(+date)) {
                                        setFieldValue('startedOn', date);
                                    } else {
                                        setFieldValue('startedOn', null);
                                    }
                                }}
                                value={values.startedOn}
                                label={t('season.startDate')}
                                slotProps={{
                                    textField: {
                                        error: !!errors.startedOn,
                                        helperText: typeof errors.startedOn === 'string' ? errors.startedOn : undefined
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        onClose();
                        resetForm();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained'>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal>
    )
};

export default AddEventModalShift;
