import { DailyNoteEntity, DepartmentEntity, DepositEntity, EmployeeEntity, PaymentMethodEntity, PaymentMethodEntityTypeEnum, SectionEntity, TurnoverItemEntity } from "api/generated";
import { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, useTheme, Tooltip, Box, useMediaQuery, IconButtonProps, styled, Accordion, AccordionDetails, AccordionSummary, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowDown, Savings, AttachMoney, EditNote, FilterList } from "@mui/icons-material";
import PaymentsIcon from '@mui/icons-material/Payments';
import { fadeColor, getDates, ignoreUTC, searchByName } from "utils/utils";
import { t } from "i18next";
import DailyNotesModal from "./dataTableV2/DailyNotesModal";
import { format, isSameDay } from "date-fns";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { checkTokenRole } from "utils/checkToken";
import ControllerModal from "./dataTableV2/ControllerModal";
import Chart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { depositsApi } from "api";
import { useSeason } from "contexts/SeasonContext";
import { SettingsContext } from "contexts/settingsContext";
import InfoIcon from '@mui/icons-material/Info';
interface DataTableProps {
    startDate: Date;
    endDate: Date;
    groupedTurnoverItems: Record<string, any>;
    departments: DepartmentEntity[];
    sections: SectionEntity[];
    paymentMethods: PaymentMethodEntity[];
    deposits: DepositEntity[];
    dailyNotes: DailyNoteEntity[];
    employees: ExtendEmployeeEntity[];
    searchValue: string;
    showEmpty: boolean;
    employeesView: boolean;
    fetchData: () => void;
    openDepositModal: (date: Date) => void;
    setChartDataCallback: (data: any) => void;
    chartOptions: ApexOptions;
    chartSeries: Array<{ name: string; data: Array<number | null>; type: string; }>;
    setDateFromTableCallback: (data: any) => void;
}

interface ExtendEmployeeEntity extends EmployeeEntity {
    isRemoved: boolean
}

const SmallerIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    height: 5
}));

const TurnoverDataTable: FC<DataTableProps> = ({
    startDate,
    endDate,
    groupedTurnoverItems,
    departments,
    sections,
    deposits,
    employees,
    searchValue,
    dailyNotes,
    fetchData,
    paymentMethods,
    showEmpty,
    employeesView,
    openDepositModal,
    setChartDataCallback,
    chartOptions,
    chartSeries,
    setDateFromTableCallback
}) => {
    const [statusFilter, setStatusFilter] = useState<string | null>(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);

    const statusColor = (status: string | undefined) => {

        if (status)
            switch (status.toUpperCase()) {
                case "CASHED":
                    return fadeColor(theme.palette.error.main, settings.theme === 'dark' ? 0 : undefined);
                case "RECEIVED":
                    return fadeColor(theme.palette.warning.main, settings.theme === 'dark' ? 0 : undefined);
                case "CONFIRMED":
                    return fadeColor(theme.palette.success.main, settings.theme === 'dark' ? 0 : undefined);
            }

        return undefined
    }

    const downPaymentMethods: PaymentMethodEntityTypeEnum[] = ['CARDDOWNPAYMENT', 'WIREDOWNPAYMENT', 'CASHDOWNPAYMENT', 'PAYPALDOWNPAYMENT', 'CHECKDOWNPAYMENT'];

    const compareStatus = (newStatus: string, originalStatus?: string) => {

        if (!originalStatus)
            return newStatus;

        let originialStatusValue = 0;
        let newStatusValue = 0;

        switch (originalStatus.toUpperCase()) {
            case "CASHED":
                originialStatusValue = 2;
                break;
            case "RECEIVED":
                originialStatusValue = 1;
                break;
            case "CONFIRMED":
                originialStatusValue = 0;
                break;
        }

        switch (newStatus.toUpperCase()) {
            case "CASHED":
                newStatusValue = 2;
                break;
            case "RECEIVED":
                newStatusValue = 1;
                break;
            case "CONFIRMED":
                newStatusValue = 0;
                break;
        }

        if (newStatusValue > originialStatusValue)
            return newStatus;

        if (originialStatusValue > newStatusValue)
            return originalStatus;

        return originalStatus;

    }

    const moneyFormatter = Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        currencySign: 'standard',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    });

    // const { seasonId } = useSeason();
    // const employeeId = checkToken();

    const [dates, setDates] = useState<Date[]>([]);
    const iconTableCellRef = useRef<HTMLTableCellElement>(null);
    const titleTableCellRef = useRef<HTMLTableCellElement>(null);
    const firstDateTableCellRef = useRef<HTMLTableCellElement>(null);
    const [tableDimension, setTableDimension] = useState([71, 250, 150]);
    const [chartWidth, setChartWidth] = useState(dates.length * tableDimension[2]);
    const { seasonId } = useSeason();

    const { settings } = useContext(SettingsContext);


    useEffect(() => {
        const newDates = getDates(startDate, endDate);
        setDates(newDates);
        setDateFromTableCallback(newDates.map(date => format(date, 'dd/MM')));
    }, [startDate, endDate]);

    const generateRows = async () => {

        if (!dates || dates.length === 0) {
            return [];
        }

        const rows: any[] = [];
        let id = 0;


        const depositableRow: any = {
            isTurnover: false,
            isDepositable: true,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('depositable'),
            isLastOfSums: false,
            id,
            empty: false,
            total: 0,
            status: {}
        };

        id = id + 1;


        const depositsRow: any = {
            isDeposit: true,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('deposits'),
            isLastOfSums: false,
            id,
            empty: false,
            total: 0
        };
        id = id + 1;

        const turnoversRow: any = {
            isTurnover: true,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('menu.apps.turnover'),
            isLastOfSums: false,
            id,
            empty: false,
            total: 0,
            status: {}
        };
        const totalTurnoversRow: any = {
            isTotalTurnover: true,
            isTurnover: false,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('menu.apps.totalTurnover'),
            isLastOfSums: false,
            id,
            empty: false,
            total: 0,
            status: {}
        };
        id = id + 1;

        const totalCash: any = {
            isTurnover: true,
            isDeposit: false,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('totalCash'),
            isLastOfSums: false,
            id,
            empty: false,
            total: 0
        }

        id = id + 1;
        const totalCashMinusInformative: any = {
            isTurnover: true,
            isDeposit: false,
            isDepartment: false,
            isSection: false,
            isMethod: false,
            isEmployee: false,
            name: t('totalCashWithoutInformative'),
            isLastOfSums: true,
            id,
            empty: false,
            total: 0
        }

        id = id + 1;

        if (deposits) {
            for (const date of dates) {
                if (!depositsRow[ignoreUTC(date).toISOString()]) {
                    depositsRow[ignoreUTC(date).toISOString()] = 0;
                }
                const dateDeposits = deposits.filter((deposit) => {
                    return isSameDay(new Date(deposit.date), date);
                });
                for (const deposit of dateDeposits) {
                    depositsRow[ignoreUTC(date).toISOString()] = depositsRow[ignoreUTC(date).toISOString()] + deposit.value;
                    depositsRow.total = depositsRow.total + deposit.value;
                }
            }
            rows.push(depositsRow);

        }



        for (const department of departments) {
            const departmentRow: any = {
                isDepartment: true,
                isSection: false,
                isMethod: false,
                isEmployee: false,
                department,
                name: department.name,
                id,
                empty: true,
                sectionIds: [],
                total: 0,
                status: {}
            };
            id = id + 1;


            for (const section of sections) {
                let hasDownPayments = false;
                if (section.departmentId === department.id) {
                    const sectionRow: any = {
                        isDepartment: false,
                        isSection: true,
                        isMethod: false,
                        isEmployee: false,
                        department,
                        section,
                        onlyToBeShowed: section.onlyToBeShowed,
                        name: section.name,
                        id,
                        empty: true,
                        methodIds: [],
                        employeeIds: [],
                        total: 0,
                        status: {}
                    };
                    id = id + 1;
                    const downPaymentTotalRow: any = {
                        isDownPayment: true,
                        department,
                        section,
                        onlyToBeShowed: true,
                        empty: true,
                        name: t('total.downPayment'),
                        id,
                        total: 0,
                        status: {},
                    };
                    id = id + 1;
                    /*            const subTotalRow: any = {
                                    isSubtotal: true,
                                    department,
                                    section,
                                    empty: true,
                                    name: t('total.subtotal'),
                                    id,
                                    total: 0,
                                    status: {},
                                };
                                id = id + 1; */

                    /* const turnoverFromDeletedMethods = turnoverItems.filter(item => {
                        const date = ignoreUTC(new Date(item.date));
                        return item.paymentSectionId === section.id &&
                            !section.paymentMethods?.find(method => method.id === item.paymentMethodId) &&
                            isAfter(date, startDate) && isBefore(date, endDate);
                        ;
                    });


                    const deletedMethods: PaymentMethodEntity[] = [];


                    turnoverFromDeletedMethods.forEach(turnover => {
                        if (!section.paymentMethods?.find(method => method.id === turnover.paymentMethodId)) {
                            const newMethod = paymentMethods?.find(method => method.id === turnover.paymentMethodId);
                            if (newMethod)
                                deletedMethods.push(newMethod);
                        }

                    })
                    */

                    if (employeesView) {
                        for (const method of (section.paymentMethods ?? [])) {

                            if (downPaymentMethods.includes(method.type))
                                hasDownPayments = true;

                            const methodRow: any = {
                                isDepartment: false,
                                isSection: false,
                                isMethod: true,
                                isEmployee: false,
                                department,
                                section,
                                onlyToBeShowed: section.onlyToBeShowed || downPaymentMethods.includes(method.type),
                                method,
                                name: translatePaymentTypes(method.type, t) + (method.tenantBankingInformation ? ' - ' + method.tenantBankingInformation.bankName : ''),
                                id,
                                empty: true,
                                total: 0,
                                status: {}
                            };
                            id = id + 1;


                            const depEmployees = employees.filter((emp) => {
                                return emp.employeesToDepartments?.find((empToDep) => {
                                    return empToDep.departmentId === department.id
                                })
                            });
                            for (const employee of depEmployees) {
                                const employeeRow: any = {
                                    isDepartment: false,
                                    isSection: false,
                                    isMethod: false,
                                    isEmployee: true,
                                    isRemoved: employee.isRemoved,
                                    department,
                                    section,
                                    onlyToBeShowed: section.onlyToBeShowed || downPaymentMethods.includes(method.type),
                                    method,
                                    employee,
                                    name: employee.firstName + ' ' + employee.lastName,
                                    id,
                                    empty: true,
                                    total: 0,
                                    status: {},
                                }
                                id = id + 1;

                                for (const date of dates) {

                                    if (!sectionRow[ignoreUTC(date).toISOString()]) {
                                        sectionRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!departmentRow[ignoreUTC(date).toISOString()]) {
                                        departmentRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!methodRow[ignoreUTC(date).toISOString()]) {
                                        methodRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!turnoversRow[ignoreUTC(date).toISOString()]) {
                                        turnoversRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalTurnoversRow[ignoreUTC(date).toISOString()]) {
                                        totalTurnoversRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!employeeRow[ignoreUTC(date).toISOString()]) {
                                        employeeRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!downPaymentTotalRow[ignoreUTC(date).toISOString()]) {
                                        downPaymentTotalRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalCash[ignoreUTC(date).toISOString()]) {
                                        totalCash[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalCashMinusInformative[ignoreUTC(date).toISOString()]) {
                                        totalCashMinusInformative[ignoreUTC(date).toISOString()] = 0;
                                    }

                                    /*  if (!subTotalRow[ignoreUTC(date).toISOString()]) {
                                          subTotalRow[ignoreUTC(date).toISOString()] = 0;
                                      } */


                                    const item = groupedTurnoverItems[date.toISOString().split('T')[0]]?.[section.id]?.[method.id]?.[employee.id];

                                    /* const item = turnoverItems.find((item) => {

                                        return (
                                            isSameDay(new Date(item.date), date) &&
                                            item.paymentSectionId === section.id &&
                                            item.paymentMethodId === method.id &&
                                            item.employeeId === employee.id
                                        )
                                    });
*/
                                    if (item) {
                                        const shouldAddValue = !statusFilter || statusFilter.toUpperCase() === item.status.toUpperCase();

                                        if (downPaymentMethods.includes(method.type)) {
                                            downPaymentTotalRow.empty = false;
                                            downPaymentTotalRow[ignoreUTC(date).toISOString()] += item.value;
                                            downPaymentTotalRow.total += item.value;
                                        }
                                        else {
                                            /*
                                               subTotalRow.empty = false;
                                               subTotalRow[ignoreUTC(date).toISOString()] += item.value;
                                               subTotalRow.total += item.value;
                                               
                                               */

                                            sectionRow.empty = false;


                                            methodRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, methodRow.status[ignoreUTC(date).toISOString()])
                                            sectionRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, sectionRow.status[ignoreUTC(date).toISOString()]);
                                            sectionRow[ignoreUTC(date).toISOString()] = sectionRow[ignoreUTC(date).toISOString()] + item.value;
                                            if (shouldAddValue) {
                                                sectionRow.total = sectionRow.total + item.value;
                                            }
                                            if (shouldAddValue) {
                                                totalTurnoversRow[ignoreUTC(date).toISOString()] = totalTurnoversRow[ignoreUTC(date).toISOString()] + item.value;
                                                totalTurnoversRow.total += item.value;

                                            }

                                            if (!section.onlyToBeShowed) {
                                                departmentRow.empty = false;
                                                departmentRow[ignoreUTC(date).toISOString()] = departmentRow[ignoreUTC(date).toISOString()] + item.value;
                                                turnoversRow[ignoreUTC(date).toISOString()] = turnoversRow[ignoreUTC(date).toISOString()] + item.value;

                                                if (shouldAddValue) {
                                                    departmentRow.total += item.value;
                                                    turnoversRow.total += item.value;
                                                }
                                                if (method.type === 'CASH') {
                                                    totalCashMinusInformative.total += item.value;
                                                    totalCashMinusInformative[ignoreUTC(date).toISOString()] += item.value;
                                                }
                                            }
                                            // Show total that must be cashed for informative sections if filter is enables
                                            else {
                                                if (section.stillCountsDepositable) {
                                                    departmentRow.empty = false;
                                                    if (shouldAddValue) {
                                                        departmentRow.total += item.value;
                                                    }
                                                }
                                            }

                                            departmentRow.empty = false;
                                            departmentRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, departmentRow.status[ignoreUTC(date).toISOString()]);
                                            turnoversRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, turnoversRow.status[ignoreUTC(date).toISOString()]);
                                            totalTurnoversRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, totalTurnoversRow.status[ignoreUTC(date).toISOString()]);

                                            if (method.type === 'CASH') {
                                                totalCash.total += item.value;
                                                totalCash[ignoreUTC(date).toISOString()] += item.value;
                                            }
                                        }

                                        if (!departmentRow.sectionIds.includes(section.id)) {
                                            departmentRow.sectionIds.push(section.id);
                                        }
                                        if (!sectionRow.methodIds.includes(method.id)) {
                                            sectionRow.methodIds.push(method.id);
                                        }

                                        methodRow.empty = false;

                                        methodRow[ignoreUTC(date).toISOString()] += item.value;

                                        employeeRow.empty = false;
                                        employeeRow[ignoreUTC(date).toISOString()] = item.value;
                                        if (shouldAddValue) {
                                            methodRow.total = methodRow.total + item.value;
                                            employeeRow.total = employeeRow.total + item.value;
                                        }
                                        employeeRow.status[ignoreUTC(date).toISOString()] = item.status;

                                    }
                                }
                                rows.push(employeeRow);
                            }
                            rows.push(methodRow);
                        }
                    } else {
                        const depEmployees = employees.filter((emp) => {
                            return emp.employeesToDepartments?.find((empToDep) => {
                                return empToDep.departmentId === department.id
                            })
                        });
                        for (const employee of depEmployees) {
                            const employeeRow: any = {
                                isDepartment: false,
                                isSection: false,
                                isMethod: false,
                                isEmployee: true,
                                isRemoved: employee.isRemoved,
                                department,
                                section,
                                onlyToBeShowed: section.onlyToBeShowed,
                                employee,
                                name: employee.firstName + ' ' + employee.lastName,
                                id,
                                empty: true,
                                total: 0,
                                status: {}
                            }
                            id = id + 1;

                            for (const method of (section.paymentMethods ?? [])) {
                                if (downPaymentMethods.includes(method.type))
                                    hasDownPayments = true;
                                const methodRow: any = {
                                    isDepartment: false,
                                    isSection: false,
                                    isMethod: true,
                                    isEmployee: false,
                                    department,
                                    section,
                                    onlyToBeShowed: section.onlyToBeShowed || downPaymentMethods.includes(method.type),
                                    method,
                                    employee,
                                    name: translatePaymentTypes(method.type, t),
                                    id,
                                    empty: true,
                                    total: 0,
                                    status: {},
                                };
                                id = id + 1;
                                for (const date of dates) {

                                    if (!sectionRow[ignoreUTC(date).toISOString()]) {
                                        sectionRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!departmentRow[ignoreUTC(date).toISOString()]) {
                                        departmentRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!methodRow[ignoreUTC(date).toISOString()]) {
                                        methodRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!turnoversRow[ignoreUTC(date).toISOString()]) {
                                        turnoversRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalTurnoversRow[ignoreUTC(date).toISOString()]) {
                                        totalTurnoversRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!employeeRow[ignoreUTC(date).toISOString()]) {
                                        employeeRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!downPaymentTotalRow[ignoreUTC(date).toISOString()]) {
                                        downPaymentTotalRow[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalCash[ignoreUTC(date).toISOString()]) {
                                        totalCash[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    if (!totalCashMinusInformative[ignoreUTC(date).toISOString()]) {
                                        totalCashMinusInformative[ignoreUTC(date).toISOString()] = 0;
                                    }
                                    /*  if (!subTotalRow[ignoreUTC(date).toISOString()]) {
                                          subTotalRow[ignoreUTC(date).toISOString()] = 0;
                                      } */
                                    const item = groupedTurnoverItems[date.toISOString().split('T')[0]]?.[section.id]?.[method.id]?.[employee.id];

                                    /* const item = turnoverItems.find((item) => {
                                        return (
                                            isSameDay(new Date(item.date), date) &&
                                            item.paymentSectionId === section.id &&
                                            item.paymentMethodId === method.id &&
                                            item.employeeId === employee.id
                                        )
                                    }); */

                                    if (item) {
                                        const shouldAddValue = !statusFilter || statusFilter.toUpperCase() === item.status.toUpperCase();

                                        if (downPaymentMethods.includes(method.type)) {
                                            downPaymentTotalRow.empty = false;
                                            downPaymentTotalRow[ignoreUTC(date).toISOString()] += item.value;
                                            downPaymentTotalRow.total += item.value;
                                        }
                                        // if it is not a downpayment
                                        else {
                                            /* subTotalRow.empty = false;
                                            subTotalRow[ignoreUTC(date).toISOString()] += item.value;
                                            subTotalRow.total += item.value; */

                                            employeeRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, employeeRow.status[ignoreUTC(date).toISOString()]);
                                            sectionRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, sectionRow.status[ignoreUTC(date).toISOString()]);

                                            methodRow.empty = false;
                                            sectionRow.empty = false;
                                            departmentRow.empty = false;
                                            employeeRow.empty = false;

                                            employeeRow[ignoreUTC(date).toISOString()] += item.value;
                                            sectionRow[ignoreUTC(date).toISOString()] = sectionRow[ignoreUTC(date).toISOString()] + item.value;

                                            if (shouldAddValue) {
                                                methodRow.total = methodRow.total + item.value;
                                                employeeRow.total = employeeRow.total + item.value;
                                                sectionRow.total = sectionRow.total + item.value;
                                            }

                                            // if section has to be counted on department and total
                                            if (shouldAddValue) {
                                                totalTurnoversRow[ignoreUTC(date).toISOString()] = totalTurnoversRow[ignoreUTC(date).toISOString()] + item.value;
                                                totalTurnoversRow.total = turnoversRow.total + item.value;
                                            }
                                            if (!section.onlyToBeShowed) {
                                                departmentRow[ignoreUTC(date).toISOString()] = departmentRow[ignoreUTC(date).toISOString()] + item.value;
                                                turnoversRow[ignoreUTC(date).toISOString()] = turnoversRow[ignoreUTC(date).toISOString()] + item.value;
                                                if (shouldAddValue) {
                                                    departmentRow.total = departmentRow.total + item.value;
                                                    turnoversRow.total = turnoversRow.total + item.value;
                                                }
                                                if (method.type === 'CASH') {
                                                    totalCashMinusInformative.total += item.value;
                                                    totalCashMinusInformative[ignoreUTC(date).toISOString()] += item.value;
                                                }
                                            }

                                            departmentRow.empty = false;
                                            departmentRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, departmentRow.status[ignoreUTC(date).toISOString()]);
                                            turnoversRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, turnoversRow.status[ignoreUTC(date).toISOString()]);
                                            totalTurnoversRow.status[ignoreUTC(date).toISOString()] = compareStatus(item.status, totalTurnoversRow.status[ignoreUTC(date).toISOString()]);

                                            if (method.type === 'CASH') {
                                                totalCash.total += item.value;
                                                totalCash[ignoreUTC(date).toISOString()] += item.value;
                                            }
                                        }

                                        if (!departmentRow.sectionIds.includes(section.id)) {
                                            departmentRow.sectionIds.push(section.id);
                                        }
                                        if (!sectionRow.employeeIds.includes(employee.id)) {
                                            sectionRow.employeeIds.push(employee.id);
                                        }

                                        methodRow.status[ignoreUTC(date).toISOString()] = item.status;
                                        methodRow[ignoreUTC(date).toISOString()] = item.value;


                                    }
                                }
                                rows.push(methodRow);
                            }
                            rows.push(employeeRow);
                        }
                    }
                    if (hasDownPayments) {
                        rows.push(downPaymentTotalRow);
                        //   rows.push(subTotalRow)
                    }
                    rows.push(sectionRow);
                }
            }
            rows.push(departmentRow);
        }

        rows.push(turnoversRow);
        rows.push(totalTurnoversRow);
        if (seasonId !== 0) {
            const dateSums = (await depositsApi.getDateSums(seasonId, {
                data: {
                    dateTimeStamps: dates.map((date) => { return date.getTime().toString() })
                }
            })).data;
            for (const date of dates) {
                depositableRow[ignoreUTC(date).toISOString()] = dateSums.find((dateSum) => { return isSameDay(new Date(dateSum.date), date) })?.sum || 0;
            }
        }

        rows.push(depositableRow);
        rows.push(totalCash);
        rows.push(totalCashMinusInformative);

        return rows.sort((a, b) => {
            return a.id - b.id;
        });


    }

    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        generateRows().then((rows) => {
            setRows(rows)
            setChartDataCallback(rows)
        })
    }, [deposits, statusFilter, departments, sections, groupedTurnoverItems, dates, paymentMethods, employeesView, employees]);

    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        const filteredDepartments: DepartmentEntity[] = searchByName(departments, searchValue);
        const filteredSections: SectionEntity[] = searchByName(sections, searchValue);
        // this is needed to use searchByName using translated payment name
        const filteredMethods = searchByName(paymentMethods.map(
            method => {
                return {
                    name: translatePaymentTypes(method.type, t),
                    id: method.id
                }
            }
        ), searchValue);

        const filteredEmployees = searchByName(employees, searchValue);

        const result = rows.filter((row) => {
            return (
                // Deposits and Turnovers rows are always to be shown.
                row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable ||
                // Controls whether a row or its subordinates are to be shown.
                (
                    row.section &&
                    filteredSections.find((section) => { return section.id === row.section.id })
                ) || (
                    row.department &&
                    filteredDepartments.find((department) => { return department.id === row.department.id })
                ) || (
                    row.method &&
                    filteredMethods.find((method) => { return method.id === row.method.id })
                ) || (
                    row.employee &&
                    filteredEmployees.find((employee) => { return employee.id === row.employee.id })
                ) || (
                    // Check if a departmentRow is to be shown because at least one of his sections is to be shown.
                    row.isDepartments && filteredSections.find((section) => { return row.sectionIds.includes(section.id) })
                ) || (
                    filteredEmployees.length > 0
                ) || (
                    filteredMethods.length > 0
                )
            )
        })
        
        setFilteredRows(result);

    }, [rows, departments, sections, paymentMethods, searchValue, showEmpty])

    const [expandedDepartments, setExpandedDepartments] = useState<Array<{
        departmentId: number;
        expanded: boolean;
    }>>([]);

    const [expandedSections, setExpandedSections] = useState<Array<{
        sectionId: number;
        expanded: boolean;
    }>>([]);

    const [expandedMethods, setExpandedMethods] = useState<Array<{
        sectionId: number;
        methodId: number;
        expanded: boolean;
    }>>([]);

    const [expandedEmployees, setExpandedEmployees] = useState<Array<{
        sectionId: number;
        employeeId: number;
        expanded: boolean;
    }>>([]);

    useEffect(() => {
        const updatedSections = sections.map((section) => {
            // Trova la sezione esistente con lo stesso sectionId
            const existingSection = expandedSections.find(
                (s) => s.sectionId === section.id
            );

            // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
            return existingSection ?? { sectionId: section.id, expanded: false };
        });

        setExpandedSections(updatedSections);
    }, [sections]);

    useEffect(() => {
        const updatedDepartments =
            departments.map((department) => {
                // Trova il dep esistente con lo stesso id
                const existingDepartment = expandedDepartments.find(
                    (d) => d.departmentId === department.id
                );

                // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
                return existingDepartment ?? { departmentId: department.id, expanded: false };
            });

        setExpandedDepartments(updatedDepartments);
    }, [departments]);

    useEffect(() => {
        let updatedMethods: Array<{
            sectionId: number;
            methodId: number;
            expanded: boolean;
        }> = [];

        for (const section of sections) {
            updatedMethods = updatedMethods.concat(
                paymentMethods.map((method) => {
                    // Trova il method esistente con lo stesso id
                    const existingMethod = expandedMethods.find(
                        (m) => m.methodId === method.id && m.sectionId === section.id
                    );

                    // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
                    return existingMethod ?? { methodId: method.id, sectionId: section.id, expanded: false };
                })
            );
        }
        setExpandedMethods(updatedMethods);
    }, [sections, paymentMethods]);

    useEffect(() => {
        let updatedEmployees: Array<{
            sectionId: number;
            employeeId: number;
            expanded: boolean;
        }> = [];

        for (const section of sections) {
            updatedEmployees = updatedEmployees.concat(employees.map((employee) => {
                // Trova l'employee esistente con lo stesso id
                const existingEmployee = expandedEmployees.find(
                    (e) => e.employeeId === employee.id && e.sectionId === section.id
                );

                // Se esiste, mantiene il valore di 'expanded', altrimenti lo imposta a false
                return existingEmployee ?? { employeeId: employee.id, sectionId: section.id, expanded: false };
            }));
        }

        setExpandedEmployees(updatedEmployees);
    }, [sections, employees]);

    const isRowToShow = (row: any) => {
        if (row.isDepartment || row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable) {
            return true;
        } else if (row.isSection) {
            return expandedDepartments.find((department) => {
                return department.departmentId === row.department.id
            })?.expanded
        } else if ((employeesView && row.isMethod) || (!employeesView && row.isEmployee) || row.isDownPayment || row.isSubtotal) {
            return expandedSections.find((section) => {
                return section.sectionId === row.section.id
            })?.expanded
        } else {
            if (employeesView && row.method) {
                return expandedMethods.find((method) => {
                    return method.methodId === row.method.id && method.sectionId === row.section.id
                })?.expanded
            } else if (!employeesView && row.employee) {
                return expandedEmployees.find((employee) => {
                    return employee.employeeId === row.employee.id && employee.sectionId === row.section.id
                })?.expanded
            }
        }
    }

    const role = checkTokenRole();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const [selectedCell, setSelectedCell] = useState<{ rowId: number | null, date: string | null }>({ rowId: null, date: null });
    const [selectedItem, setSelectedItem] = useState<{
        item?: TurnoverItemEntity,
        employee?: EmployeeEntity
    }>();

    const [openDailyNotesModal, setOpenDailyNotesModal] = useState(false);

    const [openControllerModal, setOpenControllerModal] = useState(false);

    const [selectedNotes, setSelectedNotes] = useState<DailyNoteEntity[]>([]);
    const [selectedNotesDate, setSelectedNotesDate] = useState<Date>();

    const handleOpenNotesModal = (date: Date) => {
        setOpenDailyNotesModal(true);
        setSelectedNotesDate(date);
    }

    useEffect(() => {
        if (selectedNotesDate) {
            const dateNotes = dailyNotes.filter((note) => {
                return isSameDay(new Date(note.date), selectedNotesDate);
            })
            setSelectedNotes(dateNotes);
        } else {
            setSelectedNotes([]);
        }
    }, [selectedNotesDate, dailyNotes])

    useLayoutEffect(() => {
        const newDimensions = [...tableDimension];
        const updateDimensions = () => {
            if (iconTableCellRef.current) {
                newDimensions[0] = iconTableCellRef.current.offsetWidth;
            }
            if (titleTableCellRef.current) {
                newDimensions[1] = titleTableCellRef.current.offsetWidth;
            }
            if (firstDateTableCellRef.current) {
                newDimensions[2] = firstDateTableCellRef.current.offsetWidth;
            }
            setTableDimension(newDimensions);
            setChartWidth(dates.length * newDimensions[2] + 185);
        };
        const observer = new ResizeObserver(() => { updateDimensions() });
        if (iconTableCellRef.current) observer.observe(iconTableCellRef.current);
        if (titleTableCellRef.current) observer.observe(titleTableCellRef.current);
        if (firstDateTableCellRef.current) observer.observe(firstDateTableCellRef.current);
        updateDimensions();
        // Cleanup dell'observer quando il componente si smonta
        return () => { observer.disconnect() };
    }, [dates]);

    // Funzione per calcolare il margine in base alla lunghezza massima dei valori Y
    function calculateLeftMargin(maxValueLength: number) {
        const baseMargin = tableDimension[0] + tableDimension[1] - 80;
        if (maxValueLength === 0) {
            return baseMargin
        }
        // Aggiusta il margine in base al numero di caratteri
        const extraMargin = maxValueLength < 5 ? (5 - maxValueLength) * 8 : 0;
        return baseMargin + (extraMargin > 0 ? extraMargin : 0);
    }

    // Trova la lunghezza massima dei valori Y
    const maxYValueLength = Math.max(
        ...chartSeries.flatMap(series => series.data).map(value => {
            if (value && value >= 1_000_000) {
                return (value / 1_000_000).toFixed(1).length + 1; // +1 per la 'M'
            } else if (value && value >= 1_000) {
                return (value / 1_000).toFixed(1).length + 1; // +1 per la 'K'
            } else {
                return value ? value.toFixed(0).length : 0;
            }
        })
    );

    const dynamicMarginLeft = calculateLeftMargin(maxYValueLength);


    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    // Apply selected status filter
    const handleStatusFilterChange = (status: string | null) => {
        setStatusFilter(status);
        setFilterAnchorEl(null);
    };


    return (
        <>
            <DailyNotesModal
                open={openDailyNotesModal}
                onClose={() => {
                    setOpenDailyNotesModal(false);
                    setSelectedNotes([]);
                }}
                notes={selectedNotes}
                fetchData={fetchData}
                date={selectedNotesDate}
            />

            <ControllerModal
                open={openControllerModal}
                onClose={() => {
                    setOpenControllerModal(false);
                }}
                item={selectedItem?.item}
                employee={selectedItem?.employee}
                fetchData={fetchData}
            />

            <TableContainer
                sx={{
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                }}>
                <Accordion
                    defaultExpanded={false}
                    sx={{
                        marginBottom: 2,
                        borderRadius: 2,
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        "&:before": { display: "none" },
                        width: "100%",
                    }}
                >
                    <AccordionSummary
                        expandIcon={<KeyboardArrowDown />}
                        aria-controls="chart-content"
                        id="chart-header"
                        sx={{
                            padding: 2,
                            backgroundColor: theme.palette.background.paper,
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            borderRadius: "8px",
                        }}
                    >
                        <Box
                            sx={{
                                fontWeight: "bold",
                                textAlign: "center",
                                width: "100%",
                            }}
                        >
                            {t("report.barChart")}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: 0,
                            backgroundColor: theme.palette.background.default,
                            borderRadius: "8px",
                            width: "100%",
                        }}
                    >
                        <Chart
                            width={chartWidth}
                            height={260}
                            type="bar"
                            options={chartOptions}
                            series={chartSeries}
                            style={{
                                marginLeft: dynamicMarginLeft,
                                minWidth: tableDimension[2] * dates.length,
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
                <Menu
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={handleFilterClose}
                >
                    <MenuItem onClick={() => { handleStatusFilterChange(null) }}>
                        {t('common.all')}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            backgroundColor: statusColor("CASHED"),
                            '&:hover': {
                                backgroundColor: statusColor('CASHED'),
                            },
                        }}

                        onClick={() => { handleStatusFilterChange('CASHED') }}>
                        {t('turnoverStatus.cashed')}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            backgroundColor: statusColor("CONFIRMED"),
                            '&:hover': {
                                backgroundColor: statusColor('CONFIRMED'),
                            },
                        }}

                        onClick={() => {
                            handleStatusFilterChange('CONFIRMED');
                        }}
                    >
                        {t('turnoverStatus.confirmed')}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            backgroundColor: statusColor("RECEIVED"),
                            '&:hover': {
                                backgroundColor: statusColor('RECEIVED'),
                            },
                        }}
                        onClick={() => { handleStatusFilterChange('RECEIVED') }}>
                        {t('turnoverStatus.received')}
                    </MenuItem>
                </Menu>

                <Table
                    sx={{
                        minWidth: '100%',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                ref={iconTableCellRef}
                                align="center"
                                sx={{
                                    width: 56,
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 2,
                                    backgroundColor: theme.palette.background.default,
                                    borderBottom: '5px solid #ddd',
                                }}
                            >
                            </TableCell>
                            <TableCell
                                ref={titleTableCellRef}
                                align="center"
                                sx={{
                                    minWidth: isMobile ? 150 : 250,
                                    position: 'sticky',
                                    left: 56,
                                    zIndex: 2,
                                    top: 0,
                                    borderBottom: '5px solid #ddd',
                                    backgroundColor: theme.palette.background.default,
                                    opacity: 1,

                                }}>

                            </TableCell>
                            {
                                dates.map((date, index) => (
                                    <TableCell
                                        ref={index === 0 ? firstDateTableCellRef : null}
                                        key={index}
                                        align="center"
                                        sx={{
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 2,
                                            minWidth: 150,
                                            borderBottom: '5px solid #ddd',
                                            backgroundColor: dailyNotes.filter((note) => {
                                                return isSameDay(new Date(note.date), date);
                                            }).filter((note) => !note.resolved).length > 0 ? "#fdebd0" : theme.palette.background.default
                                        }}
                                    >
                                        {format(date, 'dd/MM')}
                                        <SmallerIconButton onClick={() => {
                                            handleOpenNotesModal(ignoreUTC(date));
                                        }}>
                                            <EditNote />
                                        </SmallerIconButton>

                                    </TableCell>
                                ))
                            }
                            <TableCell
                                align="center"
                                sx={{
                                    minWidth: 150,
                                    position: isMobile ? 'static' : 'sticky',
                                    right: isMobile ? 'auto ' : 0,
                                    zIndex: 2,
                                    top: 0,
                                    borderBottom: '5px solid #ddd',
                                    backgroundColor: !statusFilter ? theme.palette.background.default : statusColor(statusFilter),
                                    opacity: 1,

                                }}
                            >
                                {t('total')}
                                <IconButton onClick={handleFilterClick} size="small">
                                    <FilterList />
                                </IconButton>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row) => (
                            isRowToShow(row) && (showEmpty || !showEmpty && !row.empty) && <TableRow
                                key={row.id}
                            >
                                <TableCell
                                    align="center"
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: (row.isDepositable || row.isLastOfSums) ? '7px solid #000' : '1px solid #ddd',
                                        alignItems: 'left',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover || row.isDepositable ? theme.palette.grey[100] :
                                                row.isSection ? theme.palette.grey[300] : theme.palette.background.default,
                                        backgroundClip: 'padding-box',
                                        opacity: 1,

                                    }}
                                >
                                    {
                                        row.isTurnover && <AttachMoney sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isTotalTurnover && <InfoIcon sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isDeposit && <Savings sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isDepositable && <PaymentsIcon sx={{ minWidth: 55 }} />
                                    }
                                    {
                                        row.isDepartment && row.department && <SmallerIconButton
                                            onClick={() => {
                                                // If a department is closed its sections should also be closed
                                                if (
                                                    expandedDepartments.find((expandedDepartment) => {
                                                        return expandedDepartment.departmentId === row.department.id
                                                    })?.expanded
                                                ) {
                                                    setExpandedSections((prevSections) =>
                                                        prevSections.map((section) =>
                                                            // Search in rows the section and return true if his depId === row.department.id
                                                            rows.find((row) => {
                                                                return row.isSection && row.section.id === section.sectionId
                                                            }).department.id === row.department.id ?
                                                                { ...section, expanded: false } :
                                                                section
                                                        )
                                                    );
                                                    if (employeesView) {
                                                        setExpandedMethods((prevMethods) =>
                                                            prevMethods.map((method) =>
                                                                rows.find((row) => {
                                                                    return (
                                                                        row.isMethod &&
                                                                        row.method.id === method.methodId &&
                                                                        row.section.id === method.sectionId
                                                                    );
                                                                }) ?
                                                                    { ...method, expanded: false } :
                                                                    method
                                                            )
                                                        )
                                                    } else {
                                                        setExpandedEmployees((prevEmployees) =>
                                                            prevEmployees.map((employee) =>
                                                                rows.find((row) => {
                                                                    return (
                                                                        row.isEmployee &&
                                                                        row.employee.id === employee.employeeId &&
                                                                        row.section.id === employee.sectionId
                                                                    );
                                                                })?.department.id === row.department.id ?
                                                                    { ...employee, expanded: false } :
                                                                    employee
                                                            )
                                                        )
                                                    }
                                                }

                                                setExpandedDepartments((prevDepartments) =>
                                                    prevDepartments.map((department) =>
                                                        department.departmentId === row.department.id
                                                            ? { ...department, expanded: !department.expanded }
                                                            : department
                                                    )
                                                );
                                            }}>
                                            {expandedDepartments.find((expandedDepartment) => {
                                                return expandedDepartment.departmentId === row.department.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </SmallerIconButton>
                                    }
                                    {
                                        row.isSection && row.section && <SmallerIconButton
                                            onClick={() => {
                                                if (
                                                    expandedSections.find((expandedSection) => {
                                                        return expandedSection.sectionId === row.section.id
                                                    })?.expanded
                                                ) {
                                                    if (employeesView) {
                                                        setExpandedMethods((prevMethods) =>
                                                            prevMethods.map((method) =>
                                                                rows.find((row) => {
                                                                    return (
                                                                        row.isMethod &&
                                                                        row.method.id === method.methodId &&
                                                                        row.section.id === method.sectionId
                                                                    );
                                                                }) ?
                                                                    { ...method, expanded: false } :
                                                                    method
                                                            )
                                                        )
                                                    } else {
                                                        setExpandedEmployees((prevEmployees) =>
                                                            prevEmployees.map((employee) =>
                                                                rows.find((row) => {
                                                                    return (
                                                                        row.isEmployee &&
                                                                        row.employee.id === employee.employeeId &&
                                                                        row.section.id === employee.sectionId
                                                                    );
                                                                }) ?
                                                                    { ...employee, expanded: false } :
                                                                    employee
                                                            )
                                                        )
                                                    }
                                                }
                                                setExpandedSections((prevSections) =>
                                                    prevSections.map((section) =>
                                                        section.sectionId === row.section.id
                                                            ? { ...section, expanded: !section.expanded }
                                                            : section
                                                    )
                                                );
                                            }}>
                                            {expandedSections.find((expandedSection) => {
                                                return expandedSection.sectionId === row.section.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </SmallerIconButton>
                                    }
                                    {
                                        employeesView && row.isMethod && row.method && <SmallerIconButton
                                            onClick={() => {
                                                setExpandedMethods((prevMethods) =>
                                                    prevMethods.map((method) =>
                                                        method.methodId === row.method.id && method.sectionId === row.section.id
                                                            ? { ...method, expanded: !method.expanded }
                                                            : method
                                                    )
                                                );
                                            }}>
                                            {expandedMethods.find((expandedMethod) => {
                                                return expandedMethod.methodId === row.method.id && expandedMethod.sectionId === row.section.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </SmallerIconButton>
                                    }
                                    {
                                        !employeesView && row.isEmployee && row.employee && <SmallerIconButton
                                            onClick={() => {
                                                setExpandedEmployees((prevEmployees) =>
                                                    prevEmployees.map((employee) =>
                                                        employee.employeeId === row.employee.id && employee.sectionId === row.section.id
                                                            ? { ...employee, expanded: !employee.expanded }
                                                            : employee
                                                    )
                                                );
                                            }}>
                                            {expandedEmployees.find((expandedEmployee) => {
                                                return expandedEmployee.employeeId === row.employee.id && expandedEmployee.sectionId === row.section.id
                                            })?.expanded ?
                                                <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </SmallerIconButton>
                                    }
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        paddingLeft: () => {
                                            if (isMobile) {
                                                return 0;
                                            } else {
                                                if (row.isDepartment || row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable) {
                                                    return 0;
                                                } else if (row.isSection || row.isDownPayment || row.isSubtotal) {
                                                    return 5;
                                                } else {
                                                    return 10;
                                                }
                                            }
                                        },
                                        minWidth: isMobile ? 150 : 250,
                                        position: 'sticky',
                                        left: 56,
                                        zIndex: 1,
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: (row.isDepositable || row.isLastOfSums) ? '7px solid #000' : '1px solid #ddd',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable ? theme.palette.grey[100] :
                                                row.isSection ? theme.palette.grey[300] : theme.palette.background.default,
                                        backgroundClip: 'padding-box',
                                        opacity: 1,
                                        color: row.isRemoved ? theme.palette.error.main : undefined,
                                        textDecoration: row.isRemoved ? 'line-through' : undefined,
                                        fontStyle: row.onlyToBeShowed ? 'italic' : undefined
                                    }}
                                >

                                    {row.onlyToBeShowed ?
                                        <Tooltip title={t('paymentSection.onlyToBeShowed')}>
                                            {row.name}
                                        </Tooltip>
                                        : row.name}
                                </TableCell>
                                {
                                    dates.map((date) => (
                                        <TableCell
                                            key={date.toString()}
                                            align="center"
                                            sx={{
                                                minWidth: 150,
                                                borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                                borderBottom: (row.isDepositable || row.isLastOfSums) ? '7px solid #000' : '1px solid #ddd',
                                                color: row[ignoreUTC(date).toISOString()] < 0 ? theme.palette.error.main : undefined,
                                                backgroundColor:
                                                    statusColor(row.status?.[ignoreUTC(date).toISOString()]) ??
                                                    (row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable ? theme.palette.grey[100] :
                                                        row.isSection && theme.palette.grey[300])
                                                ,
                                                opacity: 1,
                                                fontStyle: row.onlyToBeShowed ? 'italic' : undefined,
                                                // if is an item and it is in status cashed show on hover color change
                                                '&:hover':
                                                    (employeesView && row.isEmployee || !employeesView && row.isMethod) && !row.isEmpty &&
                                                    { bgcolor: row.status?.[ignoreUTC(date).toISOString()] === 'CASHED' ? theme.palette.error.dark : undefined }
                                            }}
                                            onClick={() => {
                                                if (row.isDeposit) {
                                                    openDepositModal(date);
                                                } else if ((employeesView && row.isEmployee || !employeesView && row.isMethod) && !row.isEmpty) {
                                                    setSelectedItem(
                                                        {
                                                            item: groupedTurnoverItems[date.toISOString().split('T')[0]]?.[row.section.id]?.[row.method.id]?.[row.employee.id],
                                                            /* item: turnoverItems.find((item) => {
                                                                return (
                                                                    item.paymentSectionId === row.section.id &&
                                                                    item.paymentMethodId === row.method.id &&
                                                                    item.employeeId === row.employee.id &&
                                                                    isSameDay(ignoreUTC(new Date(item.date)), date)
                                                                ) 
                                                            }),
                                                            */
                                                            employee: row.employee
                                                        }
                                                    );

                                                    if ((role === 'CONTROLLER' || role === 'OWNER') && row.status[ignoreUTC(date).toISOString()]?.toUpperCase() === 'CASHED')
                                                        setOpenControllerModal(true);
                                                }
                                            }}
                                        >
                                            {

                                                (row.isDeposit || row.onlyToBeShowed) ? <Tooltip title={row.onlyToBeShowed ? t('paymentSection.onlyToBeShowed') : t('edit')}>
                                                    <Box>
                                                        {moneyFormatter.format((row[ignoreUTC(date).toISOString()] ?? 0.00).toFixed(2))}
                                                    </Box>
                                                </Tooltip> : <>{moneyFormatter.format((row[ignoreUTC(date).toISOString()] ?? 0.00).toFixed(2))}</>
                                            }
                                        </TableCell>
                                    ))
                                }
                                < TableCell
                                    align="center"
                                    sx={{
                                        minWidth: 150,
                                        position: isMobile ? 'static' : 'sticky',
                                        right: isMobile ? 'auto ' : 0,
                                        zIndex: 1,
                                        borderTop: row.isDepartment ? '5px solid #ddd' : '',
                                        borderBottom: (row.isDepositable || row.isLastOfSums) ? '7px solid #000' : '1px solid #ddd',
                                        backgroundClip: 'padding-box',
                                        backgroundColor:
                                            row.isDeposit || row.isTurnover || row.isTotalTurnover || row.isDepositable ? theme.palette.grey[100] :
                                                row.isSection ? theme.palette.grey[300] : theme.palette.background.default,
                                        opacity: 1,
                                        fontStyle: row.onlyToBeShowed ? 'italic' : undefined
                                    }}
                                >
                                    {!row.isDepositable && moneyFormatter.format(row.total ?? 0)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table >
            </TableContainer >
        </>
    );
}

export default TurnoverDataTable;