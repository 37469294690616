import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Box, Card, Grid, styled, Tooltip, useTheme } from '@mui/material';
import HeaderToolbar from 'page-sections/calendar/HeaderToolbar';
import { type FC, useEffect, useRef, useState } from 'react';
import AddEventModal, { AddWorkFormData } from './AddEventModal';
import { EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import ViewEventModal from './ViewEventModal';
import { calendarEntriesApi, seasonApi } from '../../../api';
import { CalendarEntryEntity, CalendarEntryEntityTypeEnum } from '../../../api/generated';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import toast, { Toaster } from 'react-hot-toast';
import { useSeason } from 'contexts/SeasonContext';
import { GlobalCursorState } from 'pages/employees/calendar';
import BrushIcon from '@mui/icons-material/Brush';
import itLocale from '@fullcalendar/core/locales/it'
import deLocale from '@fullcalendar/core/locales/de'
import enLocale from '@fullcalendar/core/locales/en-gb'
import frLocale from '@fullcalendar/core/locales/fr'
import { firstLetterUpperCase } from 'utils/firstLetterUpperCase';
import { fadeColor } from 'utils/utils';

// styled component
const CalendarWrapper = styled(Box)(({ theme }) => ({
  padding: '1.5rem',
  '& .fc-theme-standard td, & .fc-theme-standard th': {
    border: 'none',
  },
  '& .fc-theme-standard, & .fc-scrollgrid': {
    border: 'none !important',
  },
  '& .fc .fc-scroller-harness-liquid': {
    marginTop: '1rem',
  },
  '& .fc th': {
    color: theme.palette.primary.main,
  },
  '& .fc-daygrid-day-top': {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
  },
  '& .fc .fc-daygrid-day.fc-day-today': {
    borderRadius: '8px',
    border: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
  },
  '& .fc-daygrid-day-number': {
    fontWeight: 600,
  },
  '& .fc-h-event': {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    textAlign: 'center',
  },
  '& .fc-daygrid-day-bottom a': {
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    textAlign: 'center',
    color: theme.palette.background.paper,
    fontSize: 10,
    fontWeight: 500,
  },
  '& .fc .fc-popover': {
    border: 'none',
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    zIndex: 111,
  },
  '& .fc .fc-popover-header': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 12,
    fontWeight: 600,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '& .fc .fc-daygrid-event-harness': {
    padding: 3,
    cursor: 'pointer',
  },
}));

interface Props {
  employeeId: number;
  cursorType?: GlobalCursorState;
  // eventCount should be setted every time data being fetched or the displayed month changes
  setEventCount: (eventCount: Record<string, number>) => void;
  fetchDaysOffData?: () => void;
  userMode: boolean;
}

const Calendar: FC<Props> = ({ employeeId, cursorType, setEventCount, userMode, fetchDaysOffData }) => {
  const isMobile = screen.width <= 768;
  const isTablet = screen.width <= 1024;
  const { seasonId } = useSeason();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const calendarRef = useRef<FullCalendar>(null);
  const [date, setDate] = useState(new Date());
  const [addEventState, setAddEventState] = useState<{
    isOpened: boolean;
    data?: Partial<AddWorkFormData>;
  }>({
    isOpened: false,
  });
  const [viewEventState, setViewEventState] = useState<{
    isOpened: boolean;
    id?: number;
    color?: string;
  }>({
    isOpened: false,
  });
  const [initialEvents, setInitialEvents] = useState<EventInput[]>();

  const colors = new Map<CalendarEntryEntityTypeEnum, string>([
    [CalendarEntryEntityTypeEnum.Vacation, theme.palette.success.main],
    [CalendarEntryEntityTypeEnum.DayOff, theme.palette.warning.main],
    [CalendarEntryEntityTypeEnum.Ill, theme.palette.error.main],
    [CalendarEntryEntityTypeEnum.HalfDay, '#00eaff'],
    [CalendarEntryEntityTypeEnum.UnpaidLeave, '#6c25be'],
  ]);
  const nextMonth = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    calendarApi.next();
    setDate(calendarApi.getDate());
    // fetch eventCount of next month
    fetchEventCount();
  };

  const prevMonth = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    calendarApi.prev();
    setDate(calendarApi.getDate());
    // fetch eventCount of previous month
    fetchEventCount();
  };

  const openDetailView = (clickArg: EventClickArg) => {
    const event = clickArg.event;
    setViewEventState({
      isOpened: true,
      id: Number(event.id),
      color: event.backgroundColor,
    });
  };



  function eventOverlaps(start: Date, end: Date = start): boolean {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return true;
    }

    end = addDays(end, 1);
    // fullcalender's end date is exclusive
    return calendarApi.getEvents().some((event) => {
      if (!event.start) {
        return false;
      }
      // Avoid 31 march 2024 bug :)
      const eventEnd = event.end ?? event.start;
      return (
        (start >= event.start && start < eventEnd) ||
        (end > event.start && end <= eventEnd) ||
        (event.start >= start && event.start < end) ||
        (eventEnd > start && eventEnd <= end)
      );
    });
  }

  const addEventFromCursor = async (dateArg: DateClickArg) => {
    if (eventOverlaps(dateArg.date)) {
      return;
    }

    if (cursorType?.type) {
      const entry: AddWorkFormData = {
        startedOn: dateArg.date,
        endedOn: dateArg.date,
        type: cursorType.type
      }
      await addEvent(entry);
    }
  }

  const addEventFromDate = (dateArg: DateClickArg) => {
    if (eventOverlaps(dateArg.date)) {
      return;
    }
    setAddEventState({
      isOpened: true,
      data: {
        startedOn: dateArg.date,
        endedOn: dateArg.date,
      },
    });
  };

  const highlightToday = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  async function addEvent(calendarEntry: AddWorkFormData) {
    if (eventOverlaps(calendarEntry.startedOn, calendarEntry.endedOn)) {
      toast.error(t('employees.calendar.errEntriesOverlap'));
      return;
    }
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }

    const { startedOn, endedOn, ...rest } = calendarEntry;

    const seasonEventsId = seasonId === 0 ? (await seasonApi.findMySeasonInProgress()).data.at(-1)?.id : seasonId;

    if (seasonEventsId) {
      const { data: newEntry } = userMode ? await calendarEntriesApi.createByMyself(
        String(employeeId),
        {
          ...rest,
          startedOn: startedOn.toISOString(),
          endedOn: endedOn.toISOString(),
        },
        seasonEventsId
      ) : await calendarEntriesApi.create(
        String(employeeId),
        {
          ...rest,
          startedOn: startedOn.toISOString(),
          endedOn: endedOn.toISOString(),
        },
        seasonId
      );
      calendarApi.addEvent(convertToEvent(newEntry));
      // if a new event get added fetch event count
      fetchEventCount();
      if (fetchDaysOffData)
        fetchDaysOffData();
    } else {
      // Modal to ask to add a season
    }
  }

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <Tooltip
        title={t(`employees.calendar.types.${eventContent.event.title}`)}
      >
        <div style={{ width: '100%', height: '100%' }}></div>
      </Tooltip>
    );
  }

  function convertToEvent(item: CalendarEntryEntity) {
    return {
      id: String(item.id),
      start: new Date(item.startedOn),
      // fullcalendar's end date is exclusive
      end: addDays(new Date(item.endedOn), 1),
      allDay: true,
      title: item.type.toLowerCase(),
      color: item.accepted ? colors.get(item.type) : fadeColor(colors.get(item.type)),
    };
  }

  const fetchEventCount = async () => {
    const calendarApi = calendarRef.current?.getApi();
    let currentDate;
    if (calendarApi) {
      currentDate = calendarApi.getDate();
    }
    else {
      currentDate = date;
    }
    const eventCount = userMode ? await calendarEntriesApi.findAllEventsByMyself(
      String(employeeId),
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    ) : await calendarEntriesApi.findAllEvents(
      String(employeeId),
      String(seasonId),
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    );
    setEventCount(eventCount.data as Record<string, number>);
  }

  const fetchData = async () => {
    const calendarEntryEntities = userMode ? (await calendarEntriesApi.findAllByMyself(
      String(employeeId)
    )).data : (await calendarEntriesApi.findAll(
      String(employeeId),
      seasonId
    )).data
    // Clear the events from the calendar -> Otherwise new events will be merged with existings one.
    calendarRef.current?.getApi().removeAllEvents()
    setInitialEvents(
      calendarEntryEntities.map((item) => {
        return convertToEvent(item);
      }),
    );
    // every time data are fetched fetch eventCount
    fetchEventCount();
    if (fetchDaysOffData)
      fetchDaysOffData();
  };

  useEffect(() => {
    fetchData();

  }, [employeeId, seasonId]);

  useEffect(() => {
    toast.dismiss();
    if (cursorType?.type !== null) {
      toast(
        <span>
          Tool: {cursorType?.label}
        </span>,
        {
          duration: Infinity,
          icon: <BrushIcon fontSize='small' style={{ color: cursorType?.color }} />,
        });
    }
  }, [cursorType]);

  useEffect(() => {
    // Unmount function to remove the toast
    return () => {
      toast.remove();
    };
  }, []);

  const findSelectedLanguage = () => {
    switch (i18n.language) {
      case 'en':
        return enLocale;
      case 'it':
        return itLocale;
      case 'de':
        return deLocale;
      case 'fr':
        return frLocale;
      default:
        return enLocale;
    }
  }

  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        <Toaster />
        <Grid item xs={12}>
          {initialEvents && (
            <Card>
              <HeaderToolbar
                date={date}
                onDatePrev={() => {
                  prevMonth();
                }}
                onDateNext={() => {
                  nextMonth();
                }}
                onAddEventForm={() => {
                  setAddEventState({ isOpened: true });
                }}
                onToday={() => {
                  highlightToday();
                }}
              />
              <CalendarWrapper>
                <FullCalendar
                  timeZone='UTC'
                  eventDisplay='background'
                  initialDate={date}
                  eventClick={openDetailView}
                  dateClick={cursorType?.type === null ? addEventFromDate : addEventFromCursor}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  ref={calendarRef}
                  height={600}
                  rerenderDelay={10}
                  events={initialEvents}
                  headerToolbar={false}
                  firstDay={1}
                  eventContent={renderEventContent}
                  locale={findSelectedLanguage()}
                  dayHeaderFormat={{ weekday: isMobile ? 'narrow' : isTablet ? 'short' : 'long', }}
                  dayHeaderContent={(content) => {
                    return firstLetterUpperCase(content.text);
                  }}
                />
              </CalendarWrapper>
            </Card>
          )}
          {addEventState.isOpened && (
            <AddEventModal
              open={addEventState.isOpened}
              onClose={() => {
                setAddEventState({ isOpened: false });
              }}
              onSubmit={async (entry: AddWorkFormData) => {
                await addEvent(entry);
                setAddEventState({ isOpened: false });
              }}
              data={addEventState.data}
            />
          )}

          {viewEventState.isOpened && (
            <ViewEventModal
              open={viewEventState.isOpened}
              onClose={() => {
                setViewEventState({ isOpened: false });
              }}
              id={viewEventState.id ?? 0}
              color={viewEventState.color ?? ''}
              employeeId={employeeId}
              seasonId={seasonId}
              fetchCalendarData={fetchData}
              userMode={userMode}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calendar;
