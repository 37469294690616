import AddTurnoverModal from "page-sections/data-table/dataTableV2/AddTurnoverModal";
import { FC, useEffect, useState } from "react"
import { DailyNoteEntity, DepartmentEntity, DepositEntity, EmployeeEntity, PaymentMethodEntity, SectionEntity } from "api/generated";
import { Button, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { dailyNotesApi, departmentsApi, depositsApi, employeesApi, paymentMethodsApi, paymentSectionsApi, turnoverApi } from "api";
import FlexBox from "components/flexbox/FlexBox";
import { Add, ArrowDownward, ArrowUpward, CheckBox, CheckBoxOutlineBlank, Download, Info } from "@mui/icons-material";
import CalendarInput from "components/input-fields/CalendarInput";
import TurnoverDataTable from "page-sections/data-table/TurnoverDataTable";
import SelectSectionModal from "page-sections/data-table/dataTableV2/SelectSectionModal";
import { useSeason } from "contexts/SeasonContext";
import AddDepositModal from "page-sections/data-table/dataTableV2/AddDepositModal";
import SearchInput from "components/input-fields/SearchInput";
import { endOfDay, startOfDay } from "date-fns";
import { checkTokenRole } from "utils/checkToken";
import DefaultUserImage from '../../assets/images/defaultUserPage.svg';
import Logo from '../../assets/images/logo_onestaff.png';
import { Small } from "components/Typography";
import { translatePaymentTypes } from "utils/convertPaymentTypes";
import { ApexOptions } from "apexcharts";
import useAuth from "hooks/useAuth";
import OwnerConfirmModal from "page-sections/data-table/dataTableV2/OwnerConfirModal";
import { turnoversReport } from "./turnoverReport";

interface ExtendEmployeeEntity extends EmployeeEntity {
    isRemoved: boolean
}

const TurnoverSummary: FC = () => {
    const { user } = useAuth();

    const [openAddTurnoverModal, setOpenAddTurnoverModal] = useState(false);
    const [openAddDepositModal, setOpenAddDepositModal] = useState(false);
    const [openSelectSectionModal, setOpenSelectSectionModal] = useState(false);

    // To set if add deposit modal is opened from table or from button
    const [addMode, setAddMode] = useState(true);
    // To store the default date for depositModal when it's opened from the table.
    const [depositsDate, setDepositDate] = useState(new Date());

    const { seasonId } = useSeason();

    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentEntity>()
    const [sections, setSections] = useState<SectionEntity[]>([]);
    const [selectedSection, setSelectedSection] = useState<SectionEntity>();

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
    const [groupedTurnoverItems, setGroupedTurnoverItems] = useState({});
    const [deposits, setDeposits] = useState<DepositEntity[]>([]);
    const [turnoverEmployees, setTurnoverEmployees] = useState<ExtendEmployeeEntity[]>([]);
    const [dailyNotes, setDailyNotes] = useState<DailyNoteEntity[]>([]);

    const [endDate, setEndDate] = useState(new Date());
    const [isEndDateValid, setIsEndDateValid] = useState(true);
    const [startDate, setStartDate] = useState(() => new Date(new Date().setDate(new Date().getDate() - 6)));
    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isEndBeforeStart, setIsEndBeforeStart] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const [ascSort, setAscSort] = useState(true);
    const [showAllMethods, setShowAllMethods] = useState(false);
    const [employeesView, setEmployeesView] = useState(true);

    const role = checkTokenRole();
    const [openOwnerModal, setOpenOwnerModal] = useState(false);
    const [isOwnerModalOpenable, setIsOwnerModalOpenable] = useState(false);

    const theme = useTheme();
    const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const [chartData, setChartData] = useState<any[]>([]);
    const [datesFromTable, setDatesFromTable] = useState<Date[]>([])

    useEffect(() => {
        fetchData();
    }, [seasonId]);

    useEffect(() => {
        fetchSections();
    }, [departments])

    useEffect(() => {
        fetchData();
    }, [endDate, startDate])

    const fetchData = () => {
        fetchPaymentMethods();
        fetchDepartments();
        fetchSections();
        fetchDeposits();
        fetchDailyNotes();
        fetchGroupedTurnoverItems();
    }

    const reverseData = () => {
        setSections([...sections].reverse())
        setDepartments([...departments].reverse())
        setPaymentMethods([...paymentMethods].reverse())
    }

    useEffect(() => {
        fetchDepchiefs();
    }, [groupedTurnoverItems, seasonId])

    useEffect(() => {
        reverseData();
    }, [ascSort])

    const fetchDepchiefs = async () => {
        if ((role === 'OWNER' || role === 'CONTROLLER')) {
            const depchiefs = (await employeesApi.findAllDepchief(seasonId, true)).data;
            let removedEmployees: EmployeeEntity[] = [];
            if (seasonId) {
                removedEmployees = (await employeesApi.findRemovedFromTurnovers(
                    seasonId,
                    startDate.toISOString().split("T")[0],
                    endDate.toISOString().split("T")[0])).data;
            }
            const data: ExtendEmployeeEntity[] = depchiefs.map(employee => {
                return {
                    isRemoved: employee.employeesToSeasons?.at(0)?.statusType === 'TERMINATED',
                    ...employee
                }
            }).concat(removedEmployees.map(employee => {
                return {
                    isRemoved: true,
                    ...employee
                }
            }))
            if (ascSort) {
                data.sort((a, b) => {
                    return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
                })
            } else {
                data.sort((a, b) => {
                    return -a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
                })
            }

            setTurnoverEmployees(data);
        }
    };

    const fetchPaymentMethods = () => {
        // Everybody can see payment methods
        paymentMethodsApi.findAll().then(({ data }) => {
            if (ascSort) {
                data.sort((a, b) => {
                    return translatePaymentTypes(a.type, t).toLowerCase().localeCompare(translatePaymentTypes(b.type, t).toLowerCase())
                })
                setPaymentMethods(data);

            } else {
                data.sort((a, b) => {
                    return -translatePaymentTypes(a.type, t).toLowerCase().localeCompare(translatePaymentTypes(b.type, t).toLowerCase())
                })
                setPaymentMethods(data);

            }
        })
    };

    const fetchDepartments = () => {
        if (role !== 'USER' && role !== 'MANAGER') {
            departmentsApi.findByRole(seasonId).then(({ data }) => {
                if (ascSort) {
                    data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())

                    })
                } else {
                    data.sort((a, b) => {
                        return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                }
                setDepartments(data);
            })
        }
    };

    const fetchSections = () => {
        if (role !== 'USER' && role !== 'MANAGER') {
            paymentSectionsApi.findAll(seasonId).then(async ({ data }) => {
                if (ascSort) {
                    data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                } else {
                    data.sort((a, b) => {
                        return -a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    })
                }
                setSections(data);
            })
        }
    };

    const fetchGroupedTurnoverItems = () => {
        if ((role === 'OWNER' || role === 'CONTROLLER') && isEndDateValid && isStartDateValid) {
            turnoverApi.grouped(false, (startDate).toISOString().split("T")[0], endDate.toISOString().split("T")[0]).then(({ data }) => {
                setGroupedTurnoverItems(data);
            })
        }
    };

    const fetchDeposits = () => {
        if (role === 'OWNER') {
            depositsApi.findAll().then(({ data }) => {
                setDeposits(data);
            })
        }
    };

    const fetchDailyNotes = () => {
        if (role === 'OWNER' || role === 'CONTROLLER') {
            dailyNotesApi.findAll().then(({ data }) => {
                setDailyNotes(data);
            })
        }
    }


    const handleOpenAddTurnoverModal = () => {
        if (sections.length === 1) {
            setSelectedSection(sections[0]);
            setOpenAddTurnoverModal(true);
        } else if (departments.length === 1) {
            setSelectedDepartment(departments[0]);
            const filteredSectionsByDepartment = sections.filter((s) => { return s.departmentId === departments[0].id });
            if (filteredSectionsByDepartment.length === 1) {
                setSelectedSection(filteredSectionsByDepartment[0]);
                setOpenAddTurnoverModal(true);
            } else {
                setOpenSelectSectionModal(true);
            }
        } else {
            setOpenSelectSectionModal(true);
        }
    };

    const chartOptions: ApexOptions = {
        chart: {
            type: 'bar',
            height: 260,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                columnWidth: '80%',
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                dataLabels: {

                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        },
                        formatter: function (value) {
                            const numericValue = Number(value);
                            if (numericValue >= 1_000_000) {
                                return (numericValue / 1_000_000).toFixed(1) + 'M';
                            } else if (numericValue >= 1_000) {
                                return (numericValue / 1_000).toFixed(1) + 'K';
                            } else {
                                return numericValue.toFixed(2) + " €";
                            }
                        }
                    }
                }
            },
        },
        xaxis: {
            categories: datesFromTable.length > 0 ? datesFromTable : [],
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    if (value && value >= 1_000_000) {
                        return (value / 1_000_000).toFixed(1) + 'M';
                    } else if (value && value >= 1_000) {
                        return (value / 1_000).toFixed(1) + 'K';
                    } else {
                        return value ? value.toFixed(0) : '0';
                    }
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value, opts) => {
                const numericValue = Number(value);
                if (numericValue >= 1_000_000) {
                    return (numericValue / 1_000_000).toFixed(1) + 'M';
                } else if (numericValue >= 1_000) {
                    return (numericValue / 1_000).toFixed(1) + 'K';
                }
                return numericValue.toFixed(2) + " €";
            },
            style: {
                fontSize: '12px',
                fontWeight: 700,
            },
            background: {
                enabled: false,
                borderRadius: 3,
                padding: 4,
            },
        },

        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        }
    };

    const excludedKeys = new Set(['isDepartment', 'isSection', 'name', 'department', 'empty', 'id', 'sectionIds', 'total']);

    const chartSeries: Array<{ name: string; data: Array<number | null>; type: string; }> = chartData
        // total > 0 filters empty departments
        .filter(({ isDepartment, isSection, total }) => isDepartment && !isSection && total > 0)
        .map(({ name, ...rest }) => ({
            name: name.length > 10 ? name.substring(0, 10) + '...' : name,
            data: Object.entries(rest)
                .filter(([key, value]) => {
                    return !excludedKeys.has(key) && (typeof value === 'number' || value === null);
                })
                .map(([key, value]) => (typeof value === 'number' ? Number(value.toFixed(2)) : null)), // To prevent errors of object type - apexCharts
            type: 'bar'
        })
        )

    return (
        <>
            {
                (role === 'OWNER' || role === 'DEPCHIEF' || role === 'CONTROLLER') &&
                <>
                    <FlexBox marginTop={1} justifyContent={'end'} maxHeight={60}>
                        {
                            (departments.length <= 0 || sections.length <= 0) &&
                            <Small color={theme.palette.warning.main} marginRight={2} width={300}>{t('turnovers.cannotAdd')}</Small>
                        }
                        <Button
                            sx={{ marginRight: 1 }}
                            variant="contained"
                            disabled={
                                departments.length <= 0 ||
                                sections.length <= 0 ||
                                paymentMethods.length <= 0
                            }
                            startIcon={<Download />}
                            onClick={async () => {
                                const { data } = await turnoverApi.findAll(false);
                                turnoversReport(
                                    departments,
                                    sections,
                                    paymentMethods,
                                    data,
                                    deposits,
                                    startDate,
                                    endDate,
                                    seasonId
                                )
                            }}>{t('turnover.report')}
                        </Button>
                        {role === 'OWNER' && <Button
                            sx={{ marginRight: 1 }}
                            variant="contained"
                            disabled={departments.length <= 0 || sections.length <= 0}
                            startIcon={<Add />}
                            onClick={() => {
                                setAddMode(true);
                                setOpenAddDepositModal(true);
                            }}>{t('deposit.add')}
                        </Button>
                        }

                        {role === 'OWNER' && <Button
                            sx={{ marginRight: 1 }}
                            variant="contained"
                            disabled={!isOwnerModalOpenable}
                            startIcon={<Add />}
                            onClick={() => {
                                setOpenOwnerModal(true);
                            }}>{t('ownerConfirmModal.openButton')}
                        </Button>
                        }
                        {role !== 'CONTROLLER' &&
                            <Button
                                variant="contained"
                                disabled={departments.length <= 0 || sections.length <= 0}
                                startIcon={<Add />}
                                onClick={() => { handleOpenAddTurnoverModal() }}>
                                {t('amount.add')}
                            </Button>
                        }
                    </FlexBox>

                    <SelectSectionModal
                        open={openSelectSectionModal}
                        onClose={() => { setOpenSelectSectionModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                        onConfirm={() => {
                            setOpenAddTurnoverModal(true);
                            setOpenSelectSectionModal(false)
                        }}
                        setSelectedSection={(section: SectionEntity | undefined) => { setSelectedSection(section) }}
                        sections={sections}
                        selectedSection={selectedSection}
                        setSelectedDepartment={(department: DepartmentEntity | undefined) => { setSelectedDepartment(department) }}
                        departments={departments}
                        selectedDepartment={selectedDepartment}
                    />

                    <AddTurnoverModal
                        open={openAddTurnoverModal}
                        section={selectedSection}
                        isOwner={role === 'OWNER'}
                        onClose={() => { setOpenAddTurnoverModal(false); setSelectedDepartment(undefined); setSelectedSection(undefined); fetchData() }}
                        onBack={() => {
                            setOpenAddTurnoverModal(false);
                            const filteredSectionsByDepartemnt = sections.filter((s) => { return s.departmentId === departments[0].id });
                            if (sections.length === 1 || departments.length === 1 && filteredSectionsByDepartemnt.length === 1) {
                                setSelectedDepartment(undefined);
                                setSelectedSection(undefined);
                                fetchData();
                            } else {
                                setOpenSelectSectionModal(true);
                            }
                        }}
                    />

                    {
                        role === 'OWNER' &&
                        <OwnerConfirmModal
                            open={openOwnerModal}
                            fetchTableData={fetchData}
                            onClose={() => {
                                setOpenOwnerModal(false);
                            }}
                            setOpenable={setIsOwnerModalOpenable}

                        />
                    }

                    {role === 'OWNER' && <AddDepositModal
                        open={openAddDepositModal}
                        onClose={() => { setOpenAddDepositModal(false) }}
                        sections={sections}
                        departments={departments}
                        addMode={addMode}
                        defaultDate={depositsDate}
                        fetchTableData={fetchData}
                    />}
                </>
            }

            {
                role === 'DEPCHIEF' && <Container
                    maxWidth="lg"
                    sx={{
                        height: '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        position: 'relative',
                        paddingLeft: mobileBreakpoint ? '20px' : '40px',
                        paddingRight: mobileBreakpoint ? '20px' : '40px'
                    }}
                >
                    {mobileBreakpoint && (
                        <img
                            src={Logo}
                            alt="OneStaff Logo"
                            style={{
                                width: '80px',
                                height: 'auto',
                                position: 'absolute',
                                top: 20,
                                left: '50%',
                                transform: 'translateX(-50%)'
                            }}
                        />
                    )}

                    <Grid
                        container
                        sx={{
                            flexGrow: 1,
                            justifyContent: mobileBreakpoint ? 'center' : 'flex-start',
                            alignItems: 'center',
                            textAlign: 'left'
                        }}
                    >
                        <Grid item xs={12} md={6} sx={{ order: mobileBreakpoint ? 2 : 1 }}>
                            <img
                                src={DefaultUserImage}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: mobileBreakpoint ? '200px' : '400px'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                order: mobileBreakpoint ? 1 : 2,
                                marginTop: mobileBreakpoint ? '20px' : '0'
                            }}
                        >
                            <div style={{
                                fontSize: mobileBreakpoint ? '36px' : '64px',
                                lineHeight: '1.2',
                                textAlign: 'left',
                                marginLeft: '30px'
                            }}>
                                <span style={{ color: 'black' }}>{t('greetings')}</span>
                                <br />
                                <span style={{ color: 'violet', fontWeight: 'bold' }}>
                                    {user?.username || 'Guest'}
                                </span>
                            </div>
                        </Grid>
                    </Grid>

                    {!mobileBreakpoint && (
                        <img
                            src={Logo}
                            alt="OneStaff Logo"
                            style={{
                                width: '120px',
                                height: 'auto',
                                position: 'absolute',
                                bottom: 20,
                                left: '50%',
                                transform: 'translateX(-50%)'
                            }}
                        />
                    )}
                </Container>
            }

            {
                (role === 'OWNER' || role === 'CONTROLLER') && <Grid container>
                    <Grid item xs={12} md={2} marginTop={2} marginBottom={2} marginRight={1}>
                        <SearchInput
                            bordered={false}
                            placeholder={t('common.tables.button.searchItems', {
                                items: t('menu.apps.sections'),
                            })}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={startDate}
                            onChange={(newValue) => {
                                if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                    setStartDate(newValue);
                                    setIsStartDateValid(true);
                                    if (+startOfDay(newValue) > +endOfDay(endDate)) {
                                        setIsEndBeforeStart(true);
                                    } else {
                                        setIsEndBeforeStart(false);
                                    }
                                }
                                else
                                    setIsStartDateValid(false);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: (!isStartDateValid && t('date.error')),
                                    error: !isStartDateValid || isEndBeforeStart
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} md={2} marginTop={1} marginBottom={2} marginRight={1}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            value={endDate}
                            onChange={(newValue) => {

                                if (newValue && newValue instanceof Date && !isNaN(+newValue)) {
                                    setEndDate(newValue);
                                    setIsEndDateValid(true);
                                    if (+endOfDay(newValue) < +startOfDay(startDate)) {
                                        setIsEndBeforeStart(true);
                                    } else {
                                        setIsEndBeforeStart(false);
                                    }
                                }
                                else
                                    setIsEndDateValid(false);
                            }}
                            slotProps={{
                                textField: {
                                    helperText: !isEndDateValid && t('date.error') || (isEndBeforeStart && t('date.endBeforeStart')),
                                    error: !isEndDateValid || isEndBeforeStart
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} justifyContent={"start"}>
                        <Button style={{ marginBottom: 20, marginRight: 50 }} startIcon={ascSort ? <ArrowDownward /> : <ArrowUpward />} onClick={() => { setAscSort(!ascSort) }}>
                            {ascSort ? "ABC" : "CBA"}
                        </Button>
                        <Button
                            style={{ marginBottom: 20, marginRight: 50 }}
                            startIcon={showAllMethods ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            onClick={() => { setShowAllMethods(!showAllMethods) }}
                        >
                            {t('turnover.show')}
                        </Button>
                        <Button
                            style={{ marginBottom: 20, marginRight: 50 }}
                            startIcon={!employeesView ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            onClick={() => { setEmployeesView(!employeesView) }}
                        >
                            {t('turnover.employeesView')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} justifyContent={"end"}>
                        <Button
                            fullWidth
                            style={{ marginBottom: 20 }}
                            startIcon={<Info />}
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            {t('turnover.infoEdit')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TurnoverDataTable
                            startDate={startDate}
                            endDate={endDate}
                            groupedTurnoverItems={groupedTurnoverItems}
                            departments={departments}
                            sections={sections}
                            paymentMethods={paymentMethods}
                            deposits={deposits}
                            employees={turnoverEmployees}
                            dailyNotes={dailyNotes}
                            fetchData={fetchData}
                            searchValue={searchValue}
                            showEmpty={showAllMethods}
                            employeesView={employeesView}
                            openDepositModal={(date: Date) => {
                                setDepositDate(date);
                                setAddMode(false);
                                setOpenAddDepositModal(true);
                            }}
                            setChartDataCallback={setChartData}
                            chartOptions={chartOptions}
                            chartSeries={chartSeries}
                            setDateFromTableCallback={setDatesFromTable}
                        />
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default TurnoverSummary;