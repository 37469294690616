import { type FC } from 'react';
import { Grid } from '@mui/material';
import { TFunction } from 'i18next';
import CustomCard from 'components/CustomCard';
import Startdate from 'icons/report/reactIcons/Startdate';
import Enddate from 'icons/report/reactIcons/Enddate';
import { Workdays } from 'icons/report/reactIcons';

interface DataProps {
  contract: {
    startDate: string;
    endDate: string;
    days: number;
    totalDaysOff: number;
    todayDaysOff: number;
    daysOff: number;
  };
  t: TFunction;
}

const EmployeeContract: FC<DataProps> = (props) => {
  const { t } = props;
  const workDays = Math.floor(props.contract.days);
  let workDaysFraction;
  if (props.contract.days > workDays) workDaysFraction = '½';
  const daysSinceStart = Math.floor((Date.now() - new Date(props.contract.startDate).getTime()) / (1000 * 60 * 60 * 24));

  return (
    <Grid container spacing={3}>
      <Grid item md={3} xs={12}>
        <CustomCard
          id={1}
          title={t('employees.report.contract.startDate')}
          color="#6B63FE"
          text={props.contract.startDate}
          Icon={Startdate}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <CustomCard
          id={2}
          title={t('employees.report.contract.endDate')}
          color="#6B63FE"
          text={props.contract.endDate}
          Icon={Enddate}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <CustomCard
          id={3}
          title={t('employees.report.contract.DaysWorked')}
          color="#03BF62"
          text={isNaN(workDays) ? '-' : workDays}
          fraction={workDaysFraction}
          Icon={Workdays}
          contentStyle={{ display: 'flex', width: '100%' }}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <CustomCard
          id={4}
          title={t('employees.report.contract.daysSinceStart')}
          color="#FFA500"
          text={String(daysSinceStart)}
          Icon={Workdays}
          contentStyle={{ display: 'flex', width: '100%' }}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeContract;
