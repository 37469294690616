import { Badge, Box, Button, Divider, FormHelperText, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography, styled } from '@mui/material';
import EncryptionKeyIcon from '@mui/icons-material/VpnKeyRounded';
import NoEncryptionKeyIcon from '@mui/icons-material/VpnKeyOffRounded';
import {
  type FC,
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import PopoverLayout from './PopoverLayout';
import { useTranslation } from 'react-i18next';
import AppTextField from 'components/input-fields/AppTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEncryption } from 'contexts/EncryptionKeyContext';

import { tenantConfigApi } from 'api';
import { checkTokenRole } from 'utils/checkToken';
import CryptoJS from 'crypto-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlexBox from 'components/flexbox/FlexBox';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import EncryptionSettingsModal from 'page-sections/data-table/dataTableV2/EncryptionSettingsModal';
import { UpdateTenantConfigDto } from 'api/generated';
import { decryptData, encryptData } from 'utils/encryptionDecryptionAgreements';
import EncryptionRecoveryKeyModal from 'page-sections/data-table/dataTableV2/EncryptionRecoveryKeyModal';
import { downloadTxtFile } from 'utils/downloadTxt';
import { toast } from 'react-hot-toast';

// styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));


const EncryptionPopover: FC = () => {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const [isEncryptionKeySet, setIsEncryptionKeySet] = useState(false);
  const { encryptionKey, setEncryptionKey } = useEncryption();
  const { popoverOpen, setPopoverOpen } = useEncryption();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [testPasswordValue, setTestPasswordValue] = useState('');
  const [errorCode, setErrorCode] = useState<number>();
  const [openEncryptionKeyRecovery, setOpenEncryptionKeyRecovery] = useState(false);
  const [recoveredKey, setRecoveredKey] = useState<string>('');

  const [showPassword, setShowPassword] = useState(false);

  let employeeRole: string = '';

  try {
    employeeRole = checkTokenRole();
  }
  catch (err) {
    console.error(err);
  }


  const handleClickShowPassword = () => { setShowPassword((show) => !show) };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const initialValues: any = {
    registerPasswordInput: '',
    confirmPasswordInput: '',
    password: '',
    recoveryKey: '',
  };


  const validationSchema = isEncryptionKeySet ?
    Yup.object({
      password: Yup.string()
    })
    :
    Yup.object({
      registerPasswordInput: Yup.string().required(
        t('common.forms.field.required', {
          field: t('auth.password'),
        }),
      ),
      confirmPasswordInput: Yup.string()
        .required(
          t('common.forms.field.required', {
            field: t('account.password.confirmNewPassword'),
          }),
        )
        .oneOf(
          [Yup.ref('registerPasswordInput')],
          t('account.password.passwordsDontMatch'),
        ),
    });


  async function fetchDataAndSetEncryptionKeyState() {
    const response: { data: any } = await tenantConfigApi.isEncryptionKeySet();
    const { isPasswordSet, testPasswordValue, isEncryptionMandatory } = response.data;
    setIsEncryptionKeySet(isPasswordSet);
    setTestPasswordValue(testPasswordValue);
    localStorage.setItem('isEncryptionMandatory', isEncryptionMandatory);

    // To obtain the key if already set previously and present in the browser storage 
    const encryptionKey = localStorage.getItem('encryptionKey');
    if (encryptionKey) {
      setEncryptionKey(encryptionKey);
    }
  }
  useEffect(() => {
    fetchDataAndSetEncryptionKeyState();
  }, []);

  const getSubmitHandler = () => {
    if (openEncryptionKeyRecovery) {
      return async (values: any) => {
        // Handle encryption key recovery
        const backupKey = values.recoveryKey;
        const response: { data: any } = await tenantConfigApi.encryptionKeyRecovery();
        const { encryptedPassword, testBackupPasswordValue } = response.data;

        if (decryptData(testBackupPasswordValue, backupKey) === '') {
          setErrorCode(403);
          setRecoveredKey('');
          return;
        }
        setErrorCode(undefined);
        setRecoveredKey(decryptData(encryptedPassword, backupKey));
      };
    } else if (isEncryptionKeySet) {
      return async (values: any) => {
        // Handle login
        setErrorCode(undefined);
        const derivedKey = keyDerivationFunction(values.password);
        if (decryptData(testPasswordValue, derivedKey.toString(CryptoJS.enc.Hex)) === '') {  
          setErrorCode(401);
          return;
        }
        setEncryptionKey(derivedKey.toString(CryptoJS.enc.Hex));
        setIsEncryptionKeySet(true);
        setPopoverOpen(false);
        values.password = ''; // Reset password field after the login for security reason
        localStorage.setItem('encryptionKey', derivedKey.toString(CryptoJS.enc.Hex));
      };
    } else {
      return async (values: any) => {
        // Handle create password
        const derivedKey = keyDerivationFunction(values.confirmPasswordInput);
        setEncryptionKey(derivedKey.toString(CryptoJS.enc.Hex));
        setIsEncryptionKeySet(true);
        setPopoverOpen(false);
        localStorage.setItem('encryptionKey', derivedKey.toString(CryptoJS.enc.Hex));

        const randomBytes = CryptoJS.lib.WordArray.random(16);
        const base64String = CryptoJS.enc.Base64.stringify(randomBytes);
        const backupKey = base64String.replace(/[^a-zA-Z0-9]/g, '').substring(0, 16);
        toast.success(t('encryption.popover.alerts.backupPassword'), {
          duration: 5000
        });

        // To start downloading after closing the toast
        setTimeout(function () {
          const content = `${backupKey}`;
          const filename = "Encryption_Password_Recovery_Code.txt";
          downloadTxtFile(content, filename);
        }, 5000);

        const newConfig: UpdateTenantConfigDto = {
          isPasswordSet: true,
          testPasswordValue: encryptData(base64String, derivedKey.toString(CryptoJS.enc.Hex)),
          encryptedPassword: encryptData(values.confirmPasswordInput, backupKey),
          testBackupPasswordValue: encryptData(base64String, backupKey)
        }
        tenantConfigApi.update('1', newConfig);
      };
    }
  };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: getSubmitHandler()
    });

  function keyDerivationFunction(inputPassword: string) {
    // const salt = crypto.randomBytes(16).toString('hex');
    const salt = 'marcatech';
    const iterations = 10000;
    const keyLength = 16;
    try {
      const derivedKey = CryptoJS.PBKDF2(
        inputPassword,
        salt,
        {
          keySize: keyLength,
          iterations,
        }
      );
      return derivedKey;
    } catch (error) {
      console.error('Error during key derivation:', error);
      throw error;
    }
  }

  return (
    <>
      {employeeRole === 'OWNER' || employeeRole === 'MANAGER' || employeeRole === 'DEVELOPER' || employeeRole === 'CONTROLLER' ?
        (
          <Fragment>
            <StyledIconButton
              ref={anchorRef}
              onClick={() => {
                const isOwner = employeeRole === 'OWNER';
                if (!isOwner && !isEncryptionKeySet) {
                  return;
                }
                setPopoverOpen(true);
              }}
            >
              <Badge color='error'>
                {encryptionKey ?
                  <Tooltip title={t('encryption.icons.active')}>
                    <EncryptionKeyIcon sx={{ color: '#009900' }} />
                  </Tooltip>
                  :
                  <Tooltip title={t('encryption.icons.notActive')}>
                    <NoEncryptionKeyIcon sx={{ color: 'text.disabled' }} />
                  </Tooltip>
                }
              </Badge>
            </StyledIconButton>

            {encryptionKey &&
              <PopoverLayout
                title={t('encryption.popover.manage')}
                popoverOpen={popoverOpen}
                anchorRef={anchorRef}
                hiddenViewButton={true}
                popoverClose={() => {
                  setPopoverOpen(false);
                }}
                maxWidth={300}
              >
                <FlexBox flexDirection={'column'}>
                  <Box p={1}>
                    <Button
                      variant='text'
                      sx={{
                        width: '100%',
                        padding: '10px',
                        justifyContent: 'flex-start',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                      startIcon={<SettingsIcon />}
                      onClick={() => {
                        setOpenSettingsModal(true);
                        window.location.hash = "settings";
                        setPopoverOpen(false);
                      }}
                    >
                      {t('encryption.popover.settings')}
                    </Button>
                  </Box>
                  <Divider />
                  <Box p={1}>
                    <Button
                      variant='text'
                      sx={{
                        width: '100%',
                        padding: '10px',
                        justifyContent: 'flex-start',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                      color='error'
                      startIcon={<LogoutIcon sx={{ ml: '1px', mr: '-1px' }} />}
                      onClick={() => {
                        localStorage.removeItem('encryptionKey');
                        setEncryptionKey('');
                      }}
                    >
                      {t('encryption.popover.logout')}
                    </Button>
                  </Box>
                </FlexBox>
              </PopoverLayout>
            }

            {!encryptionKey &&
              <PopoverLayout
                title={isEncryptionKeySet ? t('encryption.popover.identityVerification') : t('encryption.popover.registerKey')}
                popoverOpen={popoverOpen}
                anchorRef={anchorRef}
                hiddenViewButton={true}
                popoverClose={() => {
                  setPopoverOpen(false);
                  setErrorCode(undefined);
                  setOpenEncryptionKeyRecovery(false);
                }}
                maxWidth={300}
              >
                <Grid container p={2} rowSpacing={2}>
                  {/* Login with encryption key */}
                  {isEncryptionKeySet && !openEncryptionKeyRecovery &&
                    <>
                      <Grid item>
                        <form onSubmit={handleSubmit}>
                          <AppTextField
                            inputRef={input => input?.focus()}
                            name='password'
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            size='small'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            InputProps={{
                              endAdornment:
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            }}
                          />
                          {errorCode === 401 && (
                            <FormHelperText
                              error
                              sx={{
                                mt: 2,
                                fontSize: 13,
                                fontWeight: 500,
                                textAlign: 'center',
                                backgroundColor: 'error.light',
                                padding: 1,
                              }}
                            >
                              {t('account.password.errors.unauthorized')}
                            </FormHelperText>
                          )}
                          <Box mt={2}>
                            <Button type='submit' variant='contained'>
                              {t('encryption.popover.unlock')}
                            </Button>
                          </Box>
                        </form>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                        <Typography
                          variant="body2"
                          color="primary"
                          onClick={() => { setOpenEncryptionKeyRecovery(true) }}
                          style={{ cursor: 'pointer', display: 'inline' }}
                        >
                          {t('encryption.popover.passwordRecovery')}
                        </Typography>
                      </Grid>
                    </>
                  }

                  {/* Register encryption key */}
                  {!isEncryptionKeySet && !openEncryptionKeyRecovery &&
                    <Grid container spacing={2}>
                      <Grid item>
                        <form onSubmit={handleSubmit}>
                          <Stack spacing={2} mt={2}>
                            <AppTextField
                              name='registerPasswordInput'
                              label="Password"
                              type={'password'}
                              variant="outlined"
                              size='small'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.registerPasswordInput}
                              helperText={(touched.registerPasswordInput && errors.registerPasswordInput) as string}
                              error={Boolean(
                                touched.registerPasswordInput && errors.registerPasswordInput,
                              )}
                            />
                            <AppTextField
                              name='confirmPasswordInput'
                              label="Confirm password"
                              type={'password'}
                              variant="outlined"
                              size='small'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirmPasswordInput}
                              helperText={(touched.confirmPasswordInput && errors.confirmPasswordInput) as string}
                              error={Boolean(
                                touched.confirmPasswordInput && errors.confirmPasswordInput,
                              )}
                            />
                          </Stack>

                          <Box mt={2}>
                            <Button type='submit' variant='contained'>
                              {t('encryption.popover.register')}
                            </Button>
                          </Box>
                        </form>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </PopoverLayout>
            }
            <EncryptionSettingsModal
              open={openSettingsModal}
              onClose={() => {
                setOpenSettingsModal(false);
                window.location.hash = "";
                history.replaceState(null, document.title, window.location.pathname + window.location.search);
              }}
              setOpenSettingsModalCallback={setOpenSettingsModal}
            />
            <EncryptionRecoveryKeyModal
              open={openEncryptionKeyRecovery}
              onClose={() => {
                setOpenEncryptionKeyRecovery(false);
                setRecoveredKey('');
                values.recoveryKey = '';
                setErrorCode(undefined);
              }}
              errorCode={errorCode}
              handleBlur={handleBlur}
              handleChange={handleChange}
              onSubmit={handleSubmit}
              recoveredKey={recoveredKey}
              recoveryKeyValue={values.recoveryKey}
            />
          </Fragment >
        )

        : (<></>)}

    </>
  );
};


export default EncryptionPopover;
