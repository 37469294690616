import { KeyboardArrowLeft, Send } from "@mui/icons-material";
import { Box, Divider, Grid, InputBase, Stack } from "@mui/material";
import { dailyNotesApi } from "api";
import { DailyNoteEntity } from "api/generated";
import AppModal from "components/AppModal";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import { StyledIconButton } from "components/TenantDisplay";
import { H2, H4, Small } from "components/Typography";
import { format } from "date-fns";
import DailyNotesBubble from "page-sections/notes/DailyNotesBubble";
import React, { ChangeEvent, useEffect, FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface ModalProps {
  notes: DailyNoteEntity[];
  open: boolean;
  onClose: () => void;
  fetchData: () => void;
  date?: Date;
}

const DailyNotesModal: FC<ModalProps> = ({ notes, open, onClose, fetchData, date }) => {
  const [note, setNote] = useState('');
  const notesEnd = React.useRef<HTMLInputElement>(null);
  const [noteActionId, setNoteActionId] = useState(-1);
  const [disableHover, setDisableHover] = useState(false);
  const { t } = useTranslation();

  /* // Exit edit mode by clicking 'Esc'
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetEditModeNote();
    }
  }; */

  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const createNote = () => {
    if (note.trim().length > 0 && date) {
      dailyNotesApi.create(
        {
          date: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
          text: note
        }
      ).then(() => {
        fetchData();
        setDisableHover(true);
      })
      setNote('');
    }
  };

  const editNote = (note: DailyNoteEntity) => {
    setNoteActionId(note.id);
    setNote(note.text);

    const inputField = document.getElementById('noteInput');
    if (inputField) {
      inputField.focus();
    }
  };

  const updateNote = () => {
    if (note.trim().length > 0) {
      dailyNotesApi.update(
        String(noteActionId),
        {
          text: note
        }
      ).then(() => {
        fetchData();
        setNote('');
        setNoteActionId(-1);
      })

    }
  }

  const resetEditModeNote = () => {
    setNote('');
    setNoteActionId(-1);
  };

  useEffect(() => {
    notesEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [notes]);

  return (
    <AppModal
      open={open}
      handleClose={() => {
        onClose();
        setNote('');
      }}
      sx={{ width: 500 }}
    >
      <H2>{t('notes.collectionName')}</H2>
      <H4>{date && format(date, 'dd/MM/yyyy')}</H4>
      <Grid container justifyContent="center">
        <Grid item xl={12} md={12} xs={12}>
          <Box
            position="relative"
            onMouseMove={() => {
              if (disableHover) {
                setDisableHover(false);
              }
            }}
          >
            {noteActionId > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.2)',
                  backdropFilter: 'blur(1px)',
                  zIndex: 1,
                }}
              />
            )}

            <Scrollbar style={{ height: 400 }}>
              <Stack spacing={4} px={3} py={2}>
                {notes.sort(function (a, b) { return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime() }).map((note) => (
                  <div ref={notesEnd} key={note.id}>
                    <DailyNotesBubble
                      noteInstance={note}
                      fetchData={fetchData}
                      editNoteCallback={editNote}
                      disableHover={disableHover}
                    />
                  </div>
                ))}
              </Stack>
            </Scrollbar>

          </Box>

          {noteActionId > 0 && (
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(92, 84, 112, 0.3)' }}>
              <KeyboardArrowLeft fontSize="small"
                onClick={resetEditModeNote}
              />
              <Small fontWeight={600} fontSize={10}>{t('notes.edit')}</Small>
            </div>
          )}


          <Divider />

          <FlexBetween padding={2} gap={2}>

            <InputBase
              id="noteInput"
              fullWidth
              multiline
              placeholder={t('notes.inputPlaceholder')}
              sx={{ fontSize: 12, fontWeight: 500 }}
              value={note}
              onChange={handleNoteChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  noteActionId < 0 ? createNote() : updateNote();
                }

              }}
            />
            <StyledIconButton
              onClick={() => { noteActionId < 0 ? createNote() : updateNote() }}
              disabled={note.trim().length === 0 || noteActionId >= 0}
            >
              <Send sx={{ color: (note.trim().length === 0) ? 'gray' : 'primary.main' }} />
            </StyledIconButton>
          </FlexBetween>
        </Grid>
      </Grid>
    </AppModal>
  )
}

export default DailyNotesModal;