import { agreementApi, calendarEntriesApi, employeePaymentsApi, employeesApi } from 'api';
import { AgreementEntity, CalendarEntryEntityTypeEnum, PaymentEntity } from 'api/generated';
import { addDays, endOfMonth, format, isAfter, isBefore, isWithinInterval, parse, parseISO, startOfMonth } from 'date-fns';
import { decryptData } from 'utils/encryptionDecryptionAgreements';


function millisecondsToDays(milliseconds: number) {
  return Math.round(milliseconds / 1000 / 3600 / 24);
}


enum CalendarEntryType {
  HALF_DAY = 'HALF_DAY',
  DAY_OFF = 'DAY_OFF',
  VACATION = 'VACATION',
  ILL = 'ILL',
  UNPAID_LEAVE = 'UNPAID_LEAVE',
  WORK = 'WORK'
}
interface PaymentDetail {
  date: Date;
  payed: number;
  paymentId: number;
  receiptPath: string | null;
  remainingCredit: number;
  toBePayed: number;
}

interface PaymentDetail {
  date: Date;
  payed: number;
  paymentId: number;
  receiptPath: string | null;
  remainingCredit: number;
  toBePayed: number;
}


export async function getEmployeeReport(employeeId: number, seasonId: number | null, encryptionKey: string, setHasEncryptedAgreements: (arg: boolean) => void, setIsUnlimited: (arg: boolean) => void, year?: number, selectedDate?: Date) {


  const employeeToSeason = (await employeesApi.findOne(employeeId, {
    params: {
      seasonId,
      includePayments: true,
      includeContract: true,
    },

  })).data.employeesToSeasons?.at(0);

  if (!employeeToSeason)
    return;

  if (!employeeToSeason.contract)
    return;
  if (!employeeToSeason.contract?.startedOn)
    return;

  const contractStartDate = new Date(employeeToSeason.contract?.startedOn);
  const firstDayOfYear = year && new Date(Date.UTC(year, 0, 1));
  const startDate = firstDayOfYear ? contractStartDate > firstDayOfYear ? contractStartDate : firstDayOfYear : contractStartDate;
  let endDate = employeeToSeason.contract?.endedOn ? new Date(employeeToSeason.contract?.endedOn) : new Date(Date.UTC(startDate.getFullYear(), 11, 31));
  if (!employeeToSeason.contract?.endedOn) {
    seasonId = null;
    setIsUnlimited(true);
  } else {
    setIsUnlimited(false);
  }
  const agreements = (employeeToSeason.contract as any).agreements;
  // seasonId = 0 means null in this endpoint implementation
  const payments: PaymentEntity[] = (await employeePaymentsApi.findAll(employeeId, seasonId ?? 0)).data;
  const paymentsData: Array<{
    date: Date;
    toBePayed: number,
    payed: number,
    remainingCredit: number,
    paymentId: number,
    receiptPath?: string,
    isEncrypted: boolean
  }> = [];

  let eventCount: Record<string, number> = {};

  let daysOff: number = 0;

  let workDays: number = 0;

  let total = 0;
  let totalWeightedNetDaily = 0;
  let totalDaysOff = 0;
  let todayDaysOff = 0;
  let totalDuration = 0;
  let monthly = 0;
  let avgNetDaily = 0;


  const monthlyPayments: number[] = [];
  const monthlyDues: number[] = [];
  const credit: number[] = [];

  const seniority = (await employeesApi.getStats(employeeId)).data;


  if (agreements.length > 0) {
    // maxDate and minDate used to compute the monthly payments from total
    let minDate = agreements[0].startedOn;
    let maxDate = agreements[0].endedOn;

    setHasEncryptedAgreements(false);
    for (const agreement of agreements as AgreementEntity[]) {
      /* if (!agreement.endedOn)
           continue;
       */

      let countStartDate = new Date(agreement.startedOn);
      const countEndDate = agreement.endedOn ? new Date(agreement.endedOn) : endDate;

      // if agreement has not and end start date should be decided by year selected
      if (!agreement.endedOn) {
        countStartDate = new Date(agreement.startedOn) > startDate ? new Date(agreement.startedOn) : startDate;
      }

      // if startDate is greater thant endDate means agreement is not valid in year selected
      if (countStartDate > countEndDate)
        continue;

      const duration = millisecondsToDays(+countEndDate - +countStartDate) + 1;
      const fromStart = millisecondsToDays(+(selectedDate ?? new Date()) - +countStartDate) + 1;

      todayDaysOff =
        todayDaysOff + (
          agreement.freeDays.filter((elem, index, self) => {
            return index === self.indexOf(elem);
          }).length
        ) * (fromStart > duration ? duration : fromStart) / 7;


      totalDaysOff =
        totalDaysOff + (
          agreement.freeDays.filter((elem, index, self) => {
            return index === self.indexOf(elem);
          }).length
        ) * duration / 7;


      let netValue = 0;
      if (agreement.net_daily_encrypted === null && agreement.net_daily) {
        netValue = agreement.net_daily;
      } else if (agreement.net_daily_encrypted !== null) {
        const net = decryptData(agreement.net_daily_encrypted, encryptionKey);
        if (net !== '') {
          netValue = Number(net);
        } else {
          setHasEncryptedAgreements(true);
          continue;
        }

      }

      totalDuration += duration;

      totalWeightedNetDaily += netValue * duration;

      if (minDate > countStartDate)
        minDate = countStartDate;

      if (maxDate < countEndDate)
        maxDate = countEndDate;

      total = total + netValue * duration;


    }
    avgNetDaily = totalWeightedNetDaily / totalDuration;
    monthly = avgNetDaily * 30;
  }


  if (employeeToSeason.contract && startDate && endDate) {

    const selectedEndDate = selectedDate ?? new Date();

    endDate = new Date(endDate);
    const nextDayEndDate = new Date(endDate.getTime());
    nextDayEndDate.setDate(nextDayEndDate.getDate() + 1);
    const finalEndDate = +selectedEndDate < +nextDayEndDate ? selectedEndDate : endDate;

    eventCount = (await calendarEntriesApi.findEventsPerPeriodOfTime(
      startDate.toDateString(),
      finalEndDate.toDateString(),
      employeeId,
      seasonId?.toString(),
    )).data as Record<string, number>;
    // const todayEventCount = (await calendarEntriesApi.findEventsPerPeriodOfTime(startDate.toUTCString(), (selectedDate ?? endDate).toUTCString(), employeeId.toString(), seasonId?.toString())).data as Record<string, number>;
    daysOff = eventCount[CalendarEntryEntityTypeEnum.DayOff] + eventCount[CalendarEntryEntityTypeEnum.HalfDay] * 0.5;
    workDays = eventCount.WORK - (eventCount[CalendarEntryType.HALF_DAY] * 0.5);
  }

  if (agreements && startDate && endDate) {

    await computePaymentsData(startDate, endDate, employeeId, seasonId, agreements, payments, monthlyDues, monthlyPayments, credit, encryptionKey);
    for (const payment of payments) {

      let alreadyPayed = 0;
      if (seasonId)
        // find previous payments, map to get a number array and reduce to get the total sum
        alreadyPayed = payments.filter(checkedPayment => checkedPayment.paymentDate <= payment.paymentDate && checkedPayment.id !== payment.id)
          .map(payment => encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid))
          .reduce((a, b) => {
            return a + b;
          }, 0);
      else {
        // if it is an annual employee compute only payments of current payment year
        const firstOfYear = new Date(Date.UTC(new Date(payment.paymentDate).getFullYear(), 0, 1));
        alreadyPayed = payments.filter(checkedPayment => checkedPayment.paymentDate <= payment.paymentDate && checkedPayment.id !== payment.id &&
          new Date(checkedPayment.paymentDate) > firstOfYear)
          .map(payment => encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid))
          .reduce((a, b) => {
            return a + b;
          }, 0);
      }
      const toBePayed = await computeDue(agreements, year ? new Date(new Date(payment.paymentDate).getFullYear(), 0, 1) : startDate,
        new Date(payment.paymentDate), employeeId, seasonId, encryptionKey) - alreadyPayed;
      const remainingCredit = toBePayed - (encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid));
      paymentsData.push({
        date: new Date(payment.paymentDate),
        payed: encryptionKey && payment.paid_encrypted ? Number(Number(decryptData(payment.paid_encrypted, encryptionKey)).toFixed(2)) : Number(Number(payment.paid).toFixed(2)),
        toBePayed: Number(toBePayed.toFixed(2)),
        remainingCredit: Number(remainingCredit.toFixed(2)),
        paymentId: payment.id,
        receiptPath: payment.filePath,
        isEncrypted: !!(payment.paid_encrypted && decryptData(payment.paid_encrypted, encryptionKey) === '')
      });

    }

  }

  return {
    effectiveContractStartDate: contractStartDate,

    contarctId: employeeToSeason.contract.id,

    contract: {
      startDate: startDate?.toLocaleDateString('it-IT'),
      endDate: endDate?.toLocaleDateString('it-IT'),
      days: workDays,
      // TODO to be defined on contract
      totalDaysOff,
      todayDaysOff,
      daysOff,
    },

    agreements: {
      avgNetDaily,
      monthly,
      total,
    },

    stats: {
      unpaidLeave: eventCount[CalendarEntryType.UNPAID_LEAVE],
      ill: eventCount[CalendarEntryType.ILL],
      vacation: eventCount[CalendarEntryType.VACATION],
      seniority,
    },
    chart: {
      paid: monthlyPayments,
      due: monthlyDues,
      credit,
    },

    paymentsData,

    paymentsCount: paymentsData.length,

  };

}

async function computePaymentsData(startDate: Date, endDate: Date, employeeId: number, seasonId: number | null,
  agreements: AgreementEntity[], payments: PaymentEntity[], monthlyDues: number[], monthlyPayments: number[], credit: number[], encryptionKey: string) {
  for (let currentDate = startDate; currentDate <= endDate; currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)) {
    const firstDayOfMonth = startOfMonth(currentDate);
    const endDayOfMonth = endOfMonth(currentDate);

    let monthlyPayment = 0;

    monthlyDues.push(await computeDue(agreements, firstDayOfMonth, endDayOfMonth, employeeId, seasonId, encryptionKey));

    payments.forEach((payment) => {
      if (isWithinInterval(new Date(payment.paymentDate), { start: firstDayOfMonth, end: endDayOfMonth })) {
        monthlyPayment = monthlyPayment + (encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid));
      }
    });

    monthlyPayments.push(monthlyPayment);


    if (credit.length === 0) {
      credit.push(monthlyDues[monthlyDues.length - 1] - monthlyPayment);
    } else {
      credit.push(monthlyDues[monthlyDues.length - 1] + credit[credit.length - 1] - monthlyPayment);
    }

  }
}

export async function computeDue(agreements: AgreementEntity[], startDate: Date, endDate: Date, employeeId: number, seasonId: number | null, encryptionKey: string) {
  let monthlyDue = 0;
  for (const agreement of agreements) {


    let netValue = 0;

    if (agreement.net_daily_encrypted === null && agreement.net_daily) {
      netValue = agreement.net_daily;
    } else if (agreement.net_daily_encrypted !== null) {
      const net = decryptData(agreement.net_daily_encrypted, encryptionKey);
      if (net !== '') {
        netValue = Number(net);
      } else
        continue;
    }
    let events: Record<string, number> | undefined;
    if (agreement.endedOn) {
      if (new Date(agreement.startedOn) >= startDate && new Date(agreement.endedOn) <= endDate) {
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(agreement.startedOn, agreement.endedOn, employeeId, seasonId?.toString())).data as Record<string, number>;
      }

      if (new Date(agreement.startedOn) < startDate && new Date(agreement.endedOn) <= endDate && new Date(agreement.endedOn) >= startDate) {
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(startDate.toDateString(), agreement.endedOn, employeeId, seasonId?.toString())).data as Record<string, number>;
      }

      if (new Date(agreement.startedOn) > startDate && new Date(agreement.endedOn) > endDate && new Date(agreement.startedOn) < endDate) {
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(agreement.startedOn, endDate.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;
      }

      if (new Date(agreement.startedOn) <= startDate && new Date(agreement.endedOn) > endDate) {
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(startDate.toDateString(), endDate.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;
      }
    } else if (new Date(agreement.startedOn) <= endDate) {

      if (new Date(agreement.startedOn) >= startDate)
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(agreement.startedOn, endDate.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;

      if (new Date(agreement.startedOn) < startDate)
        events = (await calendarEntriesApi.findEventsPerPeriodOfTime(startDate.toDateString(), endDate.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;

    }
    // if an agreement is valid in this month
    if (events)
      monthlyDue += (events.WORK + events[CalendarEntryType.ILL] + events[CalendarEntryType.VACATION] + events[CalendarEntryType.DAY_OFF] + events[CalendarEntryType.HALF_DAY])
        * netValue;

  }


  return monthlyDue;
}

export function getMonthlyNets(agreements: AgreementEntity[], date: Date, encryptionKey: string) {
  const results = [];

  for (const agr of agreements) {
    let netValue = 0;

    // if the agreement ended before the selected month, continue 
    if (agr.endedOn && isBefore(new Date(agr.endedOn), startOfMonth(date))) continue;
    // if the agreement will start after the selected month, continue 
    if (isAfter(new Date(agr.startedOn), endOfMonth(date))) continue;

    if (agr.net_daily_encrypted === null && agr.net_daily) {
      netValue = agr.net_daily;
    } else if (agr.net_daily_encrypted !== null) {
      const net = decryptData(agr.net_daily_encrypted, encryptionKey);
      if (net !== '') {
        netValue = Number(net);
      }
    }

    /* if (!isSameMonth(new Date(agr.startedOn), date) && (!agr.endedOn || !isSameMonth(new Date(agr.endedOn), date))) {
      result = result + (netValue * 31);
      continue;
    } else {
      result = result + (netValue * differenceInCalendarDays(
        agr.endedOn && isSameMonth(new Date(agr.endedOn), date) ? new Date(agr.endedOn) : endOfMonth(date),
        isSameMonth(new Date(agr.startedOn), date) ? new Date(agr.startedOn) : startOfMonth(date)
      ))
      if (!(agr.endedOn && isSameMonth(new Date(agr.endedOn), date))) {
        result = result + (netValue * (31 - endOfMonth(date).getDate()));
      }
    } */

    results.push(netValue * 30);
  }
  return results;
}

export async function fetchCurrentPaymentData(
  employeeId: number,
  seasonId: number | null,
  contractId: number | null,
  encryptionKey: string,
  setTotalEarned: (total: number) => void,
  setTotalPaid: (total: number) => void,
  date?: Date
) {
  if (!employeeId)
    return;
  const agreements = (await (agreementApi.findAll(employeeId, seasonId ?? undefined, contractId ?? undefined))).data;
  // seasonId = 0 means null in this endpoint implementation
  const payments = (await (employeePaymentsApi.findAll(employeeId, seasonId ?? 0))).data;
  const today = date ?? new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);

  let total = 0;
  for (const agreement of agreements) {
    let netValue = 0;

    if (agreement.net_daily_encrypted === null && agreement.net_daily) {
      netValue = agreement.net_daily;
    } else if (agreement.net_daily_encrypted !== null) {
      const net = decryptData(agreement.net_daily_encrypted, encryptionKey);
      if (net !== '') {
        netValue = Number(net);
      }
    }

    const computeStartedOn = () => {
      // if employee is seasonal
      if (seasonId)

        return new Date(agreement.startedOn);
      // if employee is annual
      else {

        const agreementStart = new Date(agreement.startedOn);
        return agreementStart > startOfYear ? agreementStart : startOfYear;
      }
    };


    // if agreements starts before current year start the count from first day of this year
    const startedOn = computeStartedOn();

    const computeEndedOn = () => {
      if (agreement.endedOn)
        return new Date(agreement.endedOn);
      else
        return new Date(today.getFullYear(), 11, 31);
    };

    const endedOn = computeEndedOn();

    startedOn.setHours(0, 0, 0, 0);
    endedOn.setHours(0, 0, 0, 0);


    // this checks that the agreement is valid in current year
    if (startedOn > endedOn)
      continue;

    if (endedOn) {
      if (endedOn <= today) {

        const events = (await calendarEntriesApi.findEventsPerPeriodOfTime(startedOn.toDateString(), endedOn.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;
        total += (events.WORK + events[CalendarEntryEntityTypeEnum.Ill] + events[CalendarEntryEntityTypeEnum.Vacation] + events[CalendarEntryEntityTypeEnum.DayOff]) *
          netValue + (events[CalendarEntryEntityTypeEnum.HalfDay] * netValue / 2);
      } else if (startedOn <= today) {
        const events = (await calendarEntriesApi.findEventsPerPeriodOfTime(startedOn.toDateString(), today.toDateString(), employeeId, seasonId?.toString())).data as Record<string, number>;
        total += (events.WORK + events[CalendarEntryEntityTypeEnum.Ill] + events[CalendarEntryEntityTypeEnum.Vacation] + events[CalendarEntryEntityTypeEnum.DayOff]) *
          netValue + (events[CalendarEntryEntityTypeEnum.HalfDay] * netValue / 2);
      }
    }
  }
  setTotalEarned(Number(total.toFixed(2)));
  if (seasonId)
    setTotalPaid(
      payments
        .filter((payment) => new Date(payment.paymentDate) <= addDays(today, 1))
        .map(payment => (encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid))).reduce((a, b) => {
          return Number((a + b).toFixed(2));
        }, 0));
  else {
    setTotalPaid(payments
      .filter((payment) => {
        const paymentDate = new Date(payment.paymentDate);
        return paymentDate >= startOfYear && paymentDate < addDays(today, 1);

      })
      .map(payment => (encryptionKey && payment.paid_encrypted ? Number(decryptData(payment.paid_encrypted, encryptionKey)) : Number(payment.paid))).reduce((a, b) => {
        return Number((a + b).toFixed(2));
      }, 0));
  }
}

export async function generateDailyNetEarnings(employeeId: number, seasonId: number, contractId: number, encryptionKey: string, startDate: string, endDate: string) {
  const agreements = (await agreementApi.findAll(employeeId, seasonId ?? undefined, contractId ?? undefined)).data;
  const dailyNetEarningsMap: Map<string, number> = new Map<string, number>();
  if (!agreements.length) {
    return dailyNetEarningsMap; // Return an empty map if there are no agreements
  }

  // Identify the earliest start date among the agreements
  const earliestStartDate = new Date(Math.min(...agreements.map(agreement => new Date(agreement.startedOn).getTime())));


  // Ensure the earliest start date is not earlier than the provided startDate
  let currentDate = new Date(Math.max(earliestStartDate.getTime(), new Date(startDate).getTime()));
  const endDateObj = parse(endDate, 'yyyy-MM-dd', new Date());
  // Initialize the map with dates
  // .setDate is not recognized by eslint - the condition is modified
  /* eslint-disable-next-line no-unmodified-loop-condition */
  while (currentDate <= endDateObj) {
    dailyNetEarningsMap.set(currentDate.toISOString().slice(0, 10), 0);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Reset the current date for iteration
  currentDate = new Date(Math.max(earliestStartDate.getTime(), new Date(startDate).getTime()));

  agreements.forEach(agreement => {
    const agreementStartDate = new Date(Date.UTC(
      parseInt(agreement.startedOn.substr(6, 4)),
      parseInt(agreement.startedOn.substr(3, 2)) - 1,
      parseInt(agreement.startedOn.substr(0, 2)),
    ));
    const agreementEndDate = agreement.endedOn ? parseISO(agreement.endedOn) : new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));

    for (let date = new Date(Math.max(currentDate.getTime(), agreementStartDate.getTime()));
      // .setDate is not recognized by eslint - the condition is modified
      /* eslint-disable-next-line no-unmodified-loop-condition */
      date <= endDateObj && date <= agreementEndDate;
      date.setUTCDate(date.getUTCDate() + 1)) {  // Increment date in UTC

      const dateKey = date.toISOString().slice(0, 10);  // This is always in UTC
      let dailyNet = agreement.net_daily;

      if (agreement.net_daily_encrypted) {
        const decryptedValue = decryptData(agreement.net_daily_encrypted, encryptionKey);
        dailyNet = parseFloat(decryptedValue) || 0;
      }
      dailyNetEarningsMap.set(dateKey, (dailyNetEarningsMap.get(dateKey) || 0) + (dailyNet || 0));
    }
  });

  // Calculate cumulative earnings
  let cumulativeTotal = 0;
  const dailyNetEarningsArray: Array<[string, number]> = Array.from(dailyNetEarningsMap.entries());
  dailyNetEarningsArray.forEach(([date, earnings], index) => {
    cumulativeTotal += earnings;
    dailyNetEarningsArray[index][1] = cumulativeTotal;
  });

  // Convert the array back to a map for the return value
  const cumulativeNetEarningsMap: Map<string, number> = new Map<string, number>(dailyNetEarningsArray);

  return cumulativeNetEarningsMap;
}

export async function generateCumulativePayouts(payments: PaymentDetail[], startDate: string, endDate: string): Promise<Map<string, number>> {
  const dateFormat = 'dd/MM/yyyy';
  const startDt = parse(startDate, dateFormat, new Date());
  const endDt = parse(endDate, dateFormat, new Date());
  const dailyPayoutsMap: Map<string, number> = new Map<string, number>();

  // Initialize the map with zeros for each date in the range
  for (let date = new Date(startDt); date <= endDt; date = addDays(date, 1)) {
    dailyPayoutsMap.set(format(date, 'yyyy-MM-dd'), 0);
  }

  // Populate the daily payouts map
  payments.forEach(payment => {

    const paymentDate = payment.date;
    const dateKey = format(paymentDate, 'yyyy-MM-dd'); // Format to YYYY-MM-DD for map keys

    if (paymentDate >= startDt && paymentDate <= endDt) {
      const currentAmount = dailyPayoutsMap.get(dateKey) || 0;
      dailyPayoutsMap.set(dateKey, currentAmount + parseFloat(payment.payed.toString()));
    }
  });

  // Calculate cumulative payouts
  let cumulativeTotal = 0;
  const cumulativePayoutsMap: Map<string, number> = new Map<string, number>();
  Array.from(dailyPayoutsMap.keys()).sort().forEach(dateKey => {
    cumulativeTotal += dailyPayoutsMap.get(dateKey) || 0;
    cumulativePayoutsMap.set(dateKey, cumulativeTotal);
  });

  return cumulativePayoutsMap;
}
