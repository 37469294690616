import { Alert, IconButton, Snackbar, useTheme } from '@mui/material';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { Small } from 'components/Typography';
import { FileUploadOutlined } from '@mui/icons-material';
import { ChangeEvent, type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FileUploadInputProps {
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  resetOnChange?: boolean;
  maxDimension?: number
  disabled?: boolean,
  multiple?: boolean,
}

const FileUploadInput: FC<FileUploadInputProps> = ({
  handleOnChange,
  accept = '',
  maxDimension,
  disabled,
  resetOnChange,
  multiple
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {

    const files = event.target.files;
    if (files) {
      const file = files[0];
      if (maxDimension) {
        const fileSize = file.size / (1024 * 1024); // Dimension in MB
        if (fileSize > maxDimension) {
          showAlert(t('alerts.uploadSizeErrorMessage', {
            dimension: maxDimension,
          })
          ); // Open Snackbar
          return;
        }
      }
      if (!resetOnChange)
        setFileName(file?.name);
    }

    if (handleOnChange) {
      handleOnChange(event);
    }
  };


  function showAlert(errorMessage: string) {
    setErrorMessage(errorMessage);
    setOpenSnackbar(true);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <label style={{ width: '100%' }}>
      <input
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        disabled={disabled}
        multiple
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity='error' style={{ borderRadius: '10px' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <IconButton
        disableRipple
        component='span'
        sx={{ display: 'inline-block', width: '100%' }}
      >
        <FlexRowAlign
          gap={0.5}
          sx={{
            minHeight: 40,
            borderRadius: '8px',
            backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
            display: 'flex',
          }}
        >
          {fileName ? (
            <>
              <FileUploadOutlined sx={{ fontSize: 18, color: disabled ? theme.palette.text.disabled : theme.palette.common.white }} />
              <Small color={disabled ? theme.palette.text.disabled : theme.palette.common.white}>{fileName}</Small>
            </>
          ) : (
            <>
              <FileUploadOutlined sx={{ fontSize: 18, color: disabled ? theme.palette.text.disabled : theme.palette.common.white }} />
              <Small color={disabled ? theme.palette.text.disabled : theme.palette.common.white}>
                {t('inputs.fileUpload.label')}
              </Small>
            </>
          )}
        </FlexRowAlign>
      </IconButton>
    </label >
  );
};

export default FileUploadInput;