import { Alert, Button, Checkbox, FormControlLabel, Grid, Switch, TextField, Typography, styled, useMediaQuery } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2, Tiny } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { DocumentTypeEntity, NetArrayElementEntity } from 'api/generated';
import AppSelectField from 'components/input-fields/MultipleChoiceField';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { documentTypesApi, idDocumentsApi } from 'api';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import AddPaymentModal, { EmployeeEntityWithAmount } from './dataTableV2/AddPaymentModal';

// component props interface
interface UpdateZipProps {
    open: boolean;
    onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const UploadZipModal: FC<UpdateZipProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:499px)');
    const [documentTypes, setDocumentTypes] = useState<DocumentTypeEntity[]>([]);
    const [selectedType, setSelectedType] = useState<DocumentTypeEntity>();
    const [isVisible, setIsVisible] = useState(true);
    const [prefix, setPrefix] = useState('')
    const [selectedFile, setSelectedFile] = useState<File>();
    const [notAssignedFiles, setNotAssignedFiles] = useState<string[]>([]);
    const [notAssignedPayments, setNotAssignedPayments] = useState<string[]>([]);
    const [createdFiles, setCreatedFiles] = useState(0);
    const [error, setError] = useState('');
    const [sentFile, setSentFile] = useState(false);
    const [lookForPayment, setLookForPayment] = useState(false);
    const [employeesPayment, setEmployeesPayment] = useState<EmployeeEntityWithAmount[]>([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [attachFile, setAttachFile] = useState(true);

    useEffect(() => {
        documentTypesApi.findAll().then(({ data }) => {
            setDocumentTypes(data);
        })
    }, [])

    const resetAll = () => {
        setSelectedType(undefined);
        setIsVisible(true);
        setSelectedFile(undefined);
        setSentFile(false);
        setCreatedFiles(0);
        setNotAssignedFiles([]);
        setNotAssignedPayments([]);
        setPrefix('');
        setEmployeesPayment([]);
        setLookForPayment(false);
    }

    const sendFile = (async () => {
        if (selectedType && selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);
                let uploadedZip;
                if (prefix === "") {
                    uploadedZip = (await idDocumentsApi.createFileIfTaxCode(selectedType.name, isVisible.toString(), lookForPayment, sendEmail, attachFile, { data: formData })).data;
                } else {
                    uploadedZip = (await idDocumentsApi.createFileIfTaxCodePrefix(selectedType.name, isVisible.toString(), prefix, lookForPayment, sendEmail, attachFile, { data: formData })).data;
                }
                const notCreated = uploadedZip.badFiles;
                const notPayments = uploadedZip.badPayments;
                const created = uploadedZip.count;
                setEmployeesPayment(uploadedZip.netArray.map((net: NetArrayElementEntity) => {
                    return {
                        amount: net.amount,
                        ...net.employee
                    }
                }))
                setCreatedFiles(created);
                if (notCreated.length > 0) {
                    setNotAssignedFiles(notCreated);
                }
                if (notPayments.length > 0)
                    setNotAssignedPayments(notPayments);
            } catch (e: any) {
                setError(e.message);
            }
            setSentFile(true);
        }
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: (value: boolean) => void) => {
        setValue(event.target.checked);
    };

    function handleTypeChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setSelectedType(documentTypes.find((type) => {
            return type.name === event.target.value;
        }))

    }

    const resultModal = () => {
        if (error !== '') {
            return (

                <AppModal open={sentFile} handleClose={() => {
                    resetAll();
                    onClose();
                }}>
                    <H2 mb={2}>
                        {t('alerts.error')}
                    </H2>
                    <Alert variant="outlined" severity="error">
                        {t('uploadZip.fileFormatError')}
                    </Alert>
                    <Button
                        sx={{ marginTop: 2 }}
                        fullWidth
                        size='small'
                        type='button'
                        variant='contained'
                        onClick={() => {
                            resetAll();
                            onClose();
                        }}
                    >
                        {t('confirm')}
                    </Button>
                </AppModal>

            )
        } else {
            return (

                <AppModal open={sentFile} handleClose={() => {
                    resetAll();
                    onClose();
                }}>
                    <H2 mb={2}>
                        {t('uploadZip.completed')}
                    </H2>
                    {createdFiles > 0 ?
                        <Alert variant="outlined" severity="success">
                            {t('uploadZip.success', { item: createdFiles })}
                        </Alert>
                        : <></>
                    }


                    {notAssignedFiles.length > 0 ?
                        <Alert style={{ marginTop: 15 }} variant="outlined" severity="error">
                            {t('uploadZip.notAssignedMessage')}
                            <ul style={{ marginTop: 1 }}>
                                {notAssignedFiles.map((file) => {
                                    return (<li> {file} </li>);
                                })}
                            </ul>
                        </Alert>
                        : <></>}

                    {notAssignedPayments.length > 0 ?
                        <>
                            <Alert style={{ marginTop: 15 }} variant="outlined" severity="warning">
                                {t('uploadZip.notAssignedPayments')}
                                <ul style={{ marginTop: 1 }}>
                                    {notAssignedPayments.map((file) => {
                                        return (<li> {file} </li>);
                                    })}
                                </ul>
                                {
                                    t('uploadZip.contatctUs')
                                }
                            </Alert>
                        </>
                        : <></>}

                    <Button
                        fullWidth
                        sx={{ marginTop: 2 }}
                        size='small'
                        type='button'
                        variant='contained'
                        onClick={() => {
                            if (lookForPayment && employeesPayment.length > 0) {
                                onClose();
                                setOpenPaymentModal(true);
                            }
                            else {
                                resetAll();
                                onClose();
                            }
                        }}
                    >
                        {lookForPayment && employeesPayment.length > 0 ? t('uploadZip.goToPayments') : t('confirm')}
                    </Button>
                </AppModal>

            )
        }

    }

    return (
        <>
            <AddPaymentModal
                open={openPaymentModal}
                onClose={() => {
                    setOpenPaymentModal(false);
                    resetAll();
                }}
                setTableData={() => { }}
                tableData={undefined}
                employees={employeesPayment}
            />
            <StyledAppModal open={open} handleClose={() => {
                resetAll();
                onClose();
            }}>
                <H2 mb={2}>
                    {t('common.tables.button.UploadZip')}
                </H2>

                <p>
                    {t('uploadZip.description')}
                </p>

                <Tiny>
                    {t('uploadZip.filesFormat')}
                </Tiny>

                <form>
                    <Grid container mt={2}
                        spacing={3}
                        textAlign={isMobile ? "left" : "center"}
                        alignItems="center"
                        justifyItems={"center"}
                        justifyContent={"center"}
                    >
                        <Grid item xs={6}>
                            <AppSelectField
                                InputLabelProps={{ shrink: true }}
                                select
                                variant='outlined'
                                placeholder={t('employees.id.documentTypes.default')}
                                SelectProps={{
                                    native: true,
                                    IconComponent: KeyboardArrowDown,
                                }}
                                value={selectedType?.name}
                                onChange={(e) => {
                                    handleTypeChange(e)
                                }}
                            >
                                <option>{t('employees.id.documentTypes.default')}</option>
                                {documentTypes.map((documentType) => (
                                    <option key={documentType.id} value={documentType.name}>
                                        {documentType.name}
                                    </option>
                                ))}

                            </AppSelectField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('uploadZip.prefix')}
                                value={prefix}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPrefix(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                sx={{ minWidth: '0px' }}
                                label={<Typography textOverflow={'ellipsis'} overflow={'hidden'}>{t('employees.id.documentTypes.isVisible')}</Typography>}
                                labelPlacement='start'
                                control={
                                    <Switch name='isVisibleForUser' checked={isVisible} onChange={(e) => { handleChange(e, setIsVisible) }} />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                sx={{ minWidth: '0px' }}
                                label={<Typography textOverflow={'ellipsis'} overflow={'hidden'}>{t('uploadZip.lookForPayment')}</Typography>}
                                labelPlacement='start'
                                control={
                                    <Switch name='lookForPayment' checked={lookForPayment} onChange={(e) => { handleChange(e, setLookForPayment) }} />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileUploadInput
                                handleOnChange={(e) => {
                                    if (e.target.files) {
                                        setSelectedFile(Array.from(e.target.files)[0]);
                                    }
                                }}
                                resetOnChange={false}
                                disabled={false}
                                multiple={false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                label={t('employees.document.sendEmail')}
                                control={
                                    <Switch
                                        checked={sendEmail && selectedFile !== undefined}
                                        onChange={() => { setSendEmail(!sendEmail) }}
                                        disabled={selectedFile === undefined}

                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                label={t('employees.document.attachFile')}
                                control={
                                    <Checkbox
                                        checked={attachFile && sendEmail && selectedFile !== undefined}
                                        onChange={() => { setAttachFile(!attachFile) }}
                                        disabled={selectedFile === undefined || !sendEmail}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>


                    <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                        <Button fullWidth variant='outlined' onClick={() => {
                            resetAll();
                            onClose();
                        }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                        <Button fullWidth
                            disabled={!selectedFile || !selectedType}
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={sendFile}
                        >
                            {t('common.forms.button.upload')}
                        </Button>
                    </FlexBox>
                </form>
                {resultModal()}



            </StyledAppModal>
        </>
    );
};

export default UploadZipModal;