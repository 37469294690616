import { Button, Grid, styled } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { calendarEntriesShiftTemplateApi, shiftApi } from "api";
import {
    ShiftEntity,
    ShiftTemplateEntity,
    UpdateShiftWithChoiceDtoChoiceEnum,
} from "api/generated";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { enGB, it } from "date-fns/locale";
import { useFormik } from "formik";
import PositionAccordion from "page-sections/shifts/positions-accordion";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ignoreUTC } from "utils/utils";
import * as Yup from "yup";
import AssignPositionToShiftModal from "./AssignPositionsToShiftModal";
import { useSeason } from "contexts/SeasonContext";
import FutureShiftUpdateModal from "./FutureShiftUpdateModal";

export interface ShiftDetailLocal {
    id: number;
    workPositionId: number;
    startTime?: string;
    endTime?: string;
    breakStart?: string | null;
    breakEnd?: string | null;
    _isDeleted?: boolean;
}

interface ModalProps {
    data?: ShiftEntity | null;
    date?: Date;
    open: boolean;
    template: ShiftTemplateEntity;
    onClose: () => void;
    fetchData: () => void;
    setData: (data: ShiftEntity | undefined) => void;
    departmentId: number;
}

const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 1100,
    minWidth: 200,
    [theme.breakpoints.down(325)]: { maxWidth: "100%" },
}));

const AddShiftModal: FC<ModalProps> = ({
    fetchData,
    onClose,
    template,
    open,
    data,
    setData,
    departmentId,
    date,
}) => {
    const { t } = useTranslation();
    const { seasonId } = useSeason();

    const [shiftDetails, setShiftDetails] = useState<ShiftDetailLocal[]>([]);
    const [openAssignPositionToShift, setOpenAssignPositionToShift] = useState(false);
    const [openEditOccurrenceModal, setOpenEditOccurrenceModal] = useState(false);
    const [assignments, setAssignments] = useState<ShiftEntity[]>([]);

    const initialValues = {
        name: "",
        startTime: new Date(),
        endTime: new Date(),
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        resetForm,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .trim()
                .min(3, t("common.forms.field.min", { field: t("shiftTemplate.name"), min: 3 }))
                .required(t("common.forms.field.required", { field: t("shiftTemplate.name") })),
            startTime: Yup.date()
                .typeError(t("employees.agreements.validation.typeError"))
                .required(t("common.forms.field.required", { field: t("employees.agreements.field.date") })),
            endTime: Yup.date()
                .typeError(t("employees.agreements.validation.typeError"))
                .required(t("common.forms.field.required", { field: t("employees.agreements.field.date") })),
        }),
        onSubmit: async (values) => {
            if (!data) {
                await shiftApi
                    .create({
                        name: values.name,
                        startTime: ignoreUTC(values.startTime).toISOString(),
                        endTime: ignoreUTC(values.endTime).toISOString(),
                        templateId: template.id,
                        departmentId,
                        seasonId,
                    })
                    .then(() => {
                        fetchData();
                        onClose();
                    });
            } else {
                setOpenEditOccurrenceModal(true);
            }
        },
    });
    useEffect(() => {
        if (data) {
            setFieldValue("name", data.name, true);
            setFieldValue("startTime", ignoreUTC(new Date(data.startTime), true));
            setFieldValue("endTime", ignoreUTC(new Date(data.endTime), true));

            const localDetails: ShiftDetailLocal[] = (data.shiftDetails ?? []).map((d) => ({
                id: d.id,
                workPositionId: d.workPositionId,
                startTime: d.startTime,
                endTime: d.endTime,
                breakStart: d.breakStart ?? null,
                breakEnd: d.breakEnd ?? null,
                _isDeleted: false,
            }));
            setShiftDetails(localDetails);

        if (date) {
            calendarEntriesShiftTemplateApi.findByDate(
                date.toISOString(),
                departmentId,
                seasonId
            ).then((res) => {
                const assignments =res.data.shiftTemplate?.shifts?.filter((shift) => shift.id === data?.id );                
                if (assignments) {
                    setAssignments(assignments);                    
                }
            });

        }
        } else {
            setFieldValue("name", initialValues.name, true);
            setFieldValue("startTime", initialValues.startTime, true);
            setFieldValue("endTime", initialValues.endTime, true);
            setShiftDetails([]);
        }
    }, [data]);

    const handleAddResource = (workPositionId: number, resourceCount: number) => {
        const newDetails: ShiftDetailLocal[] = [];

        for (let i = 0; i < resourceCount; i++) {
            newDetails.push({
                id: 0,
                workPositionId,
                startTime: undefined,
                endTime: undefined,
                breakStart: null,
                breakEnd: null,
                _isDeleted: false,
            });
        }

        setShiftDetails((prev) => [...prev, ...newDetails]);
    };

    const fetchShiftAssigns = async () => {
        if (!data) return;
        const result = (await shiftApi.findShiftDetails(data.id, departmentId, seasonId)).data;
        const localDetails: ShiftDetailLocal[] = result.map((d) => ({
            id: d.id,
            workPositionId: d.workPositionId,
            startTime: d.startTime,
            endTime: d.endTime,
            breakStart: d.breakStart ?? null,
            breakEnd: d.breakEnd ?? null,
            _isDeleted: false,
        }));
        setShiftDetails(localDetails);
        data.shiftDetails = result;
    };

    const submitData = async (choice?: UpdateShiftWithChoiceDtoChoiceEnum) => {
        if (!data) {
            onClose();
            return;
        }

        const finalShiftData = {
            name: values.name,
            startTime: ignoreUTC(values.startTime).toISOString(),
            endTime: ignoreUTC(values.endTime).toISOString(),
        };

        let anyError = false;
        for (const detail of shiftDetails) {
            if (!detail._isDeleted) {
                const hasBreakStart = !!detail.breakStart;
                const hasBreakEnd = !!detail.breakEnd;
                if ((hasBreakStart && !hasBreakEnd) || (!hasBreakStart && hasBreakEnd)) {
                    anyError = true;
                }
            }
        }
        if (anyError) return;

        try {
            
            // Build the array, converting undefined => null
            const detailsToSend = shiftDetails.map((d) => ({
                id: d.id,
                workPositionId: d.workPositionId,
                startTime: d.startTime ?? null,
                endTime: d.endTime ?? null,
                breakStart: d.breakStart ?? null,
                breakEnd: d.breakEnd ?? null,
                _isDeleted: d._isDeleted ?? false,
            }));

            await shiftApi.updateShiftAndDetailsWithChoice(data.id, {
                choice: choice ?? "all", // 'single'|'all'|'future'
                date: date?.toISOString() ?? new Date().toISOString(),
                departmentId,
                seasonId,
                updateShiftDto: finalShiftData,
                updateShiftDetails: detailsToSend,
            });

            fetchData();
            onClose();
            resetForm();
        } catch (err) {
            console.error("Failed to update shift or details in one request:", err);
        }
    };
    const handleOccurrenceConfirm = async (choice: "single" | "all" | "future") => {
        await submitData(choice);
        setOpenEditOccurrenceModal(false);
    };

    const openShiftAssign = async () => {
        if (!data) {
            await shiftApi
                .create({
                    name: values.name,
                    startTime: ignoreUTC(values.startTime).toISOString(),
                    endTime: ignoreUTC(values.endTime).toISOString(),
                    templateId: template.id,
                    seasonId,
                    departmentId,
                })
                .then((res) => {
                    fetchData();
                    setData(res.data);
                });
        }
        setOpenAssignPositionToShift(true);
    };

    const handleTimeChange = (isStart: boolean, value: Date | null) => {
        if (value && !isNaN(+value)) {
            value.setSeconds(0, 0);
            setFieldValue(isStart ? "startTime" : "endTime", value, true);
        } else {
            setFieldValue(isStart ? "startTime" : "endTime", undefined, true);
        }
    };

    const timePicker = typeof window !== "undefined" ? document.getElementById("startTime") : null;

    return (
        <>
            <StyledAppModal open={open} handleClose={onClose}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems={"stretch"} display={"flex"}>
                        <Grid item xs={12} display={"flex"} mb={2}>
                            <H2>
                                {data
                                    ? t("common.forms.button.edit") + " " + t("shift.itemName")
                                    : t("common.forms.addItemLabel", {
                                        item: t("shift.itemName"),
                                    })}
                            </H2>
                        </Grid>
                        <Grid item xs={4}>
                            <AppTextField
                                InputProps={{
                                    style: {
                                        height: timePicker?.clientHeight ?? 56,
                                        display: "flex",
                                        alignSelf: "flex-end",
                                    },
                                }}
                                sx={{ display: "flex" }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                placeholder=""
                                size="small"
                                name="name"
                                label={t("shiftTemplate.name")}
                                value={values.name}
                                onChange={handleChange}
                                error={Boolean(errors.name && touched.name)}
                                helperText={(touched.name && errors.name) as string}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                <TimePicker
                                    label={t("shift.startTime")}
                                    value={values.startTime}
                                    onChange={(val) => { handleTimeChange(true, val) }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!errors.startTime,
                                            id: "startTime",
                                            helperText: errors.startTime as string,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                                <TimePicker
                                    label={t("shift.endTime")}
                                    value={values.endTime}
                                    onChange={(val) => { handleTimeChange(false, val) }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!errors.endTime,
                                            helperText: errors.endTime as string,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <PositionAccordion
                        assignments={assignments}
                        data={shiftDetails}
                        setData={setShiftDetails}
                        openAddShiftDetail={openShiftAssign}
                        turnStart={values.startTime}
                        turnEnd={values.endTime}
                        departmentId={departmentId}
                    />
                    {data && (
                        <AssignPositionToShiftModal
                            onAddResource={handleAddResource}
                            fetchData={fetchShiftAssigns}
                            departmentId={template.departmentId}
                            shiftId={data.id}
                            open={openAssignPositionToShift}
                            onClose={() => { setOpenAssignPositionToShift(false) }}
                        />
                    )}
                    <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
                        <Button
                            fullWidth
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                onClose();
                                resetForm();
                            }}
                        >
                            {t("common.forms.button.cancel")}
                        </Button>
                        <Button fullWidth size="small" type="submit" variant="contained">
                            {t("common.forms.button.save")}
                        </Button>
                    </FlexBox>
                </form>
            </StyledAppModal>
            <FutureShiftUpdateModal
                open={openEditOccurrenceModal}
                selectedDate={date}
                onClose={() => { setOpenEditOccurrenceModal(false) }}
                onConfirm={handleOccurrenceConfirm}
            />
        </>
    );
};

export default AddShiftModal;
