import { ShiftTemplateEntity } from "api/generated";
import { Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import DataTable from "./DataTable";
import { Button } from "@mui/material";

interface DataTableProps {
    data?: any[];
    handleRowSelect: (rows: []) => void;
    editShiftTemplateCallback: (data?: ShiftTemplateEntity | null) => void;
}



const ShiftTemplateDataTable: FC<DataTableProps> = (props) => {

    const { t } = useTranslation()
    const columns: any = useMemo(() => getColumnShape(), [t]);


    function handleModifyShiftTemplate(shiftTemplateData: ShiftTemplateEntity) {
        props.editShiftTemplateCallback(shiftTemplateData);
    };


    function getColumnShape() {
        return [
            {
                minWidth: 50,
                Header: t('season.name'),

                Cell: ({ row }: CellProps<ShiftTemplateEntity>) => {
                    const { name } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{name}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                maxWidth: 100,
                Header: t('shiftTemplate.description'),
                Cell: ({ row }: CellProps<ShiftTemplateEntity>) => {
                    const { description } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{description}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                Header: ' ',
                Cell: ({ row }: CellProps<ShiftTemplateEntity>) => {
                    return (
                        <Button
                            onClick={() => { handleModifyShiftTemplate(row.original) }}
                        >
                            {t('common.tables.button.editWithItem', {
                                item: t('shiftTemplate.itemName')
                            })}
                        </Button>
                    );
                },
            },

        ]
    }

    return DataTable({
        ...props,
        columns,
    });

}
export default ShiftTemplateDataTable;

