import { Grid, IconButton, Tooltip, Typography, styled } from "@mui/material";
import { idDocumentsApi } from "api";
import { DocumentFileEntity } from "api/generated";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import FileUploadInput from "components/input-fields/FileUploadInput";
import DeleteIcon from "icons/DeleteIcon";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import UploadingOverlay from "components/UploadOverlay";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { imagesToPDF } from '@coderosh/images-to-pdf'
import { filetypeextension } from 'magic-bytes.js'
import CustomToast from "components/CustomToast/customToast";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PDFMerger from "pdf-merger-js";

interface ModalProps {
    open: boolean;
    documentId: number;
    documentName: string;
    onClose: () => void;
    data?: DocumentFileEntity[];
    setData: (files: DocumentFileEntity[]) => void;
    onDocumentRemove: (id: number, index: number) => void;
    setIsLoading?: (isLoading: boolean) => void;
    isLoading?: boolean;
    setShowToastCallback?: (param: {
        show: boolean;
        message: string;
        type: string;
    }) => void;
    edit?: boolean;
    isCompiledByEmployee?: boolean,
    userMode: boolean
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

// valid images formats for pdf conversion
const validFormats = ['jpeg', 'png', 'webp', 'bmp']



const DocumentFilesModal: FC<ModalProps> = ({ open, onClose, setData, data, documentId, documentName, onDocumentRemove, edit, setIsLoading, isLoading, setShowToastCallback, isCompiledByEmployee, userMode }) => {
    const { t } = useTranslation();
    const [showedData, setShowedData] = useState(data);
    const [imageFiles, setImageFiles] = useState<ArrayBuffer[]>([]);
    const [pdfFiles, setPdfFiles] = useState<string[]>([]);
    const [showToast, setShowToast] = useState<{ show: boolean, message: string, icon: any }>({ show: false, message: '', icon: undefined });

    useEffect(() => {
        if (data && data.length > 0) {
            setShowedData(data);
            fetchImages();
        }
        else
            onClose();
    }, [data]);

    const fetchImages = async () => {
        const mappedData: ArrayBuffer[] = [];
        const pdfUrls: string[] = [];
        if (data) {
            for (const file of data) {
                const img = await fetch(file?.filePath).then(async (res) => res.arrayBuffer());
                const fileType = filetypeextension(new Uint8Array(img));
                if (fileType.filter(type => validFormats.includes(type)).length > 0)
                    mappedData.push(img);
                else if (fileType.includes('pdf'))
                    pdfUrls.push(file.filePath);
            }
        }
        setImageFiles(mappedData);
        setPdfFiles(pdfUrls);
    }

    const addDocumentFile = async (files: File[]) => {
        const formData = new FormData();
        files.forEach(file => { formData.append('files', file) })
        if (documentId) {
            if (setIsLoading)
                setIsLoading(true);

            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const tenant = urlParams.get('tenant');

            const handleResponse = () => {
                if (setIsLoading)
                    setIsLoading(false);
                if (setShowToastCallback)
                    setShowToastCallback({ show: true, message: t('alerts.fileEntred'), type: 'Created' });
            }

            const newDocument = !isCompiledByEmployee ? await idDocumentsApi.createFile(
                String(documentId),
                false,
                false,
                {
                    data: formData
                }) : tenant && token &&
            await idDocumentsApi.createFileWithToken(String(documentId), tenant, {
                data: formData,
                params: {
                    token
                }
            });

            handleResponse();

            if (newDocument) {
                if (data)
                    setData(data.concat(newDocument.data));
                else
                    setData(newDocument.data);
            }
        }
    }

    const generatePDF = async () => {
        try {
            const merger = new PDFMerger();
            // if there is at least an image
            if (imageFiles.length > 0) {
                const pdf = await imagesToPDF(imageFiles);
                await merger.add(pdf.arrayBuffer());
            }
            // concat every pdf file
            for (const filePath of pdfFiles)
                await merger.add(filePath);
            // download file
            merger.save(documentName + '_joined.pdf');

            // if there are files that are not recognised images or pdf files show a warning
            if (showedData && showedData.length !== (imageFiles.length + pdfFiles.length))
                setShowToast({ message: t('alerts.fileMergeWarning'), show: true, icon: <WarningAmberIcon /> })
        }
        catch (e) {
            setShowToast({ message: t('alerts.fileMergeError'), show: true, icon: undefined })

            console.error(e);
        }

    }

    return (
        <StyledAppModal
            open={open}
            handleClose={onClose}
        >
            {showToast.show &&
                <CustomToast
                    duration={8000}
                    onClose={() => {
                        setShowToast({ show: false, message: '', icon: undefined })
                    }}
                    message={showToast.message}
                    icon={showToast.icon}
                    color={showToast.icon ? 'warning' : undefined}
                />
            }

            {isLoading &&
                <UploadingOverlay />
            }

            {showedData && showedData.length > 0 && (
                <Grid container columns={9} alignItems={'center'}>
                    <Grid item xs={3} textAlign={'center'}>
                        <Tooltip title={t('documents.downloadAsPDF')}>
                            <IconButton disabled={imageFiles.length === 0 && pdfFiles.length === 0} onClick={generatePDF}><PictureAsPdfIcon /></IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}><Typography fontWeight={'bold'} textAlign={'center'}>File</Typography></Grid>
                    <Grid item xs={3} textAlign={'center'}><IconButton onClick={onClose}><CloseIcon /></IconButton></Grid>
                </Grid>
            )}

            {showedData ?
                (<>
                    {showedData.map((file, index) => {
                        return (
                            <Grid container key={file.id} paddingY={1} justifyContent={'space-evenly'} borderBottom={1} borderColor={"lightgray"}>
                                <Grid container columns={9} alignItems={'center'} textAlign={'center'}>
                                    <Grid item xs={3}>
                                        File {index + 1}:
                                    </Grid>
                                    <Grid item xs={3} textAlign={'center'}>
                                        <a target='_blank' rel='noopener noreferrer' style={{ width: '100%' }} href={file?.filePath}>
                                            {t('employees.contract.contractFile')}
                                        </a>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <FlexBox justifyContent={'center'}>
                                            {edit ?? (
                                                !userMode &&
                                                <IconButton>
                                                    <DeleteIcon
                                                        sx={{ alignSelf: 'center', display: 'flex', justifySelf: 'center' }}
                                                        color="error"
                                                        onClick={() => {
                                                            onDocumentRemove(file.id, index);
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',

                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </FlexBox>

                                    </Grid>
                                </Grid>

                            </Grid>
                        )
                    })}
                </>
                ) :
                (<></>)}
            <Grid xs={12} columns={9} container justifyItems={'center'} paddingTop={1}>
                <Grid item xs={3} />
                {edit ?? (
                    <>
                        <Grid item xs={3} textAlign={'center'}>
                            <FileUploadInput
                                resetOnChange
                                maxDimension={100}
                                handleOnChange={(e) => {
                                    if (e.target.files)
                                        addDocumentFile(Array.from(e.target.files));
                                }} />
                        </Grid>
                    </>
                )}
                <Grid item xs={3} />
            </Grid>
        </StyledAppModal>
    )
}

export default DocumentFilesModal;