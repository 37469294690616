import { Autocomplete, Button, MenuItem, styled, TextField } from "@mui/material";
import { statusChangeApi } from "api";
import { EmployeeToSeasonEntityStatusTypeEnum, StatusEntity } from "api/generated";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import CalendarInput from "components/input-fields/CalendarInput";
import FileUploadInput from "components/input-fields/FileUploadInput";
import { H2, Small } from "components/Typography";
import { useSeason } from "contexts/SeasonContext";
import { isSameDay } from "date-fns";
import DeleteIcon from "icons/DeleteIcon";
import { FC, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ignoreUTC, translateStatus } from "utils/utils";


// component props interface
interface ModalProps {
    open: boolean;
    onClose: () => void;
    selectedStatus?: StatusEntity | null;
    handleStatusChange: (status: StatusEntity | null, oldStatus: StatusEntity, formData: any, date?: Date) => Promise<void>;
    deleteStatus: (id: number) => void;
    optionsWithButton: StatusEntity[];
    employeeId: number
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 450,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));



const StatusChangeNoteModal: FC<ModalProps> = ({
    open,
    onClose,
    selectedStatus,
    deleteStatus,
    handleStatusChange,
    optionsWithButton,
    employeeId
}) => {
    const { t } = useTranslation();

    const [note, setNote] = useState<string>();

    const [selectedFile, setSelectedFile] = useState<File>();

    const [newStatus, setNewStatus] = useState(selectedStatus);

    const [dateStatus, setDateStatus] = useState(selectedStatus);

    const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

    const { seasonId } = useSeason();

    const now = ignoreUTC(new Date());

    const [date, setDate] = useState<Date>()


    const StatusTextField = styled(TextField)({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: newStatus?.color,
            },
        },
    });

    const onSubmit = async (note?: string, file?: File) => {

        const formData = new FormData();
        if (file)
            formData.append('file', file);
        formData.append('updateStatusChangeDto', JSON.stringify({
            note
        }));
        if (dateStatus && newStatus && (newStatus.id !== dateStatus?.id || newStatus.name !== dateStatus.name)) {
            try {
                await handleStatusChange(newStatus, dateStatus, formData, date);
                toast.success(t('alerts.success'))
                handleClose();
            }
            catch (e: any) {

                if (e?.response?.status === 400) {
                    toast.error(t('status.changeExists'));
                }
            }

        }
    }

        const handleClose = () => {
            onClose();
            setNote(undefined);
            setSelectedFile(undefined);
            setNewStatus(selectedStatus);
            setDate(undefined);
            setAutoCompleteOpen(false);
        }

        useEffect(() => {
            setNewStatus(dateStatus);
        }, [dateStatus]);

        const fetchDateStatus = async () => {
            if (!date)
                setDateStatus(selectedStatus);
            else {
                const statusByDate = (await statusChangeApi.findByDate(
                    {
                        params: {
                            employeeId,
                            seasonId,
                            date: date.toISOString()
                        }
                    }
                )).data as any as {
                    statusId: number | null,
                    statusType: string
                } | undefined;
                if (!statusByDate)
                    setDateStatus(selectedStatus);
                else {
                    if (statusByDate.statusId)
                        setDateStatus(optionsWithButton.find(x => x.id === statusByDate.statusId));
                    else {
                        const translatedStatus = translateStatus(statusByDate.statusType.toUpperCase() as EmployeeToSeasonEntityStatusTypeEnum);
                        setDateStatus({
                            id: -1,
                            color: translatedStatus.color,
                            name: statusByDate.statusType.toUpperCase()
                        })
                    }
                }
            }
        }

        useEffect(() => {
            fetchDateStatus();
        }, [date])

        return (
            <>
                <Toaster />
                <StyledAppModal open={open} handleClose={() => {
                    handleClose();
                }}>


                    <H2 mb={2}>
                        {t('account.basicInformation.setStatus')}
                    </H2>

                    <Autocomplete
                        sx={{
                            mb: 2,
                        }}
                        options={optionsWithButton}
                        size="small"
                        disableClearable
                        getOptionLabel={(option) => option.id === 0 ? t('status.addNew') : option.name}
                        value={newStatus ? newStatus.id === -1 ? {
                            id: -1,
                            ...translateStatus(newStatus.name as any)
                        } : newStatus : { name: '', color: '', id: 0 }}
                        isOptionEqualToValue={(option, value) => {
                            if (value?.id === -1 && value.name === option.name) {
                                return true;
                            }
                            else {
                                return value?.id === option.id;
                            }
                        }}
                        open={autoCompleteOpen}
                        onOpen={() => { setAutoCompleteOpen(true) }}
                        onClose={() => { setAutoCompleteOpen(false) }}
                        renderOption={(option, value) => (
                            <MenuItem onClick={() => { setNewStatus(value); setAutoCompleteOpen(false) }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <span style={{
                                        whiteSpace: value.name.includes(' ') ? 'pre-wrap' : 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '80%',
                                    }}>
                                        {value.id !== 0 && (
                                            <Small
                                                sx={{
                                                    backgroundColor: value.color,
                                                    borderRadius: '50%',
                                                    width: '0.7rem',
                                                    height: '0.7rem',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                    marginRight: '0.5rem'
                                                }}
                                            />
                                        )}
                                        {value.id === -1 ? translateStatus(value.name as EmployeeToSeasonEntityStatusTypeEnum).name : value.name}
                                    </span>
                                    {value.id !== 0 && value.id !== -1 && (
                                        <DeleteIcon
                                            color="error"
                                            style={{ cursor: 'pointer' }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                deleteStatus(value.id);
                                            }}
                                        />
                                    )}
                                </div>
                            </MenuItem>
                        )}

                        renderInput={(params) => <StatusTextField {
                            ...params}

                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                readOnly: true,

                            }}
                        />
                        }
                    />



                    <AppTextField
                        fullWidth
                        value={note}
                        onChange={(e) => {
                            setNote(e.target.value)
                        }}
                        multiline
                        rows={5}
                        sx={{ mb: 2 }}
                        placeholder={t('status.changeNote')}
                    />

                    <FileUploadInput

                        handleOnChange={(e) => {
                            if (e.target.files) {
                                setSelectedFile(Array.from(e.target.files)[0]);
                            }
                        }}
                    />

                    <CalendarInput
                        sx={{
                            mt: 2
                        }}
                        format='dd.MM.yyyy'
                        onChange={(date) => {
                            if (date) {
                                setDate(isSameDay(date, now) ? undefined : date);
                            }
                        }}
                        value={date ?? now}
                        disablePast={true}
                    />



                    <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                        <Button fullWidth variant='outlined' onClick={() => {
                            handleClose()
                        }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                        <Button fullWidth
                            size='small'
                            type='button'
                            variant='contained'
                            disabled={newStatus?.id === dateStatus?.id && newStatus?.name === dateStatus?.name}
                            onClick={() => {
                                if (dateStatus)
                                    onSubmit(note, selectedFile);
                            }}
                        >
                            {t('save')}
                        </Button>
                    </FlexBox>



                </StyledAppModal>
            </>
        );
    };

    export default StatusChangeNoteModal;
