import { Button, Grid, styled } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2 } from 'components/Typography';
import { useFormik } from 'formik';
import { useEffect, useState, type FC } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AppTextField from 'components/input-fields/AppTextField';
import { Toaster } from 'react-hot-toast';
import { DepartmentEntity, PublicDocumentEntity, SeasonEntity, SegmentEntity } from 'api/generated';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import SelectTable from 'page-sections/public-document/selectTableForModal';
import { useSeason } from 'contexts/SeasonContext';

// component props interface
interface ModalProps {
    open: boolean;
    onClose: () => void;
    departments: DepartmentEntity[];
    segments: SegmentEntity[];
    onSumbit: (data: any, file: File) => void,
    onUpdate: (data: any) => void,
    data?: PublicDocumentEntity
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 800,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddPublicDocumentModal: FC<ModalProps> = ({
    open,
    onClose,
    onSumbit,
    onUpdate,
    segments,
    data,
    departments
}) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File>();
    const [selectedDepartments, setSelectedDepartments] = useState<DepartmentEntity[]>([]);
    const [allDepartments, setAllDepartments] = useState(true);
    const [selectedSegments, setSelectedSegments] = useState<SegmentEntity[]>([]);
    const [allSegments, setAllSegments] = useState(true);
    const [selectedSeasons, setSelectedSeasons] = useState<SeasonEntity[]>([]);
    const [allSeasons, setAllSeasons] = useState(true);
    const { seasons } = useSeason();

    useEffect(() => {
        if (data)
            setData();
        else
            resetData();
    }, [open, data]);

    const resetData = () => {
        setSelectedDepartments([]);
        setAllDepartments(true);
        setSelectedSegments([])
        setAllSegments(true);
        setSelectedSeasons([]);
        setAllSeasons(true);
        resetForm();
        setFile(undefined);
    }

    const setData = () => {
        setSelectedDepartments(data?.departments ?? []);
        setAllDepartments(!((data?.departments?.length ?? 0) > 0));
        setSelectedSegments(data?.segments ?? [])
        setAllSegments(!((data?.segments?.length ?? 0) > 0));
        setSelectedSeasons(data?.seasons ?? []);
        setAllSeasons(!((data?.seasons?.length ?? 0) > 0));

    }



    const setSelectedDepartmentsFunction = (updatedDepartments: DepartmentEntity[]) => {
        if (updatedDepartments.find(x => x.id === 0)) {
            setAllDepartments(!allDepartments);
            setSelectedDepartments([]);
        }
        else {
            setSelectedDepartments(updatedDepartments);

        }
    }

    const setSelectedSeasonsFunction = (updatedSeasons: SeasonEntity[]) => {
        if (updatedSeasons.find(x => x.id === 0)) {
            setAllSeasons(!allSeasons);
            setSelectedSeasons([]);
        }
        else {
            setSelectedSeasons(updatedSeasons);

        }
    }

    const setSelectedSegmetnsFunction = (updatedSegments: SegmentEntity[]) => {
        if (updatedSegments.find(x => x.id === 0)) {
            setAllSegments(!allSegments);
            setSelectedSegments([]);
        }
        else {
            setSelectedSegments(updatedSegments);
            /*    const concatDepartments = departments.filter(department => !selectedDepartments.includes(department) && updatedSegments.map(x => x.id).includes(department.segmentId))
                if (concatDepartments.length > 0) {
                    setAllDepartments(false);
                    setSelectedDepartments([...selectedDepartments.concat(concatDepartments)]);
                } 
            */
            // remove departments that are altready selected as part of the selected segments
            const filtredDepartments = [...selectedDepartments.filter(department => !updatedSegments.map(s => s.id).includes(department.segmentId))];
            setSelectedDepartments(filtredDepartments);
        }
    }



    const initialValues = {
        name: ''
    };

    const fieldValidationSchema = Yup.object().shape({
        name: !data ? Yup.string().required(t('value.notValid')) : Yup.string()
    });

    const handleClose = () => {
        resetForm();
        onClose();
    }

    const { handleSubmit, resetForm, setFieldValue, touched, errors } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        onSubmit: (values) => {
            if (file && !data) {
                onSumbit({
                    name: values.name,
                    departmentIds: !allDepartments ? selectedDepartments.map(x => x.id) : undefined,
                    segmentIds: !allSegments ? selectedSegments.map(x => x.id) : undefined,
                    seasonIds: !allSeasons ? selectedSeasons.map(x => x.id) : undefined,

                } as any, file);
                onClose()
            }
            else if (data) {
                onUpdate(
                    {
                        departmentIds: !allDepartments ? selectedDepartments.map(x => x.id) : [],
                        segmentIds: !allSegments ? selectedSegments.map(x => x.id) : [],
                        seasonIds: !allSeasons ? selectedSeasons.map(x => x.id) : [],

                    } as any
                );
                onClose();
            }
        },
    });

    return (
        <StyledAppModal open={open} handleClose={() => {
            handleClose();
        }}>
            <Toaster />



            <form onSubmit={handleSubmit}>
                <Grid container alignItems="center" spacing={2}>
                    {
                        !data &&
                        <>

                            <Grid item xs={12} justifyContent={'center'}>
                                <H2>
                                    {t('common.tables.button.addItem', { item: t('employees.contract.contractFile'), })}
                                </H2>
                            </Grid>
                            <Grid item xs={6} justifyContent={'center'}>
                                <AppTextField
                                    label={t('status.name')}
                                    fullWidth
                                    size='small'
                                    inputProps={{
                                        min: 0,
                                        step: 0.01,
                                    }}
                                    onChange={(e) => {

                                        setFieldValue('name', e.target.value);
                                    }}
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FileUploadInput
                                    maxDimension={30}
                                    handleOnChange={(e) => {
                                        if (e.target.files)
                                            setFile(e.target.files[0]);
                                    }}
                                />
                            </Grid>
                        </>


                    }




                    <Grid item xs={4} alignSelf={'flex-start'}>
                        <SelectTable
                            data={[{ id: 0, name: t('common.all') }].concat(segments)}
                            selectedData={selectedSegments}
                            setSelectedData={setSelectedSegmetnsFunction}
                            allOptionsSelected={allSegments}
                            header={t('department.segments')}
                        />
                    </Grid>

                    <Grid item xs={4} alignSelf={'flex-start'}>
                        <SelectTable
                            data={[{ id: 0, name: t('common.all') }].concat(departments)}
                            selectedData={selectedDepartments}
                            setSelectedData={setSelectedDepartmentsFunction}
                            allOptionsSelected={allDepartments}
                            selectedSegmentIds={selectedSegments.map(s => s.id)}
                            header={t('departments.collectionName')}
                        />
                    </Grid>
                    
                    <Grid item xs={4} alignSelf={'flex-start'}>
                        <SelectTable
                            data={[{ id: 0, name: t('common.all') }].concat(seasons)}
                            selectedData={selectedSeasons}
                            setSelectedData={setSelectedSeasonsFunction}
                            allOptionsSelected={allSeasons}
                            header={t('menu.management.seasons')}
                        />
                    </Grid>

                </Grid>
                <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                    <Button fullWidth size='small' variant='outlined' onClick={() => {
                        handleClose();
                    }}>
                        {t('common.forms.button.cancel')}
                    </Button>
                    <Button fullWidth size='small' type='submit' variant='contained' disabled={!file && !data}>
                        {t('common.forms.button.save')}
                    </Button>
                </FlexBox>
            </form>
        </StyledAppModal>
    );
};

export default AddPublicDocumentModal;