import { Accordion, AccordionSummary, Grid, AccordionDetails, Button } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { PublicDocumentEntity } from "api/generated";
import { H3 } from "components/Typography";
import DeleteIcon from "icons/DeleteIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Base {
    id: number,
    name: string
}


interface Props {
    sections: Base[],
    sectionRecords: Record<number, PublicDocumentEntity[]>,
    isManager: boolean,
    handleOpenconfirmDeleteCallback: (id: number) => void,
    updateCallBack: (data: PublicDocumentEntity) => void
}

const RecordAccordion: FC<Props> = ({ sections, sectionRecords, isManager, handleOpenconfirmDeleteCallback, updateCallBack }) => {
    const { t } = useTranslation();
    return (
        <>
            {
                sections.map((section) => {
                    if (sectionRecords[section.id]?.length > 0)
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls={section.name + '-content'}
                                    id={section.name + '-header'}
                                >
                                    <Grid container alignContent={'center'} padding={"3px"} >

                                        <Grid item zeroMinWidth >
                                            <H3
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                    overflow: 'hidden',
                                                    minWidth: '0',
                                                    maxWidth: '100%', // Ensures the text does not exceed the parent width
                                                    display: 'flex',
                                                    padding: '5px 10px',
                                                }}>
                                                {section.name}
                                            </H3>
                                        </Grid>

                                    </Grid>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>

                                        {
                                            sectionRecords[section.id].map((record) => {
                                                return (
                                                    <Grid container item xs={12} paddingY={1} sx={{ borderTop: 1, borderColor: "lightgray", }}>
                                                        <Grid item xs={7} sm={2}>
                                                            <a
                                                                style={{ paddingLeft: '20px', paddingTop: '5px' }}
                                                                href={record.filePath}>
                                                                {record.name}
                                                            </a>
                                                        </Grid>
                                                        {
                                                            isManager &&
                                                            <>
                                                                <Grid item xs={3}>
                                                                    <Button onClick={() => {
                                                                        updateCallBack(record)
                                                                    }}>
                                                                        {t('publicDocuments.update')}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <DeleteIcon
                                                                        sx={{ alignSelf: 'center', display: 'flex' }}
                                                                        color="error"
                                                                        onClick={() => {

                                                                            handleOpenconfirmDeleteCallback(record.id);
                                                                        }}
                                                                        style={{
                                                                            cursor: 'pointer',

                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    else
                        return <></>

                })
            }
        </>
    )
}

export default RecordAccordion;