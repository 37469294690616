import { Button, Grid } from "@mui/material";
import { departmentsApi, publicDocumentsApi, segmentApi } from "api";
import { DepartmentEntity, PublicDocumentEntity, SegmentEntity, UpdatePublicDocumentDto } from "api/generated";
import { useSeason } from "contexts/SeasonContext";
import AddPublicDocumentModal from "page-sections/data-table/dataTableV2/AddPublicDocumentModal";
import PublicDocumentAccordion from "page-sections/public-document/publicDocumentAccordion";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkTokenRole } from "utils/checkToken";
import AddIcon from '@mui/icons-material/Add';


const PublicDocuments: FC = () => {

    const [segments, setSegments] = useState<SegmentEntity[]>([]);
    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);
    const [documents, setDocuments] = useState<PublicDocumentEntity[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [updatingDocument, setUpdatingDocument] = useState<PublicDocumentEntity>();
    const { seasonId, seasons } = useSeason();

    const { t } = useTranslation();
    const role = checkTokenRole();
    const isManager = role === 'MANAGER' || role === 'OWNER' || role === 'DEVELOPER';

    const fetchSegments = async () => {
        if (role === 'USER' || role === 'DEPCHIEF')
            setSegments((await segmentApi.findByUser(seasonId)).data);
        else
            setSegments((await segmentApi.findAll()).data);
    }


    const fetchDepartments = async () => {
        if (role === 'USER' || role === 'DEPCHIEF')
            setDepartments(((await departmentsApi.findByRole(seasonId)).data))
        else
            setDepartments((await departmentsApi.findAll()).data);
    }

    const fetchData = async () => {
        if (role === 'USER' || role === 'DEPCHIEF')
            setDocuments(((await publicDocumentsApi.findByUser()).data))
        else
            setDocuments(((await publicDocumentsApi.findAll()).data))
    }

    const createDocument = async (data: any, file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('createPublicDocumentDto', JSON.stringify(data))
        await publicDocumentsApi.create({
            data: formData

        });
        await fetchData();
    }

    const updateVisibility = async (data: UpdatePublicDocumentDto) => {
        if (updatingDocument)
            await publicDocumentsApi.update(updatingDocument.id, data);
        await fetchData();
    }

    useEffect(() => {
        fetchData();
        fetchDepartments();
        fetchSegments();
    }, []);

    const openUpdateVisibility = (data: PublicDocumentEntity) => {
        setUpdatingDocument(data);
        setOpenModal(true);
    }


    return (
        <div>
            <AddPublicDocumentModal
                departments={departments}
                segments={segments}
                onClose={() => { setOpenModal(false); setUpdatingDocument(undefined) }}
                onSumbit={createDocument}
                data={updatingDocument}
                onUpdate={updateVisibility}
                open={openModal}
            />


            <Grid container justifyContent={'flex-end'}>
                {isManager &&
                    <Grid item xs={6} justifyContent={'right'} display={'flex'} >
                        <Button variant="contained"
                            color="primary"
                            sx={{ display: 'flex' }}
                            startIcon={<AddIcon />}
                            onClick={() => { setOpenModal(true) }}>
                            {t('employees.contract.contractFile')}
                        </Button>

                    </Grid>
                }
                <Grid item xs={12}>
                    <PublicDocumentAccordion
                        departments={departments}
                        documents={documents}
                        seasons={seasons}
                        segments={segments}
                        isManager={isManager}
                        fetchDataCallBack={fetchData}
                        updateCallBack={openUpdateVisibility}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default PublicDocuments;